import { useQuery } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { fetchBusinessEventPlans } from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import {
  getActiveTasks,
  getCompletedTasks,
} from 'contexts/redux/task/taskSlice';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { IEvent } from 'services/@types';
import MeetingService from 'services/meeting.api';
import EventService from '../../../../../services/event.api';
export default function useTaskHistory() {
  const { i18n } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const business = useMyBusiness();
  const [searchParams] = useSearchParams();
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
  const { result } = useSelector((state: RootState) => ({
    result: state.businessEventPlan.businessEventPlans,
    isLoading: state.businessEventPlan.loading,
  }));

  const { activeTasks, completedTasks } = useSelector(
    (state: RootState) => state.task,
  );

  const isAll = useMemo(
    () => searchParams.get('all') === 'true',
    [searchParams],
  );

  const eventResult = useQuery({
    queryKey: [`getEvent-${params?.id}`],
    queryFn: () => EventService.getEvent(params?.id),
    enabled: !!params?.id,
  });

  useQuery({
    queryKey: [
      `getEventActiveTasks-${params?.id || selectedEvent?.id || 'all'}`,
    ],
    queryFn: () => {
      const filter: Record<string, string> = {
        status: 'pending',
      };
      if (params?.id || selectedEvent?.id) {
        filter.event = params?.id || selectedEvent?.id;
      }
      dispatch(getActiveTasks(filter));
    },
    enabled: !!params?.id || isAll || !!selectedEvent?.id,
  });

  useQuery({
    queryKey: [
      `getEventCompletedTasks-${params?.id || selectedEvent?.id || 'all'}`,
    ],
    queryFn: () => {
      const filter: Record<string, string> = {
        status: 'completed',
      };
      if (params?.id || selectedEvent?.id) {
        filter.event = params?.id || selectedEvent?.id;
      }
      dispatch(getCompletedTasks(filter));
    },
    enabled: !!params?.id || isAll || !!selectedEvent?.id,
  });

  const todayMeetings = useQuery({
    queryKey: ['todayMeetings', params?.id],
    queryFn: async () =>
      await MeetingService.getMeetings({
        eventId: params?.id,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      }),
    enabled: !!params?.id,
  });

  const eventData = useMemo(() => eventResult?.data, [eventResult?.data]);

  const eventId = useMemo(
    () => params?.id || selectedEvent?.id,
    [params?.id, selectedEvent?.id],
  );

  const eventPlans = useMemo(() => result, [result]);

  const date = useMemo(() => {
    if (eventData?.dateAndTime) {
      return format(new Date(eventData?.dateAndTime), 'dd.MM.yyyy', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      });
    }
    return '';
  }, [eventData?.dateAndTime, i18n.language]);

  const onSelectedEvent = useCallback(
    ({ id }: { id: string }) => {
      const event = eventPlans?.find((plan) => plan.eventId.id === id);
      setSelectedEvent((prev) =>
        prev?.id === event?.eventId.id ? null : event?.eventId,
      );
    },
    [eventPlans],
  );

  useEffect(() => {
    if (isAll) {
      dispatch(
        fetchBusinessEventPlans({
          page: 1,
          limit: 1000,
          businessId: business?.id,
          populate: 'eventId',
        }),
      );
    }
  }, [isAll, business?.id, dispatch]);

  return {
    isAll,
    eventData,
    date,
    eventPlans,
    selectedEvent,
    onSelectedEvent,
    activeTasks,
    completedTasks,
    todayMeetings,
    eventId,
  };
}
