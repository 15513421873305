import { Button, Divider, Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AppTooltip } from 'components/tooltip';
import { useTranslation } from 'react-i18next';
import SelectClients from 'views/main/managment/events/createUpdateEvent/components/SelectClients';
import CreateQuoteHeader from './components/CreateQuoteHeader';
import PaymentDetails from './components/PaymentDetails';
import Products from './components/Products';
import useCreateQuote from './hooks/useCreateQuote';

export default function CreateQuote() {
  const { t } = useTranslation();
  const {
    newQuote,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    event,
    setNewQuote,
    isLoading,
    products,
    handleSelectedProduct,
    handleChangeFile,
    clearFile,
    handleChangePaymentAmount,
    currencyIcon,
    error,
    handleChangeAllProductsVatIncluded,
    isAllProductsVatIncluded,
    handleChangeProductVatIncluded,
    handleOpenClientDialog,
    setClient,
    isSaveButtonDisabled,
    isPaymentInInstallmentsCorrect,
    wrongAmount,
    wrongAmountText,
    handleAddToLastPayment,
  } = useCreateQuote();

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '57px' }}
      flexDirection="column"
      gap={4}>
      <Card gap="36px">
        <CreateQuoteHeader
          handleChange={handleChange}
          newQuote={newQuote}
          handleChangeEventType={handleChangeEventType}
          selectedEventType={selectedEventType}
          handleOpenEventDialog={handleOpenEventDialog}
          event={event}
        />
        <SelectClients
          handleOpenClientDialog={handleOpenClientDialog}
          handleChange={handleChange}
          clients={newQuote?.clients}
          hasError={error?.clients}
          setClient={setClient}
        />
        <Flex
          w="100%"
          flexDirection={{ base: 'column', md: 'row' }}
          gap="16px"
          h="100%">
          <Products
            handleAddProduct={handleAddProduct}
            newQuote={newQuote}
            handleChange={handleChange}
            setNewQuote={setNewQuote}
            products={products}
            handleSelectedProduct={handleSelectedProduct}
            handleChangeFile={handleChangeFile}
            clearFile={clearFile}
            currencyIcon={currencyIcon}
            error={error}
            handleChangeAllProductsVatIncluded={
              handleChangeAllProductsVatIncluded
            }
            isAllProductsVatIncluded={isAllProductsVatIncluded}
            handleChangeProductVatIncluded={handleChangeProductVatIncluded}
          />
          <Divider
            orientation="vertical"
            borderColor="#EBEDF0"
            h="900px"
            display={{ base: 'none', md: 'block' }}
          />
          <PaymentDetails
            newQuote={newQuote}
            handleIncreasePaymentQuantity={handleIncreasePaymentQuantity}
            handleDecreasePaymentQuantity={handleDecreasePaymentQuantity}
            setNewQuote={setNewQuote}
            currencyIcon={currencyIcon}
            error={error}
            handleChangePaymentAmount={handleChangePaymentAmount}
          />
        </Flex>
        <Flex
          justify={{ base: 'center', md: 'flex-end' }}
          align="center"
          gap="10px">
          {!isPaymentInInstallmentsCorrect && (
            <AppTooltip
              label={t('create_quote.total_payment_tooltip')}
              placement="top"
            />
          )}
          <Flex flexDirection="column" align="center">
            {!isPaymentInInstallmentsCorrect && (
              <Text display="inline" color="red" fontSize="12px">
                {wrongAmountText}
                {wrongAmount > 0 && (
                  <Text
                    mx="5px"
                    display="inline"
                    color="red"
                    fontSize="12px"
                    onClick={handleAddToLastPayment}
                    cursor="pointer"
                    textDecoration="underline">
                    {t('create_quote.add_to_last_payment')}
                  </Text>
                )}
              </Text>
            )}
            <Button
              isLoading={isLoading}
              disabled={isSaveButtonDisabled}
              variant="h1cta"
              w="240px"
              onClick={handleCreateQuote}>
              {t('create_quote.save')}
            </Button>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
