// src/components/ProtectedRoute.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getCookie } from 'utils/cookie.util';
import ApproveQuote from 'views/auth/approveQuote';
import { RootState } from '../contexts/redux/store';

const ProtectedRoute: React.FC = () => {
  console.log('#protected route ->', window.location.toString());
  const location = useLocation();
  const visited = getCookie('visited');
  const authCookie = getCookie('auth');

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth?.isAuthenticated,
  );
  const onboardingStep = useSelector(
    (state: RootState) => state.auth?.user?.onboardingStep,
  );

  console.log(
    '# protected route ->',
    isAuthenticated,
    onboardingStep,
    authCookie,
    visited,
  );

  if (authCookie === 'true') {
    console.debug('# protected route redirected to main layout');
    return <Outlet />;
  }

  // condition to redirect to auth layout if location is /invite-quote
  if (location.pathname === '/auth/invite-quote') {
    return <ApproveQuote />;
  }

  if (visited === 'true') {
    return <Navigate to="/auth/sign-in" />;
  } else {
    return <Navigate to="/auth/get-started" />;
  }
};

export default ProtectedRoute;
