import {
  Icon,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';

import { Menu } from '@chakra-ui/react';
import { setPaymentDetailsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { usePaymentMutations } from 'hooks/usePayment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

export default function PaymentsTableItemMenu({
  paymentId,
  quoteId,
  eventId,
}: {
  paymentId: string;
  quoteId: string;
  eventId: string;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const toast = useToast();
  const {
    deletePayment: { mutateAsync: deletePaymentAsync },
  } = usePaymentMutations();

  const handleDeletePayment = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      deletePaymentAsync(paymentId);
      toast({
        title: t('finance_overview.payment_deleted'),
        status: 'success',
        position: 'top-right',
        variant: 'main',
      });
    },
    [deletePaymentAsync, paymentId, toast, t],
  );

  // open modal
  const handlePaymentDetailsDialog = useCallback(() => {
    dispatch(
      setPaymentDetailsDialog({
        item: {
          paymentId,
          quoteId,
          eventId,
        },
      }),
    );
  }, [dispatch, paymentId, quoteId, eventId]);
  return (
    <Menu>
      <MenuButton onClick={(e) => e.stopPropagation()}>
        <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
      </MenuButton>
      <MenuList>
        <MenuItem
          h="44px"
          onClick={(e) => {
            e.stopPropagation();
            handlePaymentDetailsDialog();
          }}>
          <Text variant="eventTabButtonTitle">
            {t('finance_overview.view_receipt')}
          </Text>
        </MenuItem>
        <MenuItem h="44px" onClick={handleDeletePayment}>
          <Text variant="eventTabButtonTitle">
            {t('finance_overview.delete')}
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
