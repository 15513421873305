import { AxiosInstance, AxiosResponse } from 'axios';
import { IFileMedia, IFileShare, ISharedWith } from './@types';
import { createAxiosInstance } from './axios.util';

interface FilesResponse {
  files: IFileMedia[];
  totalResults: number;
}

class FileService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async getFiles<T = FilesResponse>(
    queryParams?: Record<string, any>,
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.api.get('/files', {
      params: queryParams,
    });
    return response.data;
  }

  public async getShareInfo(
    sourceId: string,
    fileName: string,
  ): Promise<IFileShare | null> {
    const response: AxiosResponse = await this.api.get(
      `/files/${sourceId}/${fileName}`,
    );
    return response.data;
  }

  public async shareFile(
    sourceId: string,
    fileName: string,
    sharedWith: ISharedWith[],
  ): Promise<any> {
    const response: AxiosResponse<any> = await this.api.post('/files', {
      sourceId,
      fileName,
      sharedWith,
    });
    return response.data;
  }
}

const _fileService = new FileService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _fileService;
