import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useUpdateBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useVatSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [vatPercentValue, setVatPercentValue] = useState(18);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVatPercentValue(Number(e.target.value));
  }, []);

  const { mutate: updateBusinessPreferences } = useUpdateBusinessPreferences();

  const handleSetVat = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('finance_settings.vat.dialog.title'),
        content: t('finance_settings.vat.dialog.content'),
        confirmLabel: t('dialogs.alert.confirm'),
        cancelLabel: t('dialogs.alert.cancel'),
        onConfirm: () => {
          updateBusinessPreferences({ vatPercent: vatPercentValue });
          dispatch(closeAlertDialog());
        },
      }),
    );
  }, [dispatch, t, updateBusinessPreferences, vatPercentValue]);

  return {
    vatPercentValue,
    setVatPercentValue,
    loading,
    setLoading,
    error,
    setError,
    handleChange,
    handleSetVat,
  };
};

export default useVatSettings;
