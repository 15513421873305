import { Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilesHeader from './components/FilesHeader';
import ReceivedFiles from './components/SharedWithMe/SharedWithMeFiles';
import SentFiles from './components/SentFiles';

const tabs = ['sent', 'received'];
const FilesOverview = () => {
  const { t } = useTranslation /*  */();
  const [activeTab, setActiveTab] = useState<'sent' | 'received'>('sent');
  return (
    <Flex
      w="100%"
      h="calc(100dvh - 50px)"
      pt={{ base: '200px', md: '80px' }}
      flexDirection="column"
      pb={{ base: '40px', lg: '60px' }}
      gap={'8px'}>
      <Flex alignItems="center" gap="24px" ms={4} flexGrow={0}>
        {tabs.map((tab, index) => (
          <Flex
            key={index}
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            borderBottom="4px solid transparent"
            transition="all 0.4s ease"
            borderBottomColor={activeTab === tab ? '#93C3B3' : 'transparent'}
            onClick={() => setActiveTab(tab as 'sent' | 'received')}
            w="94px"
            h="41px">
            <Text variant="contractsTab">{t(`quote_list.${tab}`)}</Text>
          </Flex>
        ))}
      </Flex>
      <Card
        flex={1}
        h="100%"
        w="100%"
        gap="16px"
        borderRadius={{ base: '0px', md: '30px' }}>
        <Flex flexDirection="column" h="100%" w="100%">
          <FilesHeader />
          {activeTab === 'sent' && <SentFiles />}
          {activeTab === 'received' && <ReceivedFiles />}
        </Flex>
      </Card>
    </Flex>
  );
};

export default FilesOverview;
