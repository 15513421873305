import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import {
  setContractPreview,
  useContractPreview,
} from 'contexts/redux/contract/contractSlice';
import {
  AppDialogProps,
  closeContractSharingDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { useAppNavigate } from 'hooks/useAppNavigate';
export default function ContractSharingDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { contractSharingDialog } = useSelector((state: RootState) => ({
    contractSharingDialog: state.dialogs.contractSharingDialog,
  }));
  const contractPreview = useContractPreview();
  const dialog = contractSharingDialog as AppDialogProps;
  const { appNavigate: navigate } = useAppNavigate();
  const [form, setForm] = useState<{
    to: string;
    cc: string;
  }>({
    to: '',
    cc: '',
  });

  const handleClose = useCallback(() => {
    dispatch(closeContractSharingDialog());
    setForm({
      to: '',
      cc: '',
    });
  }, [dispatch]);

  const handleSharePdf = useCallback(() => {
    dispatch(showSplashDialog({ title: 'dialogs.splash.please_wait' }));
    dispatch(
      setContractPreview({
        ...contractPreview,
      }),
    );

    setTimeout(() => {
      navigate(`/main/finance/contracts/preview`);
    }, 400);
  }, [navigate, dialog?.item?.contractId, dispatch, form]);

  const handleSend = useCallback(() => {
    handleSharePdf();
    handleClose();
  }, [handleClose, handleSharePdf]);

  const handleShareBodyChange = useCallback((e: any) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="24px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text variant="cardTitle">
                {t('contract_sharing_dialog.title')}
              </Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <InputField
              placeholder={t('contract_sharing_dialog.to_placeholder')}
              label={t('contract_sharing_dialog.to')}
              value={form?.to}
              name="to"
              onChange={handleShareBodyChange}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
            />
            <HStack alignItems="end" gap="12px">
              <InputField
                w="100%"
                flex="1"
                value={form?.cc}
                name="cc"
                onChange={handleShareBodyChange}
                placeholder={t('contract_sharing_dialog.cc_placeholder')}
                label={t('contract_sharing_dialog.cc')}
                labelProps={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#00000099',
                  ps: '10px',
                }}
              />
              <Icon
                as={FiPlus}
                bg="brand.400"
                w="32px"
                h="32px"
                cursor="pointer"
                color="white"
                borderRadius="8px"
                onClick={() => {}}
              />
            </HStack>
            <Button
              w="280px"
              variant="h1cta"
              alignSelf="center"
              onClick={handleSend}>
              {t('contract_sharing_dialog.send')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
