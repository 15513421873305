import { Divider, Flex, Text } from '@chakra-ui/react';
import { formatNumber } from 'helpers/formatNumber';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuote } from 'services/@types';
import PaymentItem from './PaymentItem';

interface ISummaryProps {
  total: number;
  discount: number;
  totalPayment: number;
  vat: number;
  amountToPay: number;
  currency: string;
  paymentInInstallments: IQuote['paymentInInstallments'];
}

export default function Summary(props: ISummaryProps) {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const {
    total,
    discount,
    totalPayment,
    vat,
    amountToPay,
    currency,
    paymentInInstallments,
  } = props;
  const { t } = useTranslation();

  const formattedCurrency = useMemo(() => {
    return currency === 'ILS' ? '₪' : '$';
  }, [currency]);

  return (
    <Flex flex={1} flexDir="column" gap={4}>
      <Text variant="previewQuoteTitle">{t('quote_preview.summary')}</Text>

      <Flex flexDir="column" gap={3}>
        <Flex justify="space-between" fontSize="14px">
          <Text variant="previewQuoteSummaryLabel">
            {t('quote_preview.total')}
          </Text>
          <Text variant="previewQuoteSummaryLabel" fontWeight="500">
            {formattedCurrency}
            {total ? formatNumber(total).toLocaleString() : '-'}
          </Text>
        </Flex>

        <Flex justify="space-between" fontSize="14px">
          <Text variant="previewQuoteSummaryLabel">
            {t('quote_preview.discount')}
          </Text>
          <Text variant="previewQuoteSummaryLabel" fontWeight="500">
            {formattedCurrency}
            {discount ? formatNumber(discount).toLocaleString() : '-'}
          </Text>
        </Flex>

        <Flex justify="space-between" fontSize="14px">
          <Text variant="previewQuoteSummaryLabel">
            {t('quote_preview.total_payment')}
          </Text>
          <Text variant="previewQuoteSummaryLabel" fontWeight="500">
            {formattedCurrency}
            {totalPayment ? formatNumber(totalPayment).toLocaleString() : '-'}
          </Text>
        </Flex>

        <Flex justify="space-between" fontSize="14px">
          <Text variant="previewQuoteSummaryLabel">
            {t('quote_preview.vat', {
              percentage: businessPreferences?.vatPercent * 100,
            })}
          </Text>
          <Text variant="previewQuoteSummaryLabel" fontWeight="500">
            {formattedCurrency}
            {vat ? formatNumber(vat).toLocaleString() : '-'}
          </Text>
        </Flex>

        <Divider borderColor="#0000001A" />

        <Flex justify="space-between" fontSize="16px">
          <Text variant="previewQuoteSummaryLabel">
            {t('quote_preview.amount_to_pay')}
          </Text>
          <Text variant="previewQuoteSummaryAmount">
            {formattedCurrency}
            {amountToPay ? formatNumber(amountToPay).toLocaleString() : '-'}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir="column" gap="12px">
        <Text variant="previewQuoteTitle">
          {t('quote_preview.payment_in_installments')}
        </Text>

        {paymentInInstallments?.installments?.map((installment, index) => (
          <PaymentItem
            key={index}
            installment={installment}
            formattedCurrency={formattedCurrency}
            index={index}
            length={paymentInInstallments?.installments?.length}
          />
        ))}
      </Flex>
    </Flex>
  );
}
