import { useToast } from '@chakra-ui/react';
import {
  AppDialogProps,
  closeAskForQuoteDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { createQuote } from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IQuote } from 'services/@types';

interface UseQuoteResult {
  isDataAndTimeActive: boolean;
  handleOpenEventDialog: () => void;
  handleClose: () => void;
  isOpenned: boolean;
  updateIsDataAndTimeActive: () => void;
  quote: Record<string, any>;
  sendQuote: () => void;
  handleChange: (key: string, value: any) => void;
  loading: boolean;
  isSendDisabled: boolean;
  handleDateAndTime: (date: string, type: 'date' | 'time') => void;
}
export default function useQuote(): UseQuoteResult {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { askForQuoteDialog, currentSupplier, loading } = useSelector(
    (state: RootState) => ({
      askForQuoteDialog: state.dialogs.askForQuoteDialog,
      currentSupplier: state.suppliers.currentSupplier,
      loading: state.quote.loading,
    }),
  );
  const dialog = askForQuoteDialog as AppDialogProps;
  const [quote, setQuote] = useState<Record<string, any>>({});
  const [isDataAndTimeActive, setIsDataAndTimeActive] =
    useState<boolean>(false);

  const isSendDisabled = useMemo(() => {
    if (isDataAndTimeActive) {
      return !quote.freeText || !quote.date || !quote.time;
    }
    return !quote.event || !quote.freeText;
  }, [quote, isDataAndTimeActive]);

  const updateIsDataAndTimeActive = () =>
    setIsDataAndTimeActive((prev) => !prev);

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          setQuote((prev) => ({ ...prev, event: e }));
        },
      }),
    );
  }, [dispatch]);

  const handleChange = useCallback((key, value) => {
    setQuote((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleClose = useCallback(() => {
    setQuote({});
    dispatch(closeAskForQuoteDialog());
  }, [dispatch]);

  const handleDateAndTime = useCallback(
    (date: string, type: 'date' | 'time') => {
      console.log(date, type);
      setQuote((prev) => ({ ...prev, [type]: date }));
    },
    [],
  );

  const sendQuote = useCallback(async () => {
    const quoteData: IQuote = {
      freeText: quote.freeText,
      supplierId: currentSupplier?.id,
      status: 'requested',
    };
    if (isDataAndTimeActive) {
      quoteData.dateAndTime = new Date(`${quote.date} ${quote.time}`);
    } else {
      quoteData.eventId = quote.event?.id;
    }
    const res = await dispatch(createQuote(quoteData));
    if (res.payload) {
      toast({
        variant: 'main',
        title: t('dialogs.ask_for_quote.toast.quote_sent'),
      });
    }
    handleClose();
  }, [
    handleClose,
    dispatch,
    quote,
    currentSupplier?.id,
    t,
    toast,
    isDataAndTimeActive,
  ]);
  return {
    isDataAndTimeActive,
    handleOpenEventDialog,
    handleClose,
    updateIsDataAndTimeActive,
    quote,
    isOpenned: !!dialog,
    sendQuote,
    handleChange,
    loading,
    isSendDisabled,
    handleDateAndTime,
  };
}
