import { Flex, HStack, Icon, Image, Text, VStack } from '@chakra-ui/react';
import QuoteDeadlineIcon from 'assets/svg/notification/quote-deadline-icon.svg';
import moment from 'moment';
import 'moment/locale/he'; // Add Hebrew locale support
import { useGetEntity } from 'queries/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDoubleQuotesR } from 'react-icons/ri';
import { INotification, IQuote } from 'services/@types';
import { AppNotificationEventName } from 'services/@types/notificationEventNames';
import { getNotificationMomentFormat } from './NotificationComponent';
import QuoteNotification from './quote/QuoteNotification';

export default function PlainNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { t, i18n } = useTranslation();

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  const { data: entity } = useGetEntity(
    notification?.type,
    notification?.entityId,
  );

  const icon = useMemo(() => {
    if (notification?.type === 'quote') {
      if (
        notification?.notificationEventName ===
        AppNotificationEventName.QuoteDeadlineReminder
      ) {
        return QuoteDeadlineIcon;
      }
      return null;
    }
    return null;
  }, [notification?.notificationEventName, notification?.type]);

  return notification.type === 'quote' &&
    notification.notificationEventName !==
      AppNotificationEventName.QuoteDeadlineReminder ? (
    <QuoteNotification
      ref={notificationRef}
      createdAt={moment(notification?.createdAt).format(
        getNotificationMomentFormat(i18n.language),
      )}
      quote={entity as IQuote}
    />
  ) : (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px"
      flexDirection="column">
      <VStack
        border="1px solid #F9FAFB"
        p="8px"
        borderRadius={'8px'}
        bgColor={'#93C3B31A'}
        spacing={0}>
        <HStack alignItems={'center'} gap="12px">
          {icon ? (
            <Image src={icon} alt="" w="24px" h="24px" />
          ) : (
            <Icon as={RiDoubleQuotesR} color="brand.900" />
          )}
          <VStack spacing={0} alignItems={'flex-start'}>
            <HStack spacing={0}>
              <Text variant="cardSubTitle" color="#1B2037" lineHeight={'20px'}>
                {t(notification.message, {
                  ...{
                    date: moment(notification?.variables?.date).format(
                      'D MMM, YYYY',
                    ),
                  },
                  ...notification?.variables,
                })}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {moment(notification?.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Flex>
  );
}
