// src/features/invites/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Invite, PaginatedResults } from 'services/@types';
import InviteService from 'services/invite.api';
import {
  closeAddSeatDialog,
  closeSplashDialog,
  openAlertDialog,
} from '../dialog/dialogsSlice';
import store, { RootState } from '../store';
export interface InviteState {
  invites: Invite[];
  seatInvites: Invite[];
  mySeatRole: 'manager' | 'admin' | null;
  loading: boolean;
  loadingId: string | null;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: InviteState = {
  invites: [],
  seatInvites: [],
  mySeatRole: null,
  loadingId: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchInvites = createAsyncThunk(
  'invites/fetchInvites',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await InviteService.getInvites(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchInvite = createAsyncThunk(
  'invites/fetchInvite',
  async (tagId: string, { rejectWithValue }) => {
    try {
      const response = await InviteService.getInvite(tagId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createInvite = createAsyncThunk(
  'invites/createInvite',
  async (invite: Invite, { rejectWithValue }) => {
    try {
      const newInvite = await InviteService.createInvite(invite);
      console.log('#Redux create business ->', newInvite);
      store.dispatch(closeSplashDialog());
      store.dispatch(closeAddSeatDialog());
      return newInvite;
    } catch (error) {
      const e = error as AxiosError;
      var title = 'error_messages.400';
      var content = 'error_messages.400';
      if (e.response.status === 409) {
        switch (invite.type) {
          case 'calendar':
            title = 'invite_calendar.already_invited';
            content = 'invite_calendar.already_invited_description';
            break;
          case 'supplier':
            title = 'invite_supplier.already_invited';
            content = 'invite_supplier.already_invited_description';
            break;
          case 'seat':
            title = 'invite_seat.already_invited';
            content = 'invite_seat.already_invited_description';
            break;
        }
      }
      const errorMsg = {
        response: {
          status: e.response.status,
          title,
          content,
        },
      };

      store.dispatch(closeSplashDialog());
      return rejectWithValue(errorMsg);
    }
  },
);

export const createInvites = createAsyncThunk(
  'invites/createInvites',
  async (invites: Invite[], { rejectWithValue }) => {
    try {
      const newInvites = await Promise.all(
        invites.map(async (invite) => {
          return await InviteService.createInvite(invite);
        }),
      );
      console.log('#Redux create invites ->', newInvites);
      store.dispatch(closeSplashDialog());
      store.dispatch(closeAddSeatDialog());
      return newInvites;
    } catch (error) {
      const e = error as AxiosError;
      var title = 'invite_supplier.already_invited';
      var content = 'invite_supplier.already_invited_description';
      if (e.response.status === 409) {
        switch (invites[0].type) {
          case 'calendar':
            title = 'invite_calendar.already_invited';
            content = 'invite_calendar.already_invited_description';
            break;
          case 'supplier':
            title = 'invite_supplier.already_invited';
            content = 'invite_supplier.already_invited_description';
            break;
          case 'seat':
            title = 'invite_seat.already_invited';
            content = 'invite_seat.already_invited_description';
            break;
        }
        store.dispatch(
          openAlertDialog({
            title,
            content,
          }),
        );
      }

      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const updateInvite = createAsyncThunk(
  'invites/updateInvite',
  async (
    { userId, updates }: { userId: string; updates: Partial<Invite> },
    { rejectWithValue },
  ) => {
    try {
      const response = await InviteService.updateInvite(userId, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteInvite = createAsyncThunk(
  'invites/deleteInvite',
  async (tagid: string, { rejectWithValue }) => {
    try {
      await InviteService.deleteInvite(tagid);
      return tagid;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
    setSeatInvites: (state, action: PayloadAction<Invite[]>) => {
      state.seatInvites = action.payload;
    },
    setMySeatRole: (
      state,
      action: PayloadAction<{ role: 'manager' | 'admin' }>,
    ) => {
      state.mySeatRole = action.payload.role;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all businesses
      .addCase(fetchInvites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchInvites.fulfilled,
        (state, action: PayloadAction<PaginatedResults<Invite>>) => {
          state.invites = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(fetchInvites.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a tag
      .addCase(createInvite.pending, (state, { meta }) => {
        state.loading = true;
        state.loadingId = meta.arg.email;
      })
      .addCase(
        createInvite.fulfilled,
        (state, action: PayloadAction<Invite>) => {
          state.invites.push(action.payload);
          state.loading = false;
          state.loadingId = null;
        },
      )
      .addCase(createInvite.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.loadingId = null;
        state.error = action.payload;
      })
      // Update a user
      .addCase(updateInvite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateInvite.fulfilled,
        (state, action: PayloadAction<Invite>) => {
          const index = state.invites.findIndex(
            (tag) => tag.id === action.payload.id,
          );
          if (index !== -1) {
            state.invites[index] = action.payload;
          }
          state.loading = false;
        },
      )
      .addCase(updateInvite.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a user
      .addCase(deleteInvite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteInvite.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.invites = state.invites.filter(
            (tag) => tag.id !== action.payload,
          );
          state.loading = false;
        },
      )
      .addCase(deleteInvite.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSeatInvites, setMySeatRole } = userSlice.actions;

export const selectSeatInvites = (state: RootState) =>
  state.invites.seatInvites;
export const selectMySeatRole = (state: RootState) => state.invites.mySeatRole;

export default userSlice.reducer;
