import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const FilesHeader = () => {
  const { t } = useTranslation();
  return (
    <Flex
      flexGrow={0}
      flexShrink={0}
      justify="space-between"
      flexDirection={{ base: 'column', md: 'row' }}
      gap={5}>
      <Flex gap={2} align="center">
        {/* <InputField
          w={{ base: '100%', md: 'auto' }}
          placeholder={t('clients.search')}
          iconRight={<Icon as={FiSearch} w="20px" h="20px" />}
          onChange={handleSearch}
          value={search}
        />
        <Button
          variant="h1cta"
          w={{ base: '110px', md: 'auto' }}
          leftIcon={<Icon as={FiPlus} />}
          onClick={onOpenCreateContractPreview}>
          {t(
            isMobile
              ? 'contracts.new_contract_mobile'
              : 'contracts.new_contract',
          )}
        </Button> */}
      </Flex>
    </Flex>
  );
};

export default FilesHeader;
