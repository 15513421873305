import { Button, Icon, HStack, Flex, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { memo } from 'react';
import { LuX } from 'react-icons/lu';
import { AssignedItem } from '../hooks/useAddParticipant';

const tagsVariants = {
  pop: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
};

const SelectedOptionsContainer = (props: {
  selectedOptions: AssignedItem[];
  handleRemove: (value: string) => void;
}) => {
  const { selectedOptions, handleRemove } = props;
  return (
    <Flex
      height="100%"
      width="auto"
      maxW="300px"
      gap={2}
      px={selectedOptions?.length > 0 ? '13px' : 0}
      alignItems="center">
      <AnimatePresence>
        {selectedOptions?.map(
          ({ email, id, label, invite, supplier, value }, key) => (
            <motion.div
              key={key}
              animate={'pop'}
              variants={tagsVariants}
              initial={{ scale: 0.6, opacity: 0 }}
              exit={{ scale: 0.6, opacity: 0 }}>
              <Button
                variant="assignSelectedOptions"
                onClick={() => handleRemove(value)}
                noOfLines={1}>
                <HStack>
                  <Text>{label}</Text>
                  <Icon as={LuX} />
                </HStack>
              </Button>
            </motion.div>
          ),
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default memo(SelectedOptionsContainer);
