import { Button, HStack, IconButton, Image, Spacer } from '@chakra-ui/react';
import backChevronIcon from 'assets/svg/icons/onboarding/back-chevron.svg';
import { useSignout } from 'components/navbar/hooks/useSignout';
import {
  clearCredentials,
  updateMyUser,
  useMyUser,
} from 'contexts/redux/auth/authSlice';
import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { OnboardFlow, SignUpStep } from '../SignUpCentered';
interface OnboardingNavigationHeaderProps {
  hideCloseButton?: boolean;
  hideBackButton?: boolean;
}
export const OnboardingNavigationHeader: React.FC<
  OnboardingNavigationHeaderProps
> = ({ hideCloseButton, hideBackButton }) => {
  const { signOut, loading } = useSignout();
  const onboardingStep = useSelector(
    (state: RootState) => state.auth?.user?.onboardingStep,
  );

  const { t, i18n } = useTranslation();
  const user = useMyUser();
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch<any>();
  const ChevronIcon = () => {
    return (
      <Image
        w={3}
        objectFit="fill"
        h={4}
        px={0}
        src={backChevronIcon}
        fontSize={22}
        transform={i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg'}
      />
    );
  };
  const handleBackClicked = useCallback(() => {
    const previousStep =
      OnboardFlow[OnboardFlow.indexOf(onboardingStep as SignUpStep) - 1];
    console.log('previousStep ->', previousStep);
    if (!previousStep) {
      navigate('/auth/get-started');
      return;
    }
    dispatch(
      updateMyUser({
        updates: { onboardingStep: previousStep },
      }),
    );
  }, [dispatch, navigate, onboardingStep]);
  const handleCancelClicked = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: 'signup.onboarding.dialogs.cancel_onboarding.title',
        content: 'signup.onboarding.dialogs.cancel_onboarding.description',
        onConfirm: async () => {
          if (user?.googleID) {
            await signOut();
          }
          dispatch(clearCredentials());
          navigate('/auth/sign-in');
          dispatch(closeAlertDialog());
        },
      }),
    );
  }, [dispatch, navigate, signOut, user?.googleID]);

  return (
    <HStack
      spacing={0}
      // bg="red"
      position="absolute"
      top={0}
      w="100%"
      py={3}
      px={6}
      zIndex={3}>
      <Button
        w="fit-content"
        px={0}
        gap={0}
        display={hideBackButton ? 'none' : 'flex'}
        h="100%"
        fontSize={'sm'}
        fontWeight="normal"
        onClick={handleBackClicked}
        variant="underline"
        leftIcon={<ChevronIcon />}>
        {t('signup.onboarding.navigation.back')}
      </Button>

      {/* {!hideBackButton && !hideCloseButton && <Spacer />} */}
      <Spacer />
      <IconButton
        aria-label="cancel onboarding"
        as={MdClose}
        variant="ghost"
        isLoading={loading}
        display={hideCloseButton ? 'none' : 'flex'}
        color="#00000066"
        size="xs"
        onClick={handleCancelClicked}
      />
    </HStack>
  );
};
