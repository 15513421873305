import React from 'react';

// Chakra imports
import {
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

// Custom components
// Assets
import PayPalIcon from 'assets/svg/icons/onboarding/paypal-blue.svg';
import { useTranslation } from 'react-i18next';
import { FaPhone } from 'react-icons/fa6';
import { IoCard } from 'react-icons/io5';

import InputField from 'components/fields/InputField';
import { SignUpStep } from '../SignUpCentered';
import { OnboardingNavigationHeader } from './OnboardingNavigationHeader';
const PaymentTab: React.FC<{
  nextClick: () => void;
  activeTab: SignUpStep;
  paymentInProgress: boolean;
}> = ({ nextClick, activeTab, paymentInProgress }) => {
  // Chakra color mode
  const { t } = useTranslation();
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorDetails = useColorModeValue('gray.600', 'secondaryGray.600');
  const cardBg = useColorModeValue('#f5f5f5', 'brand.800');
  const buttonsBg = useColorModeValue('white', 'brand.800');

  return (
    <Flex w={{ base: 'lg', sm: '100%', md: '3xl', lg: '5xl' }} justify="center">
      <Card
        p="0px"
        boxShadow={{ base: 'none', sm: '0px', md: 'xl', lg: 'xl' }}
        bg={{ base: cardBg, sm: 'transparent', md: cardBg, lg: cardBg }}
        overflow={'hidden'}
        display={activeTab === SignUpStep.payment ? 'flex' : 'none'}>
        <OnboardingNavigationHeader />
        <Flex wrap={'wrap'}>
          <VStack align="start" p={'40px'} flex={1} maxW={450} gap={4} w="100%">
            <Text color={textColor} fontSize="2xl" fontWeight="700">
              {t('signup.onboarding.payment.brilliant_plan')}
            </Text>
            <Text color={textColorDetails} fontSize="md" maxW={280}>
              {t('signup.onboarding.payment.payment_info')}
            </Text>
            <Flex gap={2}>
              <VStack>
                <IconButton
                  // as={IoCard}
                  icon={<Icon as={IoCard} />}
                  aria-label="card"
                  p={3}
                  size="lg"
                  isRound
                  bg={buttonsBg}
                />
                <Text
                  color={textColorDetails}
                  fontSize="sm"
                  fontWeight={'medium'}
                  mb="20px">
                  {t('signup.onboarding.payment.card')}
                </Text>
              </VStack>
              <VStack>
                <IconButton
                  icon={<Image src={PayPalIcon} />}
                  aria-label="paypal"
                  p={2}
                  size="lg"
                  isRound
                  bg={buttonsBg}
                />
                <Text
                  color={textColorDetails}
                  fontSize="sm"
                  fontWeight={'medium'}
                  mb="20px">
                  {t('signup.onboarding.payment.paypal')}
                </Text>
              </VStack>
            </Flex>
            <Flex
              flexDirection={'column'}
              maxW={290}
              gap={2}
              alignSelf="center"
              align="center">
              <InputField
                w="100%"
                bg="white"
                borderColor="gray.400"
                placeholder="1234 5678 9012 3456"
                iconLeft={<Icon as={IoCard} fontSize="lg" />}
              />

              <Grid templateColumns="repeat(2, 1fr)" columnGap={2} rowGap={2}>
                <InputField mb={'0px'} w="100%" placeholder="MM/YY" />
                <InputField
                  w="100%"
                  placeholder={t('signup.onboarding.payment.placeholders.cvv')}
                />
                <InputField
                  placeholder={t(
                    'signup.onboarding.payment.placeholders.first_name',
                  )}
                />
                <InputField
                  placeholder={t(
                    'signup.onboarding.payment.placeholders.last_name',
                  )}
                />
                <InputField
                  placeholder={t(
                    'signup.onboarding.payment.placeholders.postal_code',
                  )}
                />
                <InputField
                  placeholder={t(
                    'signup.onboarding.payment.placeholders.country',
                  )}
                />
              </Grid>
              <InputField
                w="100%"
                placeholder="+97254567890"
                iconLeft={<Icon as={FaPhone} fontSize="lg" />}
              />
            </Flex>
          </VStack>
          <VStack
            justify="space-between"
            align="center"
            px={'40px'}
            py={'40px'}
            flex={1}
            bg={{ base: '#fff', sm: 'transparent', md: '#fff', lg: '#fff' }}>
            <VStack align="start" w="100%" gap={2} maxW={350}>
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="0px">
                {t('signup.onboarding.payment.summary')}
              </Text>
              <Flex h={'1px'} mt={2} bg={textColor} w="100%" />
              <Text
                color={textColorDetails}
                fontSize="lg"
                mb="20px"
                fontWeight={'medium'}
                wordBreak="break-word"
                maxW={370}
                textAlign="center">
                {t('signup.onboarding.payment.payment_summary.plan_name')}
              </Text>
              <HStack w="100%" justify="space-between">
                <Text color={textColorDetails} fontSize="md">
                  Seats{' '}
                </Text>
                <Text color={textColorDetails} fontSize="lg">
                  5
                </Text>
              </HStack>

              <HStack w="100%" justify="space-between" align="start">
                <Text color={textColorDetails} fontSize="md">
                  Subscription{' '}
                </Text>
                <VStack>
                  <Text color={textColorDetails} fontSize="xl">
                    430
                    {t('signup.onboarding.plans.price_per_month')}
                  </Text>
                  <Text color={'gray.400'} fontSize="sm">
                    {t('signup.onboarding.payment.payment_summary.vat')}
                  </Text>
                </VStack>
              </HStack>
              <Flex h={'1px'} my={4} bg={textColor} w="100%" />
              <HStack w="100%" justify="space-between">
                <Text color={textColorDetails} fontSize="md">
                  {t('signup.onboarding.payment.payment_summary.vat')}
                </Text>
                <VStack>
                  <Text color={textColorDetails} fontSize="sm">
                    430
                    {t('signup.onboarding.plans.price_per_month')}
                  </Text>
                  <Text color={'gray.400'} fontSize="sm">
                    {t('signup.onboarding.payment.payment_summary.vat')}
                  </Text>
                </VStack>
              </HStack>
              <HStack w="100%" justify="space-between" align="start">
                <Text color={textColorDetails} fontSize="sm">
                  {t('signup.onboarding.payment.total')}
                </Text>
                <VStack>
                  <Text color={textColorDetails} fontSize="sm">
                    430
                    {t('signup.onboarding.plans.price_per_month')}
                  </Text>
                  <Text color={'gray.400'} fontSize="sm">
                    {t('signup.onboarding.payment.payment_summary.vat')}
                  </Text>
                </VStack>
              </HStack>
              <Button
                variant="h1cta"
                size="md"
                minW="100%"
                mt={20}
                isLoading={paymentInProgress}
                onClick={nextClick}>
                {t('signup.onboarding.payment.make_purchase')}
              </Button>
              <Text
                color={textColorDetails}
                fontSize="xs"
                mt="10px"
                textAlign="start">
                {t('signup.onboarding.payment.agree_terms_payment')}
                <Link textDecorationLine={'underline'} color="brand.900">
                  {t('signup.onboarding.signup.terms_of_use')}
                </Link>
              </Text>
            </VStack>
          </VStack>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PaymentTab;
