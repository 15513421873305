import { useTranslation } from 'react-i18next';
import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

export const EmptyAnchorMeeting = ({
  onClick,
  title,
}: {
  onClick: () => void;
  title: string;
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      border="1px solid #AA915D"
      borderRadius="20px"
      p="16px">
      <Text variant="emptyAnchorMeetingTitle">
        {t(`event.anchor_meetings.${title}`)}
      </Text>
      <IconButton
        aria-label="edit"
        icon={<Icon as={FaPlus} color="#EEEEEE" w="10px" h="10px" />}
        variant="actionIconBlueSmall"
        onClick={onClick}
        transition="all 0.2s ease-in-out"
        _hover={{
          transform: 'scale(1.1)',
        }}
      />
    </Flex>
  );
};
