import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { setChangesDetected } from 'contexts/redux/uiHelper/uiHelperSlice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavigateFunction,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import routes from 'routes';

interface AppNavigateHook {
  appNavigate: NavigateFunction;
  triggerChangesDetected: () => void;
  preventChangesDetected: () => void;
}

export const useAppNavigate = (): AppNavigateHook => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const location = useLocation();

  const changesDetected = useSelector(
    (state: RootState) => state.uiHelper.changesDetected,
  );

  const triggerChangesDetected = useCallback(() => {
    console.log('#App Navigate changes detected true');
    dispatch(setChangesDetected(true));
  }, [dispatch]);

  const preventChangesDetected = useCallback(() => {
    console.log('#App Navigate changes detected false');
    dispatch(setChangesDetected(false));
  }, [dispatch]);

  const appNavigate = useCallback(
    (to: To) => {
      const findRouteRecursively = (
        routes: RoutesType[],
      ): RoutesType | undefined => {
        for (const route of routes) {
          if (route.layout + route.path === location.pathname) {
            return route;
          }
          if (route.items) {
            const found = findRouteRecursively(route.items);
            if (found) return found;
          }
        }
        return undefined;
      };
      const currentRoute = findRouteRecursively(routes);

      if (currentRoute?.isEditable && changesDetected) {
        dispatch(
          openAlertDialog({
            title: t('common.unsaved_changes_title'),
            content: t('common.unsaved_changes'),
            onConfirm: () => {
              navigate(to);
              dispatch(closeAlertDialog());
              setChangesDetected(false);
            },
          }),
        );
      } else {
        navigate(to);
        dispatch(setChangesDetected(false));
      }
    },
    [changesDetected, dispatch, location.pathname, navigate, t],
  );

  return {
    appNavigate: appNavigate as NavigateFunction,
    triggerChangesDetected,
    preventChangesDetected,
  };
};
