export function getDateRange(
  filter: { range: string },
  now: Date,
): { fromDate?: Date; toDate?: Date } {
  const startOfDay = new Date(now);
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date(now);
  endOfDay.setHours(23, 59, 59, 999);

  switch (filter.range) {
    case 'today': {
      return { fromDate: startOfDay, toDate: endOfDay };
    }
    case 'this_month': {
      const fromDate = new Date(now.getFullYear(), now.getMonth(), 1);
      const toDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return { fromDate, toDate };
    }
    case '30days': {
      const fromDate = new Date(startOfDay);
      fromDate.setDate(fromDate.getDate() - 30);
      return { fromDate, toDate: endOfDay };
    }
    case 'this_year': {
      const fromDate = new Date(now.getFullYear(), 0, 1);
      const toDate = new Date(now.getFullYear(), 11, 31);
      return { fromDate, toDate };
    }
    case 'previous_year': {
      const fromDate = new Date(now.getFullYear() - 1, 0, 1);
      const toDate = new Date(now.getFullYear() - 1, 11, 31);
      return { fromDate, toDate };
    }
    default:
      return {};
  }
}
