import {
  Checkbox,
  Flex,
  Hide,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPaperclip, FiPlus, FiX } from 'react-icons/fi';
import { CreateQuoteError, IQuote } from 'services/@types';
import { currency } from 'variables/currency';
import ProductItem from './ProductItem';
import InputDateField from 'components/fields/InputDateField';
import SwitchField from 'components/fields/SwitchField';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';

export default function Products({
  handleAddProduct,
  newQuote,
  handleChange,
  setNewQuote,
  products,
  handleSelectedProduct,
  handleChangeFile,
  clearFile,
  currencyIcon,
  error,
  handleChangeAllProductsVatIncluded,
  isAllProductsVatIncluded,
  handleChangeProductVatIncluded,
}: {
  handleAddProduct: () => void;
  newQuote: IQuote;
  handleChange: (path: string, value: any) => void;
  setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
  products: { value: string; label: string }[];
  handleSelectedProduct: (e: any, index: number) => void;
  handleChangeFile: (e: any) => void;
  clearFile: () => void;
  currencyIcon: string;
  error: CreateQuoteError;
  handleChangeAllProductsVatIncluded: (e: any) => void;
  isAllProductsVatIncluded: boolean;
  handleChangeProductVatIncluded: (e: any, index: number) => void;
}) {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const { t, i18n } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectedCurrency = useMemo(() => {
    const selected = currency.find((c) => c.value === newQuote?.currency);
    return selected ? [selected] : [''];
  }, [newQuote?.currency]);

  const showedOptions = useMemo(() => {
    const selected = currency.find((c) => c.value === newQuote?.currency);
    return selected ? t(selected.label) + ' - ' + selected.symbol : '';
  }, [newQuote?.currency, t]);

  return (
    <VStack alignItems="start" gap="16px" flex="1">
      <Text variant="createQuoteTitle">{t('create_quote.products')}</Text>
      <Hide below="md">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <DropdownField
            closeOnSelect
            showedOptions={showedOptions}
            label={t('create_quote.currency')}
            options={currency}
            placeholder={t('create_quote.currency')}
            selectedOptions={selectedCurrency}
            onSelected={(e: any) => {
              handleChange('currency', e.value);
            }}
            menuButton={{
              w: {
                base: '100%',
                md: '239px',
              },
              bg: '#FFFFFF',
            }}
          />
          <VStack alignItems="start" width="144px">
            <Text variant="createQuoteCheckboxText">
              {t('create_quote.vat')}
            </Text>
            <Checkbox
              variant="roundBrand"
              isChecked={isAllProductsVatIncluded}
              onChange={handleChangeAllProductsVatIncluded}
              h="50px"
              alignItems="center">
              <Text variant="createQuoteCheckboxText">
                {t('quote_preview.vat', {
                  percentage: businessPreferences?.vatPercent,
                })}
              </Text>
            </Checkbox>
          </VStack>
        </Flex>
      </Hide>
      {newQuote?.products?.map((product, index) => (
        <ProductItem
          product={product}
          index={index}
          setNewQuote={setNewQuote}
          products={products}
          handleSelectedProduct={handleSelectedProduct}
          currencyIcon={currencyIcon}
          key={index}
          error={error}
          currency={newQuote?.currency}
          handleChangeProductVatIncluded={handleChangeProductVatIncluded}
        />
      ))}
      <HStack>
        <IconButton
          borderRadius="10px"
          icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
          onClick={handleAddProduct}
          aria-label="add"
          w="33px"
          h="33px"
          minW="33px"
          bg="#93C3B3"
        />
        <Text variant="addRowText">{t('create_quote.add_row')}</Text>
      </HStack>
      <TextField
        label={t('create_quote.comment')}
        placeholder={t('create_quote.comment')}
        w="100%"
        minHeight={{ base: '233px', md: '203px' }}
        value={newQuote.comment}
        onChange={(e: any) => {
          setNewQuote((prev) => ({ ...prev, comment: e.target.value }));
        }}
        iconPointerEvents="auto"
        iconLeft={
          <Flex
            marginLeft={i18n.dir() === 'rtl' ? '0px' : '20px'}
            marginRight={i18n.dir() === 'rtl' ? '20px' : '0px'}
            alignItems="center">
            {(!newQuote?.media || newQuote?.media?.length === 0) && (
              <>
                <IconButton
                  aria-label=""
                  icon={<Icon as={FiPaperclip} color="white" />}
                  bg="#93C3B3"
                  borderRadius="10px"
                  w="33px"
                  h="33px"
                  minW="33px"
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                />
                <Input
                  type="file"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                  display="none"
                  onChange={handleChangeFile}
                  ref={fileInputRef}
                />
              </>
            )}
          </Flex>
        }
      />
      {newQuote?.media?.length > 0 && (
        <Flex alignItems="center" gap="4px">
          <Text variant="eventGridCardDesc">
            {newQuote.media[0]?.displayName}
          </Text>
          <Icon
            aria-label=""
            as={FiX}
            cursor="pointer"
            onClick={() => clearFile()}
          />
        </Flex>
      )}
      <InputField
        label={t(`create_quote.payment_terms`)}
        flex="1"
        w="100%"
        value={newQuote.paymentTerms}
        onChange={(e: any) =>
          setNewQuote((prev) => ({ ...prev, paymentTerms: e.target.value }))
        }
      />
      <InputField
        label={t(`create_quote.legal_text`)}
        w="100%"
        value={newQuote.legalText}
        onChange={(e: any) =>
          setNewQuote((prev) => ({ ...prev, legalText: e.target.value }))
        }
      />
      <InputDateField
        label={t(`create_quote.quote_valid_until`)}
        justifyContent="end"
        containerStyles={{ flex: '1', w: { base: '100%' } }}
        buttonStyles={{
          w: { base: '100%' },
          maxW: { base: '100%' },
          borderColor: '#0000001A',
          justifyContent: 'space-between',
        }}
        value={
          newQuote?.validUntil ? new Date(newQuote.validUntil) : new Date()
        }
        imgSrc={CalendarIcon}
        onChange={(e: Date) => handleChange('validUntil', e.toString())}
        imgStyles={{
          bg: 'transparent',
        }}
      />
      <SwitchField
        w="100%"
        wrapperProps={{
          w: '100%',
          p: '0px',
        }}
        labelProps={{
          marginLeft: i18n.dir() === 'rtl' ? 0 : '12px',
          marginRight: i18n.dir() === 'rtl' ? '12px' : 0,
          fontSize: '14px',
          fontWeight: 400,
        }}
        colorScheme="yellow"
        color=""
        display="flex"
        labelColor="#00000099"
        reversed
        label={t('create_quote.quote_reminder')}
        isChecked={!!newQuote?.shouldNotify}
        onChange={() => {
          handleChange('shouldNotify', !newQuote?.shouldNotify);
        }}
        id="quoteReminder"
      />
    </VStack>
  );
}
