import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { getTasks } from 'contexts/redux/task/taskSlice';
import { motion } from 'framer-motion';
import { ForwardedRef, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import TaskCard from './TaskCard';
import TaskHistoryIcon from '../../../../assets/svg/event/clockRewind.svg';
interface TaskListProps {
  cardStyles?: any;
  ref?: ForwardedRef<HTMLDivElement>;
  showTaskHistory?: boolean;
}
export default function TaskList(props: TaskListProps) {
  const { cardStyles = {}, ref, showTaskHistory = false } = props;
  const { t } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  const { tasks } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch<any>();

  const { isLoading, refetch } = useQuery({
    queryKey: ['tasks'],
    queryFn: async () =>
      await dispatch(
        getTasks({ status: '-completed', sortBy: '-dateAndTime' }),
      ),
  });

  useEffect(() => {
    refetch();
  }, []);

  const filterTasks = useMemo(() => {
    const todayMaxTime = new Date();
    todayMaxTime.setHours(23, 59, 59, 999);
    const result = tasks?.filter(
      (task) => new Date(task.dateAndTime) <= todayMaxTime,
    );
    return result;
  }, [tasks]);

  const Tasks = memo(() => {
    return (
      <Flex
        overflow="scroll"
        w="100%"
        h="100%"
        flexDirection="column"
        ref={ref}>
        {isLoading && (
          <HStack justifyContent="center" height="auto" align="center">
            <Spinner />
          </HStack>
        )}
        <Flex width="100%" overflow="scroll">
          <VStack gap="8px" pb="17px" width="100%" overflow="scroll">
            {filterTasks?.length === 0 && (
              <VStack pt="50px" height="100%" w="100%">
                <Text variant="taskCardSubTitle">{t('tasks.no_tasks')}</Text>
                <Button
                  onClick={() => navigate('/main/tasks/create')}
                  variant="h1cta">
                  {t('tasks.create_new_task')}
                </Button>
              </VStack>
            )}
            <VStack gap="8px" pb="17px" width="100%" overflow="scroll">
              {filterTasks?.map((task, index) => (
                <TaskCard
                  key={index}
                  task={task}
                  onTop={task.priority === 'important'}
                />
              ))}
            </VStack>
          </VStack>
        </Flex>
      </Flex>
    );
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      minW={310}
      flex={1}
      borderRadius={{ base: 0, md: '30px' }}
      p="31px 26px 0"
      height="100%"
      // maxH="580px"
      overflow="hidden"
      gap={3}
      {...cardStyles}>
      <HStack justifyContent="space-between" mb="8px">
        <Text variant="cardTitle">{t('tasks.title')}</Text>
        <Flex alignItems="center" gap="12px">
          {filterTasks?.length > 0 && (
            <IconButton
              bg="brand.400"
              aria-label="plus"
              w="24px"
              h="24px"
              minW="24px"
              cursor="pointer"
              onClick={() => navigate(`/main/tasks/create`)}
              icon={<Icon as={FaPlus} color="warmWhite.100" />}
            />
          )}
          {showTaskHistory && (
            <Flex
              w="24px"
              h="24px"
              bg="#8E7A7033"
              align="center"
              justify="center"
              borderRadius="6px"
              transition="all 0.2s ease-in-out"
              _hover={{ opacity: 0.6 }}
              onClick={() => navigate('/main/tasks-history?all=true')}>
              <Image
                src={TaskHistoryIcon}
                alt="history"
                w="17px"
                h="17px"
                minW="17px"
                cursor="pointer"
              />
            </Flex>
          )}
        </Flex>
      </HStack>
      <Flex
        pt="20.5px"
        h="100%"
        as={motion.div}
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.98 }}
        flexDirection="column">
        <Tasks />
        <Flex
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          top="0"
          height="100%"
          background="linear-gradient(to bottom, rgba(255,255,255,0) 60%, white 100%)"
          pointerEvents="none"
        />
      </Flex>
    </Card>
  );
}
