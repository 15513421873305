import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  setMySeatRole,
  setSeatInvites,
} from 'contexts/redux/invite/inviteSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Business, BusinessCreator, Invite } from 'services/@types';

const useSeatInviteRole = () => {
  const dispatch = useDispatch<AppDispatch>();
  const invites = useSelector((state: RootState) => state.invites.invites);
  const myBusiness: Business<BusinessCreator> =
    useMyBusiness<BusinessCreator>();
  const myUser = useMyUser();

  const { seatInvites, myRole } = useMemo(() => {
    if (!myBusiness || !myUser) return { seatInvites: [], myRole: null };
    const seatInvitesData = [
      {
        id: myBusiness?.id,
        type: 'seat',
        status: 'accepted',
        role: 'admin',
        fullName: `${myBusiness?.createdBy?.firstName} ${myBusiness?.createdBy?.lastName}`,
        businessID: myBusiness?.id,
        email: myBusiness?.createdBy?.email,
        jobTitle: 'Owner',
        recipientID: myBusiness?.createdBy?.id,
      } as Invite,
      ...invites.filter(
        (invite: Invite) =>
          invite.type === 'seat' && invite.status !== 'cancelled',
      ),
    ];
    const myRole = seatInvitesData.find(
      (inv) => inv.recipientID === myUser.id,
    )?.role;

    return {
      seatInvites: seatInvitesData,
      myRole,
    };
  }, [invites, myBusiness, myUser]);

  useEffect(() => {
    if (myRole) {
      dispatch(setMySeatRole({ role: myRole as 'manager' | 'admin' }));
      dispatch(setSeatInvites(seatInvites));
    }
  }, [dispatch, seatInvites, myRole]);
};

export default useSeatInviteRole;
