import { Flex, HStack, Text } from '@chakra-ui/layout';
import { Hide, Image } from '@chakra-ui/react';
import FacebookIcon from 'assets/svg/socials/facebook.svg';
import InstagramIcon from 'assets/svg/socials/instagram.svg';
import TiktokIcon from 'assets/svg/socials/tiktok.svg';
import WebsiteIcon from 'assets/svg/socials/website.svg';
import InputField from 'components/fields/InputField';
import { VSeparator } from 'components/separator/Separator';
import { useTranslation } from 'react-i18next';
import { Business, UrlItem } from 'services/@types';
const socialLinks = [
  {
    icon: WebsiteIcon,
    type: 'website',
    title: 'business_settings.website',
  },
  {
    icon: FacebookIcon,
    type: 'facebook',
    title: 'business_settings.facebook',
  },
  {
    icon: InstagramIcon,
    type: 'instagram',
    title: 'business_settings.instagram',
  },
  {
    icon: TiktokIcon,
    type: 'tiktok',
    title: 'business_settings.tiktok',
  },
];
interface BusinessSocialsProps {
  isEditModeEnabled: boolean;
  inputValues: Record<string, any>;
  handleChange: (event: { target: { name: string; value: any } }) => void;
  myBusiness: Business;
}
export default function BusinessSocials({
  isEditModeEnabled,
  handleChange,
  inputValues,
  myBusiness,
}: BusinessSocialsProps) {
  const { t } = useTranslation();
  const handleChangeLink = (e: any, type: string): void => {
    const { value } = e.target;
    const urlItem: UrlItem = {
      type: type,
      url: value,
    };
    const updatedLinks = [
      ...inputValues.links?.filter((l: any) => l.type !== type),
      urlItem,
    ];
    handleChange({
      target: {
        name: 'links',
        value: updatedLinks,
      },
    });
  };
  const getLinkValue = (type: string): string => {
    return inputValues?.links?.find((l: any) => l?.type === type)?.url || '';
  };
  return (
    <Flex p="30px 26px" flexDirection="column">
      <Text variant="cardTitle">
        {t('business_settings.business_social_profiles')}
      </Text>
      <Text fontWeight={400} fontSize={'md'}>
        {t('business_settings.business_social_profile_subtitle')}
      </Text>
      <Flex wrap="wrap" gap={6} mt={8}>
        {socialLinks.map((socialLink, key) => {
          const link: UrlItem = myBusiness?.links?.find(
            (l) => l.type === socialLink.type,
          );
          return (
            <Flex key={key} minW={{ base: null, sm: '100%', lg: 200 }} flex="1">
              <Flex flexDirection="column" gap={2} flex="1">
                <HStack gap={1}>
                  <Image src={socialLink.icon} alt="" w={4} h={4} />
                  <Text variant="cardSectionTitle">{t(socialLink.title)}</Text>
                </HStack>
                {isEditModeEnabled ? (
                  <InputField
                    flex="1"
                    h="50px"
                    w={{ base: '100%', md: '245px' }}
                    name={socialLink.type}
                    value={getLinkValue(socialLink.type)}
                    onChange={(e: any) => handleChangeLink(e, socialLink.type)}
                  />
                ) : (
                  <Text
                    onClick={() => {
                      if (link?.url) {
                        // open even if its only face.com/dsasdad in a new site with https://
                        window.open(
                          link?.url.startsWith('http')
                            ? link?.url
                            : `https://${link.url}`,
                          '_blank',
                        );
                      }
                    }}
                    style={{
                      textTransform: 'capitalize',
                      fontSize: '14px',

                      fontWeight: link?.url ? 700 : 500,
                      color: link?.url
                        ? 'rgba(0, 0, 0, 0.8)'
                        : 'rgba(0, 0, 0, 0.3)',
                      paddingLeft: 2,
                      textDecorationLine: link?.url ? 'underline' : 'none',
                      cursor: 'pointer',
                    }}
                    variant="cardSectionInfo">
                    {link?.url ? link.type : t('business_settings.not_added')}
                  </Text>
                )}
              </Flex>
              <Hide below="lg">
                {!isEditModeEnabled && key !== socialLinks.length - 1 && (
                  <VSeparator />
                )}
              </Hide>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
