import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import EventCard from 'components/eventsPickerDialog/eventCard';
import InputField from 'components/fields/InputField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import {
  setCurrentThread,
  setCurrentThreadEvent,
  setCurrentThreadTitle,
  setNewThread,
  setNewThreadEvent,
  setNewThreadTitle,
  updateThread,
} from 'contexts/redux/messenger/messengerSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';
import { FiEdit3 } from 'react-icons/fi';
import { LuMinus, LuX } from 'react-icons/lu';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import EventService from 'services/event.api';

export default function ChatTopHeaderTitleAndEvent() {
  const { newThread, currentThread } = useSelector((state: RootState) => ({
    newThread: state.messenger.newThread,
    currentThread: state.messenger.currentThread,
  }));

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<any>();

  const eventResult = useQuery({
    queryKey: [`getEvent-${currentThread?.eventId}`],
    queryFn: () => EventService.getEvent(currentThread?.eventId),
    enabled: !!currentThread?.eventId,
  });

  const eventData = useMemo(() => eventResult?.data, [eventResult?.data]);

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(
    !newThread?.title || !currentThread?.title,
  );

  const {
    isOpen: isEditable,
    onClose,
    onOpen,
  } = useDisclosure({
    defaultIsOpen: !newThread?.title && !currentThread?.title,
  });
  const assigned = useMemo(
    () => newThread?.participants || currentThread?.participants || [],
    [newThread?.participants, currentThread?.participants],
  );

  const title = useMemo(
    () => newThread?.title || currentThread?.title || '',
    [newThread?.title, currentThread?.title],
  );

  const event = useMemo(
    () => newThread?.event || eventData || null,
    [newThread?.event, eventData],
  );

  const user = useMyUser();

  const isCreator = useMemo(
    () =>
      newThread?.participants ||
      currentThread?.creatorUserId === user?.id ||
      false,
    [newThread?.participants, currentThread?.creatorUserId, user?.id],
  );

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          if (newThread) {
            dispatch(setNewThreadEvent(e));
            return;
          }

          if (currentThread) {
            dispatch(setCurrentThreadEvent(e));
            if (currentThread?.id) {
              dispatch(
                updateThread({
                  threadId: currentThread.id,
                  thread: { eventId: e.id },
                }),
              );
            }
          }
        },
      }),
    );
  }, [dispatch, newThread, currentThread]);

  const onFocus = useCallback(() => {
    setIsSaveBtnDisabled(false);
  }, []);

  const onBlur = useCallback(() => {
    console.log('title and id: ', currentThread?.title, currentThread?.id);
    // save title on db for existing thread
    if (currentThread?.id) {
      dispatch(
        updateThread({
          threadId: currentThread.id,
          thread: { title: title || null },
        }),
      );
    }
    setIsSaveBtnDisabled(true);
  }, [title, currentThread?.title, currentThread?.id, dispatch]);

  const handleChangeTitle = useCallback(
    (e: any) => {
      if (newThread?.participants) {
        dispatch(setNewThreadTitle(e.target.value));
      } else {
        // updated currect thread
        dispatch(setCurrentThreadTitle(e.target.value));
      }
    },
    [dispatch, newThread?.participants],
  );

  if (assigned.length > 0) {
    return (
      <Flex
        w="100%"
        pt={{ base: 4, md: 0 }}
        wrap={{ base: 'wrap', md: 'nowrap' }}
        gap={{ base: '16px', md: '31px' }}>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          aria-label="back"
          icon={
            <Icon
              fontSize={24}
              as={i18n.dir() === 'rtl' ? MdChevronRight : MdChevronLeft}
            />
          }
          variant="actionIconTransparent"
          onClick={() => {
            dispatch(setCurrentThread(null));
          }}
        />
        {isCreator && isEditable ? (
          <InputField
            placeholder={t('messenger.chat.header.add_title')}
            maxH="44px"
            flex="1"
            w="100%"
            value={title}
            onChange={handleChangeTitle}
            onFocus={onFocus}
            onBlur={onBlur}
            bg="#F5F5F5"
            _placeholder={{ color: '#00000066' }}
            iconPointerEvents="auto"
            iconRight={
              !isSaveBtnDisabled && (
                <Button onClick={() => onClose()} variant="h4" me={4} mb={1}>
                  {t('messenger.save')}
                </Button>
              )
            }
          />
        ) : (
          <Flex
            flex="1"
            w="100%"
            gap="12px"
            alignItems="center"
            justifyContent={{ base: 'space-between', md: 'start' }}>
            <Text variant="chatHeaderTitle">{title}</Text>
            {isCreator && (
              <IconButton
                onClick={() => onOpen()}
                variant="actionIconTransparent"
                aria-label={'new message'}
                icon={
                  <Icon
                    as={FiEdit3}
                    width="24px"
                    height="24px"
                    color="#5FBF96"
                  />
                }
              />
            )}
          </Flex>
        )}

        {event && (
          <Flex
            alignItems="center"
            flex="1"
            minW={{ base: '100%', md: '40%' }}
            gap={'18px'}>
            <EventCard
              event={event}
              onClick={() => {
                if (isCreator) {
                  handleOpenEventDialog();
                }
              }}
              isQuoteEvent
              w={{ base: 'auto', md: '100%' }}
              cursor={isCreator ? 'pointer' : 'default'}
              h="44px"
            />
            {isCreator && (
              <Icon
                as={FaX}
                width="12px"
                height="12px"
                color="#8E7A70"
                cursor={'pointer'}
                onClick={() => {
                  if (newThread) {
                    dispatch(setNewThreadEvent(null));
                  }

                  if (currentThread) {
                    dispatch(setCurrentThreadEvent(null));
                    if (currentThread?.id) {
                      dispatch(
                        updateThread({
                          threadId: currentThread.id,
                          thread: { eventId: null },
                        }),
                      );
                    }
                  }
                }}
              />
            )}
          </Flex>
        )}
        {isCreator && !event && (
          <Button
            variant="h1cta"
            onClick={handleOpenEventDialog}
            flex="1"
            w="100%"
            minW={{ base: '100%', md: '40%' }}
            px={24}>
            {t('create_meeting.assign_to_event')}
          </Button>
        )}
      </Flex>
    );
  }
  return (
    <HStack w="100%" pt={{ base: 4, md: 0 }}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        aria-label="back"
        icon={
          <Icon
            fontSize={24}
            as={i18n.dir() === 'rtl' ? MdChevronRight : MdChevronLeft}
          />
        }
        variant="actionIconTransparent"
        onClick={() => {
          dispatch(setNewThread(null));
        }}
      />
      <Text fontSize="2xl" fontWeight="bold">
        {t('messenger.chat.header.new_message')}
      </Text>
      <Spacer />
      {/* <IconButton
        icon={<LuMinus />}
        variant="actionIconTransparent"
        aria-label={'new message'}
      /> */}
      <IconButton
        icon={<LuX />}
        variant="actionIconTransparent"
        aria-label={'new message'}
        onClick={() => {
          dispatch(setNewThread(null));
          dispatch(setCurrentThread(null));
        }}
      />
    </HStack>
  );
}
