import { useQuery } from '@tanstack/react-query';
import fileService from 'services/files.api';
import { IFileMedia, FilesHeader } from 'services/@types';
import { useState } from 'react';

interface FilesResponse {
  files: IFileMedia[];
  totalResults: number;
}

const useFiles = () => {
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<FilesHeader>('name');

  const { data, isLoading } = useQuery<FilesResponse>({
    queryKey: ['files', `files-${search}-${sortBy}`],
    queryFn: () => fileService.getFiles({ sortBy }),
  });

  const handleSort = (value: FilesHeader) => {
    setSortBy(value);
  };

  return {
    data,
    isLoading,
    search,
    setSearch,
    sortBy,
    handleSort,
    files: data?.files || [],
  };
};

export default useFiles;
