import { fetchCalendars } from 'contexts/redux/calendar/calendarsSlice';
import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import useUpdateInvite from 'mutations/invite';
import useGetInvite from 'queries/invite';
import useGetUser from 'queries/user';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { InviteType } from 'services/@types';

import { UseNavigationType } from '@sentry/react/build/types/types';
import _inviteService from 'services/invite.api';
import { useAppNavigate } from './useAppNavigate';

// Define the possible invite types
interface InviteConfig {
  title: string;
  message: string;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: (navigate: ReturnType<UseNavigationType>) => void;
}

const useInviteInterceptor = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const { appNavigate: navigate } = useAppNavigate();

  const inviteToken = searchParams.get('token');
  const inviteId =
    searchParams.get('inviteId') || localStorage.getItem('inviteId');

  const { data: invite } = useGetInvite(inviteId);

  const { data: fromUser } = useGetUser(invite?.senderID);
  const { mutateAsync: updateInvite } = useUpdateInvite();
  const getInviteConfig = useCallback(
    (type: InviteType, fromUserName: string): InviteConfig => {
      switch (type) {
        case 'calendar':
          return {
            title: `Calendar Invite from ${fromUserName}`,
            message:
              'You have been invited to join a calendar. Would you like to accept?',
            confirmLabel: 'Join Calendar',
            cancelLabel: 'Decline',
            onConfirm: (navigate: any) => navigate('/main/calendar'),
          };
        default:
          throw new Error('Unknown invite type');
      }
    },
    [navigate],
  );

  const clearLocalStorage = () => {
    localStorage.removeItem('inviteId');
  };

  const handleInvite = useCallback(
    (inviteType: InviteType) => {
      console.log('fromUser ->', fromUser);
      if (!fromUser) return;
      const fromUserName = `${fromUser?.firstName} ${fromUser?.lastName}`;
      const config = getInviteConfig(inviteType, fromUserName);

      dispatch(
        openAlertDialog({
          ...config,
          onClose: async () => {
            clearLocalStorage();
            await dispatch(closeAlertDialog());
            updateInvite({
              id: inviteId as string,
              updates: { status: 'rejected' },
            });
            navigate('/main/dashboard');
          },
          onConfirm: async () => {
            config.onConfirm(navigate as any);
            clearLocalStorage();
            await updateInvite({
              id: inviteId as string,
              updates: { status: 'accepted' },
            });
            await dispatch(closeAlertDialog());
            await dispatch(fetchCalendars({}));
            navigate('/main/calendar');
          },
        }),
      );
    },
    [dispatch, fromUser, getInviteConfig, inviteId, navigate, updateInvite],
  );

  useEffect(() => {
    if (!inviteToken) return;
    _inviteService
      .getInviteByToken(inviteToken as string)
      .then(({ invite }) => {
        if (invite.type === 'seat') {
          localStorage.setItem('seatInviteId', invite.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [inviteToken]);

  useEffect(() => {
    if (inviteId) {
      localStorage.setItem('inviteId', inviteId);
    }
  }, [inviteId]);

  useEffect(() => {
    if (!fromUser) return;
    if (
      invite?.status === 'pending' ||
      (invite?.type === 'calendar' && invite?.status === 'cancelled')
    ) {
      setSearchParams({}, { replace: true });

      if (invite?.type && !isAlertOpen) {
        setIsAlertOpen(true);
        handleInvite(invite.type as InviteType);
      }
    }
  }, [
    invite?.status,
    invite?.type,
    handleInvite,
    isAlertOpen,
    fromUser,
    setSearchParams,
  ]);

  useEffect(() => {
    if (
      invite?.status &&
      ['rejected', 'accepted'].includes(invite.status) &&
      !isAlertOpen
    ) {
      setIsAlertOpen(true);
      dispatch(
        openAlertDialog({
          title: 'Oops!',
          content: 'This invite was already accepted or rejected.',
          onConfirm: () => {
            setSearchParams({}, { replace: true });
            dispatch(closeAlertDialog());
            clearLocalStorage();
          },
          onClose: () => {
            setSearchParams({}, { replace: true });
            dispatch(closeAlertDialog());
            clearLocalStorage();
          },
        }),
      );
    }
  }, [isAlertOpen, dispatch, invite, setSearchParams]);

  return { inviteId };
};

export default useInviteInterceptor;
