import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  ExpandedIndex,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { formatCurrency, formatNumber } from 'helpers/formatNumber';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaymentDetails } from 'views/main/event/hooks/usePaymentsDetails';
import { PaymentDetailBody } from './PaymentDetailBody';
import PaymentRowItem from './PaymentRowItem';

const MiniTopCard: React.FC<{
  label: string;
  value: number;
  isBlurred: boolean;
}> = ({ label, value, isBlurred }) => {
  return (
    <Flex
      align="center"
      gap={2}
      color="brand.300"
      justify="start"
      bg="#7BA3854B"
      filter={isBlurred ? 'blur(8px)' : 'none'}
      borderRadius="2xl"
      p={2}
      alignItems="start"
      flexDirection="column"
      w="100%"
      h="fit-content">
      <Text fontSize="sm" opacity={0.9}>
        {label}
      </Text>
      <Text fontSize="sm" fontWeight="bold">
        {formatCurrency(value, '₪')}
      </Text>
    </Flex>
  );
};
const PaymentDetailsModal = () => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const {
    paymentDetailsDialog,
    onClose,
    event,
    onUpdatePayment,
    savingPayment,
    paidPayments,
    currentPaymentProps,
    setCurrentPaymentProps,
    payments,
  } = usePaymentDetails();

  useEffect(() => {
    if (
      paymentDetailsDialog?.item?.paymentIndex !== undefined &&
      !currentPaymentProps
    ) {
      const payment = payments?.results.find(
        (payment) =>
          payment.paymentIndex === paymentDetailsDialog?.item?.paymentIndex,
      );

      if (payment) {
        setCurrentPaymentProps({ ...payment });
      }
    }
  }, [
    currentPaymentProps,
    paymentDetailsDialog?.item?.paymentIndex,
    payments?.results,
    setCurrentPaymentProps,
  ]);

  const handleChanged = useCallback(
    (index: ExpandedIndex) => {
      const payment = payments?.results[Number(index)];
      if (!payment) return;
      setCurrentPaymentProps(payment);
    },
    [currentPaymentProps?.id, payments?.results, setCurrentPaymentProps],
  );
  if (!paymentDetailsDialog?.item) return null;
  return (
    <Modal
      isOpen={!!paymentDetailsDialog}
      onClose={onClose}
      size={isMobile ? 'full' : 'xl'}
      isCentered
      scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        maxW={{ base: '100%', md: '540px' }}
        borderRadius={{ base: '0', md: '3xl' }}
        py={0}>
        <ModalHeader>
          <HStack mb={0} w="100%" align="start">
            <VStack align="start">
              <Text flex={1} fontSize="2xl" fontWeight="bold">
                {t('event_finance.payment_details_modal.title')}
              </Text>
              <HStack spacing={1}>
                <Text fontSize="sm" color="gray.500" fontWeight="normal">
                  {event?.name}
                </Text>
                <Text fontSize="sm" color="brand.900" fontWeight="bold">
                  {t(`event_types.${event?.type}`)}
                </Text>
              </HStack>
            </VStack>
            <Spacer />
            <ModalCloseButton
              position="relative"
              top={0}
              right={0}
              onClick={onClose}
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <HStack justify="center" mb={6}>
            <MiniTopCard
              label={t('event_finance.expected')}
              value={formatNumber(
                payments?.results?.reduce(
                  (acc, payment) => acc + payment.amount,
                  0,
                ),
              )}
              isBlurred={false}
            />
            <MiniTopCard
              label={t('event_finance.received')}
              value={formatNumber(
                paidPayments?.reduce(
                  (acc, payment) => acc + payment.paidAmount,
                  0,
                ),
              )}
              isBlurred={false}
            />
            <MiniTopCard
              label={t('event_finance.due')}
              value={formatNumber(
                payments?.results
                  ?.filter(
                    (p) => p.dueDate && moment(p.dueDate).isBefore(moment()),
                  )
                  ?.reduce(
                    (acc, payment) =>
                      acc + (payment.amount - (payment.paidAmount || 0)),
                    0,
                  ),
              )}
              isBlurred={false}
            />
          </HStack>
          <Accordion allowToggle onChange={handleChanged}>
            {payments?.results?.map((payment) => {
              return (
                <AccordionItem
                  key={payment.id}
                  tabIndex={payment.paymentIndex}
                  border={'none'}
                  _focus={{ border: 'none' }}>
                  <AccordionButton px={0} _focus={{ border: 'none' }}>
                    <PaymentRowItem
                      paymentIndex={payment.paymentIndex}
                      paymentsTotal={payments?.totalResults || 0}
                      dueDate={new Date(payment.dueDate || 0)}
                      amount={payment.amount}
                      paidAmount={payment.paidAmount}
                      isPaid={payment.isPaid}
                    />
                  </AccordionButton>

                  <AccordionPanel pb={4} px={0}>
                    <PaymentDetailBody
                      dueAmount={payment.amount}
                      isSaving={savingPayment}
                      payment={payment}
                      onSave={() => onUpdatePayment(payment.id)}
                      currentPaymentProps={currentPaymentProps}
                      onChange={(key, value) => {
                        setCurrentPaymentProps({
                          ...currentPaymentProps,
                          [key]: value,
                        });
                      }}
                      paymentsTotal={payments?.totalResults || 0}
                    />
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentDetailsModal;
