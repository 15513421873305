import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { TbMailOpened, TbMapPin } from 'react-icons/tb';
import { IClient, IClientContact } from 'services/@types';

type ChosenClientProps = {
  client: IClient;
  contact: IClientContact;
  removeClient: (clientId: string, contactId?: string) => void;
};

const ChosenClient = ({ client, contact, removeClient }: ChosenClientProps) => {
  const { fullName, email, address } = useMemo(() => {
    const fullName = contact
      ? `${contact?.firstName} ${contact?.lastName}`
      : `${client?.firstName} ${client?.lastName}`;
    const email = contact?.email ?? client?.email;
    const address = contact?.address ?? client?.address;
    return {
      fullName,
      email,
      address,
    };
  }, [client, contact]);

  return (
    <HStack
      p={'8px 12px'}
      overflow="hidden"
      borderRadius={'16px'}
      w="283px"
      color={'#939393'}
      alignItems={'center'}
      gap={'10px'}
      spacing={0}
      fontWeight={400}
      fontSize={'12px'}
      border={'1px solid'}
      borderColor={contact ? 'brand.900' : 'brand.100'}
      lineHeight="16px">
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        flex={1}
        w="full"
        overflow="hidden">
        <Text
          color="#141718"
          fontSize={'14px'}
          fontWeight={700}
          lineHeight="19px"
          title={fullName}>
          {fullName}
        </Text>
        <HStack spacing={0} gap={3} flex={1} w="full" justify={'flex-start'}>
          <HStack
            spacing={0}
            gap={1}
            alignItems="center"
            flex={1}
            w="full"
            overflow="hidden"
            isTruncated>
            <Icon as={TbMailOpened} fontSize={'16px'} />
            <Text title={email}>{email}</Text>
          </HStack>
          {address && (
            <HStack
              w="full"
              overflow="hidden"
              isTruncated
              spacing={0}
              gap={1}
              flex={1}
              alignItems="center">
              <Icon as={TbMapPin} fontSize={'16px'} />
              <Text title={address}>{address}</Text>
            </HStack>
          )}
        </HStack>
      </VStack>
      <IconButton
        flexShrink={0}
        size="xs"
        color={'#939393'}
        onClick={() => removeClient(client?.id, contact?.id)}
        aria-label="Remove client"
        icon={<CloseIcon />}
      />
    </HStack>
  );
};

export default ChosenClient;
