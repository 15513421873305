import { CloseIcon } from '@chakra-ui/icons';
import {
  HStack,
  Icon,
  IconButton,
  PropsOf,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TbMailOpened, TbMapPin } from 'react-icons/tb';
import { IClient } from 'services/@types';

type ChosenClientProps = {
  client: IClient;
  onRemove?: () => void;
  onClick?: () => void;
  wrapperProps?: PropsOf<typeof HStack>;
};

const ChosenClient = ({
  client,
  onRemove,
  onClick,
  wrapperProps,
}: ChosenClientProps) => {
  return (
    <HStack
      p={'8px 12px'}
      overflow="hidden"
      borderRadius={'16px'}
      w="fit-content"
      maxW="600px"
      color={'#939393'}
      alignItems={'center'}
      gap={'10px'}
      spacing={0}
      fontWeight={400}
      fontSize={'12px'}
      border={'1px solid #AA915D'}
      lineHeight="16px"
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
      _hover={onClick ? { opacity: 0.8 } : undefined}
      {...wrapperProps}>
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        flex={1}
        w="full"
        overflow="hidden">
        <Text
          color="#141718"
          fontSize={'14px'}
          fontWeight={700}
          lineHeight="19px">
          {client?.firstName} {client?.lastName}
        </Text>
        <HStack spacing={0} gap={3} flex={1} w="full">
          <HStack
            spacing={0}
            gap={1}
            alignItems="center"
            flex={1}
            w="full"
            overflow="hidden"
            isTruncated>
            <Icon as={TbMailOpened} fontSize={'16px'} />
            <Text title={client?.email}>{client?.email}</Text>
          </HStack>
          <HStack
            w="full"
            overflow="hidden"
            isTruncated
            spacing={0}
            gap={1}
            flex={1}
            alignItems="center">
            <Icon as={TbMapPin} fontSize={'16px'} />
            <Text title={client?.businessID}>{client?.address}</Text>
          </HStack>
        </HStack>
      </VStack>
      {onRemove && (
        <IconButton
          flexShrink={0}
          size="xs"
          color={'#939393'}
          onClick={onRemove}
          aria-label="Remove supplier"
          icon={<CloseIcon />}
        />
      )}
    </HStack>
  );
};

export default ChosenClient;
