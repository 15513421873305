// Chakra imports
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  HStack,
  Icon,
  Show,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
// Assets
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Business, Product } from 'services/@types';
import { AddProductModal } from 'views/main/managment/products/components/AddProductModal';
import ProductDetails from 'views/main/managment/products/components/ProductDetails';
// import Conversations from 'views/admin/main/others/messages/components/Conversations';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  closeAlertDialog,
  openAlertDialog,
  setProductCategoriesDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  deleteProduct,
  fetchProductCategories,
  fetchProducts,
  setCurrentProduct,
} from 'contexts/redux/product/productSlice';
import { AnimatePresence, motion } from 'framer-motion';
import { AddPackageModal } from './components/AddPackageModal';
import { EmptyProductListPlaceholder } from './components/EmptyProductListPlaceholder';
import NoProducts from './components/NoProducts';
import { ProductCategoriesModal } from './components/ProductCategoriesModal';
import { ProductItemComponent } from './components/ProductItemComponent';
import { useFilteredProducts } from './hooks/useFilteredProducts';
export default function Products() {
  const dispatch = useDispatch<any>();
  const user = useMyUser();
  // Chakra Color Mode
  console.log('Products index.tsx rerendered...');
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const {
    filteredProducts,
    filteringCategories,
    setSearchQuery,
    setFilteringCategories,
    products,
  } = useFilteredProducts();

  const [isEditMode, setIsEditMode] = useState(false);
  const { productCategories, currentProduct } = useSelector(
    (state: RootState) => state.product,
  );
  // Ellipsis modals
  const [, setFoundBusiness] = useState<any>(null);
  const [currentEditableProduct, setCurrentEditableProduct] =
    useState<Partial<Product>>(null);

  const [currentEditablePackage, setCurrentEditablePackage] =
    useState<Partial<Product>>(null);
  // Chakra Color Mode

  const {
    isOpen: isAddProductOpen,
    onOpen: onOpenAddProduct,
    onClose: onAddProductClosed,
  } = useDisclosure();

  const {
    isOpen: isAddPackageOpen,
    onOpen: onOpenAddPackage,
    onClose: onAddPackageClosed,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const handleOnEditProduct = useCallback(
    (product: Product) => {
      setCurrentEditableProduct(product);
      onOpenAddProduct();
    },
    [onOpenAddProduct],
  );
  const handleOnDeleteProduct = useCallback(
    (product: Product) => {
      console.log('delete product ->', product);
      dispatch(
        openAlertDialog({
          title: t('products.modals.delete_product.title'),
          content: t('products.modals.delete_product.description'),
          onConfirm: () => {
            console.log('delete product confirmed ->', product);
            dispatch(deleteProduct(product.id));
            dispatch(closeAlertDialog());
            toast({
              variant: 'main',
              title: t('products.toast.product_deleted'),
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            dispatch(setCurrentProduct(null));
          },
          onClose: () => {
            dispatch(closeAlertDialog());
          },
        }),
      );
    },
    [dispatch, t, toast],
  );

  const handleAddProductClosed = useCallback(() => {
    onAddProductClosed();
    setCurrentEditableProduct(null);
  }, [onAddProductClosed]);

  const handleCategorySelected = useCallback(
    (selected: any) => {
      if (selected.id === 'all') {
        setFilteringCategories([]);
        dispatch(setCurrentProduct(null));
        return;
      }
      const selectedCategory = productCategories.find(
        (bc) => bc.id === selected.id,
      );
      const updatedFilteredCategories = [...filteringCategories];
      if (updatedFilteredCategories.includes(selectedCategory.id)) {
        updatedFilteredCategories.splice(
          updatedFilteredCategories.indexOf(selectedCategory.id),
          1,
        );
      } else {
        updatedFilteredCategories.push(selectedCategory.id);
      }
      setFilteringCategories(updatedFilteredCategories);
      dispatch(setCurrentProduct(null));
    },
    [productCategories, filteringCategories, setFilteringCategories, dispatch],
  );
  useEffect(() => {
    if (!user?.businessID) return;
    dispatch(fetchProducts({}));
    dispatch(fetchProductCategories({ businessID: user.businessID }));
  }, [dispatch, user?.businessID]);
  useEffect(() => {
    if (!currentProduct) {
      onClose();
    }
  }, [currentProduct, onClose]);

  const dropdownProductsCategories = useMemo(() => {
    return [
      { label: t('products.all_product_categories'), id: 'all' },
      ...productCategories.map((bc) => ({
        label:
          bc.name === 'general_category'
            ? t('products.general_category')
            : bc.name,
        id: bc.id,
      })),
    ];
  }, [productCategories, t]);

  const handleOnEditClick = useCallback(() => {
    if (!isEditMode) {
      setIsEditMode(true);
    }
    onOpen();
  }, [onOpen, setIsEditMode, isEditMode]);
  const handleOnOpenClick = useCallback(() => {
    if (isEditMode) {
      setIsEditMode(false);
    }
    onOpen();
  }, [onOpen, setIsEditMode, isEditMode]);

  if (products.length === 0) {
    return (
      <>
        <NoProducts
          onOpenAddProduct={onOpenAddProduct}
          filteringCategories={filteringCategories}
        />
        ;
        <AddProductModal
          isOpen={isAddProductOpen}
          editableProduct={currentEditableProduct}
          onFoundExistingProduct={(business: Business) => {
            console.log('found business ->', business);
            setFoundBusiness(business);
          }}
          onClose={handleAddProductClosed}
        />
      </>
    );
  }

  return (
    <Flex
      w="100%"
      h="100%"
      pt={{ base: '200px', md: '80px', xl: '80px' }}
      flexDirection="column"
      gap={4}>
      <Flex
        wrap={'wrap'}
        w="100%"
        justify={'flex-end'}
        align={'center'}
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        px={{ base: '26px', md: 0 }}
        gap={2}>
        <DropdownField
          w={{ base: '100%', md: '360px' }}
          hideLabel
          mb={0}
          maxW={{ base: '100%', md: '360px' }}
          flex={1}
          options={dropdownProductsCategories}
          topItem={
            <HStack
              onClick={() => {
                dispatch(setProductCategoriesDialog({}));
              }}
              w="100%"
              justify={'end'}>
              <Text color="brand.900" textDecoration={'underline'}>
                {t('products.edit_categories')}
              </Text>
            </HStack>
          }
          placeholder={
            filteringCategories.length > 0
              ? t('products.categories', { count: filteringCategories.length })
              : t('products.all_product_categories')
          }
          onSelected={handleCategorySelected}
          name="category"
          selectedOptions={filteringCategories}
        />

        <HStack
          gap={2}
          flex={1}
          justify="end"
          w={{ base: '100%', md: 'fit-content' }}>
          <Button
            onClick={onOpenAddPackage}
            variant={'h2outlined'}
            // px={12}
            flex={{ base: 1, md: 'unset' }}>
            {t('products.add_package')}
          </Button>
          <Button
            flex={{ base: 1, md: 'unset' }}
            onClick={onOpenAddProduct}
            variant={'h1cta'}
            // px={12}
          >
            {t('products.add_product')}
          </Button>
        </HStack>
      </Flex>

      <Flex
        gap={{ base: '20px', xl: '20px' }}
        h={'100%'}
        flexDirection={{ base: 'column', lg: 'row' }}>
        <Card
          p="0px"
          h="100%"
          px="0px"
          borderRadius={{ base: '0px', md: '30px' }}
          pt="30px"
          overflow={'scroll'}
          pb="30px"
          maxW={{ base: '100%', lg: '360px' }}>
          {/* <Conversations onOpenMessages={onOpen} /> */}
          <Flex px={6} pb={2}>
            <SearchBar
              onChange={(e: any) => setSearchQuery(e.target.value)}
              w="100%"
            />
          </Flex>
          {filteredProducts?.map((product) => (
            <ProductItemComponent
              product={product}
              onOpen={handleOnOpenClick}
              currentProduct={currentProduct}
              key={product.id}
              onEdit={handleOnEditClick}
            />
          ))}
          {products.length === 1 && <EmptyProductListPlaceholder />}
          <VStack display={filteredProducts.length === 0 ? 'flex' : 'none'}>
            <Text>{t('products.no_products_found')}</Text>
          </VStack>
        </Card>

        <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            style={{ width: '100%' }}
            transition={{ duration: 0.15 }}
            key={currentProduct?.id}>
            <Card
              display={{ base: 'none', lg: currentProduct ? 'flex' : 'none' }}
              px="0px"
              w="100%"
              minH="100%"
              maxW={{ base: '100%' }}
              pt={{ base: '20px', md: '30px' }}>
              <ProductDetails
                onDeleteProduct={handleOnDeleteProduct}
                onEditProduct={handleOnEditProduct}
                product={currentProduct}
                status="active"
                name="Roberto Michael"
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
              />
            </Card>
          </motion.div>
        </AnimatePresence>
        <Show below="lg">
          <Drawer
            isOpen={isOpen}
            onCloseComplete={() => {
              setCurrentProduct(null);
            }}
            placement={i18n.dir() === 'rtl' ? 'right' : 'left'}
            onClose={() => {
              onClose();
            }}>
            <DrawerContent
              maxW="full"
              mt={{
                sm: 180,
                md: 200,
              }}
              padding={0}
              borderRadius="0px"
              bg={sidebarBackgroundColor}>
              <HStack
                position="absolute"
                h={{ sm: 54, md: 74, lg: 84 }}
                align="center"
                ps={{ sm: 2, md: 0, lg: 0 }}>
                <DrawerCloseButton
                  boxShadow="unset !important"
                  _hover={{ bg: 'transparent' }}
                  position={'relative'}
                  alignSelf="center"
                  color="secondaryGray.700"
                  right={0}
                  zIndex={1}
                  top={0}>
                  <Icon
                    as={i18n.dir() === 'rtl' ? MdChevronRight : MdChevronLeft}
                    color={searchIconColor}
                    w="28px"
                    h="28px"
                  />
                </DrawerCloseButton>
              </HStack>
              <DrawerBody px="0px">
                <ProductDetails
                  onDeleteProduct={handleOnDeleteProduct}
                  onEditProduct={handleOnEditProduct}
                  product={currentProduct}
                  status="active"
                  name="Roberto Michael"
                  isEditMode={isEditMode}
                  setIsEditMode={setIsEditMode}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Show>
      </Flex>
      <AddProductModal
        isOpen={isAddProductOpen}
        editableProduct={currentEditableProduct}
        onFoundExistingProduct={(business: Business) => {
          console.log('found business ->', business);
          setFoundBusiness(business);
        }}
        onClose={handleAddProductClosed}
      />
      <AddPackageModal
        isOpen={isAddPackageOpen}
        editablePackage={currentEditablePackage}
        onClose={onAddPackageClosed}
      />
      <ProductCategoriesModal />
    </Flex>
  );
}
