import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICurrentDocumentNumber {
  value: number;
  type: string;
  onlyCurrent: boolean;
}

export interface CurrentDocumentNumberState {
  currentDocumentNumber: ICurrentDocumentNumber | null;
}

const initialState: CurrentDocumentNumberState = {
  currentDocumentNumber: null,
};

const currentDocumentNumberSlice = createSlice({
  name: 'currentDocumentNumber',
  initialState,
  reducers: {
    setCurrentDocumentNumber: (
      state,
      action: PayloadAction<ICurrentDocumentNumber>,
    ) => {
      state.currentDocumentNumber = action.payload;
    },
    resetCurrentDocumentNumber: (state) => {
      state.currentDocumentNumber = null;
    },
  },
});

export const { setCurrentDocumentNumber, resetCurrentDocumentNumber } =
  currentDocumentNumberSlice.actions;

export default currentDocumentNumberSlice.reducer;
