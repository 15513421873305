import {
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import Dropzone from 'components/dropzone';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { updateContract } from 'contexts/redux/contract/contractSlice';
import { closeUploadSignedContractDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { IContract, UrlItem } from 'services/@types';
import UploadService from 'services/upload.api';

export default function UploadSignedContractDialog() {
  const myUser = useMyUser();
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.uploadSignedContractDialog,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [signedContract, setSignedContract] = useState<{
    media: UrlItem;
    comment: string;
  } | null>(null);

  const isDisabled = useMemo(() => {
    return !signedContract?.media?.url || !signedContract?.comment;
  }, [signedContract]);

  const signedContracts = useMemo(() => {
    return dialog?.item?.signedContracts || [];
  }, [dialog?.item?.signedContracts]);

  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const handleRemoveFile = useCallback(async () => {
    if (!signedContract?.media?.url) return;

    const [target, targetId, access, fileId] =
      signedContract?.media?.url.split('/');

    // remove from db
    await UploadService.deleteUpload({
      target,
      targetId,
      access,
      fileId,
    });

    // remove from state
    setSignedContract((prev) => ({
      ...prev,
      media: null,
    }));
  }, [signedContract?.media?.url]);

  const handleAddFile = useCallback(
    (urlItem: UrlItem) => {
      setSignedContract((prev) => ({
        ...prev,
        media: urlItem,
      }));
      stopLoading();
    },
    [stopLoading],
  );

  const handleChangeComment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSignedContract((prev) => ({
        ...prev,
        comment: e.target.value,
      }));
    },
    [],
  );

  const onClose = useCallback(
    (isSuccess: boolean = false) => {
      dispatch(closeUploadSignedContractDialog());
      setSignedContract(null);
      if (!isSuccess) {
        handleRemoveFile();
      }
    },
    [dispatch, handleRemoveFile],
  );

  const handleUploadSignedContract = useCallback(async () => {
    const updates: Partial<IContract> = {
      signedContracts: [...signedContracts, signedContract],
    };
    if (dialog?.item?.status === 'sent') {
      updates.status = 'signed';
    }
    const updatedContract = await dispatch(
      updateContract({
        contractId: dialog?.item?.contractId,
        updates,
      }),
    );

    if (updatedContract.meta.requestStatus === 'fulfilled') {
      onClose(true);
      toast({
        title: t('upload_signed_contract_dialog.signed_contract_uploaded'),
        status: 'success',
        variant: 'main',
        position: 'top-right',
      });
    } else {
      toast({
        title: t('upload_signed_contract_dialog.signed_contract_upload_failed'),
        status: 'error',
        position: 'top-right',
      });
    }
  }, [
    dialog?.item?.contractId,
    dialog?.item?.status,
    signedContracts,
    signedContract,
    dispatch,
    onClose,
    toast,
    t,
  ]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={() => onClose()}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        gap="36px"
        minW={{ base: '100%', md: '526px' }}
        p="24px"
        borderRadius={{ base: '0', md: '30px' }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="previewQuoteContactTitle">
            {t('upload_signed_contract_dialog.title')}
          </Text>
          <IconButton
            aria-label="close"
            icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
            onClick={() => onClose()}
          />
        </Flex>
        <Flex flexDir="column" gap="18px">
          {signedContract?.media?.url ? (
            <Flex alignItems="center" gap="12px">
              <Text variant="trackerEventName">
                {signedContract?.media?.displayName}
              </Text>
              <IconButton
                aria-label="close"
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                onClick={handleRemoveFile}
              />
            </Flex>
          ) : (
            <Dropzone
              goldenUploadLabel={t(
                'upload_signed_contract_dialog.upload_file_here_by_clicking_or_drag_and_rop',
              )}
              uploadKey="signed_contract"
              dropzoneOptions={{
                accept: ['application/pdf', 'application/msword'],
                multiple: false,
                maxSize: 25 * 1024 * 1024, // 25MB
              }}
              target="user"
              targetId={myUser?.id}
              access="private"
              h="68px"
              minH="68px"
              onStartUpload={startLoading}
              onUploadCompleted={handleAddFile}
              initialProgress={signedContract ? 100 : 0}
              currentImage={signedContract?.media?.url}
            />
          )}
          <Text variant="uploadSignedContractDialogDropzoneText">
            {t(
              'upload_signed_contract_dialog.uploaded_file_must_be_doc_or_pdf_and_less_then_25_mb',
            )}
          </Text>
        </Flex>
        <TextField
          label={t('upload_signed_contract_dialog.comment')}
          value={signedContract?.comment}
          onChange={handleChangeComment}
          placeholder={t('upload_signed_contract_dialog.comment_placeholder')}
          minH="157px"
        />
        <Button
          w="220px"
          variant="h1cta"
          alignSelf="flex-end"
          isDisabled={isDisabled}
          isLoading={loading}
          onClick={handleUploadSignedContract}>
          {t('upload_signed_contract_dialog.upload')}
        </Button>
      </ModalContent>
    </Modal>
  );
}
