// Chakra imports
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormLabel,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  useDimensions,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck, MdInfoOutline } from 'react-icons/md';
import { RadioButtonItem } from './RadioItem';
// Custom components

export default function Default(props: {
  id?: string;
  label?: string;
  error?: string;
  extra?: JSX.Element;
  placeholder?: string | JSX.Element;
  type?: string;
  isDisabled?: boolean;
  tooltipLabel?: string;
  menuItemProps?: MenuItemProps;
  onSelected?: (value: any | { id: string; label: string }) => void;
  maxItems?: number;
  showRadioButton?: boolean;
  radioButtonChecked?: boolean;
  onRadioButtonChecked?: (value: string) => void;
  closeOnSelect?: boolean;
  showedOptions?: string;
  menuButton?: MenuButtonProps;
  [x: string]: any;
}) {
  const {
    id,
    label,
    extra,
    error,
    placeholder,
    type,
    options,
    selectedOptions,
    onSelected,
    topItem,
    hideLabel,
    mb,
    showRadioButton,
    onRadioButtonChecked,
    radioButtonChecked,
    menuItemProps,
    menuButton,
    formLabelStyles,
    isDisabled = false,
    maxItems,
    closeOnSelect = false,
    tooltipLabel,
    showedOptions,
    showedOptionsIcon,
    downIconProps,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const { t } = useTranslation();
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(dropdownContainerRef);
  const [isSelectionFull, setIsSelectionFull] = useState<boolean>(false);
  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure();
  const getIsDisabled = useCallback(
    (option) =>
      isSelectionFull &&
      !selectedOptions?.includes(
        (option as { id: string })?.id || (option as string),
      ),
    [isSelectionFull, selectedOptions],
  );

  useEffect(() => {
    if (!maxItems) {
      return;
    }
    setIsSelectionFull(maxItems === selectedOptions.length);
  }, [maxItems, selectedOptions]);

  return (
    <Flex direction="column" {...rest} ref={dropdownContainerRef}>
      <HStack>
        {showRadioButton && (
          <RadioButtonItem
            value={id}
            h={10}
            label={''}
            w="fit-content"
            ms="0px"
            checked={radioButtonChecked}
            onChange={onRadioButtonChecked}
          />
        )}
        {label && (
          <FormLabel
            display={hideLabel ? 'none' : 'flex'}
            htmlFor={id}
            mb={1}
            fontSize="sm"
            onClick={onRadioButtonChecked && (() => onRadioButtonChecked(id))}
            color={textColorPrimary}
            fontWeight="500"
            _hover={{ cursor: 'pointer' }}
            {...formLabelStyles}>
            {label}
            {extra && (
              <Text fontSize="sm" fontWeight="400" ms="2px" height={'28px'}>
                {extra}
              </Text>
            )}
          </FormLabel>
        )}
        {tooltipLabel && (
          <Tooltip
            variant="business"
            hasArrow
            isOpen={isOpenTooltip}
            label={tooltipLabel}
            placement="right">
            <span>
              <Icon
                onMouseEnter={onOpenTooltip}
                onMouseLeave={onCloseTooltip}
                onClick={onToggleTooltip}
                as={MdInfoOutline}
                color="brand.400"
                fontSize="18px"
                cursor="pointer"
                ms="0px"
                mt="0px"
                aria-label={''}
              />
            </span>
          </Tooltip>
        )}
      </HStack>
      <Menu closeOnSelect={closeOnSelect}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon {...downIconProps} />}
          leftIcon={showedOptions && showedOptionsIcon}
          size="sm"
          textAlign="start"
          borderRadius={{ base: '16px', md: '2xl' }}
          _active={{ borderColor: 'brand.600' }}
          border="1px solid"
          borderColor={'gray.300'}
          h="50px"
          maxH="50px"
          overflow="hidden"
          disabled={isDisabled}
          fontWeight="500"
          {...menuButton}>
          {showedOptions || placeholder}
        </MenuButton>
        <MenuList
          zIndex={999}
          minWidth={dimensions?.contentBox?.width}
          style={{ maxHeight: 300, overflow: 'scroll' }}
          borderRadius="2xl">
          {topItem && (
            <MenuItem
              _hover={{ backgroundColor: 'transparent', cursor: 'unset' }}>
              {topItem}
            </MenuItem>
          )}
          {options?.map(
            (
              option:
                | {
                    id: string;
                    label: string;
                    icon: JSX.Element;
                    startElement?: JSX.Element;
                  }
                | string,
              key: number,
            ) => (
              <MenuItem
                {...menuItemProps}
                key={key}
                h={'44px'}
                onClick={() => onSelected(option)}
                w="100%"
                isDisabled={getIsDisabled(option)}>
                <HStack w="100%">
                  {/* <Text>{option?.id ? option?.label : t(option)}</Text> */}
                  {(option as { startElement: JSX.Element })?.startElement &&
                    (option as { startElement: JSX.Element })?.startElement}
                  {(option as { label: string; icon?: JSX.Element })?.label ? (
                    <>
                      <Text
                        fontWeight={
                          selectedOptions?.includes(
                            (option as { id: string })?.id ||
                              (option as string),
                          ) && '500'
                        }>
                        {' '}
                        {t(
                          (option as { label: string; icon?: JSX.Element })
                            ?.label,
                        )}
                      </Text>
                      {(option as { label: string; icon?: JSX.Element })?.icon}
                    </>
                  ) : (
                    <Text
                      fontWeight={
                        selectedOptions?.includes(
                          (option as { id: string })?.id || (option as string),
                        ) && '500'
                      }>
                      {t(option as string)}
                    </Text>
                  )}
                  <Flex flex={1} />
                  <Icon
                    as={MdCheck}
                    color="brand.400"
                    fontSize="xl"
                    display={
                      selectedOptions?.includes(
                        (option as { id: string })?.id || (option as string),
                      )
                        ? 'block'
                        : 'none'
                    }
                  />
                </HStack>
              </MenuItem>
            ),
          )}
        </MenuList>
      </Menu>
      <Text
        fontSize="xs"
        color="red.500"
        mt="2px"
        fontWeight={'semibold'}
        display={error ? 'block' : 'none'}>
        {t(error)}
      </Text>
    </Flex>
  );
}
