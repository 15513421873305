import { VStack, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import NoEventsImg from 'assets/svg/event/not-found.svg';

const NoDataFound = () => {
  const { t } = useTranslation();

  return (
    <VStack
      w="100%"
      h="100%"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      display="flex"
      gap="24px"
      fontWeight={500}
      textAlign="center">
      <Image src={NoEventsImg} w="180px" h="180px" alt="Meeting Not Found" />
      <Text color="#00000033" fontSize="24px" lineHeight="26px">
        {t('event.no_data_found')}
      </Text>
      <Text color="#00000033" fontSize="14px" lineHeight="18px">
        {t('event.no_data_message')}
      </Text>
    </VStack>
  );
};

export default NoDataFound;
