import { useQueryClient } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setFileShareDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createInvite, deleteInvite } from 'contexts/redux/invite/inviteSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IFileMedia, Invite, SharedWithMeFileMedia, User } from 'services/@types';
import _uploadService from 'services/upload.api';

const useFileShare = (file: SharedWithMeFileMedia | IFileMedia) => {
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const [imageData, setImageData] = useState<string | null>(null);

  const myBusiness = useMyBusiness();
  const myBusinessId = myBusiness?.id;
  const downloadFile = useCallback(async () => {
    try {
      let imgData = imageData;

      if (!imgData) {
        const response = await _uploadService.getUpload(file.url);
        imgData = URL.createObjectURL(response);
      }
      const a = document.createElement('a');
      a.href = imgData;
      a.download = `${file.name}.${file.type.toLowerCase()}`;
      a.click();
      if (!imageData) {
        setImageData(imgData);
      }
    } catch (error) {
      console.error(error);
    }
  }, [file.name, file.type, file.url, imageData]);

  const viewFile = useCallback(async () => {
    try {
      let imgData = imageData;

      if (!imgData) {
        const response = await _uploadService.getUpload(file.url);
        imgData = URL.createObjectURL(response);
      }
      window.open(imgData, '_blank');
      if (!imageData) {
        setImageData(imgData);
      }
    } catch (error) {
      console.error(error);
    }
  }, [file.url, imageData]);

  const shareFile = useCallback(() => {
    if (!('resourceId' in file)) {
      return;
    }
    dispatch(
      setFileShareDialog({
        item: {
          file,
        },
        onConfirm: async (users: {
          newUsers: (User | { email: string })[];
          removedUsers: Invite[];
        }) => {
          for (const usr of users.newUsers) {
            let invitePayload: Invite;
            if ('id' in usr) {
              invitePayload = {
                businessID: myBusinessId,
                email: usr.email,
                fullName: `${usr.firstName} ${usr.lastName}`,
                type: 'file',
                recipientID: usr.id,
                status: 'accepted',
                phone: usr.phone,
                resourceId: file.resourceId,
              };
            } else {
              const { email } = usr;
              invitePayload = {
                businessID: myBusinessId,
                email,
                fullName: email,
                type: 'file',
                status: 'accepted',
                resourceId: file.resourceId,
              };
            }
            try {
              await dispatch(createInvite(invitePayload));
            } catch (error) {
              console.error(error);
            }
          }

          // handle removing invites
          users.removedUsers.forEach((invite) => {
            dispatch(deleteInvite(invite.id));
          });
          queryClient.invalidateQueries({
            queryKey: ['files'],
          });
          queryClient.invalidateQueries({
            queryKey: ['invites'],
          });
        },
      }),
    );
  }, [dispatch, file, myBusinessId, queryClient]);

  return { downloadFile, viewFile, shareFile };
};

export default useFileShare;
