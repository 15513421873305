import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { IWeather } from 'services/@types';
import WeatherService from 'services/weather.api';
import { RootState } from '../store';

export interface WeatherState {
  data: IWeather | null;
  loading: boolean;
  error: string | null;
}

const initialState: WeatherState = {
  data: null,
  loading: false,
  error: null,
};

export const getWeather = createAsyncThunk(
  'weather/getWeather',
  async (params: { lat: number; lon: number }, { rejectWithValue }) => {
    try {
      const response = await WeatherService.getWeather(params.lat, params.lon);
      console.log('getWeather response', response);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const WeatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWeather.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWeather.fulfilled, (state, action) => {
      state.data = action.payload.weather;
      state.loading = false;
    });
    builder.addCase(getWeather.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const useWeatherData = () => {
  return useSelector((state: RootState) => state.weather.data);
};

export default WeatherSlice.reducer;
