import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  IPayment,
  IPaymentCreateData,
  IPaymentUpdate,
} from '../services/@types';
import paymentService from '../services/payment.api';

export const PAYMENT_KEYS = {
  all: ['payments'] as const,
  lists: () => [...PAYMENT_KEYS.all, 'list'] as const,
  list: (filters: Record<string, any>) =>
    [...PAYMENT_KEYS.lists(), filters] as const,
  details: () => [...PAYMENT_KEYS.all, 'detail'] as const,
  detail: (id: string) => [...PAYMENT_KEYS.details(), id] as const,
};

export const usePayments = (
  queryParams?: Partial<IPayment> & { limit?: number; noSupplier?: boolean },
) => {
  if (!queryParams?.limit) {
    queryParams.limit = 1000;
  }
  return useQuery({
    enabled: !!queryParams?.businessId,
    queryKey: PAYMENT_KEYS.list(queryParams || {}),
    queryFn: () => paymentService.getPayments(queryParams),
  });
};

export const usePayment = (paymentId: string) => {
  return useQuery({
    queryKey: PAYMENT_KEYS.detail(paymentId),
    queryFn: () => paymentService.getPayment(paymentId),
    enabled: !!paymentId,
  });
};
export const useGetEventExpenses = (eventId: string) => {
  return useQuery({
    queryKey: PAYMENT_KEYS.list({ eventId, type: 'expense' }),
    queryFn: () => paymentService.getExpenses({ eventId }),
  });
};

export const useGetEventPayments = (eventId: string) => {
  return useQuery({
    queryKey: PAYMENT_KEYS.list({ eventId, type: 'income' }),
    queryFn: () => paymentService.getIncomes({ eventId }),
  });
};

export const useGetIncomes = (queryParams?: Record<string, any>) => {
  return useQuery({
    queryKey: PAYMENT_KEYS.list({ type: 'income', ...queryParams }),
    queryFn: () => paymentService.getIncomes(queryParams),
  });
};

export const useGetExpenses = (queryParams?: Record<string, any>) => {
  return useQuery({
    queryKey: PAYMENT_KEYS.list({ type: 'expense', ...queryParams }),
    queryFn: () => paymentService.getExpenses(queryParams),
  });
};

export const usePaymentMutations = () => {
  const queryClient = useQueryClient();

  const invalidatePayments = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: PAYMENT_KEYS.lists() });
  }, [queryClient]);

  const createPayment = useMutation({
    mutationFn: async (payment: Partial<IPaymentCreateData>) =>
      await paymentService.createPayment(payment),
    onSuccess: () => invalidatePayments(),
  });

  const updatePayment = useMutation({
    mutationFn: ({
      paymentId,
      updates,
    }: {
      paymentId: string;
      updates: IPaymentUpdate;
    }) => paymentService.updatePayment(paymentId, updates),
    onSuccess: () => invalidatePayments(),
  });

  const deletePayment = useMutation({
    mutationFn: (paymentId: string) => paymentService.deletePayment(paymentId),
    onSuccess: () => invalidatePayments(),
  });

  return {
    createPayment,
    updatePayment,
    deletePayment,
  };
};
