import {
  Avatar,
  Button,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  closeAlertDialog,
  openAlertDialog,
  setAddSeatDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  selectMySeatRole,
  selectSeatInvites,
  updateInvite,
} from 'contexts/redux/invite/inviteSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMoreHorizontal, LuPenLine, LuTrash2 } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { Business, BusinessCreator, Invite } from 'services/@types';

export default function TeamContainer() {
  const dispatch = useDispatch<AppDispatch>();
  const myBusiness: Business<BusinessCreator> =
    useMyBusiness<BusinessCreator>();
  const myUser = useMyUser();
  const myRole = useSelector(selectMySeatRole);
  const seatsInvites = useSelector(selectSeatInvites);

  const { t } = useTranslation();
  const toast = useToast();

  const handleResendInvite = useCallback(
    async (invite: Invite) => {
      const response = await dispatch(
        updateInvite({
          userId: invite.id,
          updates: { status: 'pending' },
        }),
      );
      const payload = response.payload as any;
      console.log('#Redux cancelInvite response ->', payload);
      payload as any;
      if (payload.status === 409) {
        toast({
          title: t(payload.title),
          description: t(payload.content),
          status: 'error',
        });
      } else if (payload.status >= 400) {
        toast({
          title: t('error_messages.400'),
          status: 'error',
        });
      } else {
        toast({
          title: t('invite_seat.invite_resent'),
          status: 'success',
          variant: 'main',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }
    },
    [dispatch, t, toast],
  );

  const handleCancelInvite = useCallback(
    async (invite: Invite, isRemoveAction: boolean = false) => {
      const response = await dispatch(
        updateInvite({
          userId: invite.id,
          updates: { status: 'cancelled' },
        }),
      );
      const payload = response.payload as any;
      console.log('#Redux cancelInvite response ->', payload);
      payload as any;
      if (payload.status === 409) {
        toast({
          title: t(payload.title),
          description: t(payload.content),
          status: 'error',
        });
      } else if (payload.status >= 400) {
        toast({
          title: t('error_messages.400'),
          status: 'error',
        });
      } else {
        toast({
          title: t(
            `invite_seat.${
              isRemoveAction ? 'seat_removed' : 'invite_cancelled'
            }`,
          ),
          status: 'success',
          variant: 'main',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }
    },
    [dispatch, t, toast],
  );

  const handleOpenDialog = useCallback(
    (type: 'cancel' | 'resend' | 'remove', seat: Invite) => {
      const dialogContent = {
        cancel: {
          title: t('the_office.dialogs.cancel_invite.title'),
          content: t('the_office.dialogs.cancel_invite.description'),
          onConfirm: () => {
            console.log('Cancelling invite for:', seat);
            handleCancelInvite(seat);
            dispatch(closeAlertDialog());
          },
        },
        resend: {
          title: t('the_office.dialogs.resend_invite.title'),
          content: t('the_office.dialogs.resend_invite.description', {
            email: seat.email,
          }),
          onConfirm: () => {
            console.log('Resending invite to:', seat.email);
            handleResendInvite(seat);
            dispatch(closeAlertDialog());
          },
        },
        remove: {
          title: t('the_office.dialogs.remove_member.title'),
          content: t('the_office.dialogs.remove_member.description', {
            name: seat.fullName,
          }),
          style: 'destructive' as const,
          onConfirm: () => {
            console.log('Removing member:', seat.fullName);
            handleCancelInvite(seat, true);
            dispatch(closeAlertDialog());
          },
        },
      };

      const selectedDialog = dialogContent[type];

      dispatch(
        openAlertDialog({
          title: selectedDialog.title,
          content: selectedDialog.content,
          confirmLabel: t('dialogs.alert.confirm'),
          cancelLabel: t('dialogs.alert.cancel'),
          onConfirm: selectedDialog.onConfirm,
        }),
      );
    },
    [dispatch, handleCancelInvite, handleResendInvite, t],
  );

  const getItemVariant = useCallback((seat: Invite) => {
    switch (seat?.status) {
      case 'pending':
        return 'h3outlinedYellow';
      case 'accepted':
        return `h3outlined${seat?.role === 'admin' ? 'Brand' : 'DarkGreen'}`;
      default:
        return 'h3outlined';
    }
  }, []);

  return (
    <Card
      flexDirection="column"
      w="100%"
      h="100%"
      flex={1}
      p={{ base: '24px 16px', md: '24px' }}
      maxW={{ base: '100%' }}
      borderRadius={{ base: 0, md: '30px' }}
      overflow="scroll">
      <Flex
        flexDirection="column"
        justifyContent="center"
        wrap="wrap"
        gap={'25px'}>
        <Text variant="cardTitle">{t('the_office.your_team')}</Text>
        <Flex flexDirection="column">
          {(myRole === 'admin'
            ? [
                ...seatsInvites,
                ...Array.from({ length: 5 - seatsInvites.length }),
              ]
            : seatsInvites
          ).map((seat: Invite | any, index) => {
            let canEdit = true;

            if (seat?.recipientID === myBusiness?.createdBy?.id) {
              canEdit = false;
            } else if (seat?.recipientID === myUser.id) {
              canEdit = false;
            } else if (myRole !== 'admin') {
              canEdit = false;
            }

            return (
              <Flex flexDirection={'column'} key={index}>
                <Flex py={6} key={index} justify="space-between" align="center">
                  <Flex gap={2} align="center">
                    {seat && (
                      <Avatar
                        fontSize="12px"
                        variant="main"
                        name={seat?.fullName}
                        borderRadius="50%"
                        overflow="hidden"
                        size="original"
                        w={'32px'}
                        h={'32px'}
                        id={seat?.fullName}
                        objectFit={'cover'}
                        objectPosition={'center'}
                      />
                    )}
                    <Text variant="cardSectionTitle" fontWeight={'normal'}>
                      {`${seat?.fullName || t('the_office.seat')}`}
                      {myUser.id === seat?.recipientID
                        ? ` (${t('the_office.you')})`
                        : ''}
                    </Text>
                  </Flex>

                  <Flex gap={2} align="center">
                    {seat ? (
                      <>
                        <Button
                          onClick={() =>
                            dispatch(
                              setAddSeatDialog({
                                item: { ...seat, editAllowed: canEdit },
                              }),
                            )
                          }
                          disabled={!canEdit}
                          minWidth="96px"
                          height="30px"
                          p="8px 2px !important"
                          style={
                            !canEdit
                              ? { opacity: 1, pointerEvents: 'none' }
                              : {}
                          }
                          variant={getItemVariant(seat)}>
                          {(seat as Invite)?.status === 'pending'
                            ? t('the_office.pending')
                            : (seat as Invite)?.status === 'accepted'
                            ? t(
                                `the_office.add_seat_modal.roles.${seat?.role}.label`,
                              )
                            : t('the_office.set_up')}
                        </Button>
                        {canEdit && (
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<LuMoreHorizontal />}
                              variant="ghost"
                              color={!canEdit ? '#00000088' : '#465D55'}
                              size="sm"
                              disabled={!canEdit}
                            />
                            <MenuList p={5} borderRadius={'3xl'} minW={'140px'}>
                              {(seat as Invite)?.status === 'accepted' ? (
                                <>
                                  <MenuItem
                                    icon={
                                      <LuPenLine size={24} color="#AA915D" />
                                    }
                                    color="#999EA9"
                                    fontSize={'md'}
                                    onClick={() =>
                                      dispatch(
                                        setAddSeatDialog({
                                          item: {
                                            ...seat,
                                            editAllowed: canEdit,
                                          },
                                        }),
                                      )
                                    }>
                                    {t('the_office.edit')}
                                  </MenuItem>
                                  <MenuItem
                                    icon={
                                      <LuTrash2 size={24} color="#F28482" />
                                    }
                                    color="#999EA9"
                                    fontSize={'md'}
                                    onClick={() =>
                                      handleOpenDialog('remove', seat)
                                    }>
                                    {t('the_office.remove')}
                                  </MenuItem>
                                </>
                              ) : (seat as Invite)?.status === 'pending' ? (
                                <>
                                  <MenuItem
                                    onClick={() =>
                                      handleOpenDialog('resend', seat)
                                    }>
                                    {t('the_office.resend')}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleOpenDialog('cancel', seat)
                                    }>
                                    {t('the_office.cancel')}
                                  </MenuItem>
                                </>
                              ) : null}
                            </MenuList>
                          </Menu>
                        )}
                      </>
                    ) : (
                      <Button
                        minWidth="96px"
                        height="30px"
                        p="8px 2px !important"
                        onClick={() => dispatch(setAddSeatDialog({ item: {} }))}
                        variant="h3outlined">
                        {t('the_office.set_up')}
                      </Button>
                    )}
                  </Flex>
                </Flex>
                <Divider />
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      <Spacer />
      {myRole === 'admin' && (
        <Button
          textAlign="center"
          color="rgba(170, 145, 93, 1)"
          variant={'h4'}
          mt={'24px'}>
          {t('the_office.need_more_seats')}
        </Button>
      )}
    </Card>
  );
}
