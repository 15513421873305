import {
  Collapse,
  Flex,
  Icon,
  IconButton,
  Image,
  Switch,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import noNotes from 'assets/img/tracker/noNotes.png';
import noNotesMobile from 'assets/img/tracker/noNotesMobile.png';
import Card from 'components/card/Card';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { setAddTrackerCustomEventDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { fetchTrackedEvents } from 'contexts/redux/trackedEvent/trackedEventSlice';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ITrackerCustomEvent } from 'services/@types';
import TrackItem from './TrackItem';

type TrackerProps = {
  componentTitle?: string;
  customEventCreation?: boolean;
};

export default function Tracker({
  componentTitle,
  customEventCreation = false,
}: TrackerProps) {
  const { t, i18n } = useTranslation();
  const userPreferences = useMyUserPreferences();
  const myUser = useMyUser();
  const dispatch = useDispatch<any>();

  const updateTrackerNotesShown = useCallback(async () => {
    await dispatch(
      updateUserPreferences({
        id: myUser?.id,
        updates: {
          isTrackerNotesShown: !userPreferences?.isTrackerNotesShown,
        },
      }),
    );
  }, [dispatch, myUser?.id, userPreferences?.isTrackerNotesShown]);

  const params = useParams();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { trackedEvents } = useSelector(
    (state: RootState) => state.trackedEvent,
  );
  const { style, ref } = useHighlightAnimation('tracker');

  useQuery({
    queryKey: ['trackedEvents', params?.id],
    queryFn: () =>
      dispatch(
        fetchTrackedEvents({
          eventId: params?.id,
          projectBy: '-updatedAt',
          limit: 1000,
        }),
      ),
    enabled: !!params?.id,
  });

  const openAddCustomEventDialog = useCallback(() => {
    dispatch(
      setAddTrackerCustomEventDialog({
        item: { eventId: params?.id },
      }),
    );
  }, [dispatch, params?.id]);

  return (
    <Card
      ref={ref}
      {...style}
      w="100%"
      h="100%"
      minH={{ base: 'unset', lg: '535px' }}
      maxH="535px"
      overflowY={'auto'}
      flex={1}
      minW={{ base: '100%', lg: '310px' }}
      gap="28px"
      borderRadius={{ base: '0px', md: '30px' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="cardTitle">{componentTitle || t('tracker.title')}</Text>
        <Flex alignItems="center" gap="8px">
          <Text variant="eventSubTitle" fontSize="14px">
            {t('tracker.show_notes')}
          </Text>
          <Switch
            dir={i18n.dir()}
            variant="brand"
            isChecked={!!userPreferences?.isTrackerNotesShown}
            onChange={async () => await updateTrackerNotesShown()}
          />
          {customEventCreation && (
            <IconButton
              bg="brand.400"
              aria-label="plus"
              w="24px"
              h="24px"
              minW="24px"
              cursor="pointer"
              onClick={openAddCustomEventDialog}
              icon={<Icon as={FaPlus} color="warmWhite.100" />}
            />
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="5px" overflowY="auto">
        {trackedEvents?.map((trackedEvent, index) => (
          <TrackItem
            trackedEvent={trackedEvent as ITrackerCustomEvent}
            key={index}
            isLast={trackedEvents.length - 1 === index}
            isNoteVisible={userPreferences?.isTrackerNotesShown}
          />
        ))}
        <Collapse
          in={
            userPreferences?.isTrackerNotesShown &&
            trackedEvents.length === 1 &&
            trackedEvents[0].notes?.length === 0
          }
          animateOpacity>
          <VStack
            w="100%"
            justifyContent="center"
            alignItems="center"
            textAlign="center">
            {isMobile && (
              <Image src={noNotesMobile} alt="no notes" maxW="110px" />
            )}
            {!isMobile && <Image src={noNotes} alt="no notes" maxW="120px" />}
            <Text variant="eventSubTitle">{t('tracked_event.no_notes')}</Text>
            <Text variant="eventTabButtonTitle" maxW="70%">
              {t('tracked_event.no_notes_desc')}
            </Text>
          </VStack>
        </Collapse>
      </Flex>
    </Card>
  );
}
