import { Flex, Image, Text } from '@chakra-ui/react';
import chatsEmptyIcon from 'assets/svg/messenger/chats-empty-icon.svg';
import { HSeparator } from 'components/separator/Separator';
import { RootState } from 'contexts/redux/store';
import useThreads from 'hooks/useThreads';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ThreadListItem from 'views/main/managment/messenger/components/ThreadListItem';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { SearchBar } from './searchBar/SearchBar';
export default function Messages({
  onClose,
  eventId,
}: {
  onClose: () => void;
  eventId?: string;
}) {
  const { appNavigate: navigate } = useAppNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const { newMessageReceived } = useSelector(
    (state: RootState) => state.messenger,
  );

  // fetch only the first 10 threadsResult?.data?.results for navigation bar
  const query = useMemo(() => {
    const query: { name?: string; limit: number } = {
      limit: 10,
    };
    if (search) {
      query['name'] = search;
    }
    return query;
  }, [search, newMessageReceived]);

  const queryKey = useMemo(() => {
    if (eventId && search) {
      return [`fetchEventThreads-${eventId}-${search}`];
    }
    if (eventId) {
      return [`fetchEventThreads-${eventId}`];
    }
    if (search) {
      return [`fetchThreads-${search}`];
    }
    return ['fetchThreads'];
  }, [eventId, search, newMessageReceived]);

  const { threads } = useThreads({
    query,
    queryKey,
  });

  // const threadsResult = useQuery({
  //   queryKey: ['fetchThreads-10'],
  //   queryFn: async () => await threadsService.getThreads(query),
  // });

  return (
    <>
      <Flex
        w="100%"
        mb="20px"
        alignItems="center"
        justifyContent="space-between">
        <Text variant="cardTitle">{t('messenger.messages')}</Text>
      </Flex>
      <Flex
        w="100%"
        display={search.length > 0 || threads?.length !== 0 ? 'flex' : 'none'}>
        <SearchBar
          placeholder={t('messenger.search_placeholder')}
          onChange={(e: any) => setSearch(e.target.value)}
          value={search}
          w="100%"
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        overflowY="auto"
        maxH="60vh">
        {threads?.length === 0 && search.length === 0 && (
          <>
            <Image
              src={chatsEmptyIcon}
              w={90}
              h={70}
              alt="No Conversation Found"
              mb={14}
            />
            <Text fontSize={'2xl'} fontWeight="bold">
              {t('messenger.no_messages_found')}
            </Text>
            <Text mt={6} fontSize="sm" color="primary.100">
              {t('messenger.no_messages_found_description')}
            </Text>
          </>
        )}
        {threads?.map((thread, key) => (
          <Flex key={key} w="100%" flexDirection="column" gap="10px" mt={3}>
            <ThreadListItem
              thread={thread}
              bg="transparent"
              width="100%"
              onClick={() => {
                navigate(`/main/messenger/${thread?.id}`);
                onClose();
              }}
            />
            <HSeparator />
          </Flex>
        ))}
      </Flex>
      <Text
        textAlign="center"
        mt={6}
        variant="eventLink"
        onClick={() => {
          navigate('/main/messenger');
          onClose();
        }}
        cursor="pointer">
        {t('messenger.go_to_messenger')}
      </Text>
    </>
  );
}
