import { Flex, Hide, Icon, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoTriangleSharp } from 'react-icons/io5';
import { FilesHeader, IFileMedia } from 'services/@types';
import FileListItem from './FileListItem';

const headers: (FilesHeader | '')[] = [
  'name',
  'date',
  'type',
  'size',
  'event_description',
  'shared_with',
  '',
];

interface FilesListProps {
  files: IFileMedia[];
  sortBy: FilesHeader;
  handleSort: (header: FilesHeader) => void;
}

const FilesList = ({ files, sortBy, handleSort }: FilesListProps) => {
  const { t } = useTranslation();

  return (
    <VStack w="100%" spacing={0} align="stretch" overflowY="hidden" h="100%">
      <Hide below="md">
        <Flex py={4} px={6} bg="white" gap={2} flexShrink={0}>
          {headers.map((header, index) => (
            <Flex
              key={header}
              flex={
                header === 'event_description'
                  ? '0 0 270px'
                  : index === headers.length - 1
                  ? '0 0 24px'
                  : 1
              }
              alignItems="center"
              fontSize="14px"
              fontWeight={sortBy === header ? '600' : '500'}
              color="#00000099">
              {header ? (
                <Text
                  whiteSpace={'nowrap'}
                  onClick={() => handleSort(header)}
                  cursor={header && 'pointer'}>
                  {t(`files.files_list.${header.toLowerCase()}`)}
                </Text>
              ) : (
                ''
              )}
              {header && (
                <Icon
                  as={IoTriangleSharp}
                  color="#93C3B3"
                  h="10px"
                  ml={2}
                  transition="all 0.2s ease-in-out"
                  transform={
                    sortBy === header ? 'rotate(60deg)' : 'rotate(0deg)'
                  }
                />
              )}
            </Flex>
          ))}
        </Flex>
      </Hide>
      <VStack spacing={0} align="stretch" overflowY="auto" flex={1} h="100%">
        {files.map((file) => (
          <FileListItem key={`${file.entityId}-${file.name}`} file={file} />
        ))}
      </VStack>
    </VStack>
  );
};

export default FilesList;
