// Chakra imports
// Assets
import { Flex } from '@chakra-ui/react';
import Splash from 'components/splash';
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import useGoogleSignin from 'views/auth/signUp/hooks/useGoogleSignin';
import { useAppNavigate } from 'hooks/useAppNavigate';
function Token() {
  const { appNavigate: navigate } = useAppNavigate();
  useGoogleSignin();
  const location = useLocation();
  // Chakra color mode

  useEffect(() => {
    if (window.location.toString().includes('access_token')) {
      return;
    } else {
      navigate('/main/dashboard');
    }
  }, [navigate, location]);
  return (
    <Flex w={'100dvw'} h={'100dvh'}>
      <Splash forceShow />
    </Flex>
  );
}

export default Token;
