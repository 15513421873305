import { Icon, Tooltip, TooltipProps, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { MdInfoOutline } from 'react-icons/md';

interface AppTooltipProps {
  label: string;
  icon?: IconType;
  placement?: TooltipProps['placement'];
  iconProps?: {
    [x: string]: any;
  };
}
export const AppTooltip: React.FC<AppTooltipProps> = ({
  children,
  label,
  icon,
  iconProps,
  placement = 'auto',
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure();
  return (
    <Tooltip
      hasArrow
      isOpen={isOpenTooltip}
      label={t(label)}
      placement={placement}
      variant="business"
      {...rest}>
      <span
        style={{
          display: 'flex',
        }}>
        <Icon
          fontSize="24px"
          onClick={onToggleTooltip}
          onMouseEnter={onOpenTooltip}
          onMouseLeave={onCloseTooltip}
          as={icon || MdInfoOutline}
          color="brand.400"
          {...iconProps}
        />
      </span>
    </Tooltip>
  );
};
