import { Flex, Spinner, VStack } from '@chakra-ui/react';
import useFiles from '../hooks/useFiles';
import FilesList from './FilesList';
import NoFiles from './NoFiles';

const SentFiles = () => {
  const { search, handleSort, sortBy, isLoading, files } = useFiles();

  return (
    <VStack overflow="auto" h="100%" spacing={0} flex={1}>
      {files.length && <FilesList files={files} sortBy={sortBy} handleSort={handleSort} />}
      {search === '' && files.length === 0 && <NoFiles />}
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" w="100%">
          <Spinner />
        </Flex>
      )}
    </VStack>
  );
};

export default SentFiles;
