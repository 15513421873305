import {
  Collapse,
  Flex,
  Image,
  Switch,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import noNotes from 'assets/img/tracker/noNotes.png';
import noNotesMobile from 'assets/img/tracker/noNotesMobile.png';
import Card from 'components/card/Card';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ITrackerCustomEvent } from 'services/@types';
import trackedEventsService from 'services/trackedEvent.api';
import TrackItem from '../../managment/events/eventInfo/components/TrackItem';
interface TrackerProps {
  cardStyles?: any;
}
export default function Tracker(props: TrackerProps) {
  const { cardStyles = {} } = props;
  const { t, i18n } = useTranslation();
  const userPreferences = useMyUserPreferences();
  const myUser = useMyUser();
  const dispatch = useDispatch<any>();

  const updateTrackerNotesShown = useCallback(async () => {
    await dispatch(
      updateUserPreferences({
        id: myUser?.id,
        updates: {
          isTrackerNotesShown: !userPreferences?.isTrackerNotesShown,
        },
      }),
    );
  }, [dispatch, myUser?.id, userPreferences?.isTrackerNotesShown]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { style, ref } = useHighlightAnimation('tracker');
  const myBusiness = useMyBusiness();

  const trackedEvents = useQuery({
    queryKey: ['dashboardTrackedEvents'],
    queryFn: () =>
      trackedEventsService.getTrackedEvents({
        businessId: myBusiness?.id,
        sortBy: '-createdAt',
        projectBy: '-updatedAt',
      }),
  }).data;

  return (
    <Card
      ref={ref}
      {...style}
      w={{ base: '100%' }}
      h="100%"
      flex={1}
      gap="28px"
      borderRadius={{ base: '0px', md: '18px' }}
      minW={{ base: '100%', lg: 310, ...cardStyles.minW }}
      maxW={510}
      {...cardStyles}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="cardTitle">{t('tracker.title')}</Text>
        <Flex alignItems="center" gap="8px">
          <Text variant="eventSubTitle" fontSize="14px">
            {t('tracker.show_notes')}
          </Text>
          <Switch
            dir={i18n.dir()}
            variant="brand"
            isChecked={!!userPreferences?.isTrackerNotesShown}
            onChange={async () => await updateTrackerNotesShown()}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="5px" overflowY="auto">
        {trackedEvents?.results?.map((trackedEvent, index) => (
          <TrackItem
            trackedEvent={trackedEvent as ITrackerCustomEvent}
            key={index}
            isLast={trackedEvents?.results?.length - 1 === index}
            isNoteVisible={!!userPreferences?.isTrackerNotesShown}
            showEventName
          />
        ))}
        <Collapse
          in={
            !!userPreferences?.isTrackerNotesShown &&
            trackedEvents?.results?.length === 1 &&
            trackedEvents?.results[0]?.notes?.length === 0
          }
          animateOpacity>
          <VStack
            w="100%"
            justifyContent="center"
            alignItems="center"
            textAlign="center">
            {isMobile && (
              <Image src={noNotesMobile} alt="no notes" maxW="110px" />
            )}
            {!isMobile && <Image src={noNotes} alt="no notes" maxW="120px" />}
            <Text variant="eventSubTitle">{t('tracked_event.no_notes')}</Text>
            <Text variant="eventTabButtonTitle" maxW="70%">
              {t('tracked_event.no_notes_desc')}
            </Text>
          </VStack>
        </Collapse>
      </Flex>
    </Card>
  );
}
