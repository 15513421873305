import { Flex } from '@chakra-ui/react';
import { useMyAlbums } from 'contexts/redux/albums/albumSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Album } from 'services/@types';
import { fetchAlbums } from '../../../contexts/redux/albums/albumSlice';
import { AddAlbumModal } from './components/AddAlbumModal';
import { AddPhotoModal } from './components/AddPhotoModal';
import { AddVideoLinkModal } from './components/AddVideoLinkModal';
import { AlbumComponent } from './components/AlbumComponent';
import { NewAlbumBanner } from './components/NewAlbumBanner';

export default function Gallery(props: { [x: string]: any }) {
  const dispatch = useDispatch<any>();
  const albums = useMyAlbums();

  useEffect(() => {
    dispatch(fetchAlbums({ sortBy: 'createdAt:desc' }));
  }, [dispatch]);

  return (
    <Flex
      gap={5}
      direction={{ base: 'column' }}
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      w="100%">
      <NewAlbumBanner />
      <Flex wrap="wrap" gap={5} w="100%">
        {albums?.map((album: Album) => (
          <AlbumComponent album={album} />
        ))}
      </Flex>

      <AddPhotoModal />
      <AddVideoLinkModal />
      <AddAlbumModal />
    </Flex>
  );
}
