import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { IContract } from 'services/@types';
import { isValidEmail } from 'utils/isValidEmail';

interface ICreateContractDetailsProps {
  contract: IContract;
  handleChange: (path: string, value: any) => void;
  currencySymbol: string;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleAddFile: (event: any) => void;
  clearFile: (type: 'document' | 'quote') => void;
  error: string[];
  setError: (value?: (prev: string[]) => string[]) => void;
}
export default function CreateContractDetails(
  props: ICreateContractDetailsProps,
) {
  const { contract, handleChange, clearFile, error, setError } = props;
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="24px">
      {contract?.media?.find((m) => m.type === 'document') && (
        <Flex alignItems="center" gap="8px">
          <Text variant="contractFileText">
            {contract?.media?.find((m) => m.type === 'document')?.displayName}
          </Text>
          <IconButton
            icon={<Icon as={FiX} color="#939393" />}
            aria-label="delete"
            onClick={() => {
              clearFile('document');
            }}
          />
        </Flex>
      )}
      <InputField
        label={t('create_contract.send_contract_to')}
        placeholder={t('create_contract.send_contract_to_placeholder')}
        w="100%"
        borderColor={error.includes('sentTo') ? 'red' : 'gray.300'}
        value={contract?.sentTo}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange('sentTo', e.target.value);
          setError(
            (prev: string[]) =>
              prev?.filter((item: string) => item !== 'sentTo') ?? [],
          );
        }}
        errorLabel={
          error.includes('sentTo') &&
          !isValidEmail(contract?.sentTo) &&
          t('create_contract.email_error')
        }
      />
      <InputField
        label={t('create_contract.legal_text')}
        placeholder={t('create_contract.legal_text_placeholder')}
        tooltipTopLabel={t('create_contract.legal_text_tooltip')}
        w="100%"
        value={contract?.legalText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange('legalText', e.target.value);
        }}
      />
    </Flex>
  );
}
