import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  AppDialogProps,
  closeFileSharedWithDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { deleteInvite } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IFileMedia, Invite, User } from 'services/@types';
import FileSharedWithUserItem from './FileSharedWithUserItem';

function FileSharedWithDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const myUser = useMyUser();
  const myUserId = myUser?.id;
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const fileSharedWithDialog = useSelector(
    (state: RootState) => state.dialogs.fileSharedWithDialog,
  );
  const dialog = fileSharedWithDialog as AppDialogProps;
  const allInvites = useSelector((state: RootState) => state.invites.invites);

  const fileInvitesSentFromMe = useMemo(() => {
    return allInvites.filter(
      (invite) =>
        invite.type === 'file' &&
        invite.status === 'accepted' &&
        (myUserId ? invite.senderID === myUserId : true) &&
        invite.resourceId === dialog?.item?.file?.resourceId,
    );
  }, [allInvites, myUserId, dialog?.item?.file?.resourceId]);

  const [selectedUsers, setSelectedUsers] = useState<
    Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>[]
  >([]);
  const [selectedExternalUsers, setSelectedExternalUsers] = useState<string[]>(
    [],
  ); // (email as string)[]

  const handleClose = useCallback(() => {
    dispatch(closeFileSharedWithDialog());
  }, [dispatch]);

  const handleStopShare = useCallback(
    (email: string) => {
      const inviteToRemove = fileInvitesSentFromMe.find(
        (invite) => invite.email === email,
      );
      if (inviteToRemove) {
        dispatch(deleteInvite(inviteToRemove.id));
      }
      if (fileInvitesSentFromMe.length === 1) {
        handleClose();
      }
      queryClient.invalidateQueries({
        queryKey: ['files'],
      });
      queryClient.invalidateQueries({
        queryKey: ['invites'],
      });
    },
    [dispatch, fileInvitesSentFromMe, handleClose, queryClient],
  );

  useEffect(() => {
    if (dialog?.item?.file as IFileMedia) {
      const file = dialog.item.file as IFileMedia;

      const filteredFileInvites = fileInvitesSentFromMe.filter(
        (invite: Invite) => invite.resourceId === file.resourceId,
      );

      const selUsers: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
      }[] = [];
      const extUsers: string[] = [];
      filteredFileInvites.forEach((invite: Invite) => {
        if (invite.recipientID) {
          const [firstName, lastName] = invite.fullName.split(' ');
          selUsers.push({
            id: invite.recipientID,
            email: invite.email,
            firstName,
            lastName,
          });
        } else {
          extUsers.push(invite.email);
        }
      });
      setSelectedUsers(selUsers);
      setSelectedExternalUsers(extUsers);
    }
  }, [dialog?.item, fileInvitesSentFromMe]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size={isMobile ? 'full' : 'sm'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0px', md: '30px' }}
        minH={{ base: '100%', md: '50dvh' }}>
        <ModalBody
          p="24px 16px"
          w="full"
          h="100%"
          display="flex"
          flexDir="column">
          <VStack w="100%" gap={4} spacing={0} h={'full'} pb={4}>
            <HStack w="100%" justifyContent="space-between" spacing={0}>
              <Text
                variant="cardSectionTitle"
                fontSize={'base'}
                fontWeight={700}
                color="#141718">
                {t('files.dialog.shared_with')}
              </Text>
              <ModalCloseButton
                position="relative"
                top={0}
                right={0}
                left={0}
                justifyContent={'center'}
                alignItems="center"
                display={'flex'}
                boxShadow="none !important"
              />
            </HStack>
            <VStack
              w="100%"
              maxH={{ base: '100%', md: '400px' }}
              flexShrink={0}
              overflowY="auto"
              gap={2}
              display="flex"
              flexDirection="column"
              h="100%"
              flex={1}>
              {selectedUsers.map((user) => (
                <FileSharedWithUserItem
                  key={user.id}
                  userId={user.id}
                  email={user.email}
                  handleStopShare={() => handleStopShare(user.email)}
                />
              ))}
              {selectedExternalUsers.map((email) => (
                <FileSharedWithUserItem
                  key={email}
                  email={email}
                  handleStopShare={() => handleStopShare(email)}
                />
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default FileSharedWithDialog;
