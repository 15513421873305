import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import {
  AppDialogProps,
  closeSendQuoteDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';

export default function SendQuoteDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { sendQuoteDialog } = useSelector((state: RootState) => ({
    sendQuoteDialog: state.dialogs.sendQuoteDialog,
  }));
  const dialog = sendQuoteDialog as AppDialogProps;

  const { appNavigate: navigate } = useAppNavigate();

  const [form, setForm] = useState<{
    to: string;
    cc: string;
  }>({
    to: '',
    cc: '',
  });

  const handleClose = useCallback(() => {
    dispatch(closeSendQuoteDialog());
    setForm({
      to: '',
      cc: '',
    });
  }, [dispatch]);

  const handleSendQuote = useCallback(async () => {
    dispatch(showSplashDialog({ title: 'dialogs.splash.please_wait' }));

    navigate(
      dialog?.item?.navigateUrl ||
        `/main/finance/quotes/preview/${dialog?.item?.quoteId}?share=true`,
    );
  }, [dispatch, dialog?.item?.quoteId, dialog?.item?.navigateUrl, navigate]);

  const handleSend = useCallback(() => {
    handleSendQuote();
    handleClose();
  }, [handleClose, handleSendQuote]);

  const handleShareBodyChange = useCallback((e: any) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="24px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text variant="cardTitle">
                {t('quote_preview.send_quote_dialog.title')}
              </Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <InputField
              placeholder={t('quote_preview.send_quote_dialog.to_placeholder')}
              label={t('quote_preview.send_quote_dialog.to')}
              value={form?.to}
              name="to"
              onChange={handleShareBodyChange}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
            />
            <HStack alignItems="end" gap="12px">
              <InputField
                w="100%"
                flex="1"
                value={form?.cc}
                name="cc"
                onChange={handleShareBodyChange}
                placeholder={t(
                  'quote_preview.send_quote_dialog.cc_placeholder',
                )}
                label={t('quote_preview.send_quote_dialog.cc')}
                labelProps={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#00000099',
                  ps: '10px',
                }}
              />
              <Icon
                as={FiPlus}
                bg="brand.400"
                w="32px"
                h="32px"
                cursor="pointer"
                color="white"
                borderRadius="8px"
                onClick={() => {}}
              />
            </HStack>
            <Button
              w="280px"
              variant="h1cta"
              alignSelf="center"
              onClick={handleSend}>
              {t('quote_preview.send_quote_dialog.send')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
