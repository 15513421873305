import {
  Button,
  Divider,
  Flex,
  IconButton,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useGetEntity } from 'queries/entity';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Business } from 'services/@types';

import ContactDetails from './components/ContactDetails';
import Header from './components/Header';
import NoteAndTerms from './components/NoteAndTerms';
import ProductsList from './components/ProductsList';
import Summary from './components/Summary';
import usePreviewQuote from './hooks/usePreviewQuote';

export default function PreviewQuote() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const myUser = useMyUser();

  const { data: myBusiness } = useGetEntity<Business>(
    'business',
    params?.businessId || myUser?.businessID,
  );
  const {
    memoizedQuote,
    memoizedIsDownloading,
    handleUpdateQuote,
    forcedDesktopView,
    componentRef,
    setForcedDesktopView,
    setIsDownloading,
    handleDownloadPdf,
    isContract,
    memoDiscount,
    handleSendQuote,
  } = usePreviewQuote();

  return (
    <Flex
      w={'100%'}
      pt={{
        base: '190px',
        md: '80px',
        xl: '80px',
      }}
      pb={{
        base: '100px',
        md: '0',
      }}
      flexDirection="column"
      gap="8px">
      <Card
        w={forcedDesktopView.state ? '1016px' : '100%'}
        ref={componentRef}
        gap={{
          base: forcedDesktopView.state ? 5 : '24px',
          md: 5,
        }}
        p="24px"
        borderRadius={{
          base: forcedDesktopView.state ? '30px' : 0,
          md: '30px',
        }}>
        <Header business={myBusiness} />
        <Divider borderColor="#00000033" />
        <ContactDetails
          setIsDownloading={setIsDownloading}
          isDownloading={memoizedIsDownloading}
          handleDownload={() => {
            setForcedDesktopView({ state: true, executor: handleDownloadPdf });
          }}
          createdAt={memoizedQuote?.createdAt}
          quoteNumber={memoizedQuote?.quoteNumber}
          clientName={memoizedQuote?.clients
            .map(
              (client) =>
                `${client?.client?.firstName || ''} ${
                  client?.client?.lastName || ''
                }`,
            )
            .join(' ')}
          numberOfGuests={memoizedQuote?.numberOfGuests}
          location={memoizedQuote?.location}
          totalAmount={memoizedQuote?.generalPaymentDetails?.totalPayment}
          currency={memoizedQuote?.currency}
          eventId={memoizedQuote?.eventId}
        />
        <ProductsList
          products={memoizedQuote?.products}
          currency={memoizedQuote?.currency}
        />
        <Divider borderColor="#00000033" />
        <Flex
          justify="space-between"
          gap="24px"
          flexDirection={{
            base: forcedDesktopView.state ? 'row' : 'column',
            md: 'row',
          }}>
          <NoteAndTerms
            comment={memoizedQuote?.comment}
            paymentTerms={memoizedQuote?.paymentTerms}
            legalText={memoizedQuote?.legalText}
          />
          <Summary
            currency={memoizedQuote?.currency}
            total={memoizedQuote?.generalPaymentDetails?.total}
            discount={memoDiscount}
            totalPayment={
              memoizedQuote?.generalPaymentDetails?.totalIncludingDiscount
            }
            vat={memoizedQuote?.generalPaymentDetails?.vat}
            amountToPay={memoizedQuote?.generalPaymentDetails?.totalPayment}
            paymentInInstallments={memoizedQuote?.paymentInInstallments}
          />
        </Flex>
        {!memoizedIsDownloading && (
          <Flex
            justify={{
              base: forcedDesktopView.state ? 'flex-end' : 'center',
              md: 'flex-end',
            }}>
            <Flex flexDirection="column" gap="12px">
              <Flex alignItems="center" gap="8px" justifyContent="end">
                <Switch
                  dir={i18n.dir()}
                  variant="brand"
                  isChecked={isContract}
                  onChange={handleUpdateQuote}
                />
                <Text variant="eventSubTitle" fontSize="14px">
                  {t('quote_preview.send_quote_as_contract')}
                </Text>
                <Tooltip
                  hasArrow
                  variant="business"
                  arrowShadowColor="#00000010"
                  label={t('quote_preview.tooltip_label')}
                  aria-label="Add or remove members to your account. manage account permissions">
                  <IconButton
                    aria-label="info"
                    bg="transparent"
                    icon={<MdInfoOutline />}
                    color="#7BA395"
                  />
                </Tooltip>
              </Flex>

              <Button
                w={{
                  base: forcedDesktopView.state ? '280px' : '171px',
                  md: '280px',
                }}
                variant="h1cta"
                onClick={handleSendQuote}>
                {t('quote_preview.send_to')}
              </Button>
            </Flex>
          </Flex>
        )}
      </Card>
    </Flex>
  );
}
