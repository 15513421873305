import { Box, Collapse, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { setAddTrackerNoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { updateTrackedEvent } from 'contexts/redux/trackedEvent/trackedEventSlice';
import { format } from 'date-fns';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { INote, ITrackedEvent, ITrackerCustomEvent } from 'services/@types';
import ClientService from 'services/client.api';
import EventService from 'services/event.api';
import SupplierService from 'services/supplier.api';
import UserService from 'services/user.api';
import TrackItemNote from './TrackItemNote';
export default function TrackItem(props: {
  trackedEvent: ITrackerCustomEvent;
  isLast?: boolean;
  isNoteVisible?: boolean;
  showEventName?: boolean;
}) {
  const { trackedEvent, isLast = false, isNoteVisible, showEventName } = props;
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch<any>();
  const { i18n, t } = useTranslation();
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);

  const suggestedUsers = useQuery({
    queryKey: ['suggestedUsers'],
    queryFn: () => UserService.getUsers({}),
  });

  const clients = useQueries({
    queries: trackedEvent?.participants?.clients?.map((client) => ({
      queryKey: ['client', client],
      queryFn: () => ClientService.getClient(client as string),
    })),
  });

  const suppliers = useQueries({
    queries: trackedEvent?.participants?.suppliers?.map((supplier) => ({
      queryKey: ['supplier', supplier],
      queryFn: () => SupplierService.getSupplier(supplier as string),
    })),
  });

  const eventResult = useQuery({
    queryKey: [`getEvent-${trackedEvent?.eventId}`],
    queryFn: () => EventService.getEvent(trackedEvent?.eventId),
    enabled: !!trackedEvent?.eventId,
  });

  const suppliersNames = useMemo(() => {
    return suppliers.map((supplier) => supplier.data?.name || '').join(', ');
  }, [suppliers]);

  const clientsNames = useMemo(() => {
    return clients
      .map((client) =>
        client.data?.firstName || client.data?.lastName
          ? `${client.data?.firstName || ''} ${client.data?.lastName || ''}`
          : '',
      )
      .join(', ');
  }, [clients]);

  const eventName = useMemo(
    () => eventResult?.data?.name,
    [eventResult?.data?.name],
  );

  const participants = useMemo(() => {
    return `${clientsNames} ${suppliersNames}`;
  }, [clientsNames, suppliersNames]);

  const generatedTitle = useMemo(() => {
    if (trackedEvent?.type && trackedEvent?.meetingDateAndTime) {
      return `${t(`tracker.custom_event_type.${trackedEvent?.type}`)} ${t(
        'tracker.at',
      )} ${format(
        new Date(trackedEvent?.meetingDateAndTime),
        'dd/MM/yy HH:mm',
      )} ${t('tracker.from')} ${participants}`;
    }

    return t(trackedEvent.title, trackedEvent?.variables);
  }, [
    trackedEvent.title,
    trackedEvent?.variables,
    participants,
    trackedEvent?.type,
    t,
    trackedEvent?.meetingDateAndTime,
  ]);

  const handleCreateTrackerNote = useCallback(() => {
    dispatch(
      setAddTrackerNoteDialog({ item: { trackedEventId: trackedEvent.id } }),
    );
  }, [dispatch, trackedEvent]);

  const handleDeleteNote = useCallback(
    async (id: string) => {
      const newNotesArr = trackedEvent.notes?.filter((note) => {
        const comparison = note._id !== id;
        return comparison;
      });
      await dispatch(
        updateTrackedEvent({
          id: trackedEvent.id,
          notes: newNotesArr,
        } as ITrackedEvent),
      );
    },
    [trackedEvent.id, dispatch, trackedEvent.notes],
  );

  const handleEditNote = useCallback(
    (note: INote) => {
      dispatch(
        setAddTrackerNoteDialog({
          item: { ...note, trackedEventId: trackedEvent.id },
        }),
      );
    },
    [dispatch, trackedEvent.id],
  );

  const handleNavigateToEvent = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      navigate(`/main/event/${trackedEvent?.eventId}`);
    },
    [navigate, trackedEvent?.eventId],
  );

  return (
    <Flex
      justifyContent="space-between"
      onMouseEnter={() => setIsBtnVisible(true)}
      onMouseLeave={() => setIsBtnVisible(false)}>
      <Flex gap="17px">
        <Flex flexDirection="column" gap="5px">
          <Box
            w="15px"
            minH="15px"
            border="2px solid #AA915D"
            bg="#AA915D"
            borderRadius="50%"
          />
          {!isLast && (
            <Box
              w="calc(16px / 2)"
              h="100%"
              minH="50px"
              borderRight={i18n.dir() === 'ltr' ? '2px dashed #AA915D' : 'none'}
              borderLeft={i18n.dir() === 'rtl' ? '2px dashed #AA915D' : 'none'}
            />
          )}
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex flexDirection="column">
            <Flex w="100%" gap="5px">
              <Text
                display="inline"
                variant="cardSectionInfo"
                lineHeight="14px"
                ps="0px">
                {generatedTitle}
              </Text>
              {showEventName && eventName && (
                <Text
                  display="inline"
                  cursor="pointer"
                  noOfLines={1}
                  maxW={48}
                  wordBreak="break-all"
                  onClick={handleNavigateToEvent}
                  variant="trackerEventName">{`"${eventName}"`}</Text>
              )}
            </Flex>
            <Text variant="taskCardSubTitle">
              {format(new Date(trackedEvent.createdAt), 'dd MMM yyyy')}
            </Text>
          </Flex>
          <Collapse in={isNoteVisible} animateOpacity>
            <Flex flexDirection="column" gap="16px">
              {trackedEvent.notes?.map((note, index) => (
                <TrackItemNote
                  note={note}
                  key={index}
                  onDeleteNote={() => handleDeleteNote(note._id)}
                  onEditNote={() => handleEditNote(note)}
                  suggestedUsers={suggestedUsers.data?.results || []}
                />
              ))}
            </Flex>
          </Collapse>
        </Flex>
      </Flex>

      <IconButton
        aria-label="Add"
        variant="actionIconBlueSmall"
        icon={<Icon as={FaPlus} color="warmWhite.100" />}
        onClick={handleCreateTrackerNote}
        display="flex"
        justifySelf="flex-end"
        transition="all 0.4s linear"
        visibility={{
          base: 'visible',
          md: isBtnVisible ? 'visible' : 'hidden',
        }}
      />
    </Flex>
  );
}
