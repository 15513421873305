// src/features/businesses/userSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { queryClient } from 'App';
import { IEvent, IEventFinance } from 'services/@types';
import EventService from 'services/event.api';
import { closeSplashDialog, showSplashDialog } from '../dialog/dialogsSlice';
import { updateQuote } from '../quote/quoteSlice';
export interface EventState {
  events: IEvent[];
  eventsResult: {
    result: IEvent[];
    filter: {
      name: string;
      status: string[];
      type: string;
      month: string;
    };
    pagination: {
      page: number;
      limit: number;
      totalPages: number;
      totalResults: number;
    };
  };
  event: IEvent | null;
  similarEvents: IEvent[];
  loading: boolean;
  updatingEventId: string | null;
  currentEventTab: 'main' | 'client_file' | 'finance' | 'supplier_assignment';
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
  finance: IEventFinance | null; // event finance calculates
}

const initialState: EventState = {
  eventsResult: {
    result: [],
    filter: {
      name: '',
      status: [],
      type: '',
      month: '',
    },
    pagination: {
      page: 1,
      limit: 100,
      totalPages: 0,
      totalResults: 0,
    },
  },
  events: [],
  event: null,
  currentEventTab: 'main',
  updatingEventId: null,
  similarEvents: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
  },
  finance: null,
};

export const getEvents = createAsyncThunk(
  'events/getEvents',
  async (params: Record<string, any>, { dispatch, rejectWithValue }) => {
    dispatch(showSplashDialog({}));
    try {
      const response = await EventService.getEvents(params);
      dispatch(closeSplashDialog());
      return response;
    } catch (error) {
      dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const getEventsResult = createAsyncThunk(
  'events/getEventsResult',
  async (params: Record<string, any>) => {
    const response = await EventService.getEvents(params);
    return response;
  },
);

export const getEvent = createAsyncThunk(
  'events/getEvent',
  async (eventId: string, { dispatch }) => {
    dispatch(showSplashDialog({}));
    const response = await EventService.getEvent(eventId);
    dispatch(closeSplashDialog());
    return response;
  },
);

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (event: IEvent, { dispatch }) => {
    dispatch(showSplashDialog({}));
    const response = await EventService.createEvent(event);
    if (response) {
      queryClient.invalidateQueries({
        queryKey: ['dashboardTrackedEvents'],
      });
    }
    dispatch(closeSplashDialog());
    return response;
  },
);

export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async (
    { updates, id }: { updates: Partial<IEvent>; id: string },
    { dispatch },
  ) => {
    dispatch(showSplashDialog({}));
    const response = await EventService.updateEvent(id, updates);
    if (response) {
      queryClient.invalidateQueries({
        queryKey: ['dashboardTrackedEvents'],
      });
    }
    dispatch(closeSplashDialog());
    return response;
  },
);

export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (eventId: string, { dispatch }) => {
    dispatch(showSplashDialog({}));
    await EventService.deleteEvent(eventId);
    dispatch(closeSplashDialog());
    return eventId;
  },
);

export const getSimilarEvents = createAsyncThunk(
  'events/getSimilarEvents',
  async (params: Record<string, any>, { dispatch }) => {
    const response = await EventService.getSimilarEvents(params);
    return response;
  },
);

export const fetchEventFinance = createAsyncThunk(
  'events/fetchEventFinance',
  async (eventId: string) => {
    const response = await EventService.getEventFinance(eventId);
    return response;
  },
);

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    updateEventsList: (state, action) => {
      state.events = action.payload;
    },
    updateSimilarEventsList: (state, action) => {
      state.similarEvents = action.payload;
    },
    setCurrentEventTab: (state, action) => {
      state.currentEventTab = action.payload;
    },
    setEventResultFilter: (state, action) => {
      state.eventsResult.filter = action.payload;
    },
    setEventResultPagination: (state, action) => {
      state.eventsResult.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.events = action.payload.results as IEvent[];
        state.pagination = {
          page: action.payload.page,
          limit: action.payload.limit,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalResults,
        };
        state.loading = false;
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(getEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.loading = false;
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(createEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.events.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(updateEvent.pending, (state, action) => {
        state.updatingEventId = action.meta.arg.id;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        );
        state.updatingEventId = null;
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        );
        state.eventsResult.result = state.eventsResult.result.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        );
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.updatingEventId = null;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.events = state.events.filter(
          (event) => event.id !== action.payload,
        );
        state.loading = false;
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(getSimilarEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSimilarEvents.fulfilled, (state, action) => {
        state.similarEvents = action.payload;
        state.loading = false;
      })
      .addCase(getSimilarEvents.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(getEventsResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventsResult.fulfilled, (state, action) => {
        state.eventsResult.result = action.payload.results;
        state.eventsResult.pagination = {
          page: action.payload.page,
          limit: action.payload.limit,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalResults,
        };
        state.loading = false;
      })
      .addCase(getEventsResult.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(fetchEventFinance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEventFinance.fulfilled, (state, action) => {
        state.finance = action.payload;
        state.loading = false;
      })
      .addCase(fetchEventFinance.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(updateQuote.fulfilled, (state, action) => {
        // update finance
        const updatedExpense = state.finance?.expenses.pending.find(
          (expense) => expense.quoteId === action.payload.id,
        );
        if (updatedExpense) {
          updatedExpense.isPaid =
            action.payload.paymentInInstallments.installments[
              updatedExpense.paymentIndex
            ].isPaid;
          updatedExpense.dueDate =
            action.payload.paymentInInstallments.installments[
              updatedExpense.paymentIndex
            ].dueDate;
          state.finance.expenses.paid.push(updatedExpense);
          state.finance.expenses.pending =
            state.finance.expenses.pending.filter(
              (expense) => expense.quoteId !== action.payload.id,
            );

          state.finance.expenses.totalPaid = state.finance.expenses.paid.reduce(
            (acc, expense) => acc + Number(expense.amount),
            0,
          );
          state.finance.expenses.totalPending =
            state.finance.expenses.pending.reduce(
              (acc, expense) => acc + Number(expense.amount),
              0,
            );
        }
      });
  },
});

export const {
  updateEventsList,
  updateSimilarEventsList,
  setCurrentEventTab,
  setEventResultFilter,
  setEventResultPagination,
} = eventSlice.actions;

export default eventSlice.reducer;
