import {
  Flex,
  Hide,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { IContract } from 'services/@types';
import EventService from 'services/event.api';
import QouteService from 'services/quote.api';

export default function ContractListItemDraft({
  contract,
  handleDeleteContract,
}: {
  contract: IContract;
  handleDeleteContract: (
    e: React.MouseEvent<HTMLDivElement>,
    id: string,
  ) => void;
}) {
  const { appNavigate: navigate } = useAppNavigate();
  const { t } = useTranslation();

  const customers = useMemo(() => {
    return contract?.clients
      ?.map((client) =>
        client.client.type === 'business'
          ? client.client.company
          : client.client.firstName + ' ' + client.client.lastName,
      )
      .join(', ');
  }, [contract?.clients]);

  const handleNavigateToEdit = useCallback(() => {
    navigate(`/main/finance/contracts/${contract?.id}`);
  }, [contract?.id, navigate]);

  const eventResult = useQuery({
    queryKey: [`getEvent-${contract?.eventId}`],
    queryFn: () => EventService.getEvent(contract?.eventId),
    enabled: !!contract?.eventId,
  });

  const quote = useQuery({
    queryKey: [`getQuote-${contract?.quoteId}`],
    queryFn: () => QouteService.getQuote(contract?.quoteId),
    enabled: !!contract?.quoteId,
  });

  const eventName = useMemo(
    () => eventResult?.data?.name,
    [eventResult?.data?.name],
  );

  const quoteNumber = useMemo(() => {
    return quote?.data?.quoteNumber;
  }, [quote?.data?.quoteNumber]);

  return (
    <Flex
      key={contract.id}
      justify="space-between"
      py={4}
      px={6}
      cursor="pointer"
      onClick={handleNavigateToEdit}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      fontSize="14px">
      <Flex
        flex="1"
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        gap={2}>
        <Flex flex={1} align="center">
          <Text variant="clientDetailInfo">{customers}</Text>
        </Flex>
        <Flex flex={1} align="center">
          <Text variant="clientDetailInfo">
            {contract?.contractDate &&
              format(contract.contractDate, 'dd.MM.yy')}
          </Text>
        </Flex>
        <Flex flex={1} align="center">
          <Text variant="clientDetailInfo">{eventName}</Text>
        </Flex>
        <Hide below="md">
          <Flex flex={1} align="center">
            <Text variant="clientDetailInfo">{quoteNumber}</Text>
          </Flex>
        </Hide>
        <Flex flex="0 0 60px" justify="center" align="center">
          <Menu>
            <MenuButton onClick={(e) => e.stopPropagation()}>
              <Icon as={FiMoreHorizontal} color="#93C3B3" w="24px" h="24px" />
            </MenuButton>
            <MenuList>
              <Hide above="md">
                {quoteNumber && (
                  <MenuItem>
                    <Text variant="eventTabButtonTitle">
                      {t('contracts.quote_number_info', { quoteNumber })}
                    </Text>
                  </MenuItem>
                )}
                {contract?.isDraft && (
                  <MenuItem onClick={handleNavigateToEdit}>
                    <Text variant="eventTabButtonTitle">
                      {t('contracts.edit_draft')}
                    </Text>
                  </MenuItem>
                )}
              </Hide>
              <MenuItem
                h="44px"
                onClick={(e: any) => handleDeleteContract(e, contract.id)}>
                <Text variant="eventTabButtonTitle">
                  {t('contracts.delete')}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
}
