import { Box, Flex, Image, Text } from '@chakra-ui/react';
import NoFilesYet from 'assets/img/quote/NoQuotesCreated.png';
import { useTranslation } from 'react-i18next';

export default function NoFiles() {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h="100%"
      flexDirection="column"
      gap="36px">
      <Image w="320px" src={NoFilesYet} alt="" />
      <Box gap="16px" textAlign="center">
        <Text variant="noEventsTitle">{t('files.no_files_yet')}</Text>
      </Box>
    </Flex>
  );
}
