import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPayment } from 'services/@types';
import paymentService from 'services/payment.api';
import { DropdownFieldOptions } from '../components/FinanceCard';

export default function useReports() {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<string>('');
  const [data, setData] = useState<{
    payments: IPayment[];
    totalPaidAmount: number;
    numberOfEvents: number;
  }>(null);
  const options = useMemo(
    () =>
      DropdownFieldOptions.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  );

  const selectedOptions = useMemo(() => {
    const selected = options.find((option) => option.value === dateRange);
    return selected ? [selected] : [];
  }, [options, dateRange]);

  const showedOptions = useMemo(() => {
    const showed = options.find((option) => option.value === dateRange);
    return showed ? showed.label : '';
  }, [options, dateRange]);

  const onSelected = useCallback((event: { value: string }) => {
    setDateRange(event.value);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let range = {};
        if (dateRange !== '') {
          range = { dateRange };
        }
        const data = await paymentService.getPaymentsOverviewReports(range);
        setData(data);
      } catch (error) {
        console.error('Error fetching reports data: ', error);
      }
    };
    fetchData();
  }, [dateRange]);

  return { options, selectedOptions, showedOptions, onSelected, data };
}
