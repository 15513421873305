import { HStack, Text, VStack } from '@chakra-ui/react';
import { formatCurrency } from 'helpers/formatNumber';

import { IQuoteProductListItem, Product } from 'services/@types';

interface QuoteItemProps {
  number: string | number;
  product: IQuoteProductListItem & { populatedProduct?: Product };
  currency: string;
}

export const QuoteItem = ({
  currency,
  product: {
    productId,
    quantity,
    description,
    isVatIncluded,
    amount,
    net,
    populatedProduct,
  },
  number,
}: QuoteItemProps) => {
  return (
    <HStack
      spacing="0"
      w="full"
      alignItems="flex-start"
      letterSpacing="-0.02em">
      <Text
        fontSize="16px"
        fontWeight="bold"
        color="black"
        w="40px"
        lineHeight="18px"
        wordBreak="break-all">
        {number}
      </Text>
      <VStack
        flex={1}
        align="flex-start"
        w="124px"
        gap={1}
        spacing={0}
        lineHeight="18px">
        <Text fontSize="16px" fontWeight="bold" color="black">
          {populatedProduct?.name || description}
        </Text>
        {populatedProduct?.subItems.map(
          (subProduct: string | Product, index: number) => (
            <Text
              key={index}
              mt={index === 0 ? 4 : 0}
              color="blackAlpha.600"
              fontSize="14px"
              wordBreak="break-all"
              lineHeight="14px">
              {(subProduct as Product).name}
            </Text>
          ),
        )}
      </VStack>
      <VStack
        maxW={160}
        align="start"
        w="144px"
        fontSize="14px"
        gap={1}
        spacing={0}>
        <Text
          fontSize="sm"
          fontWeight="bold"
          color="black"
          flex={1}
          lineHeight="16px"
          noOfLines={1}
          wordBreak="break-all">
          {formatCurrency(net, currency)}
        </Text>
        {populatedProduct?.subItems.map(
          (subProduct: string | Product, index: number) => (
            <Text
              key={index}
              color="blackAlpha.600"
              noOfLines={1}
              fontSize="sm"
              flex={1}
              wordBreak="break-all">
              {formatCurrency((subProduct as Product).price, currency)}
            </Text>
          ),
        )}
      </VStack>
    </HStack>
  );
};
