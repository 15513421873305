import { AxiosInstance, AxiosResponse } from 'axios';
import { BusinessPreferences } from './@types';
import { createAxiosInstance } from './axios.util';

class BusinessPreferencesService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async getBusinessPreferences(): Promise<BusinessPreferences> {
    const response: AxiosResponse<BusinessPreferences> = await this.api.get(
      '/business-preferences',
    );
    return response.data;
  }

  public async updateBusinessPreferences(
    updates: Partial<
      Omit<BusinessPreferences, 'id' | 'businessID' | 'createdAt' | 'updatedAt'>
    >,
  ): Promise<BusinessPreferences> {
    const response: AxiosResponse<BusinessPreferences> = await this.api.patch(
      '/business-preferences',
      updates,
    );
    return response.data;
  }
}

const _businessPreferencesService = new BusinessPreferencesService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _businessPreferencesService;
