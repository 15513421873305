import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setAddPaymentDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { formatCurrency, formatNumber } from 'helpers/formatNumber';
import { useGetEventExpenses, useGetEventPayments } from 'hooks/usePayment';
import useGetEvent from 'queries/event';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function useFinance() {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const business = useMyBusiness();
  const { data: event } = useGetEvent(params?.id);
  const { data: expenses } = useGetEventExpenses(params?.id);
  const { data: incomes } = useGetEventPayments(params?.id);

  const upcomingIncomes = useMemo(() => {
    return incomes?.filter((income) => income.isPaid === false) || [];
  }, [incomes]);

  const upcomingExpenses = useMemo(() => {
    return expenses?.filter((expense) => expense.isPaid === false) || [];
  }, [expenses]);

  const paidExpenses = useMemo(() => {
    return expenses?.filter((expense) => expense.isPaid === true) || [];
  }, [expenses]);

  const paidIncomes = useMemo(() => {
    return incomes?.filter((income) => income.isPaid === true) || [];
  }, [incomes]);

  // calculate the total expected, paid, and unpaid expenses
  const expensesStat = useMemo(
    () => [
      {
        label: 'expected',
        value: formatCurrency(
          upcomingExpenses.reduce((acc, expense) => acc + expense.amount, 0) +
            paidExpenses.reduce((acc, expense) => acc + expense.amount, 0),
        ),
        bgGradient: 'linear(to-r, brand.900, brand.800)',
      },
      {
        label: 'paid',
        value: formatCurrency(
          paidExpenses.reduce((acc, expense) => acc + expense.amount, 0),
        ),
        bgGradient: 'linear(to-r, brand.900, brand.800)',
      },
      {
        label: 'unpaid',
        value: formatCurrency(
          upcomingExpenses.reduce((acc, expense) => acc + expense.amount, 0),
        ),
        bgGradient: 'linear(to-r, brand.900, brand.800)',
      },
    ],
    [paidExpenses, upcomingExpenses],
  );

  const incomeStat = useMemo(
    () => [
      {
        label: 'expected',
        value: formatCurrency(
          upcomingIncomes.reduce((acc, income) => acc + income.amount, 0) +
            paidIncomes.reduce(
              (acc, income) => acc + (income.paidAmount || 0),
              0,
            ),
        ),
        bgGradient: 'linear(to-r, brand.400, brand.600)',
      },
      {
        label: 'received',
        value: formatCurrency(
          paidIncomes.reduce(
            (acc, income) => acc + (income.paidAmount || 0),
            0,
          ),
        ),
        bgGradient: 'linear(to-r, brand.400, brand.600)',
      },
      {
        label: 'due',
        value: formatCurrency(
          upcomingIncomes.reduce(
            (acc, income) => acc + income.amount - (income.paidAmount || 0),
            0,
          ),
        ),
        bgGradient: 'linear(to-r, brand.400, brand.600)',
      },
    ],
    [paidIncomes, upcomingIncomes],
  );

  const handlePlusClick = useCallback(
    (type: 'income' | 'expense') => {
      dispatch(setAddPaymentDialog({ item: { type, event } }));
    },
    [dispatch, event],
  );

  console.log(
    `use finance income stuff -> ${JSON.stringify(
      upcomingIncomes,
    )} , ${JSON.stringify(paidIncomes)}, ${JSON.stringify(
      upcomingExpenses,
    )} , ${JSON.stringify(paidExpenses)} incomes : ${JSON.stringify(
      incomes,
    )} expenses : ${JSON.stringify(expenses)}`,
  );
  return {
    expensesStat,
    upcomingExpenses,
    paidExpenses,
    upcomingIncomes,
    paidIncomes,
    incomeStat,
    handlePlusClick,
    expenses,
    incomes,
  };
}
