import { Button, HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type IClient, type IClientContact } from 'services/@types';
import AddClientModal from 'views/main/managment/clients/components/AddClientModal';
import useClients from 'views/main/managment/clients/hooks/useClients';
import ChosenClient from '../ChosenClient';
import { AnimatePresence, motion } from 'framer-motion';

type SelectClientsProps = {
  handleOpenClientDialog: () => void;
  handleChange: (key: string, value: any) => void;
  clients: {
    client: IClient;
    contacts: IClientContact[] | string[];
  }[];
  hasError: boolean;
  label?: string;
  setClient?: (client: { client: IClient; contacts: IClientContact[] }) => void;
  containerProps?: StackProps;
};

const SelectClients = ({
  handleOpenClientDialog,
  handleChange,
  clients,
  hasError,
  setClient,
  label,
  containerProps,
}: SelectClientsProps) => {
  const { t } = useTranslation();

  const { onOpenAddClient, isAddClientOpen, onAddClientClosed } = useClients({
    sentClient: setClient,
  });

  const clientsList = useMemo(
    () => [
      ...(clients
        ?.filter((c) => c.client?.type === 'private')
        ?.map((c) => c.client) ?? []),
      ...(clients
        ?.filter((c) => c.client?.type === 'business')
        ?.flatMap(
          (c: { contacts: IClientContact[] | string[] }) =>
            c.contacts as IClientContact[],
        ) ?? []),
    ],
    [clients],
  );
  return (
    <>
      <Stack gap={3} spacing={0} transition="all 0.2s ease" {...containerProps}>
        <Text
          fontSize={'base'}
          lineHeight={'28px'}
          fontWeight={700}
          color="black">
          {label ?? t('create_event.label.client_details')}
        </Text>
        <HStack
          w="full"
          spacing={0}
          gap={3}
          flexDir={{ base: 'column', md: 'row' }}
          px="5px">
          <Button
            margin={'0 !important'}
            onClick={handleOpenClientDialog}
            variant="h1cta"
            w="full"
            maxW={{ base: 'unset', md: '320px' }}
            style={{
              outline: hasError ? '1px solid #FF554A' : 'none',
              outlineOffset: '4px',
            }}>
            {t('create_event.label.select_clients')}
          </Button>
          <Button
            margin={'0 !important'}
            onClick={onOpenAddClient}
            variant="h2outlined"
            w="full"
            maxW={{ base: 'unset', md: '320px' }}>
            {t('create_event.label.create_new_client')}
          </Button>
        </HStack>
        <HStack gap={2} wrap="wrap" w="full" spacing={0}>
          <AnimatePresence>
            {clientsList.map((c) => (
              <motion.div
                layout
                key={c.id}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 25,
                }}>
                <ChosenClient
                  client={'type' in c ? c : undefined}
                  contact={'type' in c ? undefined : (c as IClientContact)}
                  removeClient={(clientId, contactId) => {
                    if (contactId) {
                      const contactClient = clients.find((ic) =>
                        ic.contacts?.some(
                          (c) => (c as IClientContact).id === contactId,
                        ),
                      );
                      const newClients =
                        contactClient.contacts.length === 1
                          ? clients.filter(
                              (ic) => ic.client.id !== contactClient?.client.id,
                            )
                          : clients.map((ic) => {
                              if (ic.client.id === contactClient?.client.id) {
                                return {
                                  ...ic,
                                  contacts: (
                                    ic.contacts as IClientContact[]
                                  ).filter((c) => c.id !== contactId),
                                };
                              }
                              return ic;
                            });
                      handleChange('clients', newClients);
                    } else {
                      handleChange(
                        'clients',
                        clients.filter((ic) => ic.client.id !== clientId),
                      );
                    }
                  }}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </HStack>
      </Stack>
      <AddClientModal isOpen={isAddClientOpen} onClose={onAddClientClosed} />
    </>
  );
};

export default SelectClients;
