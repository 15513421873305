import { Flex, Hide, Text, useDisclosure } from '@chakra-ui/react';
import { format } from 'date-fns';
import prettyBytes from 'pretty-bytes';
import { SharedWithMeFileMedia } from 'services/@types';
import SharedWithMeFileItemActions from './SharedWithMeFileItemActions';

type SharedWithMeFileListItemProps = {
  file: SharedWithMeFileMedia;
};

const SharedWithMeFileListItem = ({ file }: SharedWithMeFileListItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      justify="space-between"
      py={4}
      px={6}
      w="full"
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      zIndex={isOpen ? 2 : 1}
      fontSize="14px">
      <Flex
        flex="1"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        gap={2}
        fontWeight="500">
        <Flex flex={1} w={{ base: 'auto', md: '100px' }} align="center">
          <Text noOfLines={1} w="full" overflow="hidden">
            {file.name}
          </Text>
        </Flex>
        <Hide below="md">
          <Flex flex={1} align="center" fontWeight={'bold'}>
            {file.uploadDate && format(new Date(file.uploadDate), 'dd.MM.yy')}
          </Flex>
          <Flex flex={1} align="center">
            {file.type}
          </Flex>
          <Flex flex={1} align="center">
            {file.size ? prettyBytes(file.size) : '0 B'}
          </Flex>
          <Flex flex={1} align="center" fontWeight={'bold'}>
            {file.sender.email}
          </Flex>
          <Flex position="relative" zIndex={2}>
            <SharedWithMeFileItemActions
              file={file}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </Flex>
        </Hide>
      </Flex>
      <Hide above="md">
        <Flex
          position="relative"
          zIndex={2}
          width={{ base: '100%', sm: 'auto' }}
          flexShrink={{ base: 0, md: 1 }}
          flexGrow={{ base: 0 }}
          order={{ base: 1, md: 0 }}>
          <SharedWithMeFileItemActions
            file={file}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          />
        </Flex>
      </Hide>
      <Hide above="md">
        <Flex
          flex="0 1 100px"
          justify={{ base: 'space-between', md: 'flex-start' }}
          flexDirection="column">
          <Text>
            {file.uploadDate &&
              format(new Date(file.uploadDate), 'dd MMM, yyyy')}
          </Text>
        </Flex>
      </Hide>
    </Flex>
  );
};

export default SharedWithMeFileListItem;
