import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Invite } from 'services/@types';
import _inviteService from 'services/invite.api';

interface UseGetInviteOptions {
  enabled?: boolean;
}

const getInviteQueryKey = (inviteId: string): QueryKey => ['invite', inviteId];

const getInviteQueryOptions = (inviteId: string) => ({
  queryKey: getInviteQueryKey(inviteId),
  queryFn: () => _inviteService.getInvite(inviteId),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const useGetInvite = (
  inviteId: string,
  options: UseGetInviteOptions = {},
): UseQueryResult<Invite, Error> => {
  return useQuery({
    ...getInviteQueryOptions(inviteId),
    enabled: options.enabled !== false && !!inviteId,
  });
};

useGetInvite.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  inviteId: string,
) => {
  await queryClient.prefetchQuery(getInviteQueryOptions(inviteId));
};

useGetInvite.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  inviteId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getInviteQueryKey(inviteId) });
};

export const useCreateInvite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Partial<Invite>) =>
      _inviteService.createInvite(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites'] });
    },
    onError: (error) => {
      console.error('Failed to create invite:', error);
    },
  });
};
export const useGetInviteTokenData = (token: string) => {
  return useQuery({
    queryKey: ['inviteTokenData', token],
    queryFn: () => _inviteService.getInviteByToken(token),
    enabled: !!token,
  });
};
export default useGetInvite;
