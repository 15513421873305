import { Flex, Hide } from '@chakra-ui/react';
import { AppDialogProps } from 'contexts/redux/dialog/dialogsSlice';
import { setUploadQuoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { format } from 'date-fns';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IQuote, QuoteStatus } from 'services/@types';
import QuoteListItemEventCard from './QuoteListItemEventCard';
import RenderQuoteStatus from './QuoteStatus';
import { QuoteThreeDotsMenu } from './QuoteThreeDotsMenu';

export default function QuoteListItem({ quote }: { quote: IQuote }) {
  const dispatch = useDispatch<AppDispatch>();
  const { appNavigate: navigate } = useAppNavigate();

  const totalPayment = useMemo(() => {
    const total =
      quote?.totalAmount || quote?.generalPaymentDetails?.totalPayment;
    if (!total) return '';
    const currency = quote?.currency === 'ILS' ? '₪' : '$';
    return `${currency} ${total.toLocaleString()}`;
  }, [
    quote?.totalAmount,
    quote?.generalPaymentDetails?.totalPayment,
    quote?.currency,
  ]);

  const customers = useMemo(() => {
    const customers = quote?.clients
      .map(
        (c) =>
          `${c?.client?.firstName || ''} ${
            c?.client?.lastName || c?.client?.company || ''
          }`,
      )
      .join(', ');
    return customers;
  }, [quote?.clients]);

  const handleViewQuote = useCallback(() => {
    if (quote.totalAmount) {
      dispatch(
        setUploadQuoteDialog({
          item: {
            quoteId: quote.id,
          },
        } as AppDialogProps),
      );
    } else {
      navigate(`/main/finance/quotes/${quote.id}`);
    }
  }, [quote.totalAmount, quote.id, dispatch, navigate]);

  return (
    <Flex
      key={quote.id}
      justify="space-between"
      onClick={handleViewQuote}
      py={4}
      px={6}
      gap={2}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      fontSize="sm">
      <Flex
        flex="1"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        gap={2}
        fontWeight="500">
        <Hide below="md">
          <Flex flex={1} fontWeight="700" align="center">
            {quote?.createdAt && format(quote.createdAt, 'dd.MM.yy')}
          </Flex>
          <Flex flex={1} fontWeight="700" align="flex-start">
            {quote?.quoteNumber || ''}
          </Flex>
        </Hide>
        <Flex
          flex={1}
          fontWeight={{ base: '700', md: '500' }}
          align="center"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <QuoteListItemEventCard
            eventId={quote?.eventId}
            quoteName={quote?.description}
          />
        </Flex>
        <Flex flex={1} align="center">
          {totalPayment}
        </Flex>
        <Flex flex={1} align="center">
          {customers}
        </Flex>
        <Hide below="md">
          <Flex flexShrink={0} align="center" minW="80px">
            {/* TODO: add version */}
            {1}
          </Flex>
          <Flex flex={1} align="center">
            <RenderQuoteStatus status={quote.status as QuoteStatus} />
          </Flex>
          <Flex flex="0 0 60px" justify="center" align="center">
            <QuoteThreeDotsMenu id={quote.id} />
          </Flex>
        </Hide>
      </Flex>
      <Hide above="md">
        <Flex
          flex="0 1 100px"
          justify={{ base: 'space-between', md: 'flex-start' }}
          flexDirection="column">
          <Flex justify="flex-end">
            <QuoteThreeDotsMenu id={quote.id} />
          </Flex>
          <Flex>
            {quote?.createdAt && format(quote.createdAt, 'dd MMM, yyyy')}
          </Flex>
        </Flex>
      </Hide>
    </Flex>
  );
}
