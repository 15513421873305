import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IFileMedia } from 'services/@types';
import useFileShare from '../hooks/useFileShare';

const FileItemActions = ({
  isOpen,
  onOpen,
  onClose,
  file,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  file: IFileMedia;
}) => {
  const { t } = useTranslation();
  const { downloadFile, viewFile, shareFile } = useFileShare(file);
  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <MenuButton w="100%">
        <Icon as={FiMoreHorizontal} w="20px" h="20px" color="#465D55" />
      </MenuButton>
      <MenuList
        w="150px"
        minW="unset"
        maxW="150px !important"
        border="transparent"
        backdropFilter="blur(63px)"
        bg="white"
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
        borderRadius="20px"
        p="15px">
        <MenuItem
          isDisabled={file.entity !== 'contract' && file.entity !== 'quote'}
          transition="0.2s linear"
          p="0px"
          borderRadius="8px"
          _hover={{ color: 'brand.400' }}
          _active={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          mb="10px"
          onClick={viewFile}>
          <Text fontSize="sm" fontWeight="400">
            {t('files.preview')}
          </Text>
        </MenuItem>
        <MenuItem
          transition="0.2s linear"
          p="0px"
          borderRadius="8px"
          _hover={{ color: 'brand.400' }}
          _active={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          mb="10px"
          onClick={shareFile}>
          <Text fontSize="sm" fontWeight="400">
            {t('files.share')}
          </Text>
        </MenuItem>
        <MenuItem
          transition="0.2s linear"
          p="0px"
          borderRadius="8px"
          _hover={{ color: 'brand.400' }}
          _active={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          onClick={downloadFile}>
          <Text fontSize="sm" fontWeight="400">
            {t('files.download')}
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default FileItemActions;
