import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { queryClient } from 'App';
import { IQuote } from 'services/@types';
import QouteService from 'services/quote.api';
import { RootState } from '../store';

export interface ProductState {
  qoutes: IQuote[];
  currentQoute: IQuote | null;
  qoute: IQuote | null;
  newQuoteEventId: string | null;
  selectedQuote: IQuote | null;
  loading: boolean;
  error: string | null;
  shareBody: {
    cc?: string[];
    to?: string;
    quote: Blob;
  } | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: ProductState = {
  qoutes: [],
  qoute: null,
  loading: false,
  newQuoteEventId: null,
  error: null,
  currentQoute: null,
  selectedQuote: null,
  shareBody: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const createQuote = createAsyncThunk(
  'qoutes/createQuote',
  async (params: IQuote, { rejectWithValue }) => {
    try {
      const response = await QouteService.createQuote(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getQuotes = createAsyncThunk(
  'qoutes/getQuotes',
  async (
    params: { page?: number; limit?: number; requestQuoteToken?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await QouteService.getQuotes(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchQuote = createAsyncThunk(
  'qoutes/fetchQuote',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await QouteService.getQuote(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateQuote = createAsyncThunk(
  'qoutes/updateQuote',
  async (
    { id, updates }: { id: string; updates: Partial<IQuote> },
    { rejectWithValue },
  ) => {
    try {
      const response = await QouteService.updateQuote(id, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteQuote = createAsyncThunk(
  'qoutes/deleteQuote',
  async (id: string, { rejectWithValue }) => {
    try {
      await QouteService.deleteQuote(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const shareQuote = createAsyncThunk(
  'qoutes/shareQuote',
  async (
    {
      file,
      eventId,
      quoteId,
    }: { file: File; eventId?: string; quoteId?: string },
    { rejectWithValue, getState },
  ) => {
    try {
      const { shareBody } = (getState() as RootState).quote;
      const formData = new FormData();
      formData.append('file', file);
      if (shareBody?.to) {
        formData.append('to', shareBody?.to);
      }
      if (shareBody?.cc?.length > 0) {
        shareBody?.cc?.forEach((cc) => {
          formData.append('cc', cc);
        });
      }

      if (eventId) {
        formData.append('eventId', eventId);
      }

      await QouteService.shareQuote(quoteId, formData);

      queryClient.invalidateQueries({
        queryKey: ['dashboardTrackedEvents'],
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchNextPageQuotes = createAsyncThunk(
  'qoutes/fetchNextPageQuotes',
  async (
    params: { page?: number; limit?: number; requestQuoteToken?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await QouteService.getQuotes(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const qouteSlice = createSlice({
  name: 'qoutes',
  initialState,
  reducers: {
    setShareBody: (state, action) => {
      state.shareBody = action.payload;
    },
    setNewQuoteEventId: (state, action) => {
      state.newQuoteEventId = action.payload;
    },
    setSelectedQuote: (state, action) => {
      state.selectedQuote = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createQuote.fulfilled, (state, action) => {
      state.loading = false;
      state.qoute = action.payload;
      state.qoutes.push(action.payload);
    });
    builder.addCase(createQuote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getQuotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuotes.fulfilled, (state, action) => {
      state.loading = false;
      state.qoutes = action.payload.results as IQuote[];
      state.pagination = {
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };
    });
    builder.addCase(getQuotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchQuote.fulfilled, (state, action) => {
      state.loading = false;
      state.qoute = action.payload;
    });
    builder.addCase(fetchQuote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateQuote.fulfilled, (state, action) => {
      state.loading = false;
      state.qoute = action.payload;
      state.qoutes = state.qoutes.map((qoute) =>
        qoute.id === action.payload.id ? action.payload : qoute,
      );
    });
    builder.addCase(updateQuote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteQuote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuote.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteQuote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchNextPageQuotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNextPageQuotes.fulfilled, (state, action) => {
      state.loading = false;
      state.qoutes = [...state.qoutes, ...action.payload.results];
    });
    builder.addCase(fetchNextPageQuotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setShareBody, setSelectedQuote, setNewQuoteEventId } =
  qouteSlice.actions;

export default qouteSlice.reducer;
