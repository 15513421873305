import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEvent } from 'services/@types';

export interface AppDialogProps {
  title?: string;
  content?: string;
  item?: any;
  style?: 'destructive' | 'success' | 'info' | 'warning';
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: (p?: any) => void;
  onClose?: (p?: any) => void;
  event?: IEvent;
}

export enum DialogKeys {
  addVideoLinkDialog = 'addVideoLinkDialog',
  addPhotoDialog = 'addPhotoDialog',
}
interface DialogsState {
  alertDialog: AppDialogProps[];
  splashDialog: undefined | AppDialogProps;
  splashDialogTimeoutId?: NodeJS.Timeout;
  supplierFoundDialog: undefined | AppDialogProps;
  clientFoundDialog: undefined | AppDialogProps;
  addPhotoDialog: undefined | AppDialogProps;
  addVideoLinkDialog: undefined | AppDialogProps;
  viewPhotoDialog: undefined | AppDialogProps;
  addSeatDialog: undefined | AppDialogProps;
  addAlbumDialog: undefined | AppDialogProps;
  eventFoundDialog: undefined | AppDialogProps;
  askForQuoteDialog: undefined | AppDialogProps;
  eventsDialog: undefined | AppDialogProps;
  productCategoriesDialog: undefined | AppDialogProps;
  calendarsDialog: undefined | AppDialogProps;
  shareMyCalendarDialog: undefined | AppDialogProps;
  requestQuoteDialog: undefined | AppDialogProps;
  quoteSharingDialog: undefined | AppDialogProps;
  sendQuoteDialog: undefined | AppDialogProps;
  addCalendarDialog: undefined | AppDialogProps;
  deletedExternalEventsDialog: undefined | AppDialogProps;
  deletedExternalMeetingsDialog: undefined | AppDialogProps;
  addTrackerNoteDialog: undefined | AppDialogProps;
  newCalenderMenuDialog: undefined | AppDialogProps;
  calendarEventDetailsDialog: undefined | AppDialogProps;
  statusChangeDialog: undefined | AppDialogProps;
  reportUserDialog: undefined | AppDialogProps;
  addBriefNoteDialog: undefined | AppDialogProps;
  lineUpFormDialog: undefined | AppDialogProps;
  addEventClientDocumentDialog: undefined | AppDialogProps;
  addEventContractDialog: undefined | AppDialogProps;
  addParticipantDialog: undefined | AppDialogProps;
  clientDialog: undefined | AppDialogProps;
  addSupplierDialog: undefined | AppDialogProps;
  selectSupplierDialog: undefined | AppDialogProps;
  addTrackerCustomEventDialog: undefined | AppDialogProps;
  selectClientDialog: undefined | AppDialogProps;
  paymentDetailsDialog: undefined | AppDialogProps;
  addPaymentDialog: undefined | AppDialogProps;
  quoteDialog: undefined | AppDialogProps;
  contractSharingDialog: undefined | AppDialogProps;
  uploadSignedContractDialog: undefined | AppDialogProps;
  fileShareDialog: undefined | AppDialogProps;
  fileSharedWithDialog: undefined | AppDialogProps;
  assignDocNumberDialog: undefined | AppDialogProps;
  scheduleAnchorMeetingDialog: undefined | AppDialogProps;
  uploadQuoteDialog: undefined | AppDialogProps;
}

const initialState: DialogsState = {
  alertDialog: [],
  splashDialog: undefined,
  supplierFoundDialog: undefined,
  clientFoundDialog: undefined,
  addPhotoDialog: undefined,
  sendQuoteDialog: undefined,
  addVideoLinkDialog: undefined,
  viewPhotoDialog: undefined,
  addSeatDialog: undefined,
  addAlbumDialog: undefined,
  eventFoundDialog: undefined,
  askForQuoteDialog: undefined,
  eventsDialog: undefined,
  productCategoriesDialog: undefined,
  calendarsDialog: undefined,
  shareMyCalendarDialog: undefined,
  requestQuoteDialog: undefined,
  quoteSharingDialog: undefined,
  addCalendarDialog: undefined,
  deletedExternalEventsDialog: undefined,
  deletedExternalMeetingsDialog: undefined,
  addTrackerNoteDialog: undefined,
  newCalenderMenuDialog: undefined,
  calendarEventDetailsDialog: undefined,
  statusChangeDialog: undefined,
  reportUserDialog: undefined,
  addBriefNoteDialog: undefined,
  lineUpFormDialog: undefined,
  addEventClientDocumentDialog: undefined,
  addEventContractDialog: undefined,
  addParticipantDialog: undefined,
  clientDialog: undefined,
  addSupplierDialog: undefined,
  selectSupplierDialog: undefined,
  selectClientDialog: undefined,
  addTrackerCustomEventDialog: undefined,
  paymentDetailsDialog: undefined,
  addPaymentDialog: undefined,
  quoteDialog: undefined,
  contractSharingDialog: undefined,
  uploadSignedContractDialog: undefined,
  fileShareDialog: undefined,
  fileSharedWithDialog: undefined,
  assignDocNumberDialog: undefined,
  scheduleAnchorMeetingDialog: undefined,
  uploadQuoteDialog: undefined,
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openAlertDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.alertDialog.push(action.payload);
    },
    closeAlertDialog: (
      state,
      action: PayloadAction<{ index: number } | undefined>,
    ) => {
      if (action.payload) {
        state.alertDialog.splice(action.payload.index, 1);
      } else {
        state.alertDialog.pop();
      }
    },
    closeAllAlertDialogs: (state) => {
      state.alertDialog = [];
      state.splashDialogTimeoutId = undefined;
    },
    closeAllDialogs: (state) => {
      // Reset all dialog states to undefined, except alertDialog which is set to empty array
      Object.keys(state).forEach((key) => {
        if (key === 'alertDialog') {
          (state as any)[key] = [];
        } else {
          (state as any)[key] = undefined;
        }
      });
    },
    setSplashDialogTimeoutId: (
      state,
      action: PayloadAction<NodeJS.Timeout>,
    ) => {
      state.splashDialogTimeoutId = action.payload;
    },
    clearSplashDialogTimeoutId: (state) => {
      state.splashDialogTimeoutId = undefined;
    },
    setSplashDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.splashDialog = action.payload;
    },
    closeSplashDialog: (state) => {
      state.splashDialog = undefined;
      if (state.splashDialogTimeoutId) {
        clearTimeout(state.splashDialogTimeoutId);
        state.splashDialogTimeoutId = undefined;
      }
    },
    setSupplierFoundDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.supplierFoundDialog = action.payload;
    },
    closeSupplierFoundDialog: (state) => {
      state.supplierFoundDialog = undefined;
    },
    setAddPhotoDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addPhotoDialog = action.payload;
    },
    closeAddPhotoDialog: (state) => {
      state.addPhotoDialog = undefined;
    },
    setViewPhotoDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.viewPhotoDialog = action.payload;
    },
    closeViewPhotoDialog: (state) => {
      state.viewPhotoDialog = undefined;
    },
    setAddSeatDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addSeatDialog = action.payload;
    },
    closeAddSeatDialog: (state) => {
      state.addSeatDialog = undefined;
    },
    setAddAlbumDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addAlbumDialog = action.payload;
    },
    closeAddAlbumDialog: (state) => {
      state.addAlbumDialog = undefined;
    },
    setAddVideoLinkModal: (state, action: PayloadAction<AppDialogProps>) => {
      state.addVideoLinkDialog = action.payload;
    },
    closeAddVideoLinkModal: (state) => {
      state.addVideoLinkDialog = undefined;
    },
    setEventFoundDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.eventFoundDialog = action.payload;
    },
    closeEventFoundDialog: (state) => {
      state.eventFoundDialog = undefined;
    },
    setAskForQuoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.askForQuoteDialog = action.payload;
    },
    closeAskForQuoteDialog: (state) => {
      state.askForQuoteDialog = undefined;
    },
    setEventsDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.eventsDialog = action.payload;
    },
    closeEventsDialog: (state) => {
      state.eventsDialog = undefined;
    },
    setProductCategoriesDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.productCategoriesDialog = action.payload;
    },
    closeProductCategoriesDialog: (state) => {
      state.productCategoriesDialog = undefined;
    },
    setRequestQuoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.requestQuoteDialog = action.payload;
    },
    closeRequestQuoteDialog: (state) => {
      state.requestQuoteDialog = undefined;
    },
    setCalendarsDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.calendarsDialog = action.payload;
    },
    closeCalendarsDialog: (state) => {
      state.calendarsDialog = undefined;
    },
    setAddCalendarDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addCalendarDialog = action.payload;
    },
    closeAddCalendarDialog: (state) => {
      state.addCalendarDialog = undefined;
    },
    setDeletedExternalEventsDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.deletedExternalEventsDialog = action.payload;
    },
    closeDeletedExternalEventsDialog: (state) => {
      state.deletedExternalEventsDialog = undefined;
    },
    setDeletedExternalMeetingsDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.deletedExternalMeetingsDialog = action.payload;
    },
    closeDeletedExternalMeetingsDialog: (state) => {
      state.deletedExternalMeetingsDialog = undefined;
    },
    setShareMyCalendarDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.shareMyCalendarDialog = action.payload;
    },
    closeShareMyCalendarDialog: (state) => {
      state.shareMyCalendarDialog = undefined;
    },
    setAddTrackerNoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addTrackerNoteDialog = action.payload;
    },
    closeAddTrackerNoteDialog: (state) => {
      state.addTrackerNoteDialog = undefined;
    },
    setNewCalenderMenuDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.newCalenderMenuDialog = action.payload;
    },
    closeNewCalenderMenuDialog: (state) => {
      state.newCalenderMenuDialog = undefined;
    },
    setCalendarEventDetailsDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.calendarEventDetailsDialog = action.payload;
    },
    closeCalendarEventDetailsDialog: (state) => {
      state.calendarEventDetailsDialog = undefined;
    },
    setStatusChangeDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.statusChangeDialog = action.payload;
    },
    closeStatusChangeDialog: (state) => {
      state.statusChangeDialog = undefined;
    },
    setQuoteSharingDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.quoteSharingDialog = action.payload;
    },
    closeQuoteSharingDialog: (state) => {
      state.quoteSharingDialog = undefined;
    },
    setSendQuoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.sendQuoteDialog = action.payload;
    },
    closeSendQuoteDialog: (state) => {
      state.sendQuoteDialog = undefined;
    },
    setReportUserDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.reportUserDialog = action.payload;
    },
    closeReportUserDialog: (state) => {
      state.reportUserDialog = undefined;
    },
    setClientFoundDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.clientFoundDialog = action.payload;
    },
    closeClientFoundDialog: (state) => {
      state.clientFoundDialog = undefined;
    },
    setAddBriefNoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addBriefNoteDialog = action.payload;
    },
    closeAddBriefNoteDialog: (state) => {
      state.addBriefNoteDialog = undefined;
    },
    setLineUpFormDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.lineUpFormDialog = action.payload;
    },
    closeLineUpFormDialog: (state) => {
      state.lineUpFormDialog = undefined;
    },
    setAddEventClientDocumentDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.addEventClientDocumentDialog = action.payload;
    },
    closeAddEventClientDocumentDialog: (state) => {
      state.addEventClientDocumentDialog = undefined;
    },
    setAddEventContractDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.addEventContractDialog = action.payload;
    },
    closeAddEventContractDialog: (state) => {
      state.addEventContractDialog = undefined;
    },
    setAddParticipantDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addParticipantDialog = action.payload;
    },
    closeAddParticipantDialog: (state) => {
      state.addParticipantDialog = undefined;
    },
    setClientDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.clientDialog = action.payload;
    },
    closeClientDialog: (state) => {
      state.clientDialog = undefined;
    },
    setSelectSupplierDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.selectSupplierDialog = action.payload;
    },
    closeSelectSupplierDialog: (state) => {
      state.selectSupplierDialog = undefined;
    },
    setAddSupplierDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addSupplierDialog = action.payload;
    },
    closeAddSupplierDialog: (state) => {
      state.addSupplierDialog = undefined;
    },
    setAddTrackerCustomEventDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.addTrackerCustomEventDialog = action.payload;
    },
    closeAddTrackerCustomEventDialog: (state) => {
      state.addTrackerCustomEventDialog = undefined;
    },
    setSelectClientDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.selectClientDialog = action.payload;
    },
    closeSelectClientDialog: (state) => {
      state.selectClientDialog = undefined;
    },
    setPaymentDetailsDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.paymentDetailsDialog = action.payload;
    },
    closePaymentDetailsDialog: (state) => {
      state.paymentDetailsDialog = undefined;
    },
    setAddPaymentDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addPaymentDialog = action.payload;
    },
    closeAddPaymentDialog: (state) => {
      state.addPaymentDialog = undefined;
    },
    setQuoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.quoteDialog = action.payload;
    },
    closeQuoteDialog: (state) => {
      state.quoteDialog = undefined;
    },
    setContractSharingDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.contractSharingDialog = action.payload;
    },
    closeContractSharingDialog: (state) => {
      state.contractSharingDialog = undefined;
    },
    setUploadSignedContractDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.uploadSignedContractDialog = action.payload;
    },
    closeUploadSignedContractDialog: (state) => {
      state.uploadSignedContractDialog = undefined;
    },
    setFileShareDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.fileShareDialog = action.payload;
    },
    closeFileShareDialog: (state) => {
      state.fileShareDialog = undefined;
    },
    setFileSharedWithDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.fileSharedWithDialog = action.payload;
    },
    closeFileSharedWithDialog: (state) => {
      state.fileSharedWithDialog = undefined;
    },
    setAssignDocNumberDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.assignDocNumberDialog = action.payload;
    },
    closeAssignDocNumberDialog: (state) => {
      state.assignDocNumberDialog = undefined;
    },
    setScheduleAnchorMeetingDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.scheduleAnchorMeetingDialog = action.payload;
    },
    closeScheduleAnchorMeetingDialog: (state) => {
      state.scheduleAnchorMeetingDialog = undefined;
    },
    setUploadQuoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.uploadQuoteDialog = action.payload;
    },
    closeUploadQuoteDialog: (state) => {
      state.uploadQuoteDialog = undefined;
    },
  },
});

export const {
  closeAlertDialog,
  openAlertDialog,
  closeSplashDialog,
  setSplashDialog,
  setSplashDialogTimeoutId,
  clearSplashDialogTimeoutId,
  setSupplierFoundDialog,
  closeSupplierFoundDialog,
  setAddPhotoDialog,
  closeAddPhotoDialog,
  setViewPhotoDialog,
  closeViewPhotoDialog,
  setAddSeatDialog,
  closeAddSeatDialog,
  setAddAlbumDialog,
  closeAddAlbumDialog,
  setAddVideoLinkModal,
  closeAddVideoLinkModal,
  setEventFoundDialog,
  closeEventFoundDialog,
  setAskForQuoteDialog,
  closeAskForQuoteDialog,
  setEventsDialog,
  closeEventsDialog,
  setProductCategoriesDialog,
  closeProductCategoriesDialog,
  closeRequestQuoteDialog,
  setRequestQuoteDialog,
  setCalendarsDialog,
  closeCalendarsDialog,
  setAddCalendarDialog,
  closeAddCalendarDialog,
  setDeletedExternalEventsDialog,
  closeDeletedExternalEventsDialog,
  closeAllAlertDialogs,
  setDeletedExternalMeetingsDialog,
  closeDeletedExternalMeetingsDialog,
  setShareMyCalendarDialog,
  closeShareMyCalendarDialog,
  closeAddTrackerNoteDialog,
  setAddTrackerNoteDialog,
  setNewCalenderMenuDialog,
  closeNewCalenderMenuDialog,
  setCalendarEventDetailsDialog,
  closeCalendarEventDetailsDialog,
  setStatusChangeDialog,
  closeStatusChangeDialog,
  setQuoteSharingDialog,
  closeQuoteSharingDialog,
  setReportUserDialog,
  closeReportUserDialog,
  setClientFoundDialog,
  closeClientFoundDialog,
  setAddBriefNoteDialog,
  closeAddBriefNoteDialog,
  setLineUpFormDialog,
  closeLineUpFormDialog,
  setAddEventClientDocumentDialog,
  closeAddEventClientDocumentDialog,
  setAddEventContractDialog,
  closeAddEventContractDialog,
  setAddParticipantDialog,
  closeAddParticipantDialog,
  setClientDialog,
  closeClientDialog,
  setSelectSupplierDialog,
  closeSelectSupplierDialog,
  setAddSupplierDialog,
  closeAddSupplierDialog,
  setAddTrackerCustomEventDialog,
  closeAddTrackerCustomEventDialog,
  setSelectClientDialog,
  closeSelectClientDialog,
  setPaymentDetailsDialog,
  closePaymentDetailsDialog,
  setAddPaymentDialog,
  closeAddPaymentDialog,
  setQuoteDialog,
  closeQuoteDialog,
  setContractSharingDialog,
  closeContractSharingDialog,
  setUploadSignedContractDialog,
  closeUploadSignedContractDialog,
  setSendQuoteDialog,
  closeSendQuoteDialog,
  closeAllDialogs,
  setFileShareDialog,
  closeFileShareDialog,
  setAssignDocNumberDialog,
  closeAssignDocNumberDialog,
  setScheduleAnchorMeetingDialog,
  closeScheduleAnchorMeetingDialog,
  setFileSharedWithDialog,
  closeFileSharedWithDialog,
  setUploadQuoteDialog,
  closeUploadQuoteDialog,
} = dialogsSlice.actions;

export const showSplashDialog =
  (dialogProps: AppDialogProps): any =>
  (dispatch: any, getState: any) => {
    const timeoutId = setTimeout(() => {
      console.log(
        '# dialogs splash dialog timeout id -> ',
        getState().dialogs.splashDialogTimeoutId,
      );
      if (getState().dialogs.splashDialogTimeoutId) {
        dispatch(setSplashDialog(dialogProps));
        return;
      }
      dispatch(clearSplashDialogTimeoutId());
    }, 400);

    dispatch(setSplashDialogTimeoutId(timeoutId));
  };

export default dialogsSlice.reducer;
