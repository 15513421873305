import { useMemo } from 'react';

import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import Dropzone from 'components/dropzone';
import EventCard from 'components/eventsPickerDialog/eventCard';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  AppDialogProps,
  closeUploadQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useCreateQuote from 'views/main/finance/quote/createQuote/hooks/useCreateQuote';
import SelectClients from 'views/main/managment/events/createUpdateEvent/components/SelectClients';

const UploadQuoteDialog = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();
  const { uploadQuoteDialog } = useSelector((state: RootState) => ({
    uploadQuoteDialog: state.dialogs.uploadQuoteDialog,
  }));
  const dialog = uploadQuoteDialog as AppDialogProps;
  const myUser = useMyUser();
  const userId = useMemo(() => myUser?.id, [myUser?.id]);
  const {
    handleOpenEventDialog,
    event,
    handleDeselectEvent,
    handleChange,
    newQuote,
    setClient,
    handleOpenClientDialog,
    error,
    handleAddQuoteFile,
    clearQuoteFile,
    currencyIcon,
    handleCreateQuote,
    isSaveButtonDisabled,
    isLoading,
    handleResetQuote,
  } = useCreateQuote(true, dialog?.item?.quoteId || undefined);

  const handleClose = useCallback(() => {
    handleResetQuote();
    dispatch(closeUploadQuoteDialog());
  }, [dispatch, handleResetQuote]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size={isMobile ? 'full' : 'lg'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: 0, md: '30px' }}
        maxH="100dvh"
        overflow="auto">
        <ModalBody p="24px" pt={0} h="full" display="flex" flexDir="column">
          <Flex
            gap="36px"
            flexDir="column"
            flex={1}
            maxH={{ base: 'auto', md: '72dvh' }}
            overflow={'auto'}>
            <HStack w="full" justify="space-between" align="center">
              <Text variant="cardTitle">{t('dialogs.upload_quote.title')}</Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <VStack spacing={0} gap={4} align="stretch">
              <VStack spacing={0} gap={3} align="flex-start">
                {/* section title */}
                <Text
                  color="black"
                  fontSize="md"
                  fontWeight={600}
                  lineHeight="20px">
                  {t('dialogs.upload_quote.assign_to_relevant_event')}
                </Text>
                {/* content */}
                {event ? (
                  <EventCard
                    event={event}
                    onClick={handleOpenEventDialog}
                    isQuoteEvent
                    maxW={{ base: '100%', md: '320px' }}
                    pr={0}
                    flex="1"
                    onDelete={handleDeselectEvent}
                  />
                ) : (
                  <Button
                    variant="h1cta"
                    onClick={handleOpenEventDialog}
                    px={'50px'}>
                    {t('create_meeting.assign_to_event')}
                  </Button>
                )}
              </VStack>
              <Flex justify="space-between" align="center" gap={4}>
                <Box bgColor="#00000033" h="1px" w="full"></Box>
                <Text color="#939393">{t('dialogs.upload_quote.or_and')}</Text>
                <Box bgColor="#00000033" h="1px" w="full"></Box>
              </Flex>
              <VStack spacing={0} gap={3} align="flex-start" w="full">
                <SelectClients
                  containerProps={{
                    w: 'full',
                  }}
                  label={t('dialogs.upload_quote.select_relevant_client')}
                  handleOpenClientDialog={handleOpenClientDialog}
                  handleChange={handleChange}
                  clients={newQuote?.clients}
                  hasError={error?.clients}
                  setClient={setClient}
                />
              </VStack>
            </VStack>
            <Flex flexDir="column" gap={3}>
              <Flex h="70px" gap={2} w="100%" mb={0}>
                {!newQuote?.uploadedQuote?.find((i) => i.type === 'quote') && (
                  <Dropzone
                    uploadKey="quote_file"
                    target="user"
                    goldenUploadLabel={t('event.contracts.upload_file')}
                    labelSize="sm"
                    targetId={userId}
                    access="private"
                    noUnderlinedLabel
                    currentImage={''}
                    dropzoneOptions={{
                      accept: ['application/pdf', 'application/msword'],
                      multiple: false,
                      maxSize: 25 * 1024 * 1024, // 25MB
                    }}
                    onUploadCompleted={handleAddQuoteFile}
                    initialProgress={
                      !!newQuote?.uploadedQuote?.find(
                        (i) => i.type === 'quote_file',
                      )
                        ? 100
                        : 0
                    }
                    h="74px"
                    w="full"
                    minH="74px"
                    minW="206px"
                    transition="all 0.3s ease"
                  />
                )}
                {newQuote?.uploadedQuote?.find(
                  (i) => i.type === 'quote_file',
                ) && (
                  <Flex alignItems="center" gap="8px">
                    <Text variant="contractFileText">
                      {
                        newQuote?.uploadedQuote?.find(
                          (m) => m.type === 'quote_file',
                        )?.displayName
                      }
                    </Text>
                    <IconButton
                      icon={<Icon as={FiX} color="#939393" />}
                      aria-label="delete"
                      onClick={() => {
                        clearQuoteFile('quote_file');
                      }}
                    />
                  </Flex>
                )}
              </Flex>
              <Text variant="uploadSignedContractDialogDropzoneText">
                {t('dialogs.upload_quote.upload_type_note', {
                  type1: '.doc',
                  type2: '.pdf',
                  size: '25 MB',
                })}
              </Text>
            </Flex>
            {/* ammount input */}
            <InputField
              placeholder={'0.00'}
              label={t('dialogs.upload_quote.labels.amount')}
              value={newQuote?.totalAmount}
              name="totalAmount"
              type="number"
              onChange={(e) => handleChange('totalAmount', e.target.value)}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
              iconRight={
                <Text fontSize="24px" color="#9E9E9E">
                  {currencyIcon}
                </Text>
              }
            />
            {/* comment field */}
            <TextField
              label={t('create_quote.comment')}
              placeholder={t('create_quote.comment')}
              w="100%"
              minHeight={'158px'}
              textareaProps={{
                minHeight: '133px',
              }}
              name="comment"
              value={newQuote.comment}
              onChange={(e: any) => {
                handleChange('comment', e.target.value);
              }}
              iconPointerEvents="auto"
            />
            {/* mark as confirmed */}
            <SwitchField
              w="100%"
              wrapperProps={{
                w: '100%',
                p: '0px',
              }}
              labelProps={{
                marginLeft: i18n.dir() === 'rtl' ? 0 : '12px',
                marginRight: i18n.dir() === 'rtl' ? '12px' : 0,
                fontSize: '14px',
                fontWeight: 400,
              }}
              colorScheme="yellow"
              color="#AA915D"
              display="flex"
              labelColor="#AA915D"
              reversed
              label={t('dialogs.upload_quote.labels.mark_as_confrimed')}
              isChecked={newQuote?.status === 'approved'}
              onChange={() => {
                handleChange(
                  'status',
                  newQuote?.status === 'approved' ? 'draft' : 'approved',
                );
              }}
              id="markAsConfirmed"
            />
            <Flex justify="end" w="full">
              <Button
                variant="h1cta"
                w="full"
                maxW="220px"
                onClick={handleCreateQuote}
                isLoading={isLoading}
                disabled={isSaveButtonDisabled}>
                {t('upload')}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadQuoteDialog;
