import { Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useTranslation } from 'react-i18next';
import useAnchorMeetings from '../hooks/useAnchorMeetings';
import { AnchorMeeting } from './AnchorMeeting';
import CancelAnchorMeetingDialog from './CancelAnchorMeetingDialog';
import { EmptyAnchorMeeting } from './EmptyAnchorMeeting';

export const anchorMeetingTitles = [
  'acquaintance',
  'venue_tour',
  'closing_meeting',
  'catering_tasting',
  'all_suppliers_meeting',
  'final_payment',
];

export default function AnchorMeetings({
  additionalProps,
}: {
  additionalProps?: any;
}) {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('anchor_meetings');
  const {
    memoizedAnchorMeetings,
    openScheduleAnchorMeetingDialog,
    handleOpenCancelMeetingDialog,
    handleCloseCancelMeetingDialog,
    isCancelMeetingDialogOpen,
    cancelAnchorMeetingData,
    handleCancelMeeting,
  } = useAnchorMeetings();
  return (
    <Card
      {...style}
      minH={{ base: 'fit-content', lg: '507.25px' }}
      minW={{ base: '100%', lg: '369px' }}
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      p="24px 16px"
      gap="24px"
      spacing={0}
      ref={ref}
      {...additionalProps}>
      <Text variant="eventPageCardTitle">
        {t('event.anchor_meetings.title')}
      </Text>
      <Flex alignItems="center" flexDirection="column" gap="8px">
        {memoizedAnchorMeetings.map((meeting, index) =>
          typeof meeting === 'string' ? (
            <EmptyAnchorMeeting
              key={index}
              onClick={() => openScheduleAnchorMeetingDialog(meeting)}
              title={meeting}
            />
          ) : (
            <AnchorMeeting
              key={index}
              meeting={meeting}
              openCancelMeetingDialog={handleOpenCancelMeetingDialog}
            />
          ),
        )}
      </Flex>
      <CancelAnchorMeetingDialog
        isOpen={isCancelMeetingDialogOpen}
        onClose={handleCloseCancelMeetingDialog}
        handleCancelMeeting={handleCancelMeeting}
        participant={cancelAnchorMeetingData?.participant}
      />
    </Card>
  );
}
