import { Flex, FlexProps } from '@chakra-ui/react';
import useFinance from '../../hooks/useFinance';
import FinanceStatRibbon from './FinanceStatRibbon';
import PaymentTrackingCard from './PaymentTrackingCard';

const trackingCardsContainerStyles: FlexProps = {
  width: '100%',
  direction: { base: 'column', md: 'row' },
  gap: '24px',
};
const containerStyles: FlexProps = {
  width: '100%',
  gap: '24px',
};
const FinanceCard = ({ eventId }: { eventId: string }) => {
  const {
    expensesStat,
    upcomingExpenses,
    paidExpenses,
    incomeStat,
    upcomingIncomes,
    paidIncomes,
    handlePlusClick,
    expenses,
    incomes,
  } = useFinance();

  return (
    <Flex
      direction="column"
      h="100%"
      pb={{ base: '40px', md: '60px' }}
      {...containerStyles}>
      <FinanceStatRibbon expenses={expenses} incomes={incomes} />

      <Flex {...trackingCardsContainerStyles} h="100%">
        <Flex flex={1} minW={{ base: '100%', md: '0' }}>
          <PaymentTrackingCard
            title="event_finance.tracking_income"
            eventId={eventId}
            isExpense={false}
            stats={incomeStat}
            firstSection={upcomingIncomes}
            secondSection={paidIncomes}
            firstSectionTitle="event_finance.pending"
            secondSectionTitle="event_finance.received"
            highlightName="income"
            onPlusButtonClick={() => handlePlusClick('income')}
          />
        </Flex>
        <Flex flex={1} minW={{ base: '100%', md: '0' }}>
          <PaymentTrackingCard
            title="event_finance.tracking_expenses"
            eventId={eventId}
            stats={expensesStat}
            isExpense={true}
            firstSection={upcomingExpenses}
            secondSection={paidExpenses}
            firstSectionTitle="event_finance.pending"
            secondSectionTitle="event_finance.paid"
            highlightName="outcome"
            onPlusButtonClick={() => handlePlusClick('expense')}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FinanceCard;
