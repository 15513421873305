import { Button, Flex, Image, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import DropDownField from 'components/fields/DropdownField';
import noInvoicesAndReceipts from 'assets/img/quote/NoQuotesCreated.png';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const documentTypes = [
  'tax_invoice',
  'receipt',
  'tax_invoice_receipt',
  'tax_invoice_credit',
  'pro_forma_invoice',
];

export default function NoInvoices() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [documentType, setDocumentType] = useState<string>('');

  const options = useMemo(
    () =>
      documentTypes.map((type) => ({
        label: t(`invoicesAndReceipts.document_type.${type}`),
        id: type,
      })),
    [t],
  );

  const showedDocumentType = useMemo(() => {
    return documentType
      ? t(`invoicesAndReceipts.document_type.${documentType}`)
      : '';
  }, [documentType, t]);

  const selectedDocumentType = useMemo(() => {
    return documentType ? [documentType] : [];
  }, [documentType]);

  const handleCreateDocument = useCallback(() => {
    navigate(`/main/finance/invoices/create?documentType=${documentType}`);
  }, [documentType, navigate]);

  const onSelectedDocumentType = (typeData: { id: string }) => {
    setDocumentType(typeData.id);
  };

  return (
    <Flex
      w={'100%'}
      pt={{
        base: '210px',
        md: '80px',
        xl: '80px',
      }}
      flexDirection="column"
      gap="8px">
      <Card
        w="100%"
        h={{ base: 'calc(100vh - 50px)', md: 'calc(100vh - 150px)' }}
        gap="24px"
        p="24px"
        borderRadius={{ base: 0, md: '30px' }}
        justifyContent="center"
        alignItems="center">
        <Flex alignItems="center" gap="24px" flexDirection="column">
          <Image
            src={noInvoicesAndReceipts}
            w={{ base: '200px', md: '300px' }}
          />
          <Text variant="noEventsTitle">
            {t('invoicesAndReceipts.no_documents_created_yet')}
          </Text>
          <Text variant="noInvoicesDescription">
            {t(
              'invoicesAndReceipts.all_the_documents_you_will_create_in_the_future_will_be_stored_here',
            )}
          </Text>
          <DropDownField
            w="320px"
            options={options}
            placeholder={t('invoicesAndReceipts.choose_document_type')}
            onSelected={onSelectedDocumentType}
            showedOptions={showedDocumentType}
            selectedOptions={selectedDocumentType}
            closeOnSelect
          />
          <Button
            variant="h1cta"
            w="320px"
            disabled={!documentType}
            onClick={handleCreateDocument}>
            {t('invoicesAndReceipts.create')}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}
