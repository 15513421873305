import { setAddPaymentDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { formatToLocaleString } from 'helpers/formatNumber';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useGetExpenses, useGetIncomes } from 'hooks/usePayment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDateRange } from 'utils/date.util';

export default function useFinance() {
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [incomesFilter, setIncomesFilter] = useState<{
    fromDate: Date;
    toDate: Date;
    range: string;
  }>({
    fromDate: new Date(),
    toDate: new Date(),
    range: 'this_month',
  });

  const [expensesFilter, setExpensesFilter] = useState<{
    fromDate: Date;
    toDate: Date;
    range: string;
  }>({
    fromDate: new Date(),
    toDate: new Date(),
    range: 'this_month',
  });

  const ExpensesQueryParams = useMemo(() => {
    const params: Record<string, any> = {};
    if (expensesFilter.range) {
      params.range = expensesFilter.range;
    }
    if (expensesFilter.fromDate) {
      params.fromDate = expensesFilter.fromDate;
    }
    if (expensesFilter.toDate) {
      params.toDate = expensesFilter.toDate;
    }
    return params;
  }, [expensesFilter]);

  const IncomesQueryParams = useMemo(() => {
    const params: Record<string, any> = {};
    if (incomesFilter.range) {
      params.range = incomesFilter.range;
    }
    if (incomesFilter.fromDate) {
      params.fromDate = incomesFilter.fromDate;
    }
    if (incomesFilter.toDate) {
      params.toDate = incomesFilter.toDate;
    }
    return params;
  }, [incomesFilter]);

  const { data: expenses } = useGetExpenses(ExpensesQueryParams);
  const { data: incomes } = useGetIncomes(IncomesQueryParams);

  useEffect(() => {
    if (incomesFilter.range) {
      const { fromDate, toDate } = getDateRange(
        { range: incomesFilter.range },
        new Date(),
      );
      setIncomesFilter((prev) => ({ ...prev, fromDate, toDate }));
    }
  }, [incomesFilter.range]);

  useEffect(() => {
    if (expensesFilter.range) {
      const { fromDate, toDate } = getDateRange(
        { range: expensesFilter.range },
        new Date(),
      );
      setExpensesFilter((prev) => ({ ...prev, fromDate, toDate }));
    }
  }, [expensesFilter.range]);

  const incomeCalcs = useMemo(() => {
    let expected = 0;
    let received = 0;
    let due = 0;
    incomes?.forEach((income) => {
      expected += income.amount;
      received += income.paidAmount;
      due += income.amount - income.paidAmount;
    });
    return {
      expected,
      received,
      due: due < 0 ? 0 : due,
    };
  }, [incomes]);

  const expenseCalcs = useMemo(() => {
    let expected = 0;
    let paid = 0;
    let due = 0;
    expenses?.forEach((expense) => {
      expected += expense.amount;
      paid += expense.paidAmount;
      due += expense.amount - expense.paidAmount;
    });
    return {
      expected,
      paid,
      due: due < 0 ? 0 : due,
    };
  }, [expenses]);

  const statsIncome = useMemo(
    () => [
      {
        title: 'finance_overview.expected',
        value: `₪${formatToLocaleString(incomeCalcs.expected)}`,
        bgColor: '#465D55',
      },
      {
        title: 'finance_overview.received',
        value: `₪${formatToLocaleString(incomeCalcs.received)}`,
        bgColor: 'linear-gradient(86.51deg, #93C3B3 40.99%, #86E6C6 94.26%)',
      },
      {
        title: 'finance_overview.due',
        value: `₪${formatToLocaleString(incomeCalcs.due)}`,
        bgColor: 'linear-gradient(86.51deg, #93C3B3 40.99%, #86E6C6 94.26%)',
      },
    ],
    [incomeCalcs],
  );

  const statsExpense = useMemo(
    () => [
      {
        title: 'finance_overview.expected',
        value: `₪${formatToLocaleString(expenseCalcs.expected)}`,
        bgColor: '#3F2F0D',
      },
      {
        title: 'finance_overview.paid',
        value: `₪${formatToLocaleString(expenseCalcs.paid)}`,
        bgColor: 'linear-gradient(104.02deg, #AA915D 20.34%, #443A25 183.1%)',
      },
      {
        title: 'finance_overview.due',
        value: `₪${formatToLocaleString(expenseCalcs.due)}`,
        bgColor: 'linear-gradient(104.02deg, #AA915D 20.34%, #443A25 183.1%)',
      },
    ],
    [expenseCalcs],
  );

  const handleShowAllExpenses = useCallback(() => {
    navigate('/main/finance/outcomes');
  }, [navigate]);

  const handleShowAllIncomes = useCallback(() => {
    navigate('/main/finance/incomes');
  }, [navigate]);

  const handlePlusClick = useCallback(
    (type: 'income' | 'expense') => {
      dispatch(setAddPaymentDialog({ item: { type } }));
    },
    [dispatch],
  );

  return {
    expenses,
    incomes,
    statsIncome,
    statsExpense,
    handleShowAllExpenses,
    handleShowAllIncomes,
    incomesFilter,
    expensesFilter,
    setIncomesFilter,
    setExpensesFilter,
    handlePlusClick,
  };
}
