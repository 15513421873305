import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Image, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useMemo } from 'react';
import TrashIcon from 'assets/img/quote/QuotePreview.png';
import FormHeader from './components/FormHeader';

export default function InvoiceForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const documentType = useMemo(
    () => searchParams.get('documentType'),
    [searchParams],
  );
  return (
    <Flex
      w={'100%'}
      pt={{
        base: '210px',
        md: '80px',
        xl: '80px',
      }}
      flexDirection="column"
      gap="8px">
      <Card
        w="100%"
        h={{ base: 'calc(100vh - 50px)', md: 'calc(100vh - 150px)' }}
        gap="36px"
        p="24px"
        borderRadius={{ base: 0, md: '30px' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="cardTitle">
            {t('create_invoice.create', {
              documentType: t(
                `invoicesAndReceipts.document_type.${documentType}`,
              )?.toLowerCase(),
            })}
          </Text>
          <Flex
            cursor="pointer"
            bg="#0000004D"
            borderRadius="10px"
            w="30px"
            h="30px"
            onClick={() => navigate(-1)}
            alignItems="center"
            justifyContent="center">
            <Image src={TrashIcon} alt="trash" w="20px" h="20px" />
          </Flex>
        </Flex>
        <FormHeader handleOpenEventDialog={() => {}} />
      </Card>
    </Flex>
  );
}
