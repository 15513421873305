import { useDisclosure, useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { setScheduleAnchorMeetingDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IMeeting } from 'services/@types';
import {
  default as meetingService,
  default as MeetingService,
} from 'services/meeting.api';
import { anchorMeetingTitles } from '../components/AnchorMeetings';

export default function useAnchorMeetings() {
  const params = useParams();
  const { t } = useTranslation();
  const {
    isOpen: isCancelMeetingDialogOpen,
    onOpen: openCancelMeetingDialog,
    onClose: closeCancelMeetingDialog,
  } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const [cancelAnchorMeetingData, setCancelAnchorMeetingData] = useState<{
    participant: string[];
    meetingId: string;
    eventId: string;
  }>({
    participant: [],
    meetingId: '',
    eventId: '',
  });
  const openScheduleAnchorMeetingDialog = useCallback(
    (anchorMeetingType: string) => {
      dispatch(
        setScheduleAnchorMeetingDialog({
          item: { eventId: params?.id, anchorMeetingType },
        }),
      );
    },
    [dispatch, params?.id],
  );
  const { data: anchorMeetings } = useQuery({
    queryKey: ['anchorMeetings', params?.id],
    queryFn: () =>
      meetingService.getMeetings({
        eventId: params?.id,
        anchorMeetings: true,
      }),
  });

  const memoizedAnchorMeetings = useMemo(() => {
    const anchorMeetingsList: Array<string | IMeeting> = [];
    anchorMeetingTitles.forEach((title) => {
      const anchorMeeting = (anchorMeetings as unknown as IMeeting[])?.find(
        (meeting) => meeting.anchorMeetingType === title,
      );
      if (anchorMeeting) {
        anchorMeetingsList.push(anchorMeeting);
      } else {
        anchorMeetingsList.push(title);
      }
    });
    return anchorMeetingsList;
  }, [anchorMeetings]);

  const cancelMeeting = useCallback(async () => {
    try {
      await MeetingService.deleteMeeting(cancelAnchorMeetingData.meetingId);
      toast({
        title: t('event.anchor_meetings.meeting_cancelled'),
        variant: 'main',
        position: 'top-right',
      });
      await queryClient.invalidateQueries({
        queryKey: ['todayMeetings', cancelAnchorMeetingData.eventId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['anchorMeetings', cancelAnchorMeetingData.eventId],
      });
      await queryClient.invalidateQueries({
        queryKey: [`getMeetings-${cancelAnchorMeetingData.eventId}`],
      });
      await queryClient.invalidateQueries({
        queryKey: ['meetings', cancelAnchorMeetingData.eventId],
      });
    } catch (error) {
      console.error(error);
      toast({
        variant: 'error',
        position: 'top-right',
      });
    }
  }, [queryClient, t, toast, cancelAnchorMeetingData]);

  const handleCancelMeeting = useCallback(() => {
    cancelMeeting();
    closeCancelMeetingDialog();
  }, [cancelMeeting, closeCancelMeetingDialog]);

  const handleOpenCancelMeetingDialog = useCallback(
    ({
      participant,
      meetingId,
      eventId,
    }: {
      participant: string[];
      meetingId: string;
      eventId: string;
    }) => {
      setCancelAnchorMeetingData({
        participant,
        meetingId,
        eventId,
      });
      openCancelMeetingDialog();
    },
    [openCancelMeetingDialog, setCancelAnchorMeetingData],
  );

  const handleCloseCancelMeetingDialog = useCallback(() => {
    setCancelAnchorMeetingData({
      participant: [],
      meetingId: '',
      eventId: '',
    });
    closeCancelMeetingDialog();
  }, [closeCancelMeetingDialog, setCancelAnchorMeetingData]);
  return {
    memoizedAnchorMeetings,
    openScheduleAnchorMeetingDialog,
    handleOpenCancelMeetingDialog,
    handleCloseCancelMeetingDialog,
    isCancelMeetingDialogOpen,
    cancelAnchorMeetingData,
    handleCancelMeeting,
  };
}
