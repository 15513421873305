import { Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IFormHeaderProps {
  handleOpenEventDialog: () => void;
}

export default function FormHeader(props: IFormHeaderProps) {
  const { t } = useTranslation();
  const { handleOpenEventDialog } = props;
  return (
    <Flex>
      <Button
        variant="h1cta"
        onClick={handleOpenEventDialog}
        w={{ base: '100%', md: '320px' }}>
        {t('create_quote.assign_event')}
      </Button>
    </Flex>
  );
}
