import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  AppDialogProps,
  closeFileShareDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IFileMedia, Invite, User } from 'services/@types';
import UserService from 'services/user.api';
import UserCard from './UserCard';

function FileShareDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const myUser = useMyUser();
  const myUserId = myUser?.id;
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const fileShareDialog = useSelector(
    (state: RootState) => state.dialogs.fileShareDialog,
  );
  const dialog = fileShareDialog as AppDialogProps;
  const allInvites = useSelector((state: RootState) => state.invites.invites);

  const fileInvitesSentFromMe = useMemo(() => {
    return allInvites.filter(
      (invite) =>
        invite.type === 'file' &&
        invite.status === 'accepted' &&
        (myUserId ? invite.senderID === myUserId : true) &&
        invite.resourceId === dialog?.item?.file?.resourceId,
    );
  }, [allInvites, myUserId, dialog?.item?.file?.resourceId]);

  const [selectedUsers, setSelectedUsers] = useState<
    Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>[]
  >([]);
  const [selectedExternalUsers, setSelectedExternalUsers] = useState<string[]>(
    [],
  ); // (email as string)[]
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 700);

  const { correctEmailFormat } = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      emailRegex.test(debouncedSearch) &&
      debouncedSearch.toLowerCase() !== myUser?.email
    ) {
      if (!selectedExternalUsers.includes(debouncedSearch)) {
        return { correctEmailFormat: debouncedSearch };
      }
    }
    return { correctEmailFormat: null };
  }, [debouncedSearch, myUser?.email, selectedExternalUsers]);

  const { data: users, isLoading: isLoadingUsers } = useQuery<User[]>({
    queryKey: [`users-search-${debouncedSearch}`],
    queryFn: async () => {
      if (!debouncedSearch.length) return [];
      const response = await UserService.getUsers({
        name: debouncedSearch,
      });
      return response.results.filter((user) => user.email !== myUser?.email);
    },
    enabled: !!debouncedSearch,
  });

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    const filtered = users.filter(
      (user) => !selectedUsers.find((usr) => usr.id === user.id),
    );
    if (correctEmailFormat) {
      return filtered.filter((user) => user.email === correctEmailFormat);
    }

    return filtered;
  }, [users, selectedUsers, correctEmailFormat]);

  const handleClose = useCallback(() => {
    dispatch(closeFileShareDialog());
    setSelectedUsers([]);
    setSelectedExternalUsers([]);
    setSearch('');
  }, [dispatch]);

  const handleSubmitUsersSelection = useCallback(() => {
    if (dialog?.onConfirm) {
      const chosenUsers: (User | { email: string })[] = [
        ...selectedExternalUsers.map((email) => ({ email })),
        ...selectedUsers,
      ];

      // get only new users
      const newUsers = chosenUsers.filter((user) => {
        return !fileInvitesSentFromMe.some((invite) => {
          if ('id' in user) {
            return user.id === invite.recipientID;
          } else {
            return user.email === invite.email;
          }
        });
      });

      // Get list of users who were removed (in currentInvitedUsers but not in chosenUsers)
      const removedUsers = fileInvitesSentFromMe.filter((invite) => {
        const isStillChosen = chosenUsers.some((user) => {
          if ('id' in user) {
            return user.id === invite.recipientID;
          } else {
            return user.email === invite.email;
          }
        });
        return !isStillChosen;
      });

      dialog.onConfirm({ newUsers, removedUsers });
    }
    handleClose();
  }, [
    dialog,
    fileInvitesSentFromMe,
    handleClose,
    selectedExternalUsers,
    selectedUsers,
  ]);

  const handleSelectUser = useCallback(
    (user: Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>) => {
      if (selectedUsers.find((usr) => usr.id === user.id)) {
        setSelectedUsers((prev) => prev.filter((usr) => usr.id !== user.id));
      } else {
        setSelectedUsers((prev) => [
          ...prev,
          {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        ]);
      }
    },
    [selectedUsers],
  );

  const handleSelectExternalUser = useCallback((email: string) => {
    setSelectedExternalUsers((prev) =>
      prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email],
    );
  }, []);

  const addExternalUser = useCallback(() => {
    if (correctEmailFormat) {
      setSearch('');
      setTimeout(() => {
        setSelectedExternalUsers((prev) => [...prev, correctEmailFormat]);
      }, 700);
    }
  }, [correctEmailFormat]);

  useEffect(() => {
    if (dialog?.item?.file as IFileMedia) {
      const file = dialog.item.file as IFileMedia;

      const filteredFileInvites = fileInvitesSentFromMe.filter(
        (invite: Invite) => invite.resourceId === file.resourceId,
      );

      const selUsers: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
      }[] = [];
      const extUsers: string[] = [];
      filteredFileInvites.forEach((invite: Invite) => {
        if (invite.recipientID) {
          const [firstName, lastName] = invite.fullName.split(' ');
          selUsers.push({
            id: invite.recipientID,
            email: invite.email,
            firstName,
            lastName,
          });
        } else {
          extUsers.push(invite.email);
        }
      });
      setSelectedUsers(selUsers);
      setSelectedExternalUsers(extUsers);
    }
  }, [dialog?.item, fileInvitesSentFromMe]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size={isMobile ? 'full' : 'sm'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0px', md: '30px' }}
        minH={{ base: '100%', md: '50dvh' }}>
        <ModalBody
          p="24px 16px"
          w="full"
          h="100%"
          display="flex"
          flexDir="column">
          <VStack w="100%" gap={4} spacing={0} h={'full'} pb={4}>
            <HStack w="100%" justifyContent="space-between" spacing={0}>
              <Text
                variant="cardSectionTitle"
                fontSize={'base'}
                fontWeight={700}
                color="#141718">
                {t('files.dialog.select_users')}
              </Text>
              <ModalCloseButton
                position="relative"
                top={0}
                right={0}
                left={0}
                justifyContent={'center'}
                alignItems="center"
                display={'flex'}
                boxShadow="none !important"
              />
            </HStack>
            {/* Search */}
            <SearchBar
              value={search}
              margin={'0 !important'}
              inputProps={{
                value: search,
                onChange: (e: any) => setSearch(e.target.value),
              }}
            />
            <VStack
              w="100%"
              maxH={{ base: '100%', md: '400px' }}
              flexShrink={0}
              overflowY="auto"
              gap={2}
              display="flex"
              flexDirection="column"
              h="100%"
              flex={1}>
              {correctEmailFormat && (
                <UserCard
                  key={correctEmailFormat}
                  externalUser={{ email: correctEmailFormat }}
                  isSelected={selectedExternalUsers.includes(
                    correctEmailFormat,
                  )}
                  onSelect={addExternalUser}
                />
              )}
              {selectedExternalUsers.map((email) => (
                <UserCard
                  key={email}
                  externalUser={{ email }}
                  isSelected={selectedExternalUsers.includes(email)}
                  onSelect={() => handleSelectExternalUser(email)}
                />
              ))}
              {/* TODO: add Recents and divider  */}
              {selectedUsers.map((user) => (
                <UserCard
                  key={user.id}
                  user={{
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                  }}
                  isSelected={selectedUsers.includes(user)}
                  onSelect={() => handleSelectUser(user)}
                />
              ))}
              {isLoadingUsers ? (
                <Text>Loading...</Text>
              ) : (
                filteredUsers?.map((user) => (
                  <UserCard
                    key={user.id}
                    user={user}
                    isSelected={false}
                    onSelect={() => handleSelectUser(user)}
                  />
                ))
              )}
            </VStack>
          </VStack>
          <Spacer flexShrink={0} />
          <Button
            variant="h1cta"
            w="full"
            flexShrink={0}
            onClick={handleSubmitUsersSelection}>
            {t('files.dialog.share')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default FileShareDialog;
