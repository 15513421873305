import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  AppDialogProps,
  closeEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { getEvents, updateEventsList } from 'contexts/redux/event/eventSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EventCard from './eventCard';

function EventsPickerDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { eventsDialog, events } = useSelector((state: RootState) => ({
    eventsDialog: state.dialogs.eventsDialog,
    events: state.event.events,
  }));
  const dialog = eventsDialog as AppDialogProps;
  const handleChange = useCallback(
    async (value) => {
      if (value) {
        const filteredEvents = events.filter((event) =>
          event.name.toLowerCase().includes(value.toLowerCase()),
        );
        dispatch(updateEventsList(filteredEvents));
      } else {
        await dispatch(getEvents({}));
      }
    },
    [events, dispatch],
  );

  const handleClose = useCallback(() => {
    dispatch(closeEventsDialog());
  }, [dispatch]);

  const handleSelectEvent = useCallback(
    (selectedEvent) => {
      if (eventsDialog?.onConfirm) {
        eventsDialog.onConfirm(selectedEvent);
      }
      handleClose();
    },
    [handleClose, eventsDialog],
  );
  useEffect(() => {
    const fetchEvents = async () => {
      if (!!dialog) {
        await dispatch(getEvents({}));
      }
    };
    fetchEvents();
  }, [dispatch, dialog]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px">
        <ModalBody p="2xl">
          <VStack w="100%" gap={2}>
            <HStack w="100%" justifyContent="space-between">
              <Text variant="cardSectionTitle" ml="30px">
                {t('dialogs.events.title')}
              </Text>
              <ModalCloseButton position="relative" top="0" />
            </HStack>
            <SearchBar
              w="100%"
              autoFocus
              onChange={(e: any) => handleChange(e.target.value)}
            />
            <VStack w="100%" h="500px" overflowY="auto" gap={2}>
              {events.length === 0 && (
                <Text variant="errorTitle">
                  {t('dialogs.events.no_events')}
                </Text>
              )}
              {events.map((event) => (
                <EventCard
                  key={event.id}
                  event={event}
                  onClick={handleSelectEvent}
                  h="115px"
                />
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EventsPickerDialog;
