import { useQuery } from '@tanstack/react-query';
import { RootState } from 'contexts/redux/store';
import {
  getActiveTasks,
  getCompletedTasks,
} from 'contexts/redux/task/taskSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';

const useGeneralTaskHistory = () => {
  const dispatch = useDispatch<any>();
  const { appNavigate: navigate } = useAppNavigate();
  const { activeTasks, completedTasks } = useSelector(
    (state: RootState) => state.task,
  );

  useQuery({
    queryKey: [`getEventActiveTasks-general`],
    queryFn: () => dispatch(getActiveTasks({ status: 'pending' })),
    enabled: true,
  });

  useQuery({
    queryKey: [`getEventCompletedTasks-general`],
    queryFn: () => dispatch(getCompletedTasks({ status: 'completed' })),
    enabled: true,
  });

  const goToTaskCreate = () => {
    navigate(`/main/tasks/create`);
  };

  return { activeTasks, completedTasks, goToTaskCreate };
};

export default useGeneralTaskHistory;
