import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useUpdateBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BusinessPreferences } from 'services/@types';

const useDocNumbers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [inputValues, setInputValues] = useState<
    Omit<BusinessPreferences, 'id' | 'businessID' | 'vatPercent'>
  >({
    quote: 10000,
    contract: 10000,
    taxInvoice: 10000,
    receipt: 10000,
    receiptInvoice: 10000,
    taxInvoiceCredit: 10000,
    proForma: 10000,
  });

  const { mutate: updateBusinessPreferences } = useUpdateBusinessPreferences();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleUpdateDocumentNumbers = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('finance_settings.document_numbers.dialog.title'),
        content: t('finance_settings.document_numbers.dialog.content'),
        confirmLabel: t('dialogs.alert.confirm'),
        cancelLabel: t('dialogs.alert.cancel'),
        onConfirm: () => {
          updateBusinessPreferences(inputValues);
          dispatch(closeAlertDialog());
        },
      }),
    );
  }, [dispatch, inputValues, t, updateBusinessPreferences]);

  return {
    inputValues,
    setInputValues,
    loading,
    setLoading,
    error,
    setError,
    handleChange,
    handleUpdateDocumentNumbers,
  };
};

export default useDocNumbers;
