import { Divider, Flex, Image, Text } from '@chakra-ui/react';

import logo from 'assets/img/quote/logo.png';
import dsdLogo from 'assets/img/quote/dsdLogo.png';
import { Business } from 'services/@types';
import AuthImage from 'components/authImage/AuthImage';

interface HeaderProps {
  business: Business;
}
export default function Header({ business }: HeaderProps) {
  return (
    <Flex
      justifyContent="space-between"
      flexDirection={{ base: 'column-reverse', md: 'row' }}
      alignItems={{ base: 'start', md: 'center' }}
      w="100%">
      <Flex flexDirection="column" gap={2}>
        <Text variant="previewQuoteHeaderTitle">{business?.businessName}</Text>
        <Flex alignItems="center" gap="5px">
          <Text variant="previewQuoteHeaderSubTitle">{business?.address}</Text>
          <Divider
            orientation="vertical"
            borderColor="#00000033"
            w="1px"
            h="16px"
          />
          <Text variant="previewQuoteHeaderSubTitle">052-8353758</Text>
        </Flex>
        <Flex alignItems="center" gap="5px">
          <Text variant="previewQuoteHeaderSubTitle">{business?.email}</Text>
          <Divider
            orientation="vertical"
            borderColor="#00000033"
            w="1px"
            h="16px"
          />
          <Text variant="previewQuoteHeaderSubTitle">
            {business?.links?.find((link) => link.type === 'website')?.url}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" gap="24px">
        <Image src={dsdLogo} alt="dsdLogo" maxH="80px" />
        <AuthImage
          path={business?.media?.find((media) => media.type === 'logo')?.url}
          alt="logo"
          borderRadius="16px"
          overflow="hidden"
          imageProps={{ height: '70px', objectFit: 'contain', width: '210px' }}
        />
      </Flex>
    </Flex>
  );
}
