import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuoteStatus } from 'services/@types';

type RenderQuoteStatusProps = {
  status: QuoteStatus;
};

const RenderQuoteStatus = ({ status }: RenderQuoteStatusProps) => {
  const { t } = useTranslation();
  const { bgColor, color } = useMemo(() => {
    switch (status) {
      case 'declined':
        return { bgColor: '#1417181A', color: '#656565', status: 'declined' };
      case 'pendingApproval':
        return { bgColor: '#1417181A', color: '#656565', status: 'pendingApproval' };
      case 'pendingContract':
        return { bgColor: '#0050EF1A', color: '#60A0CB', status: 'pendingContract' };
      case 'approved':
        return { bgColor: '#5FBF961A', color: '#5FBF96', status: 'approved' };
      case 'cancelled':
        return { bgColor: '#1417181A', color: '#656565', status: 'cancelled' };
      case 'draft':
        return { bgColor: '#FFB4221A', color: '#FFB422', status: 'draft' };
      case 'requested':
        return { bgColor: '#1417181A', color: '#656565', status: 'requested' };
      default:
        return { bgColor: 'black', color: 'white', status: 'unknown' };
    }
  }, [status]);
  return (
    <Flex py={1} px={2} borderRadius="4px" bg={bgColor} color={color}>
      {t(`quote_list.statuses.${status}`)}
    </Flex>
  );
};
export default RenderQuoteStatus;
