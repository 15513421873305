import { Flex, Spinner } from '@chakra-ui/react';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import DocumentNumbers from './components/DocumentNumbers';
import VatSettings from './components/VatSettings';

const FinanceSettings = () => {
  const { data: businessPreferencesData, isLoading } =
    useGetBusinessPreferences();

  return (
    <Flex
      w="100%"
      pt={{ base: '200px', md: '80px', xl: '80px' }}
      h="fit-content">
      <Flex
        minH="calc(100vh - 170px)"
        flexDir="column"
        h="fit-content"
        mb="20px"
        w="100%"
        p="36px"
        gap={6}>
        {isLoading && (
          <Flex alignItems="center" justifyContent="center" h="100%" w="100%">
            <Spinner size="xl" />
          </Flex>
        )}
        {!isLoading && (
          <>
            <VatSettings vatPercent={businessPreferencesData.vatPercent} />
            <DocumentNumbers defaultValues={businessPreferencesData} />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default FinanceSettings;
