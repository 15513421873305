import { AxiosInstance, AxiosResponse } from 'axios';
import { createAxiosInstance } from './axios.util';

interface UserAction {
  type: 'event' | 'quote-approval' | 'quote-request';
  status?:
    | 'accepted'
    | 'rejected'
    | 'post'
    | 'active'
    | 'cancelled'
    | 'completed'
    | 'new';
  action?: 'postpone' | 'changeStatus';
  declineComment?: string;
}

class UserActionsService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async getUserActions(
    type: UserAction['type'],
    id: string,
    queryParams?: {
      status?: UserAction['status'];
      action?: UserAction['action'];
      comment?: string;
    },
  ): Promise<any> {
    const response: AxiosResponse = await this.api.get(
      `/user-actions/${type}/${id}`,
      {
        params: queryParams,
      },
    );
    return response.data;
  }
}

const _userActionsService = new UserActionsService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _userActionsService;
