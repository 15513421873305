import {
  CircularProgress,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectedOptionsContainer from './SelectedOptionsContainer';

export interface InputFieldProps extends InputProps {
  id?: string;
  isDisabled?: boolean;
  label?: string;
  extra?: JSX.Element | string;
  errorLabel?: string;
  placeholder?: string;
  type?: string;
  onSelected?: (value: string) => void;
  onExternalSelected?: (value: string) => void;
  onOptionsRendered?: (options: { value: string; label: string }[]) => void;
  selectedOptions?: string[];
  options?: {
    value: string;
    label: string;
    email?: string;
    businessName?: string;
    isExternal?: boolean;
    avatar?: { type: string; url: string };
  }[];
  handleRemove?: (value: string) => void;
  [x: string]: any;
}

export default function AssignAutocompleteField(props: InputFieldProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    id,
    label,
    errorLabel,
    maxW,
    extra,
    value,
    placeholder,
    iconLeft,
    iconRight,
    _placeholder,
    options,
    onSelected,
    onExternalSelected,
    selectedOptions,
    onOptionsRendered,
    isLoading,
    type,
    w,
    mb,
    labelProps,
    handleRemove,
    ...rest
  } = props;

  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const bgInput = useColorModeValue('white', 'brand.300');
  const { t, i18n } = useTranslation();

  const leftInputElementRef = useRef(null);
  const rightInputElementRef = useRef(null);

  const [val, setVal] = useState((value as string) || '');
  const [width, setWidth] = useState<string>('14px');
  const [showOptions, setShowOptions] = useState(false);
  const [displayedOptions, setDisplayedOptions] = useState(options);

  // eslint-disable-next-line
  const isLeft = useMemo(() => i18n.dir() === 'ltr', [i18n.dir()]);

  const handleOptionClicked = useCallback(
    (value: { value: string; label: string; isExternal?: boolean }) => {
      console.log(`handle option clicked`, value);
      setVal(value.label);
      if (!value?.isExternal) {
        onSelected && onSelected(value.value);
      } else {
        onExternalSelected && onExternalSelected(value.value);
      }
      setShowOptions(false);
    },
    [onSelected],
  );

  useEffect(() => {
    if (!showOptions) {
      onClose();
      return;
    }
    const filteredOptions = options.filter(
      (option) =>
        option.label.toLowerCase().includes(val.toLowerCase()) ||
        option.email.toLowerCase().includes(val.toLowerCase()),
    );

    if (filteredOptions?.length > 0 && val?.length > 0) {
      onOpen();
    } else {
      onClose();
    }
    setDisplayedOptions(filteredOptions);
    onOptionsRendered && onOptionsRendered(filteredOptions);
  }, [
    onClose,
    onOpen,
    onOptionsRendered,
    options,
    selectedOptions,
    val,
    showOptions,
  ]);

  useEffect(() => {
    setVal(value as string);
  }, [value]);

  useEffect(() => {
    const element = isLeft
      ? leftInputElementRef.current
      : rightInputElementRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        console.log(entries);
        console.log(entries[0].contentRect.width);
        if (entries[0].contentRect.width === 0) {
          setWidth('14px');
          return;
        }
        setWidth(`calc(${entries[0].contentRect.width}px + 1px)`);
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [isLeft]);
  return (
    <Flex direction="column" position={'relative'} mb={mb} w={w} maxW={maxW}>
      <HStack align="center">
        {isLoading && (
          <CircularProgress isIndeterminate color="brand.900" size="14px" />
        )}
        <FormLabel
          display={label || extra ? 'flex' : 'none'}
          htmlFor={id}
          mb={1}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="medium"
          _hover={{ cursor: 'pointer' }}
          {...labelProps}>
          {label}
          <Text fontSize="sm" fontWeight="400" ms="2px">
            {extra}
          </Text>
        </FormLabel>
      </HStack>
      <InputGroup>
        {isLeft && (
          <InputLeftElement
            width="auto"
            maxW="60%"
            height="100%"
            ref={leftInputElementRef}>
            <SelectedOptionsContainer
              selectedOptions={selectedOptions}
              handleRemove={handleRemove}
            />
          </InputLeftElement>
        )}
        <Input
          type={type}
          id={id}
          value={val}
          bg={props.disabled ? 'gray.150' : bgInput}
          color={textColorPrimary}
          borderColor={rest?.borderColor}
          fontWeight="500"
          pl={isLeft && width}
          pr={!isLeft && width}
          variant="main"
          onFocus={() => {
            setShowOptions(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setShowOptions(false);
            }, 100); // Small delay to ensure onClick fires
          }}
          placeholder={placeholder}
          fontSize="md"
          _active={{
            borderColor: rest?.borderColor || 'brand.600',
          }}
          _focus={{
            borderColor: rest?.borderColor || 'brand.600',
          }}
          autoComplete="off"
          _placeholder={{
            fontWeight: '400',
            color: 'secondaryGray.600',
            ..._placeholder,
          }}
          h="50px"
          maxH="50px"
          {...rest}
          onChange={(e) => {
            setVal(e.target.value);
            rest.onChange && rest.onChange(e);
          }}
        />
        {!isLeft && (
          <InputRightElement
            maxW="60%"
            height="100%"
            width="auto%"
            ref={rightInputElementRef}>
            <SelectedOptionsContainer
              selectedOptions={selectedOptions}
              handleRemove={handleRemove}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <Flex
        position="absolute"
        flexDirection="column"
        borderWidth={isOpen ? 1 : 0}
        borderColor="brand.100"
        borderRadius={'2xl'}
        bg="white"
        top={label ? 76 : 50}
        transition="all 0.2s"
        height={
          isOpen ? `${Math.min(displayedOptions?.length * 54, 160)}px` : 0
        }
        zIndex={10}
        sx={{ scrollbarWidth: 'none' }}
        overflow="scroll"
        maxH={160}
        w="100%">
        {displayedOptions?.map((option, index) => {
          return (
            <HStack
              key={index}
              cursor="pointer"
              alignItems="center"
              p={2}
              autoFocus={false}
              pointerEvents="auto"
              width={'100%'}
              _hover={{ bg: 'gray.200' }}
              onClick={() => handleOptionClicked(option)}>
              <AuthImage
                w="28px"
                h="28px"
                borderRadius="50%"
                path={option?.avatar?.url}
                isAvatar
                name={option?.label}
              />
              <Flex direction="column" justifyContent="center">
                <Text display="inline">
                  <Text variant="assingSuggestions" display="inline">
                    {option.label}
                  </Text>
                  {option?.businessName && (
                    <Text variant="createQuote1Of1Payment" display="inline">
                      {` (${option.businessName})`}
                    </Text>
                  )}
                </Text>

                <Text variant="anchorMeetingDescription">{option.email}</Text>
              </Flex>
            </HStack>
          );
        })}
      </Flex>
      {errorLabel && (
        <Text fontSize="xs" fontWeight={'semibold'} color="red.500" mt="2px">
          {t(errorLabel)}
        </Text>
      )}
    </Flex>
  );
}
