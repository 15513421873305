import { Divider, Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import { Button, Icon, Image } from '@chakra-ui/react';
import arrowTo from 'assets/svg/profile/arrow-to.svg';
import AuthImage from 'components/authImage/AuthImage';
import Card from 'components/card/Card';
import Dropzone from 'components/dropzone';
import EditBtn from 'components/editBtn';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiHeart } from 'react-icons/bi';
import { IoLocationOutline } from 'react-icons/io5';

import { useSelector } from 'react-redux';
import { Business, UrlItem } from 'services/@types';

interface BusinessBannerProps {
  inputValues: Record<string, any>;
  setInputValues: (inputs: Record<string, any>) => void;
  handleChange: (event: { target: { name: string; value: any } }) => void;
  updateBusiness: () => void;
  myBusiness: Business;
}
export default function BusinessBanner({
  handleChange,
  updateBusiness,
  inputValues,
  setInputValues,
}: BusinessBannerProps) {
  const { t, i18n } = useTranslation();
  const myBusiness = useMyBusiness();
  const [isEditMode, setIsEditMode] = useState(false);
  const [pendingBannerImageUrl, setPendingBannerImageUrl] = useState<
    string | null
  >(null);
  const categories = useSelector(
    (state: RootState) => state.businessCategories.businessCategories,
  );
  const handleUploadCompleted = useCallback(
    (data: UrlItem) => {
      console.log('Upload completed -> ', data);
      setInputValues({
        ...inputValues,
        media: [
          ...(myBusiness?.media.filter((i: UrlItem) => i.type !== 'banner') ||
            []),
          data,
        ],
      });
      setPendingBannerImageUrl(data.url);
    },
    [inputValues, myBusiness?.media, setInputValues],
  );

  const handleSave = useCallback(() => {
    updateBusiness();
    setIsEditMode(false);
  }, [updateBusiness]);

  return (
    <Flex p="30px 32px" flexDirection="column" gap={10} role="group">
      <EditBtn
        containerProps={{
          position: 'absolute',
          display: isEditMode ? 'none' : 'flex',
          top: 4,
          right: 4,
        }}
        onClick={function (): void {
          setIsEditMode(!isEditMode);
        }}
      />

      <Flex wrap="wrap" gap={10}>
        <VStack align="start" flex={1}>
          <Text variant="cardTitle">
            {t('business_settings.your_business_cover_photo')}
          </Text>
          {!isEditMode && (
            <AuthImage
              flex={1}
              minH={180}
              maxW={{ sm: 400, md: 400 }}
              minW={280}
              maxH={200}
              borderRadius="16px"
              path={
                pendingBannerImageUrl ||
                myBusiness?.media?.find((m) => m.type === 'banner')?.url
              }
            />
          )}
          {isEditMode && (
            <Dropzone
              flex={1}
              maxW={{ base: '100%', md: 324 }}
              minW={280}
              currentImage={
                pendingBannerImageUrl ||
                myBusiness?.media?.find((m) => m.type === 'banner')?.url
              }
              dropzoneOptions={{
                accept: 'image/*',
                multiple: false,
              }}
              maxH={180}
              uploadBarStyle="line"
              minH={180}
              goldenUploadLabel={t(
                'business_settings.business_banner.add_an_image',
              )}
              uploadLabel={t('business_settings.business_banner.or_just')}
              labelSize="xs"
              uploadKey={'banner'}
              access="public"
              target="business"
              targetId={myBusiness?.id}
              onUploadCompleted={handleUploadCompleted}
            />
          )}
        </VStack>
        <VStack maxW={260} flex={1} align="start" justify={'center'}>
          <Text color="brand.900" fontSize="2xl" fontWeight={'bold'}>
            {t('business_settings.business_banner.show_potential_clients')}
          </Text>
          <Text color="gray.600">
            {t('business_settings.business_banner.upload_stunning_cover_image')}
          </Text>
          <Button
            onClick={handleSave}
            px={16}
            display={isEditMode ? 'block' : 'none'}
            w={{ base: 'fit-content', sm: '100%', md: 'fit-content' }}
            variant="h1cta">
            {t('business_settings.business_banner.save')}
          </Button>
        </VStack>
        <HStack flex={1} minW={330} gap={4}>
          <Card
            boxShadow={'xl'}
            maxW={230}
            minH={260}
            borderRadius="30px"
            p={4}>
            <VStack w="100%" align="start" spacing={1}>
              <Flex w="100%" flexDirection={'column'}>
                <AuthImage
                  borderRadius={'3xl'}
                  bg="red"
                  h={110}
                  w={'100%'}
                  path={
                    pendingBannerImageUrl ||
                    myBusiness?.media?.find((m) => m.type === 'banner')?.url
                  }
                />
                <AuthImage
                  position="absolute"
                  borderRadius={'50%'}
                  bg="red"
                  m={'10px'}
                  h={50}
                  w={50}
                  path={
                    myBusiness?.media?.find((m) => m.type === 'profileImage')
                      ?.url
                  }
                />
                <Flex
                  h="24px"
                  w="24px"
                  position="absolute"
                  alignSelf={'flex-end'}
                  m={'10px'}
                  p={4}
                  align="center"
                  justify="center"
                  borderRadius={'50%'}
                  bg="#f9f9f9">
                  <Icon aria-label="like" color="brand.900" as={BiHeart} />
                </Flex>
              </Flex>
              <Text wordBreak="break-all" noOfLines={1} fontWeight={'bold'}>
                {myBusiness?.businessName}
              </Text>
              <HStack>
                <AuthImage
                  borderRadius={'full'}
                  size="sm"
                  h={6}
                  w={6}
                  path={
                    myBusiness?.media?.find((m) => m.type === 'profileImage')
                      ?.url
                  }
                />
                <Text
                  wordBreak="break-all"
                  noOfLines={1}
                  flex="1"
                  color="#00000066"
                  fontSize={'sm'}>
                  {myBusiness?.businessName}
                </Text>
              </HStack>
              {myBusiness?.address && (
                <HStack spacing={0}>
                  <Icon color="gray.500" as={IoLocationOutline} mx={1} />
                  <Text color="gray.500" fontSize="9px" noOfLines={1}>
                    {myBusiness?.address}
                  </Text>
                </HStack>
              )}
              <Divider />
              <HStack gap={1} wrap="wrap" spacing={0} px={2}>
                {myBusiness?.category?.map((i, index) => (
                  <Button variant="outlineTag" key={index}>
                    {t(
                      `categories.${categories.find((c) => c.id === i)?.name}`,
                    )}
                  </Button>
                ))}
              </HStack>
            </VStack>
          </Card>
          <VStack align="start" maxW={110}>
            <Image
              src={arrowTo}
              sx={{ transform: `scaleX(${i18n.dir() === 'ltr' ? 1 : -1})` }}
            />
            <Text
              fontSize="2xl"
              lineHeight={1.1}
              color="brand.900"
              fontWeight={'bold'}>
              {t('business_settings.business_banner.make_your_profile_pop')}
            </Text>
          </VStack>
        </HStack>
      </Flex>
    </Flex>
  );
}
