import { Button, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDoubleQuotesR } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { IQuote } from 'services/@types';

type QuoteNotificationProps = {
  createdAt: string;
  quote: IQuote;
};

const QuoteNotification = forwardRef<HTMLDivElement, QuoteNotificationProps>(
  ({ createdAt, quote }, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
      isDeclined,
      declineComment,
      status,
      quoteNumber,
      clientName,
      message,
    } = useMemo(() => {
      const client = quote?.clients?.[0];
      const clientName = client?.client?.company
        ? client?.client?.company
        : `${client?.client?.firstName} ${client?.client?.lastName}`;
      const isDeclined = quote?.status === 'declined';
      const data: any = {
        status: quote?.status,
        quoteNumber: quote?.quoteNumber,
        clientName: clientName,
        declineComment: isDeclined ? quote?.declineComment ?? '' : '',
        isDeclined,
      };

      data.message = `${t('notifications.quote_request.quote_number')} ${
        data.quoteNumber
      } ${
        data.isDeclined
          ? t('notifications.quote_request.was_declined_by')
          : t('notifications.quote_request.was_accepted_by')
      } ${clientName}`;

      return data;
    }, [
      quote?.clients,
      quote?.declineComment,
      quote?.quoteNumber,
      quote?.status,
      t,
    ]);

    const navigateToCreateContract = useCallback(() => {
      if (!quote?.id || !quote?.eventId) return;
      navigate(
        `/main/finance/contracts/create?quoteId=${quote?.id}&eventId=${quote?.eventId}`,
      );
    }, [quote?.id, navigate, quote?.eventId]);

    return (
      <VStack
        ref={ref}
        border="1px solid #F9FAFB"
        p="8px"
        borderRadius={'8px'}
        bgColor={'#93C3B31A'}
        spacing={0}>
        <HStack alignItems={'center'} gap="12px">
          <Icon as={RiDoubleQuotesR} color="brand.900" />
          <VStack spacing={0} alignItems={'flex-start'}>
            <HStack spacing={0}>
              <Text variant="cardSubTitle" color="#1B2037" lineHeight={'20px'}>
                {message}
              </Text>
            </HStack>
            {!isDeclined && (
              <Flex w="100%" my="12px !important" pr="16px">
                <Button
                  variant="h1cta"
                  size="sm"
                  w="100%"
                  onClick={navigateToCreateContract}>
                  {t('notifications.quote_request.create_a_contract')}
                </Button>
              </Flex>
            )}
            {declineComment && (
              <Text fontWeight={700} color="brand.900">
                "{declineComment}"
              </Text>
            )}
          </VStack>
        </HStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {createdAt}
        </Text>
      </VStack>
    );
  },
);

export default QuoteNotification;
