import { Flex, HStack, Text } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { setFileSharedWithDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IFileMedia } from 'services/@types';

const FileItemSharedWith = ({ file }: { file: IFileMedia }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const invites = useSelector((state: RootState) => state.invites.invites);

  const sharedWith = useMemo(() => {
    return invites.filter(
      (invite) =>
        invite.type === 'file' && invite.resourceId === file.resourceId,
    );
  }, [invites, file.resourceId]);

  const openFileSharedWithDialog = useCallback(() => {
    if (!('resourceId' in file)) {
      return;
    }
    dispatch(
      setFileSharedWithDialog({
        item: {
          file,
        },
      }),
    );
  }, [dispatch, file]);

  return sharedWith.length === 0 ? null : (
    <HStack
      spacing={0}
      position={'relative'}
      zIndex={2}
      w="100%"
      cursor={'pointer'}
      onClick={openFileSharedWithDialog}
      title={sharedWith.map((invite) => invite.email).join(',\n')}>
      <AuthImage
        name={`${sharedWith[0]?.email}`}
        path={undefined}
        h="24px"
        w="24px"
        color={'red'}
        borderRadius="full"
        isAvatar
      />
      <Flex
        display={sharedWith.length - 1 > 0 ? 'flex' : 'none'}
        transform="auto"
        translateX={i18n.dir() === 'rtl' ? '8px !important' : '-8px !important'}
        borderRadius={'full'}
        w="24px"
        h="24px"
        bg={'#93C3B3'}
        p={0}
        justifyContent={'center'}
        alignItems={'center'}
        color="white"
        fontSize="12px"
        fontWeight={'400'}>
        <Text m="0 !important">+{sharedWith.length - 1}</Text>
      </Flex>
    </HStack>
  );
};

export default FileItemSharedWith;
