import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessPreferences } from 'services/@types';
import useDocNumbers from '../hooks/useDocNumbers';

type DocumentNumbersProps = {
  defaultValues: BusinessPreferences;
};

const DocumentNumbers = ({ defaultValues }: DocumentNumbersProps) => {
  const { t } = useTranslation();
  const {
    inputValues,
    handleChange,
    handleUpdateDocumentNumbers,
    loading,
    setInputValues,
  } = useDocNumbers();

  useEffect(() => {
    if (defaultValues) {
      setInputValues({
        quote: defaultValues.quote,
        contract: defaultValues.contract,
        taxInvoice: defaultValues.taxInvoice,
        receipt: defaultValues.receipt,
        receiptInvoice: defaultValues.receiptInvoice,
        taxInvoiceCredit: defaultValues.taxInvoiceCredit,
        proForma: defaultValues.proForma,
      });
    }
  }, [defaultValues, setInputValues]);

  return (
    <Card>
      <Flex flexDirection="column" gap={6}>
        <VStack spacing={0} gap={3} align="stretch">
          <Text
            variant="cardSectionTitle"
            fontSize={'2xl'}
            fontWeight={700}
            color="#000000CC">
            {t('finance_settings.document_numbers.title')}
          </Text>
          <Text
            variant="detailInfoValue"
            fontSize={'sm'}
            fontWeight={400}
            color="#999EA9">
            {t('finance_settings.document_numbers.description')}
          </Text>
        </VStack>
        <VStack spacing={4} align="stretch">
          <InputField
            w={{ base: '100%', md: '310px' }}
            h="50px"
            type="number"
            min={1}
            step={1}
            label={t('finance_settings.document_numbers.quote')}
            name="quote"
            value={inputValues.quote}
            onChange={handleChange}
          />
          <InputField
            w={{ base: '100%', md: '310px' }}
            h="50px"
            type="number"
            min={1}
            step={1}
            label={t('finance_settings.document_numbers.contract')}
            name="contract"
            value={inputValues.contract}
            onChange={handleChange}
          />
          <InputField
            w={{ base: '100%', md: '310px' }}
            h="50px"
            type="number"
            min={1}
            step={1}
            label={t('finance_settings.document_numbers.tax_invoice')}
            name="taxInvoice"
            value={inputValues.taxInvoice}
            onChange={handleChange}
          />
          <InputField
            w={{ base: '100%', md: '310px' }}
            h="50px"
            type="number"
            min={1}
            step={1}
            label={t('finance_settings.document_numbers.receipt')}
            name="receipt"
            value={inputValues.receipt}
            onChange={handleChange}
          />
          <InputField
            w={{ base: '100%', md: '310px' }}
            h="50px"
            type="number"
            min={1}
            step={1}
            label={t('finance_settings.document_numbers.receipt_invoice')}
            name="receiptInvoice"
            value={inputValues.receiptInvoice}
            onChange={handleChange}
          />
          <InputField
            w={{ base: '100%', md: '310px' }}
            h="50px"
            type="number"
            min={1}
            step={1}
            label={t('finance_settings.document_numbers.tax_invoice_credit')}
            name="taxInvoiceCredit"
            value={inputValues.taxInvoiceCredit}
            onChange={handleChange}
          />
          <InputField
            w={{ base: '100%', md: '310px' }}
            h="50px"
            type="number"
            min={1}
            step={1}
            label={t('finance_settings.document_numbers.pro_forma')}
            name="proForma"
            value={inputValues.proForma}
            onChange={handleChange}
          />
          <Flex w="full" justify="flex-end">
            <Button
              variant="h1cta"
              w="100%"
              py="0px !important"
              height="44px !important"
              onClick={handleUpdateDocumentNumbers}
              type="button"
              fontSize="14px"
              maxW={{ base: '100%', md: '240px' }}
              isDisabled={loading}>
              {t('finance_settings.document_numbers.save_changes')}
            </Button>
          </Flex>
        </VStack>
      </Flex>
    </Card>
  );
};

export default DocumentNumbers;
