import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { fetchBusinessEventPlans } from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const cleanParams = (params: Record<string, any>) => {
  // Create a new object excluding empty string values
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== ''),
  );
};

// custom hook to fetch event plans with events
export default function useEvents() {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams(); // using this param to show client events history
  const business = useMyBusiness();
  const { filter, pagination, result, isLoading } = useSelector(
    (state: RootState) => ({
      result: state.businessEventPlan.businessEventPlans,
      filter: state.businessEventPlan.filter,
      pagination: state.businessEventPlan.pagination,
      isLoading: state.businessEventPlan.loading,
    }),
  );
  const isFilterEmpty = useMemo(
    () =>
      Object.values(filter).every((value) => {
        return value === '';
      }),
    [filter],
  );
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (!business?.id) {
          return;
        }
        const cleanedFilter = cleanParams(filter);
        const paginationParams = {
          page: pagination.page,
          limit: pagination.limit,
          businessId: business?.id,
          populate: 'eventId',
        };
        if (params?.clientId) {
          cleanedFilter.clientId = params?.clientId;
        }

        dispatch(
          fetchBusinessEventPlans({ ...cleanedFilter, ...paginationParams }),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchEvents();
  }, [
    dispatch,
    filter,
    pagination.page,
    pagination.limit,
    params?.clientId,
    business?.id,
  ]);

  return { result, isFilterEmpty, isLoading };
}
