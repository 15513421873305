import {
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import AssignAutocompleteField from 'components/AssignAutocompleteField';
import CalendarsDropDown from 'components/calendarsDropDown';
import EventCard from 'components/eventsPickerDialog/eventCard';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import TextField from 'components/fields/TextField';
import LocationSearchInput from 'components/placesAutocomplete';
import TimeSelector from 'components/timeSelector';
import moment from 'moment';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosAlert } from 'react-icons/io';
import { MdInfoOutline } from 'react-icons/md';
import { IEvent, IMeeting } from 'services/@types';
import { anchorMeetingTitles } from 'views/main/event/components/AnchorMeetings';
import { remindTypes } from 'views/main/managment/meeting/createMeeting/components/createMeetingContainer';
import { DisplayingGuest } from '../hooks/useAnchorMeetingForm';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import { LuX } from 'react-icons/lu';

interface AnchorMeetingFormProps {
  event: Partial<IEvent>;
  anchorMeeting: Partial<IMeeting>;
  handleChangeAnchorMeeting: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedAnchorMeetingType: string[];
  handleChangeAnchorMeetingType: (e: string) => void;
  errorList: string[];
  handleChangeRemind: (e: string) => void;
  selectedRemind: string[];
  displayingGuest: DisplayingGuest[];
  currentGuestInput: string;
  handleSelectGuest: (value: string) => void;
  handleRemoveGuest: (id: string) => void;
  handleChangeCurrentGuestInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleCreateAnchorMeeting: () => void;
  handleClose: () => void;
  loading: boolean;
}

export default function AnchorMeetingForm(props: AnchorMeetingFormProps) {
  const {
    event,
    anchorMeeting,
    handleChangeAnchorMeeting,
    selectedAnchorMeetingType,
    handleChangeAnchorMeetingType,
    errorList,
    handleChangeRemind,
    selectedRemind,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleRemoveGuest,
    handleChangeCurrentGuestInput,
    handleCreateAnchorMeeting,
    handleClose,
    loading,
  } = props;
  const { t, i18n } = useTranslation();

  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure();

  return (
    <VStack alignItems="start" w="100%" gap="26px">
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        gap={{ base: '12px', md: '0px' }}
        align={{ base: 'start', md: 'center' }}
        w="100%">
        <Flex
          alignItems="center"
          gap="4px"
          justifyContent="space-between"
          w={{ base: '100%', md: 'fit-content' }}>
          <Text variant="cardTitle">{t('schedule_anchor_meeting.title')}</Text>
          <Icon
            as={LuX}
            color="#939393"
            width="24px"
            height="24px"
            onClick={handleClose}
            cursor="pointer"
            display={{ base: 'block', md: 'none' }}
          />
        </Flex>
        {event && (
          <EventCard
            event={event}
            onClick={() => {}}
            isQuoteEvent
            maxW={{ base: '100%', md: '320px' }}
            w={{ base: '100%', md: 'fit-content' }}
            cursor="default"
          />
        )}
      </Flex>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        w="100%"
        justifyContent={{ base: 'start', md: 'space-between' }}
        gap={{ base: '12px', md: '0px' }}>
        <Flex
          justifyContent="start"
          alignItems="center"
          h="100%"
          gap={1}
          w="auto">
          <SwitchField
            w={{ base: 'auto', md: '100%' }}
            wrapperProps={{
              w: '100%',
              p: '0px',
            }}
            labelProps={{
              marginLeft: i18n.dir() === 'rtl' ? 0 : '12px',
              marginRight: i18n.dir() === 'rtl' ? '12px' : 0,
              fontSize: '14px',
              fontWeight: 400,
            }}
            colorScheme="yellow"
            color="#AA915D"
            display="flex"
            labelColor="#AA915D"
            reversed
            label={t('create_meeting.anchor_meeting')}
            isChecked={anchorMeeting?.isAnchorMeeting}
            onChange={(e) =>
              handleChangeAnchorMeeting({
                target: {
                  value: e.target.checked,
                  name: 'isAnchorMeeting',
                },
              } as ChangeEvent<HTMLInputElement>)
            }
            id="isAnchorMeeting"
          />
          <Tooltip
            variant="business"
            label={t('create_meeting.anchor_meeting_note')}
            onClick={() => {}}
            hasArrow
            isOpen={isOpenTooltip}
            placement="right">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                as={MdInfoOutline}
                color="brand.400"
                fontSize="18px"
                cursor="pointer"
                onMouseEnter={onOpenTooltip}
                onMouseLeave={onCloseTooltip}
                onClick={onToggleTooltip}
                ms="0px"
                mt="0px"
                aria-label={''}
              />
            </span>
          </Tooltip>
        </Flex>
        <DropdownField
          closeOnSelect
          options={anchorMeetingTitles.map(
            (type) => `event.anchor_meetings.${type}`,
          )}
          onSelected={handleChangeAnchorMeetingType}
          selectedOptions={selectedAnchorMeetingType}
          placeholder={
            anchorMeeting?.anchorMeetingType
              ? t(`event.anchor_meetings.${anchorMeeting?.anchorMeetingType}`)
              : t('schedule_anchor_meeting.meeting_type')
          }
          label={t('schedule_anchor_meeting.meeting_type')}
          w={{ base: '100%', md: '335px' }}
          formLabelStyles={{ mb: 1 }}
          menuButton={{
            borderColor: '#0000001A',
            bg: 'white',
            h: '50px',
            borderRadius: '16px',
          }}
        />
      </Flex>
      <Flex wrap="wrap" gap="21px" w="100%">
        <InputField
          label={t('create_meeting.meeting_title')}
          placeholder={t('create_meeting.meeting_title')}
          name="name"
          flex="1"
          w={{ base: '100%' }}
          value={anchorMeeting?.name || ''}
          onChange={handleChangeAnchorMeeting}
          borderColor={errorList.includes('name') ? 'error.100' : '#0000001A'}
          _placeholder={{
            color: '#00000066',
          }}
        />
        <Flex w="100%" gap="20px" flexDirection={{ base: 'column', md: 'row' }}>
          <LocationSearchInput
            sxWidth="100%"
            label={t('create_meeting.location')}
            placeholder={t('create_meeting.location')}
            onChange={(e) => {
              handleChangeAnchorMeeting({
                target: {
                  value: {
                    label: e,
                    value: e,
                  },
                  name: 'location',
                },
              } as unknown as ChangeEvent<HTMLInputElement>);
            }}
            onSelect={(e) => {
              handleChangeAnchorMeeting({
                target: {
                  value: e,
                  name: 'location',
                },
              } as unknown as ChangeEvent<HTMLInputElement>);
            }}
            inputProps={
              errorList.includes('location')
                ? {
                    _empty: {
                      borderColor: 'error.100',
                    },
                  }
                : {
                    borderColor: '#0000001A',
                    _placeholder: {
                      color: '#00000066',
                    },
                  }
            }
          />
          <CalendarsDropDown
            label={t('schedule_anchor_meeting.calendar')}
            alignSelf="self-end"
            isMeeting
            w={{ base: '100%' }}
            value={anchorMeeting?.calendarId}
            onChange={(value: string) => {
              handleChangeAnchorMeeting({
                target: {
                  value,
                  name: 'calendarId',
                },
              } as ChangeEvent<HTMLInputElement>);
            }}
            menuButton={{
              borderColor: '#0000001A',
              bg: 'white',
            }}
          />
        </Flex>
      </Flex>
      <Flex wrap="wrap" gap="20px" alignItems="end">
        <HStack>
          <Flex
            flex="1"
            gap="20px"
            alignItems="end"
            wrap={{ base: 'wrap', md: 'nowrap' }}>
            <InputDateField
              name="startDate"
              label={t('create_meeting.meeting_date')}
              value={
                anchorMeeting?.startDate
                  ? new Date(anchorMeeting?.startDate)
                  : new Date()
              }
              onChange={(e: Date) => {
                const event = {
                  target: {
                    value: e,
                    name: 'startDate',
                  },
                } as unknown as ChangeEvent<HTMLInputElement>;
                handleChangeAnchorMeeting(event);
              }}
              justifyContent="end"
              mainContainerStyles={{
                w: { base: 'calc(50% - 10px)', md: '166px' },
                flex: { base: '1', md: 'none' },
              }}
              buttonStyles={{
                borderColor: errorList.includes('startDate')
                  ? 'error.100'
                  : '#0000001A',
                width: { base: '100%', md: '166px' },
              }}
              imgStyles={{
                bg: 'transparent',
              }}
              imgSrc={CalendarIcon}
            />
            <TimeSelector
              borderColor={
                errorList.includes('endTime') ? 'error.100' : '#0000001A'
              }
              w={{ base: 'calc(50% - 10px)', md: '126px' }}
              label={t('create_meeting.start_meeting')}
              value={
                anchorMeeting?.startDate
                  ? moment(new Date(anchorMeeting?.startDate)).format('HH:mm')
                  : '00:00'
              }
              // flex="1"
              onChange={(e: string) => {
                handleChangeAnchorMeeting({
                  target: {
                    value: e,
                    name: 'startTime',
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
            />
            <TimeSelector
              borderColor={
                errorList.includes('endTime') ? 'error.100' : '#0000001A'
              }
              w={{ base: 'calc(50% - 10px)', md: '126px' }}
              label={t('create_meeting.end_meeting')}
              value={
                anchorMeeting?.endDate
                  ? moment(new Date(anchorMeeting?.endDate)).format('HH:mm')
                  : '00:00'
              }
              // flex="1"
              onChange={(e: string) => {
                handleChangeAnchorMeeting({
                  target: {
                    value: e,
                    name: 'endTime',
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
            />
            <DropdownField
              closeOnSelect
              options={remindTypes.map(
                (type) => `create_meeting.remind_${type}`,
              )}
              onSelected={handleChangeRemind}
              selectedOptions={selectedRemind}
              placeholder={
                anchorMeeting?.reminder
                  ? t(`create_meeting.remind_${anchorMeeting.reminder}`)
                  : t('create_meeting.remind_me')
              }
              label={t('create_meeting.remind_me')}
              w={{ base: 'calc(50% - 10px)', md: '166px' }}
              formLabelStyles={{ mb: 1 }}
              menuButton={{
                borderColor: '#0000001A',
                bg: 'white',
                h: '50px',
                borderRadius: '16px',
              }}
            />
          </Flex>
        </HStack>
        <TextField
          label={t('create_meeting.note')}
          placeholder={t('create_meeting.note')}
          w="100%"
          minH="150px"
          resize="none"
          name="description"
          value={anchorMeeting?.description || ''}
          onChange={handleChangeAnchorMeeting}
          borderColor={'#0000001A'}
          _placeholder={{
            color: '#00000066',
          }}
        />
        <AssignAutocompleteField
          options={displayingGuest}
          value={currentGuestInput}
          onSelected={handleSelectGuest}
          selectedOptions={anchorMeeting?.guests}
          handleRemove={handleRemoveGuest}
          onChange={handleChangeCurrentGuestInput}
          label={t('create_meeting.add_guests')}
          placeholder={t('create_meeting.add_guests')}
          w="100%"
          borderColor={'#0000001A'}
          _placeholder={{
            color: '#00000066',
          }}
        />
        <HStack
          justifyContent="end"
          w="100%"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          gap={2}>
          {errorList.length > 0 && (
            <HStack justify="center">
              <Icon
                as={IoIosAlert}
                w="16px"
                h="16px"
                bg="white"
                color="error.100"
                borderRadius="50%"
              />
              <Text variant="errorTitle">{t('create_event.error')}</Text>
            </HStack>
          )}
          <Button
            variant="h1cta"
            minH={{ base: '50px', md: 'fit-content' }}
            borderRadius="16px"
            w={{ base: '100%', md: '320px' }}
            isDisabled={errorList.length > 0 || loading}
            onClick={() => {
              handleCreateAnchorMeeting();
            }}>
            {t('create_meeting.create_meeting')}
          </Button>
        </HStack>
      </Flex>
    </VStack>
  );
}
