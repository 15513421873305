import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { BusinessPreferences } from 'services/@types';
import _businessPreferencesService from 'services/businessPreferences.api';

// Query keys
export const businessPreferencesKeys = {
  all: ['businessPreferences'] as const,
  preferences: () => [...businessPreferencesKeys.all] as const,
};

// Get Business Preferences
export const useGetBusinessPreferences = () => {
  return useQuery({
    queryKey: businessPreferencesKeys.preferences(),
    queryFn: async () => {
      const preferences =
        await _businessPreferencesService.getBusinessPreferences();
      return { ...preferences, vatValue: preferences.vatPercent / 100 };
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });
};

// Update Business Preferences
export const useUpdateBusinessPreferences = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (
      updates: Partial<Omit<BusinessPreferences, 'id' | 'businessID'>>,
    ) => _businessPreferencesService.updateBusinessPreferences(updates),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: businessPreferencesKeys.preferences(),
      });

      toast({
        title: t(
          variables.vatPercent
            ? 'finance_settings.vat.updated_successfully'
            : 'finance_settings.document_numbers.updated_successfully',
        ),
        status: 'success',
        variant: 'main',
      });
    },
    onError: (error, variables) => {
      console.log(error);
      toast({
        title: t(
          variables.vatPercent
            ? 'finance_settings.vat.error_updating_vat'
            : 'finance_settings.document_numbers.error_updating_document_numbers',
        ),
        status: 'error',
        variant: 'error',
      });
    },
  });
};

// Helper methods
useGetBusinessPreferences.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  await queryClient.prefetchQuery({
    queryKey: businessPreferencesKeys.preferences(),
    queryFn: () => _businessPreferencesService.getBusinessPreferences(),
  });
};

useGetBusinessPreferences.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  queryClient.invalidateQueries({
    queryKey: businessPreferencesKeys.preferences(),
  });
};
