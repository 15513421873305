import {
  chakra,
  forwardRef,
  shouldForwardProp,
  useStyleConfig,
} from '@chakra-ui/react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import { CustomCardProps } from 'theme/theme';
const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});
const CustomCard = forwardRef<CustomCardProps, 'div'>((props, ref) => {
  const { size, variant, ...rest } = props;
  const styles = useStyleConfig('Card', { size, variant });

  return (
    <AnimatePresence mode="wait" custom={{ styles }}>
      <ChakraBox
        layout
        __css={styles}
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0)' }}
        exit={{ opacity: 0, transform: 'translateY(40px)' }}
        // @ts-ignore
        transition={{
          type: 'easeInOut',
          duration: 0.3,
        }}
        ref={ref}
        {...rest}
      />
    </AnimatePresence>
  );
});

export default CustomCard;
