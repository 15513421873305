import { Flex, Text } from '@chakra-ui/react';
import TagsField from 'components/fields/TagsField';
import { useGetEntities } from 'queries/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessTag } from 'services/@types';
import TagBadge from './TagBadge';

type ServiceTypesProps = {
  isEditModeEnabled: boolean;
  inputValues: Record<string, any>;
  handleChange: (e: any) => void;
};
const ServiceTypes = ({
  isEditModeEnabled,
  inputValues,
  handleChange,
}: ServiceTypesProps) => {
  const entities = useGetEntities<BusinessTag>(
    'serviceType',
    inputValues?.serviceTypes || [],
    '/service-types',
  );
  const serviceTypes = useMemo(() => {
    return entities.map((entity) => entity.data);
  }, [entities]);

  const { t } = useTranslation();
  return isEditModeEnabled ? (
    <Flex flexDirection="column" gap={2}>
      <Text variant="cardSectionTitle">
        {t('business_settings.company_services')}
      </Text>
      <TagsField
        itemFontSize="sm"
        id="description"
        mb="0px"
        h="90px"
        placeholderTags={serviceTypes?.map((service) => service?.name) || []}
        handleTagsChange={(e: any) => {
          handleChange({
            target: {
              name: 'serviceTypes',
              value: e,
            },
          } as any);
        }}
        w={{ base: '100%', md: '310px' }}
      />
    </Flex>
  ) : (
    <Flex flexDirection="column" gap={2}>
      <Text variant="cardSectionTitle">
        {t('business_settings.company_services')}
      </Text>
      <Flex gap={2} wrap="wrap">
        {/* service components */}
        {(serviceTypes || [])?.map((service: BusinessTag, index: number) => (
          <TagBadge label={service?.name} key={index} />
        ))}
        {!serviceTypes.length && (
          <Text variant="cardSectionInfo" color={'rgba(0, 0, 0, 0.3)'}>
            {t('business_settings.not_added')}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default ServiceTypes;
