import {
  Flex,
  Hide,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import EventCard from 'components/eventsPickerDialog/eventCard';
import MobileEventCard from 'components/eventsPickerDialog/MobileEventCard';
import { format } from 'date-fns';
import prettyBytes from 'pretty-bytes';
import { IFileMedia } from 'services/@types';
import FileItemActions from './FileItemActions';
import FileItemSharedWith from './FileItemSharedWith';

interface FileListItemProps {
  file: IFileMedia;
}

const FileListItem = ({ file }: FileListItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // maybe needed in the future
  // const { data: entityData } = useGetEntity(file.entity, file.entityId);

  return (
    <Flex
      justify="space-between"
      py={4}
      px={{ base:1, md: 6 }}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      zIndex={isOpen ? 2 : 1}
      fontSize="14px">
      <Hide below="md">
        <Flex
          flex="1"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
          gap={2}
          fontWeight="500">
          <Flex flex={1} w={{ base: 'auto', md: '100px' }} align="center">
            <Text noOfLines={1} w="full" overflow="hidden">
              {file.name}
            </Text>
          </Flex>
          <Flex flex={1} align="center" fontWeight={'bold'}>
            {file.uploadDate && format(new Date(file.uploadDate), 'dd.MM.yy')}
          </Flex>
          <Flex flex={1} align="center">
            {file.type}
          </Flex>
          <Flex flex={1} align="center">
            {file.size ? prettyBytes(file.size) : '0 B'}
          </Flex>
          <Flex flex={1} minW="270px" align="center">
            <EventCard
              event={file.event}
              onClick={() => {}}
              isQuoteEvent
              maxW={{ base: '100%', md: '320px' }}
              flex="1"
            />
          </Flex>
          <Flex flex={1} position="relative">
            <FileItemSharedWith file={file} />
          </Flex>
          <Flex position="relative" zIndex={2}>
            <FileItemActions
              file={file}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </Flex>
        </Flex>
      </Hide>
      <Hide above="md">
        <VStack spacing={0} w="full" gap={3}>
          {/* name, size and threedots menu */}
          <HStack spacing={0} w="full" justify="space-between" align="center">
            <VStack spacing={0} align="flex-start">
              <Text
                noOfLines={1}
                w="full"
                overflow="hidden"
                fontSize="16px"
                fontWeight={600}
                lineHeight="20px">
                {file.name}
              </Text>
              <Flex
                flex={1}
                align="center"
                fontSize="14px"
                fontWeight={500}
                lineHeight="18px"
                color="#000000CC">
                {file.size ? prettyBytes(file.size) : '0 B'}
              </Flex>
            </VStack>
            <Flex
              position="relative"
              flexShrink={0}
              zIndex={2}>
              <FileItemActions
                file={file}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
              />
            </Flex>
          </HStack>
          {/* shared with and date */}
          <HStack spacing={0} w="full" justify="space-between" align="center">
            <Flex flex={1} position="relative">
              <FileItemSharedWith file={file} />
            </Flex>
            <Text fontSize="14px" lineHeight="18px" color="#000000CC">
              {file.uploadDate &&
                format(new Date(file.uploadDate), 'dd MMM, yyyy')}
            </Text>
          </HStack>
          {/* event mobile card */}
          <MobileEventCard
            event={file.event}
            onClick={() => {}}
            isQuoteEvent
            maxW={{ base: '100%', md: '320px' }}
            flex="1"
          />
        </VStack>
      </Hide>
    </Flex>
  );
};

export default FileListItem;
