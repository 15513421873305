import { Button, Flex, Hide, Icon, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoTriangleSharp } from 'react-icons/io5';
import PaymentsTableItem from './PaymentsTableItem';

const headers = [
  'due_date',
  'event_name',
  'amount',
  'paid',
  'status',
  'method',
  'paying_client',
  '',
];

interface IProps {
  payments: any[];
  handleShowAll?: () => void;
}

export default function PaymentsTable(props: IProps) {
  const { payments, handleShowAll } = props;
  const { t } = useTranslation();
  return (
    <VStack w="100%" spacing={0} align="stretch" overflowY="hidden">
      <Hide below="md">
        <Flex py={4} px={6} bg="white">
          {headers.map((header, index) => (
            <Flex
              key={header}
              flex={index === headers.length - 1 ? "0 0 24px" : 1}
              w="full"
              align="center"
              fontSize="14px"
              // fontWeight={sortBy === header ? '600' : '400'}
              color="#00000099">
              {header ? (
                <Text
                  // onClick={() =>
                  //   handleSort(
                  //     header as 'date' | 'event_name' | 'amount' | 'customer',
                  //   )
                  // }
                  noOfLines={1}
                  cursor={index < 4 && 'pointer'}>
                  {t(`finance_overview.header.${header.toLowerCase()}`)}
                </Text>
              ) : (
                ''
              )}
              {index < 4 && (
                <Icon
                  as={IoTriangleSharp}
                  color="#93C3B3"
                  h="10px"
                  ps="2px"
                  transition="all 0.2s ease-in-out"
                  //   transform={
                  //     sortBy === header ? 'rotate(60deg)' : 'rotate(0deg)'
                  //   }
                />
              )}
            </Flex>
          ))}
        </Flex>
      </Hide>
      <VStack
        spacing={0}
        align="stretch"
        overflowY="auto"
        // onScroll={handleDetectEndOfScroll}
        gap={{ base: '16px', md: '0px' }}>
        {payments.map((payment) => (
          <PaymentsTableItem key={payment.id} payment={payment} />
        ))}
      </VStack>
      {handleShowAll && (
        <Flex justify="center" py={4}>
          <Button variant="h3outlinedBrand" onClick={handleShowAll}>
            {t('finance_overview.show_all')}
          </Button>
        </Flex>
      )}
    </VStack>
  );
}
