import { useEffect, useState } from 'react';

import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import {
  AppDialogProps,
  closeAssignDocNumberDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

const AssignDocNumberDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { assignDocNumberDialog } = useSelector((state: RootState) => ({
    assignDocNumberDialog: state.dialogs.assignDocNumberDialog,
  }));
  const dialog = assignDocNumberDialog as AppDialogProps;
  const [docNumber, setDocNumber] = useState<number>(1);
  const [docType, setDocType] = useState<string>('document');
  const handleClose = useCallback(() => {
    dispatch(closeAssignDocNumberDialog());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (onlyCurrent: boolean = false) => {
      if (dialog?.onConfirm) {
        dialog?.onConfirm({ docNumber, onlyCurrent });
      }
      handleClose();
    },
    [dialog, docNumber, handleClose],
  );

  useEffect(() => {
    if (dialog?.item?.docNumber) {
      setDocNumber(Number(dialog?.item?.docNumber));
    }
    if (dialog?.item?.docType) {
      setDocType(dialog?.item?.docType);
    }
  }, [dispatch, dialog?.item?.docNumber, dialog?.item?.docType]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="24px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text variant="cardTitle">
                {t('dialogs.assign_doc_number.title')}
              </Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <Text variant="body1" color="gray.500">
              {t('dialogs.assign_doc_number.description')}
            </Text>
            <InputField
              placeholder={'10000'}
              label={`${t(`finance_settings.document_numbers.${docType}`)} ${t(
                'dialogs.assign_doc_number.label',
              )}`}
              value={docNumber}
              name="docNumber"
              type="number"
              min={1}
              step={1}
              onChange={(e) => setDocNumber(Number(e.target.value))}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
            />
            <Text variant="body1" color="gray.500">
              {t('dialogs.assign_doc_number.notice')}
            </Text>
            <Flex
              content="space-between"
              align="center"
              flexDir={{ base: 'column', md: 'row' }}
              gap={{ base: '8px', md: '24px' }}>
              <Button
                w="full"
                variant="h2outlined"
                fontSize={{ base: '12px !important', md: '14px !important' }}
                lineHeight={{ base: '12px !important', md: '16px !important' }}
                height="44px !important"
                onClick={() => handleSubmit(false)}>
                {t(
                  'dialogs.assign_doc_number.buttons.set_for_additional_documents',
                )}
              </Button>
              <Button
                w="full"
                variant="h1cta"
                fontSize={{ base: '12px !important', md: '14px !important' }}
                lineHeight={{ base: '12px !important', md: '16px !important' }}
                height="44px !important"
                onClick={() => handleSubmit(true)}>
                {t(
                  'dialogs.assign_doc_number.buttons.set_for_current_document',
                )}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AssignDocNumberDialog;
