import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  handleCancelMeeting: () => void;
  participant: string[];
}

export default function CancelAnchorMeetingDialog(props: IProps) {
  const { isOpen, onClose, handleCancelMeeting, participant } = props;
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="sm"
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent p="24px" borderRadius="30px" minW="445px" gap="24px">
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Text variant="anchorMeetingName">
            {t('event.anchor_meetings.cancel_meeting')}
          </Text>
          <LuX
            color="#939393"
            width="24px"
            height="24px"
            onClick={onClose}
            cursor="pointer"
          />
        </Flex>
        <Flex flexDirection="column" gap="12px">
          <Text variant="createQuoteCheckboxText">
            {t('event.anchor_meetings.are_you_sure_you_want_to_cancel_meeting')}
          </Text>
          {participant?.length > 0 && (
            <Text variant="createQuoteCheckboxText">
              {t(
                'event.anchor_meetings.following_participants_will_be_informed',
              )}
            </Text>
          )}
        </Flex>
        {participant?.length > 0 && (
          <Flex flexDirection="column" gap="8px">
            {participant.map((p) => (
              <Text variant="cancelAnchorMeetingDialogParticipant">{p}</Text>
            ))}
          </Flex>
        )}
        <Flex alignItems="center" gap="24px">
          <Button variant="h2outlined" w="100%" onClick={onClose}>
            {t('event.anchor_meetings.close')}
          </Button>
          <Button variant="h1cta" w="100%" onClick={handleCancelMeeting}>
            {t('event.anchor_meetings.cancel_meeting')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
