import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import Dropzone from 'components/dropzone';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  closeAddBriefNoteDialog,
  closeAlertDialog,
  openAlertDialog,
  setViewPhotoDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import {
  useCreateBriefNote,
  useDeleteBriefNote,
  useUpdateBriefNote,
} from 'queries/briefNote';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuX } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { UrlItem } from 'services/@types';
import { isImageName } from 'utils/isImageName';

interface BriefNoteForm {
  id?: string;
  title: string;
  description: string;
  media: UrlItem[];
}
const requiredFields: (keyof BriefNoteForm)[] = ['title', 'description'];
const maxLengthKey = 'description:maxLength';

export default function AddBriefNoteDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useMyUser();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addBriefNoteDialog,
  );

  const { mutateAsync: createBriefNote, isPending: creatingBrief } =
    useCreateBriefNote();
  const { mutateAsync: deleteBriefNote } = useDeleteBriefNote();
  const { mutateAsync: updateBriefNote, isPending: updatingBrief } =
    useUpdateBriefNote();
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState<BriefNoteForm>({
    id: dialog?.item.id,
    title: dialog?.item.title || '',
    description: dialog?.item.description || '',
    media: dialog?.item.media || [],
  });
  const [errors, setErrors] = useState<string[]>([]);

  const validateBriefNote = useCallback(() => {
    const newErrors: string[] = requiredFields.filter(
      (field) => !formData?.[field as keyof BriefNoteForm],
    );
    if (formData.description.length > 200) {
      newErrors.push(maxLengthKey);
    }
    setErrors(newErrors);

    return newErrors.length === 0;
  }, [formData]);

  const onClose = useCallback(() => {
    dispatch(closeAddBriefNoteDialog());
    setFormData({
      title: '',
      description: '',
      media: [],
    });
    setErrors([]);
  }, [dispatch]);

  useEffect(() => {
    setFormData({
      id: dialog?.item?.id,
      title: dialog?.item.title || '',
      description: dialog?.item.description || '',
      media: dialog?.item.media || [],
    });
  }, [dialog]);

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      if (errors.includes(name)) {
        setErrors(errors.filter((field) => field !== name));
      } else if (name === 'description' && value.length <= 200) {
        setErrors(errors.filter((field) => field !== maxLengthKey));
      }
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [errors],
  );

  const handleUploadCompleted = useCallback((media: UrlItem) => {
    setFormData((prev) => ({
      ...prev,
      media: [...prev.media, media],
    }));
    setIsUploading(false);
  }, []);

  const handleDeleteItem = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('event.brief.delete_note'),
        content: t('event.brief.delete_note_description'),
        onConfirm: async () => {
          await deleteBriefNote(formData.id);
          dispatch(closeAlertDialog());
          onClose();
        },
      }),
    );
  }, [deleteBriefNote, dispatch, formData?.id, onClose, t]);

  const handleSave = useCallback(async () => {
    if (!validateBriefNote()) return;

    if (!dialog?.item.eventId) return;

    try {
      if (formData.id) {
        await updateBriefNote({
          briefNoteId: formData.id,
          updates: {
            title: formData.title,
            description: formData.description,
            media: formData.media,
          },
        });
        toast({
          title: t('event.brief.toast.note_updated'),
          status: 'success',
          variant: 'main',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        await createBriefNote(
          {
            eventId: dialog.item.eventId,
            title: formData.title,
            description: formData.description,
            media: formData.media,
          },
          {
            onSuccess: () => {
              toast({
                title: t('event.brief.toast.note_created'),
                status: 'success',
                variant: 'main',
                duration: 3000,
                isClosable: true,
              });
              onClose();
            },
          },
        );
      }
    } catch (err) {
      toast({
        title: t('event.brief.toast.error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [
    validateBriefNote,
    dialog?.item?.eventId,
    formData.id,
    formData.title,
    formData.description,
    formData.media,
    updateBriefNote,
    toast,
    t,
    onClose,
    createBriefNote,
  ]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        minW="350px"
        maxW="350px"
        p="18px"
        gap={4}
        borderRadius="18px">
        <HStack justifyContent="space-between">
          <Flex alignItems="center">
            <Text color="black" fontWeight="700" fontSize="2xl">
              {t(
                formData.id ? 'event.brief.edit_note' : 'event.brief.add_note',
              )}
            </Text>
          </Flex>
          <Icon
            as={LuX}
            color="#00000066"
            w="20px"
            h="20px"
            cursor="pointer"
            onClick={onClose}
          />
        </HStack>

        <InputField
          name="title"
          label={t('event.brief.note_title')}
          value={formData.title}
          onChange={handleInput}
          placeholder={t('event.brief.note_title_placeholder')}
          errorLabel={
            errors.includes('title')
              ? t('event.brief.error.title_required')
              : ''
          }
          isInvalid={!!errors.includes('title')}
        />

        <TextField
          name="description"
          label={t('event.brief.note_description')}
          value={formData.description}
          onChange={handleInput}
          placeholder={t('event.brief.note_description_placeholder')}
          minH="140px"
          maxLength={-1}
          extra={`${formData.description.length} / 200`}
          extraStyles={{
            color: 'brand.900',
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '12px',
          }}
          errorLabel={
            errors.includes('description')
              ? t('event.brief.error.description_required')
              : errors.includes(maxLengthKey)
              ? t('event.brief.error.description_max_length')
              : ''
          }
          isInvalid={!!errors.includes('description')}
        />

        {isImageName(formData.media[0]?.displayName) ||
        formData.media.length === 0 ? (
          <Flex h="70px" gap={2} w="100%" mb={0}>
            <Dropzone
              labelSize="14px"
              maxW={formData.media.length > 0 ? '68px' : '100%'}
              maxH="68px"
              display={
                formData.media.length === 0 ||
                isImageName(formData.media[0]?.displayName)
                  ? 'flex'
                  : 'none'
              }
              goldenUploadLabel={t('event.brief.add_file')}
              flex={1}
              cursor="pointer"
              disabled={formData.media.length > 0}
              currentImageProps={{
                onClick: () => {
                  dispatch(
                    setViewPhotoDialog({
                      item: { ...formData.media[0], type: 'image' },
                    }),
                  );
                },
              }}
              currentImage={
                isImageName(formData.media[0]?.displayName)
                  ? formData.media[0]?.url
                  : undefined
              }
              onStartUpload={() => setIsUploading(true)}
              onUploadCompleted={handleUploadCompleted}
              dropzoneOptions={{
                accept: [
                  'image/*',
                  'video/*',
                  'audio/*',
                  'application/pdf',
                  'text/plain',
                ],
                multiple: false,
              }}
              uploadBarStyle="line"
              uploadKey="attachment"
              access="restricted"
              target={dialog?.item?.id ? 'briefNote' : 'user'}
              targetId={dialog?.item?.id || user?.id}
            />
          </Flex>
        ) : null}

        <HStack
          flex={1}
          w="100%"
          gap={2}
          display={formData.media?.length > 0 ? 'flex' : 'none'}>
          <Text
            alignSelf="center"
            fontWeight={'bold'}
            noOfLines={2}
            color="brand.900"
            textDecorationLine={'underline'}>
            {formData.media?.[0]?.displayName}
          </Text>
          <IconButton
            icon={<LuX fontSize={'24x'} />}
            variant="ghost"
            opacity={0.5}
            display={formData.media?.length > 0 ? 'flex' : 'none'}
            alignSelf="center"
            aria-label="remove"
            onClick={() => {
              setFormData({ ...formData, media: [] });
            }}
          />
        </HStack>
        <HStack justifyContent="flex-end">
          {formData.id && (
            <Button variant="h4underlineRed" onClick={handleDeleteItem}>
              {t('event.brief.delete')}
            </Button>
          )}
          <Button
            variant="h1cta"
            minW="134px"
            onClick={handleSave}
            isLoading={creatingBrief || updatingBrief || isUploading}>
            {t('event.brief.save')}
          </Button>
        </HStack>
      </ModalContent>
    </Modal>
  );
}
