import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  closeSelectClientDialog,
  closeSplashDialog,
  setSelectClientDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { shareQuote, updateQuote } from 'contexts/redux/quote/quoteSlice';
import { format } from 'date-fns';
import html2canvas from 'html2canvas-pro';
import jsPDF from 'jspdf';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useParams, useSearchParams } from 'react-router-dom';
import QuoteService from 'services/quote.api';
import { useCreateInvite } from 'queries/invite';
import { AppDispatch } from 'contexts/redux/store';
import { IClient } from 'services/@types';
import { useMyUser } from 'contexts/redux/auth/authSlice';

const blobToFile = (blob: Blob, fileName: string) => {
  return new File([blob], fileName, { type: blob.type });
};

export default function usePreviewQuote() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const componentRef = useRef<HTMLDivElement>(null);
  const { appNavigate: navigate } = useAppNavigate();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { mutateAsync: createInvite } = useCreateInvite();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const myUser = useMyUser();

  // Desktop view handling when generating PDFs
  const [forcedDesktopView, setForcedDesktopView] = useState<{
    state: boolean;
    executor: null | any;
  }>({
    state: false,
    executor: null,
  });

  const quoteResult = useQuery({
    queryKey: ['quote', params?.id],
    queryFn: async () => await QuoteService.getQuote(params?.id),
    enabled: !!params?.id,
  });

  const memoizedQuote = useMemo(() => quoteResult.data, [quoteResult.data]);

  const isContract = useMemo(
    () => quoteResult.data?.isContract,
    [quoteResult.data?.isContract],
  );

  const memoizedIsDownloading = useMemo(
    () =>
      !!searchParams.get('share') ||
      !!searchParams.get('download') ||
      isDownloading,
    [searchParams, isDownloading],
  );

  const memoDiscount = useMemo(() => {
    if (memoizedQuote?.generalPaymentDetails?.discountType === 'fixed') {
      return memoizedQuote?.generalPaymentDetails?.discount;
    }
    return (
      memoizedQuote?.generalPaymentDetails?.total -
      memoizedQuote?.generalPaymentDetails?.totalIncludingDiscount
    );
  }, [
    memoizedQuote?.generalPaymentDetails?.discount,
    memoizedQuote?.generalPaymentDetails?.total,
    memoizedQuote?.generalPaymentDetails?.totalIncludingDiscount,
    memoizedQuote?.generalPaymentDetails?.discountType,
  ]);

  const quoteFileName = useMemo(() => {
    return `quote_${memoizedQuote?.quoteNumber}_${format(
      new Date(),
      'dd-MM-yyyy',
    )}.pdf`;
  }, [memoizedQuote?.quoteNumber]);

  const handleSharePdf = useCallback(async () => {
    const canvas = await html2canvas(componentRef.current, {
      scale: 2,
    });
    const imgData = canvas.toDataURL('image/jpeg');

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);

    // Calculate image dimensions to fit the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(imgData, 'jpeg', 0, 0, pdfWidth, pdfHeight);

    // return blob pdf to share
    return pdf.output('blob');
  }, []);

  const handleDownloadPdf = useCallback(async () => {
    // Capture the component as an image
    const canvas = await html2canvas(componentRef.current, {
      scale: 2,
    });

    const imgData = canvas.toDataURL('image/jpeg');

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);

    // Calculate image dimensions to fit the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(imgData, 'jpeg', 0, 0, pdfWidth, pdfHeight);

    // Save the PDF
    pdf.save(quoteFileName);

    return true;
  }, [componentRef, quoteFileName]);

  const handleUpdateQuote = useCallback(
    async (e) => {
      const response = await dispatch(
        updateQuote({
          id: params?.id,
          updates: { isContract: e.target.checked },
        }),
      );

      if ((response as any)?.error) {
        toast({
          title: t('create_quote.error'),
          status: 'error',
        });
        return;
      }

      // toast({
      //   title: t('create_quote.quote_updated'),
      //   status: 'success',
      //   variant: 'main',
      // });
      queryClient.invalidateQueries({
        queryKey: ['quote', params?.id],
      });
    },
    [params?.id, dispatch, queryClient, toast, t],
  );

  const handleSendQuote = useCallback(async () => {
    dispatch(
      setSelectClientDialog({
        item: {
          quoteId: params?.id,
        },
        onClose: () => {
          dispatch(closeSelectClientDialog());
        },
        onConfirm: (clients: IClient[]) => {
          if (clients.length > 0) {
            clients.forEach(async (client) => {
              try {
                await createInvite({
                  businessID: myUser?.businessID,
                  quoteId: params?.id,
                  type: 'quote',
                  email: client?.email,
                });
              } catch (error: any) {
                if (error?.response?.status === 409) {
                  toast({
                    title: t('quote_preview.already_invited', {
                      email: client?.email,
                    }),
                    variant: 'error',
                    position: 'top-right',
                    status: 'error',
                  });
                } else {
                  toast({
                    title: error?.response?.data?.message,
                    variant: 'error',
                    position: 'top-right',
                    status: 'error',
                  });
                }
              }
            });
          }
        },
      }),
    );
  }, [createInvite, dispatch, myUser?.businessID, params?.id, toast, t]);

  useEffect(() => {
    // check that the page is loaded
    if (!memoizedQuote) return;

    if (searchParams.get('download')) {
      const downloadAndRedirect = async () => {
        const isDownloaded = await handleDownloadPdf();

        if (isDownloaded) {
          setTimeout(() => {
            navigate(-1);
            dispatch(closeSplashDialog());
            toast({
              title: t('quote_preview.downloaded'),
              variant: 'main',
              position: 'top-right',
              status: 'success',
            });
          }, 1000);
        }
      };

      setForcedDesktopView({ state: true, executor: downloadAndRedirect });
    }
  }, [
    searchParams,
    handleDownloadPdf,
    navigate,
    dispatch,
    memoizedQuote,
    toast,
    t,
  ]);

  useEffect(() => {
    if (!memoizedQuote) return;
    if (searchParams.get('share')) {
      const sharePdf = async () => {
        const blob = await handleSharePdf();
        const file = blobToFile(blob, quoteFileName);

        if (file) {
          const res = await dispatch(
            shareQuote({
              file,
              eventId: memoizedQuote?.eventId,
              quoteId: params?.id,
            }),
          );
          if (res) {
            setTimeout(() => {
              navigate(-1);
              dispatch(closeSplashDialog());
              toast({
                title: t('quote_sharing_dialog.sent'),
                variant: 'main',
                position: 'top-right',
                status: 'success',
              });
            }, 1000);
          }
        }
      };
      setForcedDesktopView({ state: true, executor: sharePdf });
    }
  }, [
    searchParams,
    handleSharePdf,
    navigate,
    dispatch,
    toast,
    t,
    quoteFileName,
    memoizedQuote,
    memoizedQuote?.eventId,
    params?.id,
  ]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (forcedDesktopView.state) {
      timeout = setTimeout(() => {
        forcedDesktopView?.executor();
        setForcedDesktopView({ state: false, executor: null });
      }, 300);
    }
    return () => timeout && clearTimeout(timeout);
  }, [forcedDesktopView]);

  return {
    memoizedQuote,
    memoizedIsDownloading,
    handleUpdateQuote,
    forcedDesktopView,
    componentRef,
    setForcedDesktopView,
    setIsDownloading,
    handleDownloadPdf,
    isContract,
    memoDiscount,
    handleSendQuote,
  };
}
