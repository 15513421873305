import { AxiosInstance, AxiosResponse } from 'axios';
import { IContract, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class ContractService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createContract(
    Contract: Partial<IContract>,
  ): Promise<IContract> {
    const response: AxiosResponse<IContract> = await this.api.post(
      `/contracts`,
      Contract,
    );
    return response.data;
  }

  public async getContracts(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IContract>> {
    const response: AxiosResponse<PaginatedResults<IContract>> =
      await this.api.get('/contracts', {
        params: queryParams,
      });
    return response.data;
  }

  public async getContract(contractId: string): Promise<IContract> {
    const response: AxiosResponse<IContract> = await this.api.get(
      `/contracts/${contractId}`,
    );
    return response.data;
  }

  public async updateContract(
    contractId: string,
    updates: Partial<IContract>,
  ): Promise<IContract> {
    const response: AxiosResponse<IContract> = await this.api.patch(
      `/contracts/${contractId}`,
      updates,
    );
    return response.data;
  }

  public async deleteContract(contractId: string): Promise<void> {
    await this.api.delete(`/contracts/${contractId}`);
  }

  public async shareContract(formData: FormData): Promise<void> {
    await this.api.post(`/contracts/share`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

const _ContractService = new ContractService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _ContractService;
