import {
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import Dropzone from 'components/dropzone';
import DropDownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import TextField from 'components/fields/TextField';
import ChosenClient from 'components/selectClientDialog/ChosenClient';
import TimeSelector from 'components/timeSelector';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';
import { IClient, Supplier } from 'services/@types';
import ChosenSupplier from 'views/main/managment/events/createUpdateEvent/ChosenSupplier';
import useAddTrackerCustomEvent from './hooks/useAddTrackerCustomEvent';
export default function AddTrackerCustomEventDialog() {
  const { t } = useTranslation();
  const user = useMyUser();

  const {
    customEvent,
    handleChange,
    onClose,
    handleOpenSelectSupplierDialog,
    typeOptions,
    selectedOptions,
    handleOpenSelectClientDialog,
    showedOptions,
    handleChangeTime,
    handleAddCustomEventFile,
    handleSaveCustomEvent,
    dialog,
    loading,
    handleRemoveCustomEventFile,
    startLoading,
    stopLoading,
    handleRemoveSupplier,
    handleRemoveClient,
  } = useAddTrackerCustomEvent();

  return (
    <Modal
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: '100%', md: '540px' }}
        p="18px 15px"
        gap="12px"
        borderRadius="16px"
        flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between" gap="12px">
          <Text variant="previewQuoteContactTitle">
            {t('tracker.add_custom_event_dialog.title')}
          </Text>
          <IconButton
            icon={<Icon as={FaX} color="#939393" w="12px" h="12px" />}
            aria-label="add"
            variant="brand"
            onClick={onClose}
          />
        </Flex>
        <DropDownField
          closeOnSelect
          options={typeOptions}
          selectedOptions={selectedOptions}
          showedOptions={showedOptions}
          onSelected={(e: any) => {
            handleChange('type', e.value);
          }}
          label={t('tracker.add_custom_event_dialog.type')}
          placeholder={t('tracker.add_custom_event_dialog.type_of_action')}
        />
        <Flex alignItems="center" gap="12px">
          <InputDateField
            label={t('tracker.add_custom_event_dialog.meeting_date')}
            value={customEvent?.meetingDateAndTime}
            onChange={(e: Date) => {
              handleChange('meetingDateAndTime', e);
            }}
            justifyContent="end"
            buttonStyles={{
              borderColor: customEvent?.meetingDateAndTime
                ? '#0000001A'
                : 'error.100',
              justifyContent: 'space-between',
              w: '100%',
            }}
            imgStyles={{
              bg: 'transparent',
            }}
            imgSrc={CalendarIcon}
          />
          <TimeSelector
            label={t('tracker.add_custom_event_dialog.meeting_time')}
            value={customEvent?.meetingDateAndTime
              ?.toLocaleTimeString('en-US', { hourCycle: 'h24' })
              .slice(0, 5)}
            w="100%"
            onChange={handleChangeTime}
            borderColor={
              customEvent?.meetingDateAndTime ? '#0000001A' : 'error.100'
            }
          />
        </Flex>
        <Divider />
        <Flex gap="12px">
          <Flex flexDirection="column" gap="12px" flex={1}>
            <Button
              variant="h1cta"
              onClick={handleOpenSelectClientDialog}
              w="100%">
              {t('tracker.add_custom_event_dialog.choose_clients')}
            </Button>
            {customEvent?.participants?.clients?.map((client, key) => (
              <ChosenClient
                key={key}
                client={client as IClient}
                onRemove={() => handleRemoveClient((client as IClient)?.id)}
                wrapperProps={{
                  w: { base: '194px', md: '249px' },
                  borderColor: '#AA915D',
                }}
              />
            ))}
          </Flex>
          <Flex flexDirection="column" gap="12px" flex={1}>
            <Button
              variant="h2outlined"
              w="100%"
              onClick={handleOpenSelectSupplierDialog}>
              {t('tracker.add_custom_event_dialog.choose_suppliers')}
            </Button>
            {customEvent?.participants?.suppliers?.map((supplier, key) => (
              <ChosenSupplier
                key={key}
                supplier={supplier as Supplier}
                onRemove={() =>
                  handleRemoveSupplier((supplier as Supplier)?.id)
                }
                wrapperProps={{
                  w: { base: '194px', md: '249px' },
                  borderColor: '#5FBF96',
                }}
              />
            ))}
          </Flex>
        </Flex>
        <Divider />
        <TextField
          label={t('tracker.add_custom_event_dialog.content')}
          placeholder={t('tracker.add_custom_event_dialog.content_placeholder')}
          value={customEvent?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange('title', e.target.value)
          }
          minH="44px"
        />
        <Flex
          alignItems="center"
          gap="12px"
          display={
            customEvent?.media?.find((i) => i.type === 'custom_event')?.url
              ? 'none'
              : 'flex'
          }>
          <Dropzone
            uploadKey="custom_event"
            target="user"
            uploadLabel={t('create_contract.add_file_or_drag_and_drop')}
            labelSize="xs"
            targetId={user?.id}
            access="private"
            onUploadError={stopLoading}
            currentImage={
              customEvent?.media?.find((i) => i.type === 'custom_event')?.url
            }
            dropzoneOptions={{
              multiple: false,
            }}
            onStartUpload={startLoading}
            onUploadCompleted={handleAddCustomEventFile}
            initialProgress={
              !!customEvent?.media?.find((i) => i.type === 'custom_event')
                ? 100
                : 0
            }
            h="68px"
            // w="100%"
            minH="68px"
            // minW="100%"
            transition="all 0.3s ease"
          />
        </Flex>
        {customEvent?.media?.find((i) => i.type === 'custom_event')?.url && (
          <Flex alignItems="center" gap="12px">
            <Text variant="trackerEventName">
              {
                customEvent?.media?.find((i) => i.type === 'custom_event')
                  ?.displayName
              }
            </Text>
            <IconButton
              icon={<Icon as={FaX} color="#939393" w="12px" h="12px" />}
              aria-label="add"
              variant="brand"
              onClick={handleRemoveCustomEventFile}
            />
          </Flex>
        )}
        <Flex justifyContent="end">
          <Button
            isLoading={loading}
            disabled={!customEvent?.title || !customEvent?.type}
            variant="h1cta"
            w={{ base: '100%', md: '320px' }}
            onClick={handleSaveCustomEvent}>
            {t('tracker.add_custom_event_dialog.save')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
