import { useToast } from '@chakra-ui/react';
import { User } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  getNavigateTo,
  selectSelectedDate,
  setNavigateTo,
  setSelectedDate,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import {
  createMeeting,
  getMeeting,
  updateMeeting,
} from 'contexts/redux/meeting/meetingSlice';
import { RootState } from 'contexts/redux/store';
import { fetchUser, fetchUsers } from 'contexts/redux/user/userSlice';
import { useAppNavigate } from 'hooks/useAppNavigate';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IEvent, IMeeting, UrlItem } from 'services/@types';
import _eventService from 'services/event.api';
import { isValidEmail } from 'utils/isValidEmail';

export const requiredFields = ['name', 'location', 'startDate', 'endDate'];

interface UseCreateMeetingResult {
  errorList: string[];
  meeting: Partial<IMeeting>;
  handleChangeMeeting: (e: any) => void;
  handleCreateUpdateMeeting: (navigateTo?: string) => void;
  handleChangeRemind: (e: any) => void;
  selectedRemind: string[];
  event: IEvent;
  handleOpenEventDialog: () => void;
  displayingGuest: {
    avatar: UrlItem | undefined;
    email: string;
    label: string;
    value: string;
    isExternal?: boolean;
    businessName?: string;
  }[];
  currentGuestInput: string;
  selectedGuests: string[];
  handleSelectGuest: (value: string) => void;
  handleSelectExternalGuest: (value: string) => void;
  handleRemoveGuest: (value: string) => void;
  setCurrentGuestInput: (value: string) => void;
  setEvent: (event: IEvent) => void;
}

export default function useCreateUpdateMeeting(): UseCreateMeetingResult {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const { triggerChangesDetected, preventChangesDetected } = useAppNavigate();
  const navigate = useNavigate();
  const myUser = useMyUser();
  const myBusiness = useMyBusiness();
  const selectedDate = useSelector(selectSelectedDate);

  const [event, setEvent] = useState<IEvent>(null);
  const [meeting, setMeeting] = useState<Partial<IMeeting>>({
    startDate: selectedDate.start,
    endDate: selectedDate.end,
    guests: [],
  });

  const params = useParams();

  const id = useMemo(() => params?.id, [params?.id]);
  const [errorList, setErrorList] = useState<string[]>([]);
  const selectedRemind = useMemo(
    () => [`create_meeeting.remind_${meeting?.reminder}`] || [],
    [meeting?.reminder],
  );
  const queryClient = useQueryClient();
  const { calendars } = useSelector((state: RootState) => state.calendars);

  const navigateTo = useSelector(getNavigateTo);
  const [currentGuestInput, setCurrentGuestInput] = useState<string>('');
  const [displayingGuest, setDisplayingGuest] = useState<
    {
      avatar: UrlItem | undefined;
      email: string;
      label: string;
      value: string;
      isExternal?: boolean;
      businessName?: string;
    }[]
  >([]);

  const selectedGuests = useMemo(
    () => [...(meeting?.guests || []), ...(meeting?.externalGuests || [])],
    [meeting?.guests, meeting?.externalGuests],
  );

  const handleChangeMeeting = useCallback(
    (e: any) => {
      triggerChangesDetected();
      if (errorList.includes(e.target.name)) {
        setErrorList((prev) => prev.filter((field) => field !== e.target.name));
      }

      if (e.target.name === 'startTime' || e.target.name === 'endTime') {
        const [hours, minutes] = e.target.value.split(':');
        const dateToUpdate =
          e.target.name === 'startTime' ? 'startDate' : 'endDate';
        const baseDate = meeting[dateToUpdate];
        const newDate = moment(baseDate)
          .set('hour', parseInt(hours))
          .set('minute', parseInt(minutes))
          .toDate();
        dispatch(
          setSelectedDate({
            start: newDate,
            end: moment(newDate).add(30, 'minutes').toDate(),
          }),
        );
        setMeeting((prev) => ({
          ...prev,
          [dateToUpdate]: newDate,
        }));
        return;
      } else if (e.target.name === 'startDate') {
        dispatch(
          setSelectedDate({
            start: e.target.value,
            end: moment(e.target.value).add(30, 'minutes').toDate(),
          }),
        );
      }

      setMeeting((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [dispatch, errorList, meeting, triggerChangesDetected],
  );

  const validateTask = useCallback(() => {
    let result = requiredFields.filter((field) => {
      const meetingField = meeting[field as keyof IMeeting];
      const ress =
        !meeting || field === 'location'
          ? !(meetingField as any)?.value
          : !meetingField;
      return ress;
    });

    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [meeting]);

  const handleCreateUpdateMeeting = useCallback(async () => {
    const isError = validateTask();
    if (isError) {
      return;
    }
    preventChangesDetected();
    let newMeetingData: any = { ...meeting };
    if (event) {
      newMeetingData.eventId = event.id;
    }

    if (meeting?.id) {
      const updatedMeeting = await dispatch(
        updateMeeting({ id: meeting.id, updates: newMeetingData }),
      );
      if (updatedMeeting?.error) {
        toast({
          title: t('create_meeting.update_meeting_error'),
          variant: 'main',
        });
        return;
      }
      toast({
        title: t('create_meeting.update_meeting_success'),
        variant: 'main',
      });
      navigate(navigateTo || '/');
      if (navigateTo) {
        dispatch(setNavigateTo(null));
        // invalidate query
        queryClient.invalidateQueries({
          queryKey: [`getMeetings-${meeting.eventId}`],
        });
      }
      return;
    }
    const newMeeting = await dispatch(
      createMeeting(newMeetingData as IMeeting),
    );
    if (newMeeting?.error) {
      return;
    }
    toast({
      title: t('create_meeting.success'),
      variant: 'main',
    });
    if (newMeeting?.eventId) {
      queryClient.invalidateQueries({
        queryKey: [
          'todayMeetings',
          newMeeting?.eventId,
          `getMeetings-${meeting.eventId}`,
        ],
      });
    }
    setEvent(null);
    setMeeting(null);
    navigate(navigateTo || '/');
    if (navigateTo) {
      dispatch(setNavigateTo(null));
    }
  }, [
    validateTask,
    preventChangesDetected,
    meeting,
    event,
    dispatch,
    toast,
    t,
    navigate,
    navigateTo,
    queryClient,
  ]);
  const handleChangeRemind = useCallback(
    (e: string) => {
      const split = e.split('_');
      const event = {
        target: {
          value: split[split.length - 1],
          name: 'reminder',
        },
      };
      handleChangeMeeting(event);
    },
    [handleChangeMeeting],
  );
  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          if (errorList.includes('event')) {
            setErrorList((prev) => prev.filter((field) => field !== 'event'));
          }
          setEvent(e);
        },
      }),
    );
  }, [dispatch, errorList]);

  const handleSelectGuest = useCallback(
    async (value: string) => {
      const { payload: user } = await dispatch(fetchUser(value));
      setMeeting((prev) => ({
        ...prev,
        guests: [...prev.guests, user.id],
      }));
      setCurrentGuestInput('');
    },
    [dispatch],
  );

  const handleSelectExternalGuest = useCallback((value: string) => {
    setMeeting((prev) => ({
      ...prev,
      externalGuests: [...(prev?.externalGuests || []), value],
    }));
    setCurrentGuestInput('');
  }, []);

  // id is the email or the user id
  const handleRemoveGuest = (id: string) => {
    if (isValidEmail(id)) {
      setMeeting((prev) => ({
        ...prev,
        externalGuests: prev.externalGuests.filter((item) => item !== id),
      }));
    } else {
      setMeeting((prev) => ({
        ...prev,
        guests: prev.guests.filter((item) => item !== id),
      }));
    }
  };

  useEffect(() => {
    if (currentGuestInput.length < 2 || !currentGuestInput) {
      setDisplayingGuest([]);
      return;
    }
    const fetchSeats = async () => {
      const users = await dispatch(
        fetchUsers({
          name: currentGuestInput,
          email: currentGuestInput,
          limit: 5,
          populate: 'businessID',
        }),
      );

      if (users?.payload?.results?.length > 0) {
        setDisplayingGuest(
          users.payload.results
            .filter(
              (user: User) => !meeting.guests.find((a: any) => a === user?.id),
            )
            .filter((user: User) => user.id !== myUser?.id)
            .map((user: User) => ({
              avatar: user.media?.find(
                (media: UrlItem) => media.type === 'avatar',
              ),
              email: user.email,
              label: `${user.firstName} ${user.lastName}`,
              value: user?.id,
              businessName: user?.businessID?.businessName || '',
            })),
        );
      }

      // posibility of adding email from outside of the system
      if (
        isValidEmail(currentGuestInput) &&
        users?.payload?.results?.length === 0
      ) {
        setDisplayingGuest([
          {
            value: currentGuestInput,
            avatar: undefined,
            label: currentGuestInput,
            isExternal: true,
            businessName: '',
            email: t('create_meeting.external_guest'),
          },
        ]);
      }
    };
    fetchSeats();
  }, [
    currentGuestInput,
    dispatch,
    myBusiness?.id,
    meeting?.guests,
    myUser?.id,
    t,
  ]);

  // set default calendar to meetings if it's create meeting
  useEffect(() => {
    if (!id) {
      // find external calendar
      let calendarId = calendars.find(
        (calendar) => calendar.type === 'external',
      )?.id;

      // if not found, find meetings calendar
      if (!calendarId) {
        calendarId = calendars.find(
          (calendar) => calendar.name === 'meetings',
        )?.id;
      }

      setMeeting((prev) => ({
        ...prev,
        calendarId: calendarId,
      }));
    }
  }, [calendars, id]);

  // Update meeting times when selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      const newStartDate = selectedDate;

      setMeeting((prev) => {
        // Keep the same date but update the time
        const updatedStartDate = moment(newStartDate.start)
          .hours(moment(prev?.startDate || newStartDate.start).hours())
          .minutes(moment(prev?.startDate || newStartDate.start).minutes())
          .toDate();

        const updatedEndDate = moment(updatedStartDate).add(1, 'hour').toDate();

        return {
          ...prev,
          startDate: updatedStartDate,
          endDate: updatedEndDate,
        };
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (id) {
      const getMeetingToUpdate = async () => {
        const { payload: meetingToUpdate } = await dispatch(getMeeting(id));
        setMeeting(meetingToUpdate);
        if (meetingToUpdate.eventId) {
          const eventToUpdate = await _eventService.getEvent(
            meetingToUpdate.eventId,
          );
          setEvent(eventToUpdate);
        }
      };
      getMeetingToUpdate();
    }
  }, [dispatch, id]);

  return {
    errorList,
    meeting,
    handleChangeMeeting,
    handleCreateUpdateMeeting,
    handleChangeRemind,
    selectedRemind,
    event,
    handleOpenEventDialog,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleSelectExternalGuest,
    handleRemoveGuest,
    setCurrentGuestInput,
    setEvent,
    selectedGuests,
  };
}
