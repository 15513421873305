import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  setBusinessEventPlan,
  useBusinessEventPlanSelector,
} from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import { closeAddParticipantDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  IClient,
  IClientContact,
  Invite,
  IParticipant,
  Supplier,
} from 'services/@types';
import _businessEventPlanService from 'services/businessEventPlan.api';

export interface AssignedItem {
  id: string;
  email: string;
  label: string;
  value: string;
  supplier?: Supplier;
  invite?: Invite;
  client?: IClient;
  contact?: IClientContact;
}

const useAddParticipant = () => {
  const { t } = useTranslation();
  const { businessEventPlan } = useBusinessEventPlanSelector();

  const dispatch = useDispatch<any>();
  const [isTeamMember, setIsTeamMember] = useState<boolean>(false);
  const [disabledTeamMemberField, setDisabledTeamMemberField] =
    useState<boolean>(false);
  const [adding, setAdding] = useState(false);
  const [assigned, setAssigned] = useState<AssignedItem[]>([]);
  const [currentAssigned, setCurrentAssigned] = useState<string>('');
  const toast = useToast();
  const myUser = useMyUser();

  const { addParticipantDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const { suppliers, seats, clients, loading } = useSelector(
    (state: RootState) => ({
      suppliers: state.suppliers.suppliers,
      seats: state.invites.invites,
      clients: state.clients.clients,
      loading:
        state.suppliers.loading ||
        state.invites.loading ||
        state.clients.loading,
    }),
  );

  useQuery({
    queryKey: [`participants-clients-search-${currentAssigned}`],
    queryFn: () => {
      const params: { page: number; limit: number; name?: string } = {
        page: 1,
        limit: 10,
      };

      return dispatch(fetchClients(params));
    },
  });

  const suppliersMemo = useMemo(
    () =>
      suppliers.filter(
        (s) =>
          !businessEventPlan?.participants.find(
            (p) => p?.entityId === s.id && p?.entityType === 'supplier',
          ),
      ),
    [suppliers, businessEventPlan?.participants],
  );
  const acceptedSeatsInvites = useMemo(
    () =>
      seats.filter(
        (seat) =>
          seat.status === 'accepted' &&
          seat.type === 'seat' &&
          seat.recipientID !== myUser?.id && // not myself
          !businessEventPlan?.participants.find(
            (p) => p?.entityId === seat.recipientID && p?.entityType === 'user',
          ),
      ),
    [seats, myUser?.id, businessEventPlan?.participants],
  );
  const clientsMemo = useMemo(() => {
    return clients.filter(
      (client) =>
        !businessEventPlan?.participants.find((p) =>
          client.type === 'private'
            ? p?.entityId === client.id
            : client.contacts.some((contact) => contact.id === p?.entityId),
        ),
    );
  }, [clients, businessEventPlan?.participants]);

  const handleClose = useCallback(() => {
    dispatch(closeAddParticipantDialog());
  }, [dispatch]);

  const handleCloseCompleted = useCallback(() => {
    setAssigned([]);
  }, []);

  const isOpen = useMemo(() => !!addParticipantDialog, [addParticipantDialog]);

  const [displayingAssigned, setDisplayingAssigned] = useState<AssignedItem[]>(
    [],
  );

  const handleSelectAssigned = useCallback(
    async (value: string) => {
      const newParticipant = displayingAssigned.find(
        (item) => item.id === value,
      );

      setAssigned((prev) => [...prev, newParticipant]);
      setCurrentAssigned('');
    },
    [displayingAssigned],
  );

  const validate = useCallback(() => {
    return assigned.length > 0 && businessEventPlan?.id;
  }, [assigned, businessEventPlan?.id]);

  const findParticipantInBusinessEventPlan = useCallback(
    (newParticipantPayload): IParticipant | null => {
      if (!businessEventPlan) return null;
      // search in pending
      const foundParticipant = businessEventPlan.participants.find(
        (p) =>
          p?.entityId === newParticipantPayload?.userId ||
          p?.entityId === newParticipantPayload?.supplierId ||
          p?.entityId === newParticipantPayload?.client?.client ||
          p?.entityId === newParticipantPayload?.client?.contactId,
      );
      if (!foundParticipant) {
        return null;
      }
      return foundParticipant;
    },
    [businessEventPlan],
  );

  const handleSubmit = useCallback(async () => {
    if (!businessEventPlan) return;
    const validation = validate();
    if (!validation) {
      toast({
        title: t('event.add_participants_modal.error.select_participant'),
        status: 'error',
        variant: 'error',
      });
      return;
    }
    const newParticipants: IParticipant[] = [];

    for (const newParticipant of assigned) {
      const newParticipantPayload: IParticipant = {
        isTeamMember,
        isApproved: false,
        entityId: '',
        entityType: 'user',
      };
      if (newParticipant.invite) {
        newParticipantPayload.entityId = newParticipant.invite?.recipientID;
        newParticipantPayload.entityType = 'user';
      } else if (newParticipant.supplier) {
        newParticipantPayload.entityId = newParticipant.supplier?.id;
        newParticipantPayload.entityType = 'supplier';
      } else {
        if (newParticipant.contact) {
          newParticipantPayload.entityId = newParticipant.contact.id;
          newParticipantPayload.entityType = 'contact';
        } else {
          newParticipantPayload.entityId = newParticipant.client.id;
          newParticipantPayload.entityType = 'client';
        }
      }
      newParticipants.push(newParticipantPayload);
    }

    const generateUpdatePayload = () => {
      const participantsToAdd = newParticipants.filter((p) => {
        const participantInBusinessEventPlan =
          findParticipantInBusinessEventPlan(p);

        // check if participant already exists in business event plan
        if (!!participantInBusinessEventPlan) {
          return false;
        }
        return true;
      });
      if (participantsToAdd.length === 0) {
        return null;
      }
      return {
        participants: [...businessEventPlan.participants, ...participantsToAdd],
      };
    };
    try {
      const updatePayload = generateUpdatePayload();

      if (!updatePayload) {
        toast({
          title: t('event.participants.error.participant_already_in_event'),
          status: 'error',
          variant: 'error',
        });
        return;
      } else {
        setAdding(true);
        const result = await _businessEventPlanService.updateBusinessEventPlan(
          businessEventPlan.id,
          updatePayload,
        );
        toast({
          title: t('event.participants.add_participant_success'),
          status: 'success',
          variant: 'main',
        });
        dispatch(setBusinessEventPlan(result));
        dispatch(closeAddParticipantDialog());
      }
    } catch (err) {
      toast({
        title: t('event.participants.error.add_participant'),
        status: 'error',
        variant: 'error',
      });
    } finally {
      setAdding(false);
    }
  }, [
    businessEventPlan,
    validate,
    toast,
    t,
    assigned,
    isTeamMember,
    findParticipantInBusinessEventPlan,
    dispatch,
  ]);
  const handleRemoveAssigned = useCallback(
    (id: string) => setAssigned((prev) => prev.filter((p) => p.id !== id)),
    [setAssigned],
  );

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (currentAssigned.length < 1) {
        return;
      }
      const searchedInvites = acceptedSeatsInvites.filter(
        (invite) =>
          invite.fullName
            .toLowerCase()
            .includes(currentAssigned.toLowerCase().trim()) ||
          invite.email
            .toLowerCase()
            .includes(currentAssigned.toLowerCase().trim()),
      );

      const searchedSuppliers = suppliersMemo.filter(
        (supplier) =>
          supplier.name
            .toLowerCase()
            .includes(currentAssigned.toLowerCase().trim()) ||
          supplier.email
            .toLowerCase()
            .includes(currentAssigned.toLowerCase().trim()),
      );

      const searchedClients = clientsMemo.filter((client) => {
        const companyNameMatch = client.company
          ?.toLowerCase()
          .includes(currentAssigned.toLowerCase().trim());
        const emailMatch = client.email
          .toLowerCase()
          .includes(currentAssigned.toLowerCase().trim());
        const contactMatch = client.contacts.some(
          (contact) =>
            contact.firstName
              ?.toLowerCase()
              .includes(currentAssigned.toLowerCase().trim()) ||
            contact.lastName
              ?.toLowerCase()
              .includes(currentAssigned.toLowerCase().trim()),
        );
        return companyNameMatch || emailMatch || contactMatch;
      });
      const searchResults = [
        ...searchedInvites,
        ...searchedSuppliers,
        ...searchedClients,
      ];
      setDisplayingAssigned(
        searchResults
          .reduce((acc, item) => {
            if (item.hasOwnProperty('name')) {
              const supplier = item as Supplier;
              acc.push({
                id: supplier.id,
                value: supplier.id,
                label: supplier.name,
                email: supplier.email,
                supplier,
              });
            } else if ((item as Invite).type === 'seat') {
              const seatInvite = item as Invite;
              acc.push({
                id: seatInvite.id,
                value: seatInvite.id,
                label: seatInvite.fullName,
                email: seatInvite.email,
                invite: seatInvite,
              });
            } else {
              const client = item as IClient;
              if (client.type === 'private') {
                acc.push({
                  id: client.id,
                  value: client.id,
                  label:
                    client.company || `${client.firstName} ${client.lastName}`,
                  email: client.email,
                  client,
                });
              } else {
                acc.push(
                  ...client.contacts.map((contact) => ({
                    id: contact.id,
                    value: contact.id,
                    label: `${contact.firstName} ${contact.lastName}`,
                    email: contact.email,
                    client,
                    contact,
                  })),
                );
              }
            }
            return acc;
          }, [] as AssignedItem[])
          .filter((item) => {
            return !assigned.some((a) => a.id === item.id);
          }),
      );
    };
    fetchSearchResults();
  }, [
    currentAssigned,
    dispatch,
    assigned,
    acceptedSeatsInvites,
    suppliersMemo,
    clientsMemo,
  ]);

  useEffect(() => {
    // if currentAssignedItem is a supplier, set disabledTeamMember to true

    setDisabledTeamMemberField(
      !!assigned?.[0]?.supplier || !!assigned?.[0]?.client,
    );
    setIsTeamMember(!assigned?.[0]?.supplier);
  }, [assigned]);

  return {
    isOpen,
    handleClose,
    handleCloseCompleted,
    displayingAssigned,
    assigned,
    currentAssigned,
    handleSelectAssigned,
    handleRemoveAssigned,
    isTeamMember,
    setIsTeamMember,
    disabledTeamMemberField,
    loading,
    adding,
    handleSubmit,
    setCurrentAssigned,
  };
};

export default useAddParticipant;
