import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { setQuoteSharingDialog } from 'contexts/redux/dialog/dialogsSlice';
import { format } from 'date-fns';
import { formatNumber } from 'helpers/formatNumber';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { IEvent } from 'services/@types';
import { useParams } from 'react-router-dom';

interface IContactDetailsProps {
  createdAt: Date;
  quoteNumber: number;
  clientName: string;
  numberOfGuests: number;
  location: string;
  totalAmount: number;
  currency: string;
  event: IEvent;
  handleDownload: () => void;
  isDownloading: boolean;
  setIsDownloading: (value: boolean) => void;
}

export default function ContactDetails(props: IContactDetailsProps) {
  const {
    createdAt,
    quoteNumber,
    clientName,
    numberOfGuests,
    location,
    totalAmount,
    currency,
    event,
    handleDownload,
    isDownloading,
    setIsDownloading,
  } = props;
  const { t } = useTranslation();
  const toaster = useToast();
  const toasterRef = useRef<any>(null);
  const { appNavigate: navigate } = useAppNavigate();
  const params = useParams();
  const dispatch = useDispatch<any>();

  const eventResult = useMemo(() => event, [event]);

  const eventName = useMemo(() => eventResult?.name, [eventResult?.name]);

  const memoizedCreatedAt = useMemo(() => {
    return createdAt ? format(new Date(createdAt), 'MMM d, yyyy') : '';
  }, [createdAt]);

  const memoizedTotalAmount = useMemo(() => {
    if (!totalAmount) return '';

    if (currency === 'USD')
      return `$${formatNumber(totalAmount).toLocaleString()}`;

    return `₪${formatNumber(totalAmount).toLocaleString()}`;
  }, [totalAmount, currency]);

  const handleDownloadPdf = useCallback(async () => {
    toasterRef.current = toaster({
      title: t('quote_preview.uploading'),
      description: t('quote_preview.please_wait'),
      variant: 'main',
      duration: 100000,
      position: 'top-right',
    });
    setIsDownloading(true);
    setTimeout(async () => {
      await handleDownload();
      const result = true;
      if (result) {
        setIsDownloading(false);
        toaster.update(toasterRef.current, {
          title: t('quote_preview.downloaded'),
          variant: 'main',
          position: 'top-right',
        });
        setTimeout(() => {
          toaster.close(toasterRef.current);
        }, 1000);
      }
    }, 1000);
  }, [handleDownload, setIsDownloading, t, toaster]);

  const handleShareQuote = useCallback(() => {
    dispatch(
      setQuoteSharingDialog({
        item: {
          quoteId: params?.id,
          navigateUrl: `/auth/approve-quote/${params?.id}?share=true`,
        },
      }),
    );
  }, [dispatch, params?.id]);

  return (
    <Flex flexDirection="column" w="100%" gap="24px">
      <Flex w="100%" justifyContent="space-between" alignItems="start">
        <Flex flexDirection="column">
          <Text variant="previewQuoteContactTitle">
            {t('quote_preview.quote')}
          </Text>
          <Text variant="createQuoteCheckboxText">{memoizedCreatedAt}</Text>
          <Text variant="previewQuoteContactNumber">
            {quoteNumber ? `#${quoteNumber}` : ''}
          </Text>
        </Flex>
        {!isDownloading && (
          <Flex alignItems="center" gap="8px">
            <IconButton
              aria-label="edit"
              icon={<FiDownload color="#fff" />}
              onClick={handleDownloadPdf}
              w="32px"
              h="32px"
              minH="32px"
              minW="32px"
              bg="#ACACAC"
            />
            {/* <IconButton
              aria-label="edit"
              icon={<IoMdShare color="#fff" />}
              w="32px"
              h="32px"
              minW="32px"
              minH="32px"
              bg="#ACACAC"
              onClick={handleShareQuote}
            /> */}
          </Flex>
        )}
      </Flex>
      <Flex
        border="1px solid #00000033"
        borderRadius="20px"
        p={{ base: '12px', md: '24px' }}
        gap={{ base: '16px', md: '0px' }}
        justifyContent="space-between"
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'start', md: 'end' }}>
        <Flex flexDirection="column" gap="8px">
          <Text variant="previewQuoteContactLabel">
            {t('quote_preview.quote_for')}
          </Text>
          <Text variant="taskHistoryTitle">{clientName}</Text>
          <Text variant="previewQuoteEventName">{eventName}</Text>
          <Flex alignItems="center" gap="12px">
            <Flex alignItems="center" gap="8px">
              <Icon as={HiOutlineMailOpen} color="general.100" />
              <Text variant="previewQuoteContactLabelSmall">
                {t('create_event.modals.found_event.invited', {
                  numberOfGuests: numberOfGuests,
                })}
              </Text>
            </Flex>
            <Flex alignItems="center" gap="8px">
              <Icon as={IoLocationOutline} color="general.100" />
              <Text
                variant="previewQuoteContactLabelSmall"
                wordBreak={'break-word'}>
                {location}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider
          borderColor="#00000033"
          display={{ base: 'block', md: 'none' }}
        />
        <Flex flexDirection="column" gap="16px">
          <Text variant="previewQuoteContactLabel">
            {t('quote_preview.total_amount')}
          </Text>
          <Text variant="previewQuoteTotalAmount">{memoizedTotalAmount}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
