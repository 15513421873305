import {
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  useAccordionItemState,
} from '@chakra-ui/react';
import { formatCurrency } from 'helpers/formatNumber';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronDown } from 'react-icons/lu';
import bankNote from '../../../../../../assets/svg/payment/bank-note.svg';
import calendar from '../../../../../../assets/svg/payment/calendar.svg';
import paymentPart from '../../../../../../assets/svg/payment/payment-part.svg';
import receiptCheck from '../../../../../../assets/svg/payment/receipt-check.svg';

interface IPaymentRowItem {
  paymentIndex: number;
  paymentsTotal: number;
  dueDate: Date;
  amount: number;
  paidAmount: number;
  isPaid: boolean;
}
const PaymentRowItem = ({
  paymentIndex,
  paymentsTotal,
  dueDate,
  amount,
  paidAmount,
  isPaid,
}: IPaymentRowItem) => {
  const { t, i18n } = useTranslation();
  const { isOpen } = useAccordionItemState();
  const bgColor = useMemo(() => {
    if (isPaid) return 'brand.400';
    if (moment().isAfter(moment(dueDate))) return 'red.500';
    return 'brand.300';
  }, [isPaid, dueDate]);

  console.log('payment row item isOpen', isOpen, 'paymentIndex', paymentIndex);
  const rotation = useMemo(() => {
    if (i18n.dir() === 'ltr') return isOpen ? 0 : -90;
    return isOpen ? 90 : 0;
  }, [i18n, isOpen]);
  return (
    <HStack
      spacing={4}
      align="center"
      w="100%"
      bg={bgColor}
      px={6}
      py={2}
      borderRadius="xl"
      color="white">
      <HStack
        spacing={{ base: 2, md: 6 }}
        fontSize="sm"
        flexDirection={{ base: 'column', md: 'row' }}
        justify="start">
        <HStack>
          <HStack w={'50px'}>
            <Image src={paymentPart} alt="payment part" />
            <Text>
              {paymentIndex + 1}/{paymentsTotal}
            </Text>
          </HStack>
          <HStack w={'60px'}>
            <Image src={calendar} alt="calendar" />
            <Text>{moment(dueDate).format('DD.MM.YY')}</Text>
          </HStack>
        </HStack>
        <HStack flex={1}>
          <HStack flex={1}>
            <Image src={bankNote} alt="bank note" />
            <Text noOfLines={1} wordBreak="break-all">
              {formatCurrency(paidAmount || amount)}
            </Text>
          </HStack>
          <HStack display={isPaid ? 'flex' : 'none'}>
            <Image src={receiptCheck} alt="receipt check" />
            <Text>{t('event_finance.payment_details_modal.receipt')}</Text>
          </HStack>
        </HStack>
      </HStack>
      <Spacer />
      <Icon
        as={LuChevronDown}
        fontSize="22px"
        transition="all 0.3s ease-in-out"
        transform={`rotate(${rotation}deg)`}
        color="white"
      />
    </HStack>
  );
};

export default PaymentRowItem;
