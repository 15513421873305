import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import {
  setBusinessEventPlan,
  useBusinessEventPlanSelector,
} from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { useBusinessContacts } from 'hooks/useBusinessContacts';
import { useGetEntity } from 'queries/entity';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuBan, LuMoreHorizontal } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import {
  Business,
  BusinessCategory,
  IParticipant,
  Supplier,
  User,
} from 'services/@types';
import _businessEventPlanService from 'services/businessEventPlan.api';
import { BusinessImage } from './ParticipantSearchItemImages';

type Props = {
  participant: IParticipant;
};

const RenderBusinessImage = ({ supplier }: { supplier: Supplier }) => {
  const { business } = useBusinessContacts<
    Omit<Business, 'category'> & {
      category: Pick<BusinessCategory, 'id' | 'name' | 'description'>[];
    }
  >(supplier, true);

  return <BusinessImage business={business} w="34px" h="34px" mr="12px" />;
};

const ParticipantItem = ({ participant }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const { businessEventPlan } = useBusinessEventPlanSelector();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { entityType, entityId } = participant;

  const { data: entity } = useGetEntity(
    entityType,
    entityId,
    entityType === 'contact' ? '/contactpeople' : '',
  );

  const path = useMemo(() => {
    return entityType === 'user'
      ? (entity as User)?.media?.find((m) => m.type === 'avatar')?.url
      : '';
  }, [entity, entityType]);

  const name = useMemo(() => {
    const anyEntity = entity as any;

    return (
      anyEntity?.name ||
      anyEntity?.company ||
      `${anyEntity?.firstName} ${anyEntity?.lastName}`
    );
  }, [entity]);

  const handleCancelInvitation = useCallback(async () => {
    if (!businessEventPlan) return;
    const result = await _businessEventPlanService.updateBusinessEventPlan(
      businessEventPlan.id,
      {
        participants: businessEventPlan.participants.filter(
          (p) => p.entityId !== participant.entityId,
        ),
      },
    );
    dispatch(setBusinessEventPlan(result));
    toast({
      title: t('event.participants.cancel_invitation_success'),
      status: 'success',
      variant: 'main',
    });
  }, [businessEventPlan, dispatch, toast, t, participant.entityId]);

  return (
    <HStack
      w="100%"
      spacing={0}
      h="77px"
      alignItems="center"
      _notLast={{
        borderBottom: '1px solid #E1E1E1',
      }}>
      <AuthImage
        w="34px"
        h="34px"
        borderRadius="50%"
        path={path}
        isAvatar
        name={name}
        bg="gray.200"
        mr="12px"
      />
      <VStack spacing={0} alignItems="flex-start" flex={1}>
        <Text
          display={participant.isTeamMember ? 'flex' : 'none'}
          p="2px 8px"
          borderRadius="8px"
          bg="#93C3B380"
          color="#465D55"
          fontSize={'12px'}
          fontWeight={500}
          letterSpacing={'-2%'}>
          {t('event.add_participants_modal.team_member')}
        </Text>
        <Text>{name}</Text>
        {/* description/note */}
        <Text fontSize="12px" color="gray.500">
          {entityType === 'supplier' && (entity as Supplier)?.internalNotes}
        </Text>
      </VStack>
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<LuMoreHorizontal size={24} />}
          variant="ghost"
          onClick={onOpen}
        />
        <MenuList minW={140} borderRadius={'2xl'}>
          <MenuItem
            icon={<LuBan size={24} />}
            color="blackAlpha.600"
            onClick={handleCancelInvitation}>
            {t('event.participants.cancel_invitation')}
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

export default ParticipantItem;
