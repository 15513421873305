import {
  Button,
  Flex,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import ThreeDotsBtn from 'components/buttons/ThreeDotsBtn';
import Card from 'components/card/Card';
import { AppTooltip } from 'components/tooltip';
import { deleteAlbum } from 'contexts/redux/albums/albumSlice';
import {
  closeAlertDialog,
  openAlertDialog,
  setAddAlbumDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Album } from 'services/@types';
import { useAlbumMediaItems } from '../hooks/useAlbumMediaItems';
import { MediaCollectionComponent } from './MediaCollectionComponent';

const options = ['edit', 'share', 'delete'];

export const AlbumComponent: React.FC<{ album: Album }> = ({ album }) => {
  const { mediaItems, loading } = useAlbumMediaItems(album.id);
  console.log('loading: ', loading);
  const dispatch = useDispatch<any>();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const { t } = useTranslation();

  const onSelected = useCallback(
    (option: string) => {
      switch (option) {
        case 'edit':
          dispatch(setAddAlbumDialog({ item: album }));
          break;
        case 'share':
          break;
        case 'delete':
          dispatch(
            openAlertDialog({
              title: t('gallery.new_album.delete.title'),
              content: t('gallery.new_album.delete.description'),
              onConfirm: () => dispatch(deleteAlbum({ albumId: album.id })),
              onClose: () => dispatch(closeAlertDialog()),
            }),
          );
          break;
        default:
          break;
      }
    },
    [album, dispatch, t],
  );

  return (
    <Card borderRadius={{ base: 0, md: '3xl' }} px={'20px'} w="100%">
      <HStack>
        <HStack wrap="wrap">
          <HStack align="start" spacing={0}>
            <Text fontSize="2xl" fontWeight={'bold'}>
              {album.name.toLowerCase() === 'general_album'
                ? t('gallery.album_collection.general')
                : album.name}
            </Text>
            <Flex
              align={'center'}
              alignSelf="center"
              display={album.name === 'general_album' ? 'flex' : 'none'}>
              <AppTooltip label={t('gallery.album.general_album_tooltip')} />
            </Flex>
          </HStack>

          {album.tags.map((tag) => (
            <Button variant={'outlineTag'}>{tag}</Button>
          ))}
        </HStack>
        <Spacer />
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <ThreeDotsBtn
            onClick={onOpen1}
            display={album.name === 'general_album' ? 'none' : 'flex'}
          />

          <MenuList minW="130px" w={'100px'} borderRadius="16px">
            {options.map((option) => (
              <MenuItem
                maxW="fit-content"
                w="fit-content"
                fontSize={'sm'}
                onClick={() => onSelected(option)}
                color={option === 'delete' ? 'red.500' : 'black'}>
                {t(`gallery.album.options.${option}`)}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </HStack>
      {loading ? (
        <VStack h={'160px'} justify="center">
          <Spinner size="lg" />
        </VStack>
      ) : (
        <MediaCollectionComponent album={album} items={mediaItems || []} />
      )}
    </Card>
  );
};
