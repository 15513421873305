import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import TextField from 'components/fields/TextField';
import { useTranslation } from 'react-i18next';

interface IDeclineQuoteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleDecline: () => void;
  comment: string;
  setComment: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function DeclineQuoteDialog(props: IDeclineQuoteDialogProps) {
  const { isOpen, onClose, handleDecline, comment, setComment } = props;
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={{ base: '100%', md: '435px' }}
        borderRadius={{ base: '0px', md: '30px' }}
        p="24px"
        gap="24px">
        <Flex flexDir="column" gap="12px">
          <Text variant="createQuoteTitle">
            {t('quote_preview.decline_the_quote')}
          </Text>
          <Text display="inline">
            <Text variant="clientEmail" display="inline">
              {t('quote_preview.you_are_declining_the_quote_from')}
            </Text>
            <Text variant="declineQuoteDialogName" display="inline" mx="4px">
              {'client name'}
            </Text>
          </Text>
        </Flex>
        <TextField
          placeholder={t('quote_preview.please_add_a_comment')}
          label={t('quote_preview.comment')}
          minH="157px"
          value={comment}
          onChange={setComment}
        />
        <Flex justifyContent="center" alignItems="center">
          <Button variant="h1cta" onClick={handleDecline}>
            {t('quote_preview.decline_and_send_response')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
