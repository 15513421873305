import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { queryClient } from 'App';
import { ITrackedEvent } from 'services/@types';
import trackedEventService from 'services/trackedEvent.api';

export interface TrackedEventState {
  trackedEvents: ITrackedEvent[];
  loading: boolean;
  trackedEvent: ITrackedEvent | null;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: TrackedEventState = {
  trackedEvents: [],
  loading: false,
  error: null,
  trackedEvent: null,
  pagination: {
    page: 1,
    limit: 1000,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchTrackedEvents = createAsyncThunk(
  'trackedEvent/fetchTrackedEvents',
  async (queryParams: Record<string, any>) => {
    const response = await trackedEventService.getTrackedEvents(queryParams);
    return response;
  },
);

export const fetchTrackedEvent = createAsyncThunk(
  'trackedEvent/fetchTrackedEvent',
  async (id: string) => {
    const response = await trackedEventService.getTrackedEvent(id);
    return response;
  },
);

export const createTrackedEvent = createAsyncThunk(
  'trackedEvent/createTrackedEvent',
  async (trackedEvent: ITrackedEvent) => {
    const response = await trackedEventService.createTrackedEvent(trackedEvent);
    return response;
  },
);

export const updateTrackedEvent = createAsyncThunk(
  'trackedEvent/updateTrackedEvent',
  async (trackedEvent: ITrackedEvent) => {
    const { id, ...rest } = trackedEvent;
    const response = await trackedEventService.updateTrackedEvent(id, rest);
    if (response) {
      queryClient.invalidateQueries({
        queryKey: ['dashboardTrackedEvents'],
      });
    }
    return response;
  },
);

export const deleteTrackedEvent = createAsyncThunk(
  'trackedEvent/deleteTrackedEvent',
  async (id: string) => {
    await trackedEventService.deleteTrackedEvent(id);
  },
);

export const trackedEventSlice = createSlice({
  name: 'trackedEvent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTrackedEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTrackedEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.trackedEvents = action.payload.results;
      state.pagination.limit = action.payload.limit;
      state.pagination.page = action.payload.page;
      state.pagination.totalPages = action.payload.totalPages;
      state.pagination.totalResults = action.payload.totalResults;
    });
    builder.addCase(fetchTrackedEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchTrackedEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTrackedEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.trackedEvent = action.payload;
    });
    builder.addCase(fetchTrackedEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(createTrackedEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTrackedEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.trackedEvent = action.payload;
    });
    builder.addCase(createTrackedEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateTrackedEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTrackedEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.trackedEvent = action.payload;
      state.trackedEvents = state.trackedEvents.map((event) =>
        event.id === action.payload.id ? action.payload : event,
      );
    });
    builder.addCase(updateTrackedEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteTrackedEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTrackedEvent.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTrackedEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default trackedEventSlice.reducer;
