import { VStack } from '@chakra-ui/react';
import useSharedWithMeFiles from '../../hooks/useSharedWithMeFiles';
import NoFiles from '../NoFiles';
import SharedWithMeFilesList from './SharedWithMeFilesList';

const SharedWithMeFiles = () => {
  const { files } = useSharedWithMeFiles();
  return (
    <VStack overflow="auto" h="100%" spacing={0} flex={1}>
      {files.length && <SharedWithMeFilesList files={files} />}
      {files.length === 0 && <NoFiles />}
    </VStack>
  );
};

export default SharedWithMeFiles;
