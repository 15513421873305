import { Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import {
  Button,
  Collapse,
  Icon,
  IconButton,
  Spinner,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { setStartingPayloadDate } from 'contexts/redux/createMeeting/createMeetingSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import EventCard from './EventCard';
import HolidayCard from './HolidayCard';
import MeetingCard from './MeetingCard';
interface EventsAndMeetingProps {
  cardStyles?: any;
}
export default function EventsAndMeeting(props: EventsAndMeetingProps) {
  const { cardStyles = {} } = props;
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();
  const {
    selectedDate,
    currentDate,
    calendarEvents,
    isMeetingsLoading,
    isEventsLoading,
  } = useSelector((state: RootState) => ({
    selectedDate: state.dashboard.selectedDate,
    currentDate: state.dashboard.currentDate,
    calendarEvents: state.calendarEvent.calendarEvents,
    isMeetingsLoading: state.calendarEvent.loading,
    isEventsLoading: state.calendarEvent.loading,
  }));

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const { parsedCurrentDate, isTodaySelected } = useMemo(() => {
    const isTodaySelected =
      format(new Date(selectedDate), 'yyyy-MM-dd') ===
      format(new Date(currentDate), 'yyyy-MM-dd');
    return {
      parsedCurrentDate: isTodaySelected
        ? new Date(currentDate)
        : new Date(selectedDate),
      isTodaySelected,
    };
  }, [currentDate, selectedDate]);

  const dateToShowFormatted = useMemo(
    () =>
      format(parsedCurrentDate, 'dd MMMM yy', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      }),
    [i18n.language, parsedCurrentDate],
  );

  // month-year-day format
  const keyQuery = useMemo(
    () => format(parsedCurrentDate, 'yyyy-MM-dd'),
    [parsedCurrentDate],
  );

  const events = useMemo(() => {
    return calendarEvents?.filter(
      (event) =>
        event.category === 'event' &&
        format(event.start, 'yyyy-MM-dd') === keyQuery,
    );
  }, [calendarEvents, keyQuery]);

  const meetings = useMemo(() => {
    return calendarEvents?.filter(
      (event) =>
        event.category === 'meeting' &&
        format(event.start, 'yyyy-MM-dd') === keyQuery,
    );
  }, [calendarEvents, keyQuery]);

  const holidays = useMemo(() => {
    return calendarEvents?.filter(
      (event) =>
        event.category === 'holiday' &&
        format(event.start, 'yyyy-MM-dd') === keyQuery,
    );
  }, [calendarEvents, keyQuery]);

  return (
    <Card
      flexDirection="column"
      w="100%"
      minW={310}
      flex={1}
      maxH="580px"
      borderRadius={{ base: 0, md: '30px' }}
      p="31px 26px 0"
      height="100%"
      overflow="hidden"
      gap={3}
      {...cardStyles}>
      <Text variant="cardTitle">{dateToShowFormatted}</Text>
      <HStack w="100%" justify="space-between">
        <HStack>
          <Text variant="eventCardTitle">{t('events_meeting.events')}</Text>
          <Switch
            onChange={() => onToggle()}
            dir={i18n.dir()}
            variant="brand"
            isChecked={isOpen}
          />
        </HStack>
        {events?.length > 0 && (
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => navigate('/main/events/create')}
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        )}
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Flex
          flexDirection="column"
          maxH="220px"
          w="100%"
          overflow="hidden"
          overflowY="auto"
          gap="10px">
          {isEventsLoading && (
            <Spinner alignSelf="center" justifySelf="center" />
          )}
          {events?.map((event, key: number) => (
            <EventCard calendarEvent={event} key={key} isDelete />
          ))}
          {events?.length === 0 && (
            <VStack p="50px 0 36px">
              <Text variant="taskCardSubTitle">
                {t(
                  isTodaySelected
                    ? 'events_meeting.no_events'
                    : 'events_meeting.no_events_date_specified',
                )}
              </Text>
              <Button
                variant="h1cta"
                onClick={() => {
                  dispatch(setStartingPayloadDate(parsedCurrentDate));
                  navigate('/main/events/create');
                }}>
                {t('events_meeting.create_event')}
              </Button>
            </VStack>
          )}
        </Flex>
      </Collapse>
      <HStack w="100%" justify="space-between">
        <Text variant="eventCardTitle">{t('events_meeting.schedule')}</Text>
        {meetings?.length > 0 && (
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => {
              dispatch(setStartingPayloadDate(parsedCurrentDate));
              navigate('/main/meetings/create');
            }}
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        )}
      </HStack>
      <Flex
        flexDirection="column"
        flex={1}
        w="100%"
        gap="10px"
        overflow="scroll">
        {isMeetingsLoading && (
          <Spinner alignSelf="center" justifySelf="center" />
        )}
        {holidays?.map((holiday, key: number) => (
          <HolidayCard calendarHoliday={holiday} key={key} />
        ))}
        {meetings
          ?.sort((a, b) => (a.start.getTime() > b.start.getTime() ? 1 : -1))
          ?.map((calendarMeeting, key: number) => (
            <MeetingCard calendarMeeting={calendarMeeting} key={key} isDelete />
          ))}

        {meetings?.length === 0 && (
          <VStack pt="50px">
            <Text variant="taskCardSubTitle">
              {t(
                isTodaySelected
                  ? 'events_meeting.no_meetings'
                  : 'events_meeting.no_meetings_date_specified',
              )}
            </Text>
            <Button
              variant="h1cta"
              onClick={() => {
                dispatch(setStartingPayloadDate(parsedCurrentDate));
                navigate('/main/meetings/create');
              }}>
              {t('events_meeting.create_meeting')}
            </Button>
          </VStack>
        )}
      </Flex>
      <Flex
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        top="0"
        height="100%"
        background="linear-gradient(to bottom, rgba(255,255,255,0) 80%, white 100%)"
        pointerEvents="none"
      />
    </Card>
  );
}
