import { Flex, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AppDispatch } from 'contexts/redux/store';
import { HighlightableComponent } from 'contexts/redux/uiHelper/uiHelperSlice';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { IPayment, UserPreferences } from 'services/@types';
import PaymentInfoListItem from './PaymentInfoListItem';

interface PaymentTrackingCardProps {
  title: string;
  eventId: string;
  stats: MiniDataStatProps[];
  isExpense: boolean;
  firstSection: IPayment[];
  secondSection: IPayment[];
  firstSectionTitle: string;
  secondSectionTitle: string;
  highlightName: HighlightableComponent;
  onPlusButtonClick?: () => void;
}

interface MiniDataStatProps {
  label: string;
  value: string;
  bgGradient: string;
}

const MiniDataStat = ({
  label,
  value,
  bgGradient,
  isBlurred,
}: MiniDataStatProps & { isBlurred?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Flex
      direction="column"
      p={2}
      w={{ base: '100%', md: 'auto' }}
      flex={1}
      gap={{ base: 1, md: 2 }}
      borderRadius="2xl"
      bgGradient={bgGradient || 'linear(to-r, brand.400, brand.600)'}
      color="white">
      <Text fontSize={{ base: 'xs', md: 'md' }} opacity={0.9}>
        {t(`event_finance.${label}`)}
      </Text>
      <Text
        wordBreak={'break-all'}
        fontSize={{ base: 'sm', md: 'lg' }}
        fontWeight="bold"
        noOfLines={1}
        transition="all 0.3s ease"
        filter={isBlurred ? 'blur(8px)' : 'none'}>
        {value}
      </Text>
    </Flex>
  );
};

const PaymentTrackingCard = ({
  title,
  stats,
  firstSection,
  secondSection,
  isExpense,
  firstSectionTitle,
  secondSectionTitle,
  highlightName,
  eventId,
  onPlusButtonClick,
}: PaymentTrackingCardProps) => {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation(highlightName);
  const { id, blur } = useMyUserPreferences();
  const dispatch = useDispatch<AppDispatch>();

  const isBlurred = useMemo(() => {
    const isExpenses = title === 'event_finance.tracking_expenses';
    const isIncome = title === 'event_finance.tracking_income';
    return (
      (isExpenses && blur?.financeExpenses) || (isIncome && blur?.financeIncome)
    );
  }, [title, blur]);

  const toggleDataVisibility = useCallback(() => {
    const isExpenses = title === 'event_finance.tracking_expenses';
    let updates: Partial<UserPreferences> = { blur: { ...blur } };
    if (isExpenses) {
      updates.blur.financeExpenses = !blur.financeExpenses;
    } else {
      updates.blur.financeIncome = !blur.financeIncome;
    }

    dispatch(updateUserPreferences({ id, updates }));
  }, [blur, dispatch, id, title]);

  return (
    <Card
      ref={ref}
      {...style}
      overflow="hidden"
      p={{ base: 3, md: 6 }}
      display="flex"
      h="100%"
      flexDirection="column"
      flex={1}
      borderRadius={{ base: 'none', md: '20px' }}>
      <Flex direction="column" gap={6} overflow="scroll">
        <HStack justify="space-between">
          <Text fontSize="xl" fontWeight="bold">
            {t(title)}
          </Text>
          <Flex alignItems="center" gap="5px">
            <IconButton
              aria-label={
                isBlurred ? 'Hide financial data' : 'Show financial data'
              }
              icon={!isBlurred ? <FiEye /> : <FiEyeOff />}
              variant="ghost"
              color="black"
              onClick={toggleDataVisibility}
            />
            {onPlusButtonClick && (
              <IconButton
                aria-label="Add"
                variant="actionIconBlueSmall"
                icon={<Icon as={FaPlus} color="warmWhite.100" />}
                display="flex"
                justifySelf="flex-end"
                transition="all 0.4s linear"
                onClick={onPlusButtonClick}
              />
            )}
          </Flex>
        </HStack>
        {/* top stats squares section */}
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'center', md: 'start' }}
          gap={{ base: 1, md: 2 }}
          wrap={{ base: 'wrap', md: 'nowrap' }}
          justify="center">
          {stats.map((stat, index) => (
            <MiniDataStat
              key={index}
              {...stat}
              bgGradient={stat.bgGradient}
              isBlurred={isBlurred}
            />
          ))}
        </Flex>
        {/* top list section */}
        <Flex
          direction="column"
          gap={6}
          flex={1}
          maxH={{ base: 'auto', md: 'fit-content' }}>
          <Flex
            direction="column"
            display={firstSection.length > 0 ? 'flex' : 'none'}>
            <Text fontWeight="bold" fontSize={'sm'} color="brand.50">
              {t(firstSectionTitle)}
            </Text>
            <VStack align="stretch" spacing={2}>
              {firstSection.map((financeItem, index) => (
                <PaymentInfoListItem
                  key={index}
                  {...financeItem}
                  eventId={eventId}
                  isBlurred={isBlurred}
                  isExpense={isExpense}
                />
              ))}
            </VStack>
          </Flex>

          {/* bottom list section */}
          <Flex
            direction="column"
            display={secondSection.length > 0 ? 'flex' : 'none'}>
            <Text fontWeight="bold" fontSize={'sm'} color="brand.50">
              {t(secondSectionTitle)}
            </Text>
            <VStack align="stretch" spacing={2}>
              {secondSection.map((financeItem, index) => (
                <PaymentInfoListItem
                  key={index}
                  {...financeItem}
                  eventId={eventId}
                  isBlurred={isBlurred}
                  isExpense={isExpense}
                />
              ))}
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PaymentTrackingCard;
