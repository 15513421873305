import {
  Avatar,
  AvatarGroup,
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useGetEntity } from 'queries/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'services/@types';

type FileSharedWithUserItemProps = {
  email: string;
  userId?: string;
  handleStopShare: () => void;
};
const FileSharedWithUserItem = ({
  email,
  userId,
  handleStopShare,
}: FileSharedWithUserItemProps) => {
  const { t } = useTranslation();
  const { data: user } = useGetEntity<User>('user', userId);

  const userData = useMemo(() => {
    if (user) {
      return {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        avatar: user.media?.[0]?.url,
      };
    }
    const name = email.split('@')[0];
    return {
      id: '',
      name,
      email,
      avatar: undefined,
    };
  }, [user, email]);

  return (
    <VStack spacing={0} align="stretch" w="full" gap={6}>
      {/* user details */}
      <HStack w="full" spacing={0} align="center" justify="start" gap={3}>
        <AvatarGroup max={1} variant="group">
          {userData.id ? (
            <AuthImage
              h={'36px'}
              borderRadius={'50%'}
              w={'36px'}
              path={userData?.avatar || undefined}
              name={userData?.name}
            />
          ) : (
            <Avatar
              h={'36px'}
              w={'36px'}
              src={userData.avatar}
              name={userData.name}
              bgColor="brand.400"
              color="white"
            />
          )}
        </AvatarGroup>
        <VStack spacing={0} gap={2} align="stretch" w="full" justify="center">
          {userData.id && (
            <Text
              fontSize="14px"
              lineHeight="14px"
              fontWeight={700}
              color="#141718"
              textTransform={'capitalize'}>
              {userData.name}
            </Text>
          )}
          <Text
            fontSize="12px"
            lineHeight="12px"
            fontWeight={userData.id ? 400 : 700}
            color={userData.id ? '#939393' : '#141718'}>
            {userData.email}
          </Text>
        </VStack>
      </HStack>
      {/* stop share button */}
      <Button
        variant="h2outlined"
        w="full"
        height="44px !important"
        fontSize="14px !important"
        fontWeight="500 !important"
        type="button"
        onClick={handleStopShare}>
        {t('shared_calendars.stop_sharing')}
      </Button>
    </VStack>
  );
};

export default FileSharedWithUserItem;
