import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { setStartingPayloadDate } from 'contexts/redux/createMeeting/createMeetingSlice';
import { closeNewCalenderMenuDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';

const AddCalendarEventModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();

  const { newCalenderMenuDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const isOpen = !!newCalenderMenuDialog;
  const selectedDate = newCalenderMenuDialog?.item?.start;

  const handleClose = useCallback(() => {
    dispatch(closeNewCalenderMenuDialog());
  }, [dispatch]);

  const handleNavigate = useCallback(
    (path: string) => {
      navigate(path);
      handleClose();
    },
    [navigate, handleClose],
  );
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent mx="16px" borderRadius="16px" p="24px" maxW={320}>
        <ModalHeader p={0}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="2xl" fontWeight="bold">
              {t('calendar.add_to_calendar')}
            </Text>
            <IconButton
              variant="ghost"
              size="sm"
              opacity={0.6}
              icon={<FiX fontSize={'24px'} />}
              onClick={handleClose}
              aria-label="Close"
            />
          </Flex>
        </ModalHeader>
        <ModalBody p={0} mt={4}>
          <Text fontSize="md" color="gray.600">
            {selectedDate &&
              moment(selectedDate)
                .locale(i18n.language)
                .format('MMMM D, YYYY')}
          </Text>
          <VStack spacing={4} mt={6}>
            <Button
              w="100%"
              variant="default"
              onClick={() => {
                dispatch(setStartingPayloadDate(selectedDate));
                handleNavigate('/main/meetings/create');
              }}>
              {t('calendar.meeting')}
            </Button>
            <Button
              w="100%"
              variant="h1cta"
              onClick={() => {
                dispatch(setStartingPayloadDate(selectedDate));
                handleNavigate('/main/events/create');
              }}>
              {t('calendar.event')}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCalendarEventModal;
