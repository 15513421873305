import { HStack, Text, VStack } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useMemo } from 'react';
import { IClient, IClientContact } from 'services/@types';

const ClientSearchItem = ({
  client,
  contact,
}: {
  client: IClient;
  contact?: IClientContact;
}) => {
  const name = useMemo(() => {
    if (contact) {
      const foundContact = client?.contacts?.find(
        (contact) => contact.id === contact?.id,
      );
      return `${foundContact?.firstName} ${foundContact?.lastName}`;
    }
    return `${client?.firstName} ${client?.lastName}`;
  }, [client, contact]);
  return (
    <>
      <AuthImage
        w="60px"
        h="60px"
        borderRadius="50%"
        path={undefined}
        isAvatar
        name={client?.company || `${client?.firstName} ${client?.lastName}`}
        bg="gray.200"
        mr="26px"
      />
      <VStack spacing={0} alignItems="flex-start">
        <HStack
          spacing={0}
          fontSize="16px"
          fontWeight={700}
          color="black"
          gap={1}
          letterSpacing={'-2%'}>
          <Text variant="assingSuggestions" fontWeight={700}>
            {name}
          </Text>
          <Text color="#AA915D">{client?.note || ''}</Text>
        </HStack>
      </VStack>
    </>
  );
};

export default ClientSearchItem;
