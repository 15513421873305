import { Flex } from '@chakra-ui/react';
import FinanceCard from '../financeOverview/components/FinanceCard';
import useIncomes from './hooks/useIncomes';

export default function Incomes() {
  const { statsIncome, incomes, incomesFilter, setIncomesFilter } =
    useIncomes();
  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}>
      <FinanceCard
        title={'finance_overview.income_tracking'}
        stats={statsIncome}
        payments={incomes}
        filter={incomesFilter}
        setFilter={setIncomesFilter}
        isSearchEnabled
      />
    </Flex>
  );
}
