import { Divider, Flex, Text } from '@chakra-ui/react';
import { IPayment } from 'services/@types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useMemo } from 'react';

export default function PaymentsTableItemMobile({
  payment,
  eventName,
}: {
  payment: IPayment;
  eventName: string;
}) {
  const { t } = useTranslation();

  const paymentDetails = useMemo(
    () => [
      {
        label: t('finance_overview.method'),
        value: '-',
      },
      {
        label: t('finance_overview.total'),
        value: `₪${payment?.amount || 0}`,
      },
      {
        label: t('finance_overview.paid'),
        value: `₪${payment?.paidAmount || 0}`,
      },
    ],
    [payment?.amount, payment?.paidAmount],
  );

  return (
    <Flex
      border="1px solid #00000033"
      p="10px"
      borderRadius="16px"
      flexDirection="column"
      gap="8px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="eventGridCardTitle">
          {payment?.dueDate && format(payment.dueDate, 'dd.MM.yy')}
        </Text>
        <Flex
          justify="center"
          align="center"
          borderRadius="10px"
          bg={
            payment?.paidAmount === payment?.amount ? '#5FBF961A' : '#EE5D501A'
          }
          px="30px"
          py="4px">
          <Text
            variant=""
            color={
              payment?.paidAmount === payment?.amount ? '#01B574' : '#E31A1A'
            }>
            {t(
              `finance_overview.${
                payment?.paidAmount === payment?.amount ? 'paid' : 'due'
              }`,
            )}
          </Text>
        </Flex>
      </Flex>
      <Text variant="previewQuotePaymentTermsNote">{eventName}</Text>
      <Divider bg="#0000001A" />
      <Flex alignItems="center" justifyContent="space-evenly">
        {paymentDetails.map((detail, key) => (
          <Flex key={key} flexDirection="column" alignItems="center">
            <Text variant="financeOverviewPaymentDetailsLabel">
              {detail.label}
            </Text>
            <Text variant="financeOverviewPaymentDetailsValue">
              {detail.value}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Divider bg="#0000001A" />
      <Text variant="financeOverviewDescription">
        {t('finance_overview.description') + ':'}
      </Text>
    </Flex>
  );
}
