import { Button, Divider, Flex, Image, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import DropDownField from 'components/fields/DropdownField';
import NoIncome from 'assets/svg/reports/NoIncome.svg';
import useReports from '../hooks/useReports';
export default function ReportsCard() {
  const { t } = useTranslation();
  const { onSelected, options, selectedOptions, showedOptions, data } =
    useReports();
  return (
    <Card
      p="33.5px 16px"
      gap="24px"
      flex={{ base: '1', md: '1 1 25%' }}
      borderRadius={{ base: '0', md: '30px' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="previewQuoteContactTitle">
          {t('finance_overview.reports')}
        </Text>
        <DropDownField
          options={options}
          showedOptions={showedOptions}
          selectedOptions={selectedOptions}
          onSelected={onSelected}
          placeholder={t('finance_overview.this_month')}
          closeOnSelect
        />
      </Flex>
      <Flex
        flexDirection="column"
        border="1px solid #0000001A"
        borderRadius="30px"
        p="16px 12px">
        <Text variant="financeOverviewReportsCount">
          ₪{data?.totalPaidAmount.toFixed(2)}
        </Text>
        <Text variant="financeOverviewDescription">
          {t('finance_overview.overall_income')}
        </Text>
        <Flex justifyContent="center">
          <Image src={NoIncome} alt="reports" maxWidth="180px" />
        </Flex>
      </Flex>
      <Text variant="financeOverviewReportsSubtitle">
        {t('finance_overview.events_stats')}
      </Text>
      <Flex
        border="1px solid #0000001A"
        alignItems="center"
        justifyContent="space-around"
        borderRadius="15px"
        p="8px 12px">
        <Flex
          flexDirection="column"
          gap="8px"
          alignItems="center"
          textAlign="center">
          <Text variant="financeOverviewDescription">
            {t('finance_overview.number_of_events')}
          </Text>
          <Text variant="financeOverviewReportsSubtitle">
            {data?.numberOfEvents}
          </Text>
        </Flex>
        <Divider w="1px" h="80%" bg="#DADADA" />
        <Flex
          flexDirection="column"
          gap="8px"
          alignItems="center"
          textAlign="center">
          <Text variant="financeOverviewDescription">
            {t('finance_overview.avg_per_month')}
          </Text>
          <Text variant="financeOverviewReportsSubtitle">0</Text>
        </Flex>
      </Flex>
      <Flex
        alignSelf="center"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        p="10px"
        w="160px"
        h="160px"
        borderRadius="100%"
        textAlign="center"
        border="10px solid #0000001A">
        <Text variant="financeOverviewDescription">
          {t('finance_overview.this_month_comparing_avg')}
        </Text>
        <Text variant="financeOverviewReportsSubtitle">0</Text>
      </Flex>
      <Button variant="h3outlinedBrand" w="127px" alignSelf="center">
        {t('finance_overview.show_all')}
      </Button>
    </Card>
  );
}
