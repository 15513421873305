import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const StatusBar = (props: { status: string }) => {
  const { status } = props;
  const { t } = useTranslation();
  return (
    <Flex bg={`contracts.${status}.bg`} p="4px 8px" borderRadius="4px">
      <Text color={`contracts.${status}.color`} variant="clientDetailInfo">
        {t(`contracts.${status}`)}
      </Text>
    </Flex>
  );
};

export default StatusBar;
