import i18n from 'config/i18n/config';
import { AppDispatch } from 'contexts/redux/store';
import {
  updateUserPreferences,
  useMyUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function useChangeLanguage() {
  const dispatch = useDispatch<AppDispatch>();
  const myUserPreferences = useMyUserPreferences();
  const [selectedLanguage, setSelectedLanguage] = useState<'he' | 'en'>(
    localStorage.getItem('language') as 'he' | 'en',
  );

  const handleChangeLanguage = useCallback(() => {
    setSelectedLanguage((prevValue) => {
      const newLanguage = prevValue === 'he' ? 'en' : 'he';
      i18n.changeLanguage(newLanguage);
      localStorage.setItem('language', newLanguage);
      
      if (myUserPreferences?.id) {
        dispatch(
          updateUserPreferences({
            id: myUserPreferences.id,
            updates: { language: newLanguage },
          }),
        );
      }
      return newLanguage;
    });
  }, [dispatch, myUserPreferences?.id]);

  useEffect(() => {
    if (myUserPreferences?.language) {
      if (myUserPreferences?.language !== selectedLanguage) {
        setSelectedLanguage(myUserPreferences?.language);
        i18n.changeLanguage(myUserPreferences?.language);
        localStorage.setItem('language', myUserPreferences?.language);
      }
    }
  }, [myUserPreferences?.language, selectedLanguage, handleChangeLanguage]);

  return { handleChangeLanguage, selectedLanguage };
}
