import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import DropdownField from 'components/fields/DropdownField';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContractService from 'services/contract.api';

export default function CreateContractPreviewDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  handleNavigateToCreateContract: (templateId?: string) => void;
}) {
  const { isOpen, onClose, handleNavigateToCreateContract } = props;
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);

  const { data: templates } = useQuery({
    queryKey: ['contracts', 'templates'],
    queryFn: () =>
      ContractService.getContracts({
        limit: 100,
        isTemplate: true,
      }),
  });

  const templateOptions = useMemo(() => {
    return (
      templates?.results.map((template) => ({
        label: template?.templateTitle,
        value: template?.id,
      })) || []
    );
  }, [templates]);

  const showedOptions = useMemo(() => {
    return (
      templateOptions.find((template) => template.value === templateId)
        ?.label || ''
    );
  }, [templateOptions, templateId]);

  const selectedOptions = useMemo(() => {
    const selectedTemplate = templateOptions.find(
      (template) => template.value === templateId,
    );
    return selectedTemplate ? [selectedTemplate] : [];
  }, [templateOptions, templateId]);

  const handleSelectTemplate = useCallback((e: any) => {
    setTemplateId((prev) => (prev === e.value ? undefined : e.value));
  }, []);

  const handleSave = useCallback(() => {
    handleNavigateToCreateContract(templateId);
    onClose();
  }, [handleNavigateToCreateContract, onClose, templateId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="24px"
        minW={{ base: '100%', md: '660px' }}
        borderRadius="30px"
        gap="24px">
        <Flex flexDirection="column" gap="12px">
          <Text variant="createQuoteTitle">
            {t('contracts.contract_preview')}
          </Text>
          <Text variant="previewQuoteProductHeader" textTransform="initial">
            {t('contracts.contract_preview_description')}
          </Text>{' '}
        </Flex>
        <Flex
          borderRadius="24px"
          border="1px solid #7BA395"
          w="612px"
          h="366px">
          {/* TODO: add contract preview */}
        </Flex>

        <Button
          w="320px"
          variant="h1cta"
          alignSelf="center"
          onClick={handleSave}>
          {t('contracts.start')}
        </Button>
        <DropdownField
          closeOnSelect
          options={templateOptions}
          placeholder={t('contracts.pick_from_templates')}
          w="320px"
          alignSelf="center"
          selectedOptions={selectedOptions}
          showedOptions={showedOptions}
          onSelected={handleSelectTemplate}
        />
      </ModalContent>
    </Modal>
  );
}
