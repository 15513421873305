import { AxiosInstance, AxiosResponse } from 'axios';
import { createAxiosInstance } from './axios.util';

class WeatherService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async getWeather(lat: number, lon: number): Promise<any> {
    const response: AxiosResponse<any> = await this.api.get(
      `/weather?lat=${lat}&lon=${lon}`,
    );
    return response.data;
  }
}

const _weatherService = new WeatherService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _weatherService;
