import axios from 'axios';
import { useEffect, useState } from 'react';

const GOOGLE_SCOPES =
  'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.calendarlist.readonly https://www.googleapis.com/auth/calendar.events.freebusy https://www.googleapis.com/auth/calendar.events.public.readonly https://www.googleapis.com/auth/calendar.settings.readonly https://www.googleapis.com/auth/calendar.freebusy https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.acls https://www.googleapis.com/auth/calendar.acls.readonly https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.calendarlist https://www.googleapis.com/auth/calendar.calendars https://www.googleapis.com/auth/calendar.calendars.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/calendar.events.owned.readonly https://www.googleapis.com/auth/calendar.events.readonly';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CALENDAR_CLIENT_ID;
const SECRET_ID = process.env.REACT_APP_GOOGLE_OAUTH_CALENDAR_CLIENT_SECRET;
const REDIRECT_URI = process.env.REACT_APP_GOOGLE_OAUTH_CALENDAR_REDIRECT_URI;
const TOKEN_REDIRECT_URI =
  process.env.REACT_APP_GOOGLE_OAUTH_CALENDAR_GET_TOKEN_REDIRECT_URI;

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
}

function redirectToAuthUrl() {
  const authUrl =
    `https://accounts.google.com/o/oauth2/v2/auth?` +
    `client_id=${CLIENT_ID}` +
    `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}` +
    `&response_type=code` +
    `&scope=${encodeURIComponent(GOOGLE_SCOPES)}` +
    `&prompt=consent` +
    `&access_type=offline`;
  window.open(authUrl, '_self');
}

function redirectToApi(
  {
    access_token,
    refresh_token,
  }: { access_token: string; refresh_token: string },
  userId: string,
) {
  window.location.href = `${TOKEN_REDIRECT_URI}?access_token=${access_token}&userId=${userId}&refresh_token=${refresh_token}`;
}

export const useGoogleCalendarAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    isLoading: true,
    error: null,
  });

  const initiateAuth = () => {
    redirectToAuthUrl();
  };

  useEffect(() => {
    const fetchToken = async () => {
      const urlParams = new URLSearchParams(window.location?.search);
      const code = urlParams.get('code');
      if (!code) {
        setAuthState({
          isAuthenticated: false,
          isLoading: false,
          error: 'No code found in URL',
        });
        return;
      }
      const formBody = new URLSearchParams({
        code,
        client_id: CLIENT_ID,
        client_secret: SECRET_ID,
        redirect_uri: REDIRECT_URI,
        grant_type: 'authorization_code',
        access_type: 'offline',
        prompt: 'consent',
      }).toString();
      try {
        const response = await axios.post(
          'https://oauth2.googleapis.com/token',
          formBody,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        );
        if (!response?.data?.access_token) {
          setAuthState({
            isAuthenticated: false,
            isLoading: false,
            error: 'No token found in response',
          });
          return;
        }

        const userId = localStorage.getItem('token')
          ? JSON.parse(localStorage.getItem('token') as string)?.user?.id
          : null;

        if (!userId) {
          console.error('No user id found');
          return;
        }
        redirectToApi(response?.data, userId);
      } catch (error) {
        console.error('Error fetching token: ', error);
      }
      setAuthState({
        isAuthenticated: true,
        isLoading: false,
        error: null,
      });
    };
    fetchToken();
  }, []);

  return {
    ...authState,
    initiateAuth,
  };
};
