export const formatNumber = (num: number): number => {
  if (!num) return 0;
  return parseFloat(Number(num).toFixed(2));
};
export const formatCurrency = (
  num: number | string,
  currency = '₪',
): string => {
  if (!num) return `${currency} 0`;
  console.log('formatCurrency', num);
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);

  return `${currency}${formattedNumber}`;
};

export const formatToLocaleString = (value: number) => {
  return formatNumber(value)?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
