import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  AppDialogProps,
  closeSelectSupplierDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { fetchSuppliers } from 'contexts/redux/supplier/supplierSlice';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ChosenSupplier from 'views/main/managment/events/createUpdateEvent/ChosenSupplier';

const trimAndLowerCase = (str: string) => str.toLowerCase().trim();

function SelectSupplierDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);
  const { selectSupplierDialog, suppliers } = useSelector(
    (state: RootState) => ({
      selectSupplierDialog: state.dialogs.selectSupplierDialog,
      suppliers: state.suppliers.suppliers,
    }),
  );
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 700);

  const filteredSuppliers = useMemo(() => {
    if (!debouncedSearch) return suppliers;
    return suppliers.filter((s) =>
      trimAndLowerCase(
        `${s.name}${s.email}${s.address || ''}${s.businessID || ''}`,
      ).includes(debouncedSearch.toLowerCase().trim()),
    );
  }, [suppliers, debouncedSearch]);

  const dialog = selectSupplierDialog as AppDialogProps;

  const handleClose = useCallback(() => {
    dispatch(closeSelectSupplierDialog());
    setSelectedSuppliers([]);
  }, [dispatch]);

  const handleSubmitSuppliersSelection = useCallback(() => {
    if (dialog?.onConfirm) {
      dialog.onConfirm(
        suppliers.filter((s) => selectedSuppliers.includes(s.id)),
      );
    }
    handleClose();
  }, [handleClose, dialog, selectedSuppliers, suppliers]);

  const handleSelectSupplier = useCallback(
    (supplierId: string) => {
      if (selectedSuppliers.includes(supplierId)) {
        setSelectedSuppliers((prev) => prev.filter((id) => id !== supplierId));
      } else {
        setSelectedSuppliers((prev) => [...prev, supplierId]);
      }
    },
    [selectedSuppliers],
  );

  useEffect(() => {
    const getSuppliers = async () => {
      if (!!dialog) {
        await dispatch(fetchSuppliers({}));
      }
    };
    getSuppliers();
  }, [dispatch, dialog]);

  useEffect(() => {
    if (dialog?.item?.chosenSuppliers) {
      setSelectedSuppliers(dialog.item.chosenSuppliers.map((s: any) => s.id));
    }
  }, [suppliers, dialog?.item]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size={isMobile ? 'full' : 'sm'}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0px', md: '30px' }}
        h={{ base: '100%', md: '70dvh' }}>
        <ModalBody
          p="24px 16px"
          w="full"
          h="100%"
          display="flex"
          flexDir="column">
          <VStack w="100%" gap={4} spacing={0} flex={1} overflow="hidden">
            <HStack
              w="100%"
              justifyContent="space-between"
              spacing={0}
              flexShrink={0}>
              <Text
                variant="cardSectionTitle"
                fontSize={'base'}
                fontWeight={700}
                color="#141718">
                {t('dialogs.select_supplier.title')}
              </Text>
              <ModalCloseButton
                position="relative"
                top={0}
                right={0}
                left={0}
                justifyContent={'center'}
                alignItems="center"
                display={'flex'}
                boxShadow="none !important"
              />
            </HStack>
            <SearchBar
              value={search}
              placeholder={t('dialogs.select_supplier.search')}
              margin={'0 !important'}
              flexShrink={0}
              inputProps={{
                onChange: (e: any) => setSearch(e.target.value),
              }}
            />
            <VStack
              w="100%"
              gap={2}
              display="flex"
              flexDirection="column"
              pb="4"
              flex={1}
              overflow="auto">
              {filteredSuppliers.length === 0 && (
                <Text variant="errorTitle">
                  {t('dialogs.select_supplier.no_suppliers')}
                </Text>
              )}
              {filteredSuppliers.map((supplier) => (
                <ChosenSupplier
                  key={supplier.id}
                  supplier={supplier}
                  wrapperProps={{ w: 'full' }}
                  onRemove={
                    selectedSuppliers.includes(supplier.id)
                      ? () => handleSelectSupplier(supplier.id)
                      : undefined
                  }
                  onClick={() => handleSelectSupplier(supplier.id)}
                />
              ))}
            </VStack>
          </VStack>
          <Spacer flexShrink={0} flexGrow={0} />
          <Button
            flexShrink={0}
            variant="h1cta"
            w="full"
            onClick={handleSubmitSuppliersSelection}>
            {t('dialogs.select_supplier.add')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SelectSupplierDialog;
