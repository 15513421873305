import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import ChooseEventBtn from 'components/buttons/ChooseEventBtn';
import EventCard from 'components/eventsPickerDialog/eventCard';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';
import useQuote from '../hooks/useQuote';

function AskForQuoteModal() {
  const { t, i18n } = useTranslation();
  const {
    handleClose,
    handleOpenEventDialog,
    isDataAndTimeActive,
    isOpenned,
    updateIsDataAndTimeActive,
    quote,
    sendQuote,
    loading,
    handleChange,
    isSendDisabled,
    handleDateAndTime,
  } = useQuote();
  return (
    <Modal isOpen={isOpenned} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px">
        <ModalHeader>{t('dialogs.ask_for_quote.title')}</ModalHeader>
        <ModalCloseButton dir={i18n.dir()} _rtl={{ left: 2, right: 'auto' }} />
        <ModalBody>
          <VStack gap={2}>
            <TextField
              name="description"
              w="100%"
              maxLength={80}
              minH="200px"
              placeholder={t('dialogs.ask_for_quote.free_text')}
              label={t('dialogs.ask_for_quote.free_text')}
              resize="none"
              onChange={(e: any) => handleChange('freeText', e.target.value)}
              autoFocus
            />
            <VStack width="100%" alignItems="left">
              <HStack
                onClick={updateIsDataAndTimeActive}
                cursor="pointer"
                w="fit-content">
                <Icon
                  as={FiCheck}
                  bg={!isDataAndTimeActive ? 'brand.900' : 'white'}
                  border="3px solid"
                  borderColor="brand.900"
                  w="20px"
                  h="20px"
                  transition="all 0.3s ease"
                  color="white"
                  borderRadius="10px"
                />
                <Text variant="eventSubTitle">
                  {t('dialogs.ask_for_quote.pick_existing_event')}
                </Text>
              </HStack>

              {quote?.event && (
                <EventCard
                  event={quote?.event}
                  onClick={handleOpenEventDialog}
                  isQuoteEvent
                />
              )}
              {!quote?.event && (
                <ChooseEventBtn
                  h="52px"
                  minW="100%"
                  onClick={handleOpenEventDialog}
                  disabled={isDataAndTimeActive}
                />
              )}
            </VStack>
            <VStack width="100%" alignItems="left">
              <HStack
                onClick={updateIsDataAndTimeActive}
                cursor="pointer"
                w="fit-content">
                <Icon
                  as={FiCheck}
                  bg={isDataAndTimeActive ? 'brand.900' : 'white'}
                  border="3px solid"
                  borderColor="brand.900"
                  w="20px"
                  h="20px"
                  transition="all 0.3s ease"
                  color="white"
                  borderRadius="10px"
                />
                <Text variant="eventSubTitle">
                  {t('dialogs.ask_for_quote.date_and_time')}
                </Text>
              </HStack>
              <HStack>
                <InputField
                  label={t('create_event.label.event_date')}
                  placeholder={t('create_event.placeholder.event_date')}
                  defaultValue={null}
                  type="date"
                  flex="1"
                  name="date"
                  isDisabled={!isDataAndTimeActive}
                  value={quote.date}
                  onChange={(e: any) =>
                    handleDateAndTime(e.target.value, 'date')
                  }
                  _disabled={{ opacity: '0.4' }}
                  labelProps={{ opacity: !isDataAndTimeActive ? '0.4' : '1' }}
                />
                <InputField
                  label={t('create_event.label.time')}
                  placeholder={t('create_event.placeholder.time')}
                  onChange={(e: any) =>
                    handleDateAndTime(e.target.value, 'time')
                  }
                  type="time"
                  defaultValue={null}
                  flex="1"
                  value={quote.time}
                  name="time"
                  isDisabled={!isDataAndTimeActive}
                  _disabled={{ opacity: '0.4' }}
                  labelProps={{ opacity: !isDataAndTimeActive ? '0.4' : '1' }}
                />
              </HStack>
            </VStack>
            <Button
              isLoading={loading}
              variant="h1cta"
              isDisabled={isSendDisabled}
              onClick={sendQuote}
              w="100%">
              {t('dialogs.ask_for_quote.send')}
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AskForQuoteModal;
