import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { he, enGB } from 'date-fns/locale';
import { useMemo } from 'react';
import { IMeeting } from 'services/@types';
import MeetingCard from 'views/main/dashboard/components/MeetingCard';
import { IoIosArrowDown } from 'react-icons/io';
import NoEventsImg from 'assets/img/eventsOverview/noEventsCreated.png';
export default function UpcomingMeetings({
  meetings,
}: {
  meetings: IMeeting[];
}) {
  const { t, i18n } = useTranslation();
  const day = useMemo(
    () =>
      format(new Date(), 'EEEE', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      }),
    [i18n.language],
  );
  const date = useMemo(
    () =>
      format(new Date(), 'dd MMMM', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      }),
    [i18n.language],
  );
  return (
    <Flex flexDirection="column" gap={meetings?.length === 0 ? '34px' : '9px'}>
      <Flex alignItems="center" gap="4px">
        <Text display="inline" noOfLines={1}>
          <Text variant="financeOverviewReportsSubtitle" display="inline">
            {day}
          </Text>
          ,
          <Text display="inline" variant="scheduleAnchorMeetingTodaySchedule">
            {date}
          </Text>
        </Text>
        <Icon as={IoIosArrowDown} width="16px" height="16px" />
      </Flex>
      {meetings.length > 0 ? (
        <>
          <Text variant="clientName">
            {t('schedule_anchor_meeting.today_schedule')}
          </Text>
          <Flex flexDirection="column" gap="6px" w="284px">
            {meetings.map((meeting, key) => (
              <MeetingCard key={key} meeting={meeting} />
            ))}
          </Flex>
        </>
      ) : (
        <Flex
          alignItems="center"
          flexDirection="column"
          h={{ base: '100%', md: 'fit-content' }}
          w="100%"
          minW="300px"
          gap="24px"
          justifyContent="center">
          <Image w="150px" h="150px" src={NoEventsImg} alt="" />
          <Text variant="scheduleAnchorMeetingTodaySchedule">
            {t('schedule_anchor_meeting.no_schedules_yet')}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
