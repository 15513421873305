import { setClientFoundDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IClient } from 'services/@types';
import BusinessService from 'services/business.api';

interface IUserFindClientProps {
  observedFields: (keyof IClient)[];
  observedObject: Partial<IClient>;
  onConfirm?: (client: IClient) => void;
}
export const useFindClient = (props: IUserFindClientProps) => {
  const { observedFields, observedObject, onConfirm } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [existingClient, setExistingClient] = useState<Partial<IClient> | null>(
    null,
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const clientFoundDialog = useSelector(
    (state: RootState) => state.dialogs.clientFoundDialog,
  );

  const dialog = useMemo(() => {
    return !!clientFoundDialog;
  }, [clientFoundDialog]);

  const generateParams = (
    fields: (keyof IClient)[],
    object: Partial<IClient>,
  ) => {
    const params: any = {};
    fields.forEach((field) => {
      if (object[field] !== undefined && object[field] !== '') {
        params[field] = object[field];
      }
    });
    return params;
  };

  const getIsParamsCorrect = (params: any): boolean =>
    Object.keys(params).every(
      (key) => params[key] !== undefined && params[key] !== '',
    ) && Object.keys(params).length > 0;

  const findClient = useCallback(() => {
    if (observedFields.length > 0 && observedObject && !dialog) {
      const params = generateParams(observedFields, observedObject);
      const isParamsCorrect = getIsParamsCorrect(params);

      if (isParamsCorrect) {
        clearTimeout(timeoutRef?.current as NodeJS.Timeout);
        timeoutRef.current = setTimeout(async () => {
          try {
            const res = await BusinessService.getBusinesses(params);
            if (res.results.length > 0) {
              dispatch(
                setClientFoundDialog({
                  item: res.results[0],
                  title: t('clients.found_client_title'),
                  onConfirm: () => {
                    const business = res.results[0];
                    const pluggedClient: Partial<IClient> = {
                      businessID: business.id,
                      company: business.businessName,
                      type: 'business',
                      address: business.address,
                      phone: business.phone,
                      email: business.email,
                      media: business.media,
                      idNumber: business.businessVATId,
                    };
                    setExistingClient(pluggedClient);
                    if (onConfirm) {
                      onConfirm(pluggedClient as IClient);
                    }
                  },
                  onClose: () => {
                    setExistingClient(null);
                  },
                }),
              );
            }
          } catch (error) {
            console.log(error);
          }
        }, 400); // delay of 400ms
      }
    }
  }, [observedFields, observedObject, dialog, dispatch, t, onConfirm]);

  return { existingClient, findClient };
};
