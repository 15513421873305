import { Flex, Hide, Text, useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IClient, IClientContact, IPayment } from 'services/@types';
import EventService from 'services/event.api';
import QuoteService from 'services/quote.api';
import PaymentsTableItemMenu from './PaymentsTableItemMenu';
import PaymentsTableItemMobile from './PaymentsTableItemMobile';
import { useSelector } from 'react-redux';
import { RootState } from 'contexts/redux/store';

interface IProps {
  payment: IPayment & {
    clients: { client: IClient; contacts: IClientContact[] }[];
  };
}

export default function PaymentsTableItem(props: IProps) {
  const suppliers = useSelector((state: RootState) => state.suppliers.suppliers);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();
  const { payment } = props;

  const { data: quote } = useQuery({
    queryKey: [`getQuote-${payment?.quoteId}`],
    queryFn: () => QuoteService.getQuote(payment?.quoteId),
    enabled: !!payment?.quoteId,
  });

  const { data: event } = useQuery({
    queryKey: [`getEvent-${payment?.eventId}`],
    queryFn: () => EventService.getEvent(payment?.eventId),
    enabled: !!payment?.eventId,
  });

  const supplier = useMemo(() => {
    return suppliers?.find((supplier) => supplier.id === payment?.supplierId);
  }, [suppliers, payment?.supplierId]);

  const clientName = useMemo(() => {
    let name: string[] = [];
    for (const { client } of payment.clients) {
      if (client?.company) {
        name.push(client.company);
      } else {
        name.push(`${client.firstName} ${client.lastName}`);
      }
    }
    if (name.length > 0) {
      return name.join(', ');
    }
    return supplier?.name;
  }, [payment?.clients, supplier]);

  const eventName = useMemo(() => {
    return event?.name || quote?.eventName || '';
  }, [event?.name, quote?.eventName]);

  if (isMobile) {
    return <PaymentsTableItemMobile payment={payment} eventName={eventName} />;
  }

  return (
    <Flex
      py={4}
      px={6}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      justify="space-between"
      fontSize="14px"
      flexDirection="column">
      <Flex
        flex="1"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        gap={2}
        fontWeight="500">
        <Hide below="md">
          <Flex flex={1} fontWeight="700" align="center" noOfLines={1}>
            {payment?.dueDate && format(payment.dueDate, 'dd.MM.yy')}
          </Flex>
        </Hide>
        <Flex flex={1} fontWeight={{ base: '700', md: '500' }} align="center">
          <Text noOfLines={1} wordBreak={'break-word'}>
            {eventName}
          </Text>
        </Flex>
        <Flex flex={1} align="center">
          <Text noOfLines={1}>{payment?.amount}</Text>
        </Flex>
        <Flex flex={1} align="center">
          <Text noOfLines={1}>{payment?.paidAmount}</Text>
        </Flex>
        <Flex flex={1}>
          <Flex
            justify="center"
            align="center"
            borderRadius="10px"
            bg={
              payment?.paidAmount === payment?.amount
                ? '#5FBF961A'
                : '#EE5D501A'
            }
            px="10px"
            py="4px">
            <Text
              variant=""
              color={
                payment?.paidAmount === payment?.amount ? '#01B574' : '#E31A1A'
              }>
              {t(
                `finance_overview.${
                  payment?.paidAmount === payment?.amount ? 'paid' : 'due'
                }`,
              )}
            </Text>
          </Flex>
        </Flex>
        {/* TODO: add payment method */}
        <Flex flex={1} align="center" noOfLines={1}>
          -
        </Flex>
        <Flex flex={1} align="center">
          <Text noOfLines={1} wordBreak={'break-word'}>
            {clientName}
          </Text>
        </Flex>
        <Hide below="md">
          <Flex flex="0 0 24px" justify="center" align="center">
            <PaymentsTableItemMenu
              paymentId={payment?.id}
              quoteId={payment?.quoteId}
              eventId={payment?.eventId}
            />
          </Flex>
        </Hide>
      </Flex>
      <Hide above="md">
        <Flex
          flex="0 1 100px"
          justify={{ base: 'space-between', md: 'flex-start' }}
          flexDirection="column">
          <PaymentsTableItemMenu
            paymentId={payment?.id}
            quoteId={payment?.quoteId}
            eventId={payment?.eventId}
          />
          <Flex>
            {payment?.dueDate && format(payment.dueDate, 'dd MMM, yyyy')}
          </Flex>
        </Flex>
      </Hide>
      {/* TODO: add description */}
      {/* <Text variant="financeOverviewDescription">
        {t('finance_overview.description') + ':'}
      </Text> */}
    </Flex>
  );
}
