import { Button, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { IContract, IContractParagraph } from 'services/@types';
import ParagraphItem from './ParagraphItem';

type CreateContractParagraphsProps = {
  contract: IContract;
  handleChange: (key: string, value: any) => void;
};

const CreateContractParagraphs = (props: CreateContractParagraphsProps) => {
  const { t } = useTranslation();
  const { contract, handleChange } = props;

  const handleAddNewParagraph = useCallback(() => {
    handleChange('paragraphs', [
      ...(contract?.paragraphs ?? []),
      {
        content: '',
        attachments: [],
      },
    ]);
  }, [contract?.paragraphs, handleChange]);

  useEffect(() => {
    if (contract?.paragraphs.length === 0) {
      handleAddNewParagraph();
    }
  }, [contract?.paragraphs, handleAddNewParagraph]);

  return (
    <VStack spacing={0} alignItems="flex-start" gap={4}>
      <Text
        fontSize={'base'}
        lineHeight={'28px'}
        fontWeight={700}
        color="black">
        {t('create_contract.paragraphs_title')}
      </Text>
      {contract?.paragraphs.map((paragraph, index) => (
        <ParagraphItem
          key={index}
          attachments={paragraph.attachments}
          content={paragraph.content}
          readOnly={index === 0}
          deleteParagraph={
            contract?.paragraphs.length > 1
              ? () => {
                  handleChange(
                    'paragraphs',
                    contract.paragraphs.filter((_, i) => i !== index),
                  );
                }
              : undefined
          }
          handleContentChange={(content: IContractParagraph['content']) => {
            const thisParagraph = {
              ...paragraph,
              content,
            };
            handleChange(
              'paragraphs',
              contract.paragraphs.map((p, i) =>
                i === index ? thisParagraph : p,
              ),
            );
          }}
        />
      ))}
      <Button onClick={handleAddNewParagraph} variant="main.400" gap={2} p={0}>
        <Flex
          bg="brand.400"
          color="white"
          justifyContent="center"
          alignItems="center"
          w="32px"
          h="32px"
          p={0}
          borderRadius="10px">
          <Icon as={FiPlus} w="16px" h="16px" />
        </Flex>
        <Text
          fontSize="14px"
          lineHeight="14px"
          fontWeight={400}
          letterSpacing={'-2%'}
          color="#000000CC">
          {t('create_contract.paragraphs_add_another')}
        </Text>
      </Button>
    </VStack>
  );
};

export default CreateContractParagraphs;
