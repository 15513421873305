import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import NoQuotesCreatedImg from 'assets/img/quote/NoQuotesCreated.png';
import { useAppNavigate } from 'hooks/useAppNavigate';

export default function NoContractsCreated() {
  const { t } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h="100%"
      flexDirection="column"
      gap="36px">
      <Image w="320px" src={NoQuotesCreatedImg} alt="" />
      <Box gap="16px" textAlign="center">
        <Text variant="noEventsTitle">
          {t('contracts.no_contracts_created')}
        </Text>
        <Text variant="noEventsSubTitle">
          {t('contracts.no_contracts_created_description')}
        </Text>
      </Box>
      <Button
        w="355px"
        variant="h1cta"
        onClick={() => navigate('/main/finance/contracts/create')}>
        {t('contracts.create_contract')}
      </Button>
    </Flex>
  );
}
