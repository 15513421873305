// Chakra imports
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import { useCallback, useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';

function TagsField(props: {
  label?: string;
  id?: string;

  placeholderTags?: string[];
  onTagsChange?: (tags: string[]) => void;
  handleTagsChange?: (tags: string[]) => void;
  itemFontSize?: any;
  [x: string]: any;
}) {
  const {
    label,
    id,
    disabled,
    placeholderTags,
    onTagRemovedClicked,
    onTagsChange,
    handleTagsChange,
    itemFontSize,
    ...rest
  } = props;
  const [tags, setTags] = useState(placeholderTags || []);
  const textColorPrimary = useColorModeValue('primary.100', 'white');

  const keyPress = useCallback(
    (e: any) => {
      if (
        e.keyCode === 13 &&
        e.target.value?.trim() !== '' &&
        e.target.value?.trim()?.length > 0
      ) {
        setTags([...tags.filter((t) => t !== e.target.value), e.target.value]);
        e.target.value = '';
        onTagsChange &&
          onTagsChange(
            [
              ...tags.filter((t) => t !== e.target.value),
              e.target.value,
            ].filter((t) => t !== ''),
          );
      }
    },
    [onTagsChange, tags],
  );

  let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const handleTagRemoveClicked = useCallback(
    (tag) => {
      setTags(tags.filter((t) => t !== tag));
      onTagsChange &&
        onTagsChange(tags.filter((t) => t !== tag).filter((t) => t !== ''));
    },
    [onTagsChange, tags],
  );
  useEffect(() => {
    handleTagsChange && handleTagsChange(tags);
  }, [tags]);
  return (
    <Flex flexDirection={'column'} {...rest}>
      <FormLabel
        htmlFor={id}
        color={textColorPrimary}
        fontWeight="500"
        fontSize="sm"
        mb="8px">
        {label}
      </FormLabel>
      <Flex
        direction="row"
        p="12px"
        wrap="wrap"
        bg="transparent"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="16px"
        _focus={{ borderColor: 'teal.300' }}
        minH="40px"
        h="stretch"
        gap="6px"
        cursor="text"
        {...rest}>
        {tags.map((tag, index) => {
          return (
            <Button
              variant="outlineTag"
              key={index}
              iconSpacing={1}
              fontSize={itemFontSize || 'xs'}
              rightIcon={
                <MdClear onClick={() => handleTagRemoveClicked(tag)} />
              }>
              <Text
                textAlign={'start'}
                whiteSpace="normal"
                maxW={220}
                wordBreak="break-word">
                {tag}
              </Text>
              {/* <TagCloseButton
                justifySelf="flex-end"
                color="white"
                onClick={() => handleTagRemoveClicked(tag)}
              /> */}
            </Button>
          );
        })}
        <Input
          variant="main"
          bg={disabled ? 'gray.100' : 'transparent'}
          border="none"
          p="0px"
          disabled={disabled}
          onKeyDown={(e) => keyPress(e)}
        />
      </Flex>
    </Flex>
  );
}

export default TagsField;
