import {
  Badge,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { BusinessEventPlan, IEvent } from 'services/@types';
import _businessEventPlanService from 'services/businessEventPlan.api';
interface MobileEventCardProps {
  event: Partial<IEvent>;
  onClick?: (event: IEvent) => void;
  isQuoteEvent?: boolean;
  cursor?: string;
  [key: string]: any;
}
export default function MobileEventCard(props: MobileEventCardProps) {
  const { event, onClick, isQuoteEvent = false, cursor, ...rest } = props;
  const { t, i18n } = useTranslation();
  const month = useMemo(
    () =>
      format(new Date(event.dateAndTime), 'MMM', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      }),
    [event.dateAndTime, i18n.language],
  );
  const date = useMemo(
    () => format(new Date(event.dateAndTime), 'dd'),
    [event.dateAndTime],
  );
  const businessId = useMyBusiness()?.id;

  const { data: businessEventPlan } = useQuery<BusinessEventPlan | null>({
    queryKey: [`getEventParticipants-${event.id}-${businessId}`],
    queryFn: async () => {
      const result = await _businessEventPlanService.getBusinessEventPlans({
        eventId: event.id,
        populate: 'businessId',
      });

      const data = result.results[0];
      return data;
    },
    enabled: !!event.id && !!businessId,
  });

  return (
    <Flex pos="relative" w="full" h="24px" {...rest}>
      <VStack
        spacing={0}
        width="full"
        border="1px solid"
        borderColor={
          !isQuoteEvent ? `eventStatus.${businessEventPlan?.status}` : '#5FBF96'
        }
        borderRadius="8px"
        transition="all 0.3s ease"
        cursor={cursor || 'pointer'}
        _hover={{ bg: '#F5F5F5' }}
        //   pb={!isQuoteEvent && '20px'}
        // py={!isQuoteEvent ? 6 : 2}
        onClick={() => onClick && onClick(event as IEvent)}
        justifyContent="space-between"
        h="24px"
        {...rest}>
        <Flex w="full" gap={5} px={2} py="3px" alignItems="center">
          <Text
            variant="eventCardDate"
            fontSize="12px"
            lineHeight="12px"
            whiteSpace="nowrap">
            {month} {date}
          </Text>
          <Divider orientation="vertical" borderColor="#939393" />
          <Text
            flexGrow={1}
            variant="eventCardTitle"
            color="#141718"
            fontSize="12px"
            lineHeight="15px">
            {event.name}
          </Text>
          <Divider orientation="vertical" borderColor="#939393" />
          {event?.numberOfGuests && (
            <HStack spacing={0} align="center" gap={1}>
              <Icon
                as={HiOutlineMailOpen}
                color="general.100"
                strokeWidth={1.5}
              />
              <Text
                color="general.100"
                noOfLines={1}
                pt={0.5}
                fontWeight="400"
                fontSize="12px"
                lineHeight="15px"
                wordBreak={'break-all'}>
                {t('create_event.modals.found_event.invited', {
                  numberOfGuests: event?.numberOfGuests,
                  numberOfChildren: event?.numberOfGuestsChildren,
                })}
              </Text>
            </HStack>
          )}
          <Divider orientation="vertical" borderColor="#939393" />
          {event?.location?.label && (
            <HStack spacing={0} align="center" gap={1}>
              <Icon as={IoLocationOutline} color="general.100" />
              <Text
                color="general.100"
                noOfLines={1}
                fontWeight="400"
                fontSize="12px"
                lineHeight="15px"
                wordBreak={'break-all'}>
                {event?.location?.label}
              </Text>
            </HStack>
          )}
        </Flex>
      </VStack>
      {!isQuoteEvent && (
        <HStack justifyContent="end" pos="absolute" top="0" px={4} width="100%">
          <Badge
            borderBottomLeftRadius="15px"
            borderBottomRightRadius="15px"
            borderTopLeftRadius="0px"
            borderTopRightRadius="0px"
            bg={`eventStatus.${businessEventPlan?.status || 'new'}`}
            paddingLeft="15px"
            paddingRight="15px">
            {t(`event.status.${businessEventPlan?.status || 'new'}`)}
          </Badge>
        </HStack>
      )}
    </Flex>
  );
}
