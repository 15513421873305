import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  AppDialogProps,
  closeQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { getQuotes } from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IQuote } from 'services/@types';
import QuoteCard from './quoteCard';

function QuotePickerDialog() {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { quoteDialog, quotes } = useSelector((state: RootState) => ({
    quoteDialog: state.dialogs.quoteDialog,
    quotes: state.quote.qoutes,
  }));
  const dialog = quoteDialog as AppDialogProps;
  const [searchString, setSearchString] = useState('');
  const [filteredQuotes, setFilteredQuotes] = useState<IQuote[]>([]);
  const debouncedSearch = useDebounce(searchString, 700);

  useEffect(() => {
    if (debouncedSearch) {
      const filtered = quotes.filter((quote) => {
        const clientNames = quote.clients
          .map((c) => `${c.client.firstName} ${c.client.lastName}`)
          .join(' ')
          .toLowerCase();
        const eventName = quote.eventName.toLowerCase();
        const quoteNumber = quote.quoteNumber.toString();
        const search = debouncedSearch.toLowerCase();

        return (
          clientNames.includes(search) ||
          eventName.includes(search) ||
          quoteNumber.includes(search)
        );
      });
      setFilteredQuotes(filtered);
    } else {
      setFilteredQuotes(quotes);
    }
  }, [quotes, debouncedSearch]);

  const handleClose = useCallback(() => {
    dispatch(closeQuoteDialog());
  }, [dispatch]);

  const handleSelectQuote = useCallback(
    (selectedQuote: IQuote) => {
      if (quoteDialog?.onConfirm) {
        quoteDialog.onConfirm(selectedQuote);
      }
      handleClose();
    },
    [handleClose, quoteDialog],
  );

  useEffect(() => {
    const fetchQuotes = async () => {
      if (!!dialog) {
        await dispatch(getQuotes({}));
      }
    };
    fetchQuotes();
  }, [dispatch, dialog]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px">
        <ModalBody p="2xl">
          <VStack w="100%" gap={2}>
            <HStack w="100%" justifyContent="space-between">
              <Text
                variant="cardSectionTitle"
                color="#141718"
                fontWeight={700}
                fontSize="16px">
                {t('dialogs.quotes.title')}
              </Text>
              <ModalCloseButton position="relative" top="0" right="0" />
            </HStack>
            <SearchBar
              w="100%"
              autoFocus
              onChange={(e: any) => setSearchString(e.target.value)}
            />
            <VStack w="100%" h="500px" overflowY="auto" gap={2}>
              {filteredQuotes.length === 0 && (
                <Text variant="errorTitle">
                  {t('dialogs.quotes.no_quotes')}
                </Text>
              )}
              {filteredQuotes.map((quote) => (
                <QuoteCard
                  key={quote.id}
                  quote={quote}
                  onClick={handleSelectQuote}
                />
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default QuotePickerDialog;
