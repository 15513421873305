import { Button, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import TaskCard from '../dashboard/components/TaskCard';
import useGeneralTaskHistory from './hooks/useGeneralTaskHistory';

const NoTask = () => {
  return <Image w="96px" h="96px" src={NoEventsImg} alt="" />;
};

export default function TaskHistoryView() {
  const { t } = useTranslation();

  const { activeTasks, completedTasks, goToTaskCreate } =
    useGeneralTaskHistory();

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap={4}>
      <Card gap={5} h="calc(100vh - 170px)" overflowY="auto">
        <HStack justifyContent="space-between" align="start">
          <VStack align="flex-start">
            <Text variant="eventCardTitle" fontSize="2xl" fontWeight="bold">
              {t('tasks.history.general_tasks')}
            </Text>
          </VStack>
          {/* TODO: implement filtering of the tasks by event id */}
        </HStack>
        <VStack align="flex-start">
          <Text variant="eventCardTitle">{t('tasks.history.active')}</Text>
        </VStack>
        {activeTasks?.map((task) => (
          <TaskCard key={task.id} task={task} />
        ))}
        {activeTasks?.length === 0 && (
          <VStack gap={3}>
            <NoTask />
            <Text variant="noCompletedTasksSubTitle">
              {t('tasks.history.no_active_tasks')}
            </Text>
            <Button onClick={goToTaskCreate} variant="h1cta">
              {t('tasks.create_new_task')}
            </Button>
          </VStack>
        )}

        <VStack align="flex-start">
          <Text variant="eventCardTitle">{t('tasks.history.completed')}</Text>
          {completedTasks?.map((task) => (
            <TaskCard key={task.id} task={task} isCompleted />
          ))}
        </VStack>
        {completedTasks?.length === 0 && (
          <VStack>
            <NoTask />
            <Text variant="noCompletedTasksTitle">
              {t('tasks.history.no_completed_tasks')}
            </Text>
            <Text variant="noCompletedTasksSubTitle">
              {t('tasks.history.no_completed_tasks_description')}
            </Text>
          </VStack>
        )}
      </Card>
    </Flex>
  );
}
