import {
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { ILocation } from 'services/@types';

interface LocationSearchInputProps {
  id?: string;
  label?: string;
  placeholder?: string;
  onSelect?: (location: ILocation) => void;
  value?: string;
  onChange?: (address: string) => void;
  inputProps?: InputProps;
  sxWidth?: string;
}

const LocationSearchInput = ({
  id,
  label,
  placeholder = 'Search Places ...',
  onSelect,
  value: externalValue,
  onChange: externalOnChange,
  inputProps,
  sxWidth,
}: LocationSearchInputProps) => {
  const [address, setAddress] = useState('');

  const textColorPrimary = useColorModeValue('black', 'white');
  const bgInput = useColorModeValue('white', 'brand.300');
  const suggestionBgActive = useColorModeValue('gray.100', 'brand.400');
  const suggestionBg = useColorModeValue('white', 'brand.300');

  const handleChange = useCallback(
    (newAddress: string) => {
      setAddress(newAddress);
      externalOnChange?.(newAddress);
    },
    [externalOnChange],
  );

  const handleSelect = useCallback(
    async (selectedAddress: string) => {
      try {
        console.log('selectedAddress ->', selectedAddress);
        const results = await geocodeByAddress(selectedAddress);
        console.log('results ->', results);
        const latLng = await getLatLng(results[0]);
        console.log('latLng ->', latLng);
        setAddress(selectedAddress);
        externalOnChange?.(selectedAddress);
        const location: ILocation = {
          label: selectedAddress,
          value: {
            description: selectedAddress,
            formatted_address: results[0].formatted_address,
            place_id: results[0].place_id,
            plus_code: results[0].plus_code?.global_code,
            types: results[0].types,
            geometry: {
              location: latLng,
            },
          },
        };
        onSelect?.(location);
      } catch (error) {
        console.error('Error', error);
      }
    },
    [onSelect, externalOnChange],
  );

  const value = externalValue !== undefined ? externalValue : address;

  return (
    <PlacesAutocomplete
      value={value}
      debounce={300}
      onChange={handleChange}
      onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Flex
          position="relative"
          direction="column"
          w="100%"
          sx={{
            '@media (min-width: 768px)': { width: sxWidth || '310px' },
          }}>
          <Flex direction="column" w="100%">
            {label && (
              <Flex mb={1}>
                <FormLabel
                  htmlFor={id}
                  fontSize="sm"
                  color="primary.100"
                  fontWeight="medium"
                  m={0}>
                  {label}
                </FormLabel>
              </Flex>
            )}
            <Flex w="100%">
              <InputGroup>
                <Input
                  {...getInputProps({
                    placeholder,
                    id,
                  })}
                  bg={bgInput}
                  color={textColorPrimary}
                  fontWeight="400"
                  variant="main"
                  fontSize="md"
                  pl="10px"
                  pr="10px"
                  h="50px"
                  maxH="50px"
                  w="100%"
                  _active={{
                    borderColor: 'brand.600',
                  }}
                  _focus={{
                    borderColor: 'brand.600',
                  }}
                  _placeholder={{
                    fontWeight: '400',
                    color: 'secondaryGray.600',
                  }}
                  {...inputProps}
                />
              </InputGroup>
            </Flex>
          </Flex>

          {(loading || suggestions.length > 0) && (
            <Flex
              position="absolute"
              top="100%"
              left={0}
              right={0}
              mt={1}
              direction="column"
              boxShadow="md"
              borderRadius="md"
              overflow="hidden"
              bg={suggestionBg}
              zIndex={1000}>
              {loading && (
                <Flex p={4}>
                  <Text>Loading...</Text>
                </Flex>
              )}
              <Flex direction="column" w="100%">
                {suggestions.map((suggestion) => {
                  const style = {
                    padding: '10px 20px',
                    cursor: 'pointer',
                    backgroundColor: suggestion.active
                      ? suggestionBgActive
                      : suggestionBg,
                    width: '100%',
                  };

                  return (
                    <Flex
                      key={suggestion.placeId}
                      w="100%"
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                      _hover={{
                        bg: suggestionBgActive,
                      }}>
                      <Text>{suggestion.description}</Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
