import { Button, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPercent } from 'react-icons/lu';
import useVatSettings from '../hooks/useVatSettings';

interface VatSettingsProps {
  vatPercent: number;
}

const VatSettings = ({ vatPercent }: VatSettingsProps) => {
  const { t } = useTranslation();
  const { vatPercentValue, handleChange, handleSetVat, loading } =
    useVatSettings();

  useEffect(() => {
    handleChange({
      target: { value: vatPercent.toString(), name: 'vatPercent' },
    } as React.ChangeEvent<HTMLInputElement>);
  }, [vatPercent, handleChange]);
  return (
    <Card>
      <Flex flexDirection="column" gap={6}>
        <VStack spacing={0} gap={3} align="stretch">
          <Text
            variant="cardSectionTitle"
            fontSize={'sm'}
            fontWeight={600}
            color="#1B2037">
            {t('finance_settings.vat.vat_settings')}
          </Text>
          <Text
            variant="detailInfoValue"
            fontSize={'sm'}
            fontWeight={400}
            color="#999EA9">
            {t('finance_settings.vat.vat_settings_description')}
          </Text>
        </VStack>
        <InputField
          flex="1"
          w={{ base: '100%', md: '310px' }}
          h="50px"
          type="number"
          min={0}
          max={100}
          step={0.01}
          placeholder="0.00"
          label={t('finance_settings.vat.label')}
          name="vatPercent"
          value={vatPercentValue}
          onChange={handleChange}
          iconRight={<Icon as={LuPercent} strokeWidth={2} color="#9E9E9E" />}
        />
        <Button
          variant="h1cta"
          w="100%"
          py="0px !important"
          height="44px !important"
          onClick={handleSetVat}
          type="button"
          fontSize="14px"
          maxW={{ base: '100%', md: '240px' }}
          isDisabled={loading}>
          {t('finance_settings.vat.set')}
        </Button>
      </Flex>
    </Card>
  );
};

export default VatSettings;
