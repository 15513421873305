import { Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { setClientDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { formatCurrency } from 'helpers/formatNumber';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IClient, IClientContact, IPayment } from 'services/@types';
import ChosenClient from 'views/main/managment/events/createUpdateEvent/ChosenClient';
import DropdownField from '../../../../../../components/fields/DropdownField';
import InputField from '../../../../../../components/fields/InputField';

interface PaymentDetailBodyProps {
  currentPaymentProps: Partial<IPayment>;
  payment: IPayment;
  paymentsTotal: number;
  dueAmount: number;
  onSave: () => void;
  isSaving: boolean;
  onChange: (key: keyof IPayment, value: any) => void;
}
export const PaymentDetailBody = ({
  currentPaymentProps,
  payment,
  paymentsTotal,
  dueAmount,
  onChange,
  onSave,
  isSaving,
}: PaymentDetailBodyProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [inputValuePaidAmount, setInputValuePaidAmount] = useState<string>(
    formatCurrency(currentPaymentProps?.paidAmount),
  );
  const paymentMethods = useMemo(
    () => [
      { id: 'card', label: t('event_finance.payment_details_modal.card') },
      { id: 'cash', label: t('event_finance.payment_details_modal.cash') },
      { id: 'cheque', label: t('event_finance.payment_details_modal.cheque') },
      {
        id: 'transfer',
        label: t('event_finance.payment_details_modal.transfer'),
      },
    ],
    [t],
  );

  const currencies = useMemo(
    () => [
      { id: 'ILS', label: 'ILS' },
      { id: 'USD', label: 'USD' },
      { id: 'EUR', label: 'EUR' },
    ],
    [],
  );

  const paymentStatuses = useMemo(
    () => [
      { id: 'paid', label: t('event_finance.payment_details_modal.paid') },
      { id: 'unpaid', label: t('event_finance.payment_details_modal.unpaid') },
    ],
    [t],
  );
  const clientsList = useMemo(() => {
    if (!currentPaymentProps?.clients) return [];
    const privateClients = [
      ...(currentPaymentProps?.clients
        ?.filter((c) => (c.client as IClient)?.type === 'private')
        ?.map((c) => c.client) ?? []),
    ];

    const businessContactClients = [
      ...(currentPaymentProps?.clients
        ?.filter((c) => (c.client as IClient)?.type === 'business')
        ?.map((c) => c.contacts) ?? []),
    ].flatMap((c) => c as IClientContact[]);

    return [...privateClients, ...businessContactClients];
  }, [currentPaymentProps?.clients]);

  useEffect(() => {
    if (!currentPaymentProps || !inputValuePaidAmount) return;
    setInputValuePaidAmount(formatCurrency(currentPaymentProps?.paidAmount));
  }, [currentPaymentProps, inputValuePaidAmount]);

  if (!currentPaymentProps) return;

  const {
    dueDate,
    paymentMethod,
    cardLastDigits,
    isPaid,
    paidAmount,
    paymentIndex,
    comment,
  } = currentPaymentProps;

  console.log(
    'currentPaymentProps, comment',
    currentPaymentProps,
    'comment ->',
    comment,
  );
  return (
    <VStack spacing={2} align="stretch">
      <Text fontSize="sm" fontWeight="bold">
        {t('event_finance.payment_details_modal.payment_details')}
      </Text>

      <VStack spacing={4} align="stretch">
        <HStack>
          <Flex>
            <InputField
              bg="blackAlpha.100"
              fontSize="sm"
              label={t('event_finance.payment_details_modal.due_date')}
              value={moment(dueDate).format('DD/MM/YYYY')}
              isDisabled
            />
          </Flex>
          <Flex>
            <InputField
              bg={
                paidAmount > 0 && paidAmount < dueAmount
                  ? 'red.200'
                  : 'blackAlpha.100'
              }
              fontSize="sm"
              color={
                paidAmount > 0 && paidAmount < dueAmount ? 'red.600' : 'black'
              }
              label={t('event_finance.payment_details_modal.due_amount')}
              value={formatCurrency(dueAmount)}
              isDisabled
            />
          </Flex>
          <Flex>
            <InputField
              bg="blackAlpha.100"
              fontSize="sm"
              flex={1}
              label={t('event_finance.payment_details_modal.payment_part')}
              value={t(
                'event_finance.payment_details_modal.payment_part_number_placeholder',
                {
                  paymentIndex: paymentIndex + 1,
                  paymentsTotal: paymentsTotal,
                },
              )}
              isDisabled
            />
          </Flex>
        </HStack>
        <HStack align="end">
          <Flex flex={1}>
            <DropdownField
              id="status"
              tooltipLabel={t(
                'event_finance.payment_details_modal.status_tooltip',
              )}
              isDisabled={payment.isPaid}
              menuButton={{
                bg: payment.isPaid ? 'blackAlpha.100' : 'white',
              }}
              label={t('event_finance.payment_details_modal.status')}
              options={paymentStatuses}
              selectedOptions={isPaid ? ['paid'] : ['unpaid']}
              flex={1}
              closeOnSelect={true}
              onSelected={(value: any | { id: string; label: string }) => {
                onChange('isPaid', value?.id === 'paid');
              }}
              placeholder={
                isPaid
                  ? t('event_finance.payment_details_modal.paid')
                  : t('event_finance.payment_details_modal.unpaid')
              }
            />
          </Flex>
          <Flex flex={1}>
            <InputField
              flex={1}
              w="100%"
              isDisabled={!isPaid || payment.isPaid}
              bg={!isPaid || payment.isPaid ? 'blackAlpha.100' : 'white'}
              label={t('event_finance.payment_details_modal.amount')}
              value={inputValuePaidAmount || paidAmount}
              onBlur={(e) => {
                setInputValuePaidAmount(formatCurrency(paidAmount));
                onChange('paidAmount', Number(paidAmount));
              }}
              onFocus={(e) => {
                setInputValuePaidAmount(undefined);
                onChange('paidAmount', Number(paidAmount));
              }}
              onChange={(e) =>
                onChange(
                  'paidAmount',
                  Number(e.target.value?.replaceAll(/[^0-9.]/g, '')),
                )
              }
              rightElement={
                <DropdownField
                  id="currency"
                  closeOnSelect={true}
                  options={currencies}
                  value="ILS"
                  onSelected={(value: any) => onChange('currency', value)}
                  variant="unstyled"
                  width="70px"
                />
              }
            />
          </Flex>
          <Flex flex={1}>
            <DropdownField
              onSelected={({ id }: any) => onChange('paymentMethod', id)}
              closeOnSelect={true}
              isDisabled={!isPaid || payment.isPaid}
              id="paymentMethod"
              flex={1}
              menuButton={{
                bg: !isPaid || payment.isPaid ? 'blackAlpha.100' : 'white',
              }}
              w="100%"
              placeholder={
                paymentMethod
                  ? t(`event_finance.payment_details_modal.${paymentMethod}`)
                  : t('event_finance.payment_details_modal.payment_method')
              }
              options={paymentMethods}
              selectedOptions={paymentMethod ? [paymentMethod] : []}
              label={t('event_finance.payment_details_modal.payment_method')}
            />
          </Flex>
        </HStack>

        {paymentMethod === 'card' && (
          <InputField
            label={t('event_finance.payment_details_modal.card_digits')}
            value={cardLastDigits}
            isDisabled={payment.isPaid}
            bg={payment.isPaid ? 'blackAlpha.100' : 'white'}
            placeholder={t(
              'event_finance.payment_details_modal.card_digits_placeholder',
            )}
            type="number"
            maxLength={4}
            letterSpacing={cardLastDigits?.length !== 4 ? 0 : '2px'}
            onChange={(e) =>
              onChange(
                'cardLastDigits',
                e.target.value?.length > 4
                  ? e.target.value.slice(0, 4)
                  : e.target.value,
              )
            }
          />
        )}

        <InputField
          label={t('event_finance.payment_details_modal.comment')}
          value={comment || ''}
          onChange={(e) => onChange('comment', e.target.value)}
          placeholder={t('event_finance.payment_details_modal.enter_comment')}
        />
      </VStack>

      <Text fontSize="sm" fontWeight="bold">
        {t('event_finance.payment_details_modal.client_details')}
      </Text>
      <Button
        variant="h1cta"
        onClick={() =>
          dispatch(
            setClientDialog({
              item: {
                chosenClients: currentPaymentProps?.clients || [],
              },
              onConfirm: (selection: any) => {
                onChange('clients', selection);
              },
            }),
          )
        }>
        {t('event_finance.payment_details_modal.select_client')}
      </Button>
      <Flex w="100%" gap={2} wrap="wrap" pb={10}>
        {clientsList?.map((c, index) => (
          <ChosenClient
            key={index}
            client={
              'type' in (c as IClient) && (c as IClient)
                ? (c as IClient)
                : undefined
            }
            contact={
              'type' in (c as IClientContact) && (c as IClientContact)
                ? (c as IClientContact)
                : undefined
            }
            removeClient={(clientId, contactId) => {
              let newClients: any[] = [];
              if (contactId) {
                // remove that contact from a client
                newClients = currentPaymentProps.clients?.map((ic) => {
                  if (contactId) {
                    // remove that contact from a client
                    return {
                      ...ic,
                      contacts: (ic.contacts as IClientContact[])?.filter(
                        (c: IClientContact) =>
                          (c as IClientContact)?.id !== contactId,
                      ),
                    };
                  }
                  return (ic.client as IClient)?.id !== clientId;
                });
              } else if (clientId) {
                newClients = currentPaymentProps?.clients?.filter(
                  (c) => (c.client as IClient)?.id !== clientId,
                );
              }
              onChange('clients', newClients);
            }}
          />
        ))}
      </Flex>
      <Button
        variant="h4"
        textDecorationLine="underline"
        onClick={() => {
          /* Handle generate receipt */
        }}>
        {t('event_finance.payment_details_modal.generate_receipt')}
      </Button>
      <Button
        alignSelf={'center'}
        variant="h1cta"
        onClick={onSave}
        w="fit-content"
        px={20}
        isLoading={isSaving}>
        {currentPaymentProps.isPaid && !payment.isPaid
          ? t('event_finance.payment_details_modal.mark_as_paid')
          : t('event_finance.payment_details_modal.save')}
      </Button>
    </VStack>
  );
};
