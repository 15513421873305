import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { fetchUser } from 'contexts/redux/user/userSlice';
import { motion } from 'framer-motion';
import { memo, useLayoutEffect, useState } from 'react';
import { LuX } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { User } from 'services/@types';
import { isValidEmail } from 'utils/isValidEmail';

const tagsVariants = {
  pop: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
};

const SelectedUser = (props: any) => {
  const { id, handleRemove } = props;
  const [user, setUser] = useState<User | null>(null);
  const dispatch = useDispatch<any>();

  useLayoutEffect(() => {
    if (id) {
      if (isValidEmail(id)) {
        setUser({
          email: id,
          firstName: id, // set the email as the first name
          lastName: '',
          password: '',
          role: 'user',
        });
      } else {
        const fetchGuest = async () => {
          const res = await dispatch(fetchUser(id));
          if (res.payload) {
            setUser(res.payload);
          }
        };
        fetchGuest();
      }
    }
  }, [id, dispatch]);

  if (!id) {
    return;
  }
  return (
    <motion.div
      animate={'pop'}
      variants={tagsVariants}
      initial={{ scale: 0.6, opacity: 0 }}
      exit={{ scale: 0.6, opacity: 0 }}>
      <Button variant="assignSelectedOptions" onClick={() => handleRemove(id)}>
        <HStack>
          <Text>
            {user?.firstName} {user?.lastName}
          </Text>
          <Icon as={LuX} />
        </HStack>
      </Button>
    </motion.div>
  );
};

export default memo(SelectedUser);
