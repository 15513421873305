import { HStack, Text, VStack } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { Supplier } from 'services/@types';

const SupplierSearchItem = ({ supplier }: { supplier: Supplier }) => {
  return (
    <>
      <AuthImage
        w="60px"
        h="60px"
        borderRadius="50%"
        path={undefined}
        isAvatar
        name={supplier.name}
        bg="gray.200"
        mr="26px"
      />
      <VStack spacing={0} alignItems="flex-start">
        <HStack
          spacing={0}
          fontSize="16px"
          fontWeight={700}
          color="black"
          gap={1}
          letterSpacing={'-2%'}>
          <Text variant="assingSuggestions" fontWeight={700}>
            {supplier.name}
          </Text>
          <Text color="#AA915D">{supplier.internalNotes || ''}</Text>
        </HStack>
      </VStack>
    </>
  );
};

export default SupplierSearchItem;
