import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { IContract } from 'services/@types';

export default function SaveAsTemplateDialog(props: {
  contract: IContract;
  isOpen: boolean;
  onClose: () => void;
  handleCreateContract: (filters: any) => void;
}) {
  const { isOpen, onClose, handleCreateContract, contract } = props;
  const { t } = useTranslation();
  const [templateData, setTemplateData] = useState({
    templateTitle: '',
    templateDescription: '',
  });
  const queryClient = useQueryClient();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleSave = useCallback(() => {
    const templatePayload: any = {
      ...contract,
      isTemplate: true,
      isDraft: false,
      ...templateData,
    };
    // delete specific fields that are not needed for the template
    delete templatePayload.eventId;
    templatePayload.clients = [];
    delete templatePayload.contractDate;
    delete templatePayload.quoteId;
    delete templatePayload.sentTo;
    templatePayload.recipients = [{}];

    handleCreateContract(templatePayload);
    onClose();
    setTemplateData({
      templateTitle: '',
      templateDescription: '',
    });
    queryClient.invalidateQueries({ queryKey: ['contracts', 'templates'] });
  }, [contract, templateData, handleCreateContract, onClose, queryClient]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="24px"
        minW={{ base: '100%', md: '660px' }}
        borderRadius="30px"
        gap="24px">
        <Text variant="createQuoteTitle">
          {t('create_contract.save_as_template')}
        </Text>
        <InputField
          label={t('create_contract.document_title')}
          placeholder={t('create_contract.document_title_placeholder')}
          onChange={handleChange}
          name="templateTitle"
          value={templateData.templateTitle}
        />
        <TextField
          label={t('create_contract.template_description')}
          placeholder={t('create_contract.template_description_placeholder')}
          onChange={handleChange}
          name="templateDescription"
          value={templateData.templateDescription}
          minH="157px"
        />
        <Button
          w="320px"
          variant="h1cta"
          alignSelf="center"
          onClick={handleSave}>
          {t('create_contract.save')}
        </Button>
      </ModalContent>
    </Modal>
  );
}
