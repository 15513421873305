import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, IconButton } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import usePreviewContract from '../hooks/usePreviewContract';

const PreviewContract = () => {
  const { i18n } = useTranslation();
  const {
    goBack,
    generatedContract,
    contractPreviewRef,
    handleGenerateContractPDF,
    forcedDesktopView,
  } = usePreviewContract();

  return (
    <Flex
      w="100%"
      pt={{ base: '240px', md: '80px', xl: '80px' }}
      pb={{ base: '20px', md: '40px' }}
      flexDirection="column"
      gap={4}>
      <Flex position="relative" w="full" h="full">
        {/* <Box
          position="absolute"
          top="0"
          left="0"
          w="full"
          zIndex={100}
          borderRadius="30px"
          h="full"
          bg="brand.400"
          color="white"
          display={isGeneratingPDF ? 'flex' : 'none'}
          justifyContent="center"
          alignItems="center">
          <Flex w="full" h="full" justify="center" align="center">
            <Spinner size="xl" />
          </Flex>
        </Box> */}
        <Flex
          gap={2}
          position="absolute"
          zIndex={100}
          top="20px"
          left={i18n.dir() === 'rtl' ? '20px' : 'auto'}
          right={i18n.dir() === 'rtl' ? 'auto' : '20px'}>
          <IconButton
            flexShrink={0}
            aria-label="Download Contract"
            icon={<Icon as={FiDownload} />}
            onClick={() => handleGenerateContractPDF()}
          />
          <IconButton
            flexShrink={0}
            aria-label="Go Back"
            icon={<Icon as={CloseIcon} width="10px" height="10px" />}
            onClick={goBack}
          />
        </Flex>
        <Card
          flexShrink={0}
          w={forcedDesktopView.state ? '1016px' : 'full'}
          h="full"
          flexDirection="column"
          overflow={'auto'}
          borderRadius={{
            base: forcedDesktopView.state ? '30px' : 0,
            md: '30px',
          }}
          gap={{ base: forcedDesktopView.state ? 5 : '24px', md: 5 }}
          ref={contractPreviewRef}>
          <Box>
            <Box
              w="full"
              h="full"
              dangerouslySetInnerHTML={{ __html: generatedContract }}
            />
          </Box>
        </Card>
      </Flex>
    </Flex>
  );
};

export default PreviewContract;
