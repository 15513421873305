import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  selectSelectedDate,
  setSelectedDate,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import { closeScheduleAnchorMeetingDialog } from 'contexts/redux/dialog/dialogsSlice';
import { getEvent } from 'contexts/redux/event/eventSlice';
import { createMeeting } from 'contexts/redux/meeting/meetingSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { fetchUser, fetchUsers } from 'contexts/redux/user/userSlice';
import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IEvent, IMeeting, UrlItem, User } from 'services/@types';
import MeetingService from 'services/meeting.api';
import { requiredFields } from 'views/main/managment/meeting/createMeeting/hooks/useCreateUpdateMeeting';

export interface DisplayingGuest {
  avatar: UrlItem | undefined;
  email: string;
  label: string;
  value: string;
}

export default function useAnchorMeetingForm() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const myUser = useMyUser();
  const selectedDate = useSelector(selectSelectedDate);
  const { scheduleAnchorMeetingDialog: dialog } = useSelector(
    (state: RootState) => ({
      scheduleAnchorMeetingDialog: state.dialogs.scheduleAnchorMeetingDialog,
    }),
  );

  const { loading } = useSelector((state: RootState) => state.meetings);
  const { calendars } = useSelector((state: RootState) => state.calendars);
  const [currentGuestInput, setCurrentGuestInput] = useState<string>('');
  const [errorList, setErrorList] = useState<string[]>([]);
  const [anchorMeeting, setAnchorMeeting] = useState<Partial<IMeeting>>({
    startDate: selectedDate.start || new Date(),
    endDate: selectedDate.end || new Date(),
    guests: [],
    isAnchorMeeting: true,
  });
  const [displayingGuest, setDisplayingGuest] = useState<DisplayingGuest[]>([]);

  const { data: event } = useQuery({
    queryKey: ['event', dialog?.item?.eventId],
    queryFn: () => getEvent(dialog?.item?.eventId),
    enabled: !!dialog?.item?.eventId,
  });

  const { data: meetings } = useQuery({
    queryKey: ['meetings', dialog?.item?.eventId],
    queryFn: () =>
      MeetingService.getMeetings({
        eventId: dialog?.item?.eventId,
        startDate: new Date(),
        endDate: new Date(),
      }),
    enabled: !!dialog?.item?.eventId,
  });

  const selectedAnchorMeetingType = useMemo(() => {
    return anchorMeeting?.anchorMeetingType
      ? [`event.anchor_meetings.${anchorMeeting?.anchorMeetingType}`]
      : [];
  }, [anchorMeeting?.anchorMeetingType]);

  const selectedRemind = useMemo(
    () =>
      anchorMeeting?.reminder
        ? [`create_meeeting.remind_${anchorMeeting?.reminder}`]
        : [],
    [anchorMeeting?.reminder],
  );

  const handleSelectGuest = useCallback(
    async (value: string) => {
      const data = await dispatch(fetchUser(value));
      const userId = (data.payload as User)?.id;
      if (userId) {
        setAnchorMeeting((prev) => ({
          ...prev,
          guests: [...prev.guests, userId],
        }));
      }
      setCurrentGuestInput('');
    },
    [dispatch],
  );

  const handleClose = useCallback(() => {
    setAnchorMeeting({
      startDate: selectedDate.start,
      endDate: selectedDate.end,
      guests: [],
      isAnchorMeeting: true,
    });
    setErrorList([]);
    dispatch(closeScheduleAnchorMeetingDialog());
  }, [dispatch, selectedDate]);

  const handleChangeAnchorMeeting = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (errorList.includes(name)) {
        setErrorList((prev) => prev.filter((field) => field !== name));
      }
      if (e.target.name === 'startTime' || e.target.name === 'endTime') {
        const [hours, minutes] = e.target.value.split(':');
        const dateToUpdate =
          e.target.name === 'startTime' ? 'startDate' : 'endDate';
        const baseDate = anchorMeeting[dateToUpdate];
        const newDate = moment(baseDate)
          .set('hour', parseInt(hours))
          .set('minute', parseInt(minutes))
          .toDate();
        dispatch(
          setSelectedDate({
            start: newDate,
            end: moment(newDate).add(30, 'minutes').toDate(),
          }),
        );
        setAnchorMeeting((prev) => ({
          ...prev,
          [dateToUpdate]: newDate,
        }));
        return;
      } else if (e.target.name === 'startDate') {
        dispatch(
          setSelectedDate({
            start: new Date(e.target.value),
            end: moment(e.target.value).add(30, 'minutes').toDate(),
          }),
        );
      }
      setAnchorMeeting((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [errorList, dispatch, anchorMeeting],
  );

  const handleChangeRemind = useCallback(
    (e: string) => {
      const split = e.split('_');
      const event = {
        target: {
          value: split[split.length - 1],
          name: 'reminder',
        },
      };
      handleChangeAnchorMeeting(event as ChangeEvent<HTMLInputElement>);
    },
    [handleChangeAnchorMeeting],
  );

  const handleRemoveGuest = (id: string) => {
    setAnchorMeeting((prev) => ({
      ...prev,
      guests: prev.guests.filter((item) => item !== id),
    }));
  };

  const handleChangeAnchorMeetingType = useCallback(
    (e: string) => {
      const split = e.split('.');
      const event = {
        target: {
          value: split[split.length - 1],
          name: 'anchorMeetingType',
        },
      };
      handleChangeAnchorMeeting(event as ChangeEvent<HTMLInputElement>);
    },
    [handleChangeAnchorMeeting],
  );

  const handleChangeCurrentGuestInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setCurrentGuestInput(e.target.value),
    [],
  );

  const validateAnchorMeeting = useCallback(() => {
    let result = requiredFields.filter((field) => {
      const meetingField = anchorMeeting[field as keyof IMeeting];

      const ress =
        !anchorMeeting || field === 'location'
          ? !(meetingField as any)?.value
          : !meetingField;
      return ress;
    });

    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [anchorMeeting]);

  const handleCreateAnchorMeeting = useCallback(async () => {
    const isError = validateAnchorMeeting();
    if (isError) {
      return;
    }
    // create anchor meeting
    const newMeeting: any = await dispatch(
      createMeeting({
        ...anchorMeeting,
        eventId: dialog?.item?.eventId,
      } as IMeeting),
    );
    if (newMeeting?.error) {
      toast({
        title: newMeeting?.payload?.response?.data?.message,
        variant: 'error',
        position: 'top-right',
      });
      return;
    }
    toast({
      title: t('create_meeting.success'),
      variant: 'main',
      position: 'top-right',
    });
    if (newMeeting?.payload?.eventId) {
      await queryClient.invalidateQueries({
        queryKey: ['todayMeetings', newMeeting?.payload?.eventId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['anchorMeetings', newMeeting?.payload?.eventId],
      });
      await queryClient.invalidateQueries({
        queryKey: [`getMeetings-${newMeeting?.payload?.eventId}`],
      });
      await queryClient.invalidateQueries({
        queryKey: ['meetings', newMeeting?.payload?.eventId],
      });
    }
    handleClose();
  }, [
    anchorMeeting,
    validateAnchorMeeting,
    dispatch,
    queryClient,
    t,
    toast,
    dialog?.item?.eventId,
    handleClose,
  ]);

  // Update meeting times when selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      const newStartDate = selectedDate;

      setAnchorMeeting((prev) => {
        // Keep the same date but update the time
        const updatedStartDate = moment(newStartDate.start)
          .hours(moment(prev?.startDate || newStartDate.start).hours())
          .minutes(moment(prev?.startDate || newStartDate.start).minutes())
          .toDate();

        const updatedEndDate = moment(updatedStartDate).add(1, 'hour').toDate();

        return {
          ...prev,
          startDate: updatedStartDate,
          endDate: updatedEndDate,
        };
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (currentGuestInput.length < 1 || !currentGuestInput) {
      return;
    }
    const fetchSeats = async () => {
      const users: any = await dispatch(
        fetchUsers({
          name: currentGuestInput,
          limit: 5,
        }),
      );

      if (users?.payload?.results) {
        setDisplayingGuest(
          users.payload.results
            .filter(
              (user: User) =>
                !anchorMeeting.guests.find((a: any) => a === user?.id),
            )
            .filter((user: User) => user.id !== myUser?.id)
            .map((user: User) => ({
              avatar: user.media?.find(
                (media: UrlItem) => media.type === 'avatar',
              ),
              email: user.email,
              label: `${user.firstName} ${user.lastName}`,
              value: user?.id,
            })),
        );
      }
    };
    fetchSeats();
  }, [currentGuestInput, dispatch, anchorMeeting?.guests, myUser?.id]);

  // set default calendar to meetings if it's create meeting
  useEffect(() => {
    if (!dialog?.item?.eventId) {
      const calendarId = calendars.find(
        (calendar) => calendar.name === 'meetings',
      )?.id;

      if (calendarId) {
        setAnchorMeeting((prev) => ({
          ...prev,
          calendarId: calendarId,
        }));
      }
    }
  }, [calendars, dialog?.item?.eventId]);

  useEffect(() => {
    if (dialog?.item?.anchorMeetingType) {
      setAnchorMeeting((prev) => ({
        ...prev,
        anchorMeetingType: dialog?.item?.anchorMeetingType,
      }));
    }
  }, [dialog?.item?.anchorMeetingType]);

  return {
    handleClose,
    dialog,
    event: event as Partial<IEvent>,
    meetings: meetings?.results || [],
    anchorMeeting,
    handleChangeAnchorMeeting,
    handleCreateAnchorMeeting,
    selectedAnchorMeetingType,
    handleChangeAnchorMeetingType,
    errorList,
    handleChangeRemind,
    selectedRemind,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleRemoveGuest,
    handleChangeCurrentGuestInput,
    loading,
  };
}
