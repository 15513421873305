// Chakra imports
import {
  Flex,
  FormLabel,
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
// Custom components
import SwitchField from 'components/fields/SwitchField';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { ChangeEvent } from 'react';

export default function Default(props: {
  id?: string;
  label?: string;
  extra?: string;
  textAreaAs?: any;
  iconRight?: any;
  iconProps?: any;
  iconPointerEvents?: 'none' | 'auto';
  tooltipLabel?: string;
  tooltipTopLabel?: string;
  switchProps?: {
    id: string;
    label: string;
    isChecked: boolean;
    onChange: () => void;
  };
  iconLeft?: any;
  placeholder?: string;
  errorLabel?: string;
  readOnly?: boolean;
  [x: string]: any;
}) {
  const {
    mb,
    id,
    tooltipLabel,
    tooltipTopLabel,
    label,
    textAreaAs,
    maxLength = 4000,
    extra,
    placeholder,
    iconPointerEvents,
    iconLeft,
    switchProps,
    iconRight,
    iconProps,
    labelProps,
    errorLabel,
    extraStyles,
    readOnly,
    textareaProps,
    ...rest
  } = props;
  // Chakra Color Mode
  const { i18n } = useTranslation();
  const textColorPrimary = useColorModeValue('primary.100', 'white'); // use the same like on input field
  const borderPrimary = useColorModeValue('brand.600', 'brand.600');
  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure();
  const _iconRight = i18n.dir() === 'ltr' ? iconRight : iconLeft;
  const _iconLeft = i18n.dir() === 'ltr' ? iconLeft : iconRight;
  // handle trimming
  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      e.target.value = e.target.value.trimStart();
      props.onChange?.(e);
    },
    [props],
  );
  return (
    <Flex direction="column" mb={mb || '0px'} {...rest}>
      <FormLabel
        display="flex"
        htmlFor={id}
        fontSize="sm"
        mb={1}
        color={textColorPrimary}
        fontWeight="500"
        _hover={{ cursor: 'pointer' }}
        {...labelProps}>
        {label}
        <Text fontSize="sm" fontWeight="normal" ms="2px" {...extraStyles}>
          {extra}
        </Text>
        {tooltipTopLabel && (
          <Tooltip
            variant="business"
            hasArrow
            isOpen={isOpenTooltip}
            label={tooltipTopLabel}
            placement="top">
            <span>
              <Icon
                onMouseEnter={onOpenTooltip}
                onMouseLeave={onCloseTooltip}
                onClick={onToggleTooltip}
                as={MdInfoOutline}
                color="brand.400"
                fontSize="24px"
                cursor="pointer"
                ms="4px"
                mt="-2px"
                aria-label={''}
              />
            </span>
          </Tooltip>
        )}
        {switchProps && (
          <Flex>
            <SwitchField {...switchProps} />
          </Flex>
        )}
      </FormLabel>
      <InputGroup>
        <Textarea
          id={id}
          placeholder={placeholder}
          h="44px"
          as={textAreaAs}
          height="100%"
          resize={'none'}
          maxH="44px"
          variant={'main'}
          maxLength={maxLength}
          color={textColorPrimary}
          // bg={bgPrimary}
          border="1px solid"
          borderRadius="16px"
          borderColor={'secondaryGray.100'}
          _placeholder={{ color: 'secondaryGray.500' }}
          _focus={{ borderColor: borderPrimary }}
          {...rest}
          {...textareaProps}
          readOnly={!!readOnly}
          onChange={onChange}
        />
        {_iconRight && (
          <InputRightElement
            pointerEvents={iconPointerEvents || 'none'}
            color="secondaryGray.500"
            children={_iconRight}
            alignItems={'end'}
            h="100%"
            py={2}
            {...iconProps}
          />
        )}
        {_iconLeft && (
          <InputLeftElement
            pointerEvents={iconPointerEvents || 'none'}
            color="secondaryGray.500"
            children={_iconLeft}
            alignItems={'end'}
            h="100%"
            py={2}
            {...iconProps}
          />
        )}
        {tooltipLabel && (
          <InputRightElement>
            <Tooltip
              variant="business"
              hasArrow
              isOpen={isOpenTooltip}
              label={tooltipLabel}
              placement="top">
              <span>
                <Icon
                  onMouseEnter={onOpenTooltip}
                  onMouseLeave={onCloseTooltip}
                  onClick={onToggleTooltip}
                  as={MdInfoOutline}
                  color="brand.400"
                  fontSize="24px"
                  cursor="pointer"
                  ms="2px"
                  aria-label={''}
                />
              </span>
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>
      {errorLabel && (
        <Text fontSize="xs" fontWeight={'semibold'} color="red.500" mt="2px">
          {errorLabel}
        </Text>
      )}
    </Flex>
  );
}
