import { Flex } from '@chakra-ui/react';
import { CalendarMedium } from './components/CalendarMedium';
import EventsAndMeeting from './components/EventsAndMeeting';
import TaskList from './components/TaskList';
import Tracker from './components/Tracker';
import UpcomingEvents from './components/UpcomingEvents';

export default function Dashboard() {
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      height="100%"
      w={{ base: '100%' }}
      pt={{ base: '190px', md: '80px', xl: '80px' }}>
      <Flex direction="column" w={{ lg: '100%' }}>
        <Flex gap={5} w="100%" pb={{ base: '40px', md: '60px' }}>
          <Flex gap={5} w="100%" wrap="wrap" maxW="1920px">
            <Flex direction={{ base: 'column', lg: 'row' }} w="100%" gap="20px">
              <CalendarMedium
                cardStyles={{
                  minH: '580px',
                  maxH: '580px',
                  w: 'auto',
                  flex: '1 1 70%',
                }}
              />
              <EventsAndMeeting
                cardStyles={{ minH: '580px', w: 'auto', flex: '1 1 30%' }}
              />
            </Flex>
            <Flex w="100%" gap="20px" direction={{ base: 'column', lg: 'row' }}>
              <TaskList
                cardStyles={{
                  minH: '580px',
                  maxH: '580px',
                  w: 'auto',
                  flex: '1',
                  minW: 'auto',
                  maxW: 'auto',
                }}
                showTaskHistory
              />
              <Tracker
                cardStyles={{
                  minH: '580px',
                  maxH: '580px',
                  borderRadius: { base: '0px', md: '30px' },
                  minW: 'auto',
                  maxW: 'auto',
                  w: 'auto',
                  flex: '1',
                }}
              />
            </Flex>
            <UpcomingEvents />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
