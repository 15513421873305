import { Badge, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';

interface TagBadgeProps {
  label: string;
  onClick?: () => void;
}

export default function TagBadge(props: TagBadgeProps) {
  const { label, onClick } = props;
  const { i18n } = useTranslation();
  return (
    <Badge
      borderRadius="10px"
      border="1px solid #0000004D"
      box-shadow="14px 17px 40px 4px #7090B014"
      display="flex"
      gap={2}
      dir={i18n.dir()}
      alignItems="center">
      {onClick && <Icon color="#000000" opacity={0.4} as={FaX} onClick={onClick} />}
      <Text variant="serviceTitle">{label}</Text>
    </Badge>
  );
}
