import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { queryClient } from 'App';
import { IMeeting } from 'services/@types';
import MeetingService from 'services/meeting.api';

export interface MeetingState {
  meetings: IMeeting[];
  currentMeeting: IMeeting | null;
  meeting: IMeeting | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: MeetingState = {
  meetings: [],
  meeting: null,
  loading: false,
  error: null,
  currentMeeting: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const createMeeting = createAsyncThunk(
  'tasks/createMeeting',
  async (params: IMeeting, { rejectWithValue }) => {
    try {
      const response = await MeetingService.createMeeting(params);
      if (response) {
        queryClient.invalidateQueries({
          queryKey: ['dashboardTrackedEvents'],
        });
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMeetings = createAsyncThunk(
  'tasks/getMeetings',
  async (
    params: { page?: number; limit?: number; dateAndTime?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await MeetingService.getMeetings(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMeeting = createAsyncThunk(
  'tasks/getMeeting',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await MeetingService.getMeeting(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateMeeting = createAsyncThunk(
  'tasks/updateMeeting',
  async (
    params: { id: string; updates: Partial<IMeeting> },
    { rejectWithValue },
  ) => {
    try {
      const response = await MeetingService.updateMeeting(
        params.id,
        params.updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMeeting = createAsyncThunk(
  'tasks/deleteMeeting',
  async (id: string, { rejectWithValue }) => {
    try {
      await MeetingService.deleteMeeting(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    setCurrentMeeting: (state, action) => {
      state.currentMeeting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMeeting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMeeting.fulfilled, (state, action) => {
      state.loading = false;
      state.meeting = action.payload;
      state.meetings.push(action.payload);
    });
    builder.addCase(createMeeting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getMeetings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMeetings.fulfilled, (state, action) => {
      state.loading = false;
      state.meetings = action.payload.results;
      state.pagination = action.payload;
    });
    builder.addCase(getMeetings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getMeeting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMeeting.fulfilled, (state, action) => {
      state.loading = false;
      state.currentMeeting = action.payload;
    });
    builder.addCase(getMeeting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(updateMeeting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMeeting.fulfilled, (state, action) => {
      state.loading = false;
      state.meeting = action.payload;
    });
    builder.addCase(updateMeeting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(deleteMeeting.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMeeting.fulfilled, (state, action) => {
      state.loading = false;
      state.meetings = state.meetings.filter(
        (task: IMeeting) => task.id !== action.payload,
      );
    });
    builder.addCase(deleteMeeting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setCurrentMeeting } = meetingSlice.actions;
export default meetingSlice.reducer;
